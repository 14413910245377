import {
  DropdownEntityType,
  FilterDataTypeEnum,
  ICustomSearchFilter,
  ISearchFilter,
} from '@gr/shared/models';
import { Formik } from 'formik';
import { useEffect, useState } from 'react';
import Checkbox from '../Form/Checkbox';
import { IDropdownValue } from '../Form/Dropdown';
import AllClientDropdown from '../Form/Dropdowns/AllClientDropdown';
import Autocomplete from '../Form/Dropdowns/Autocomplete';
import ClientDropdown from '../Form/Dropdowns/ClientDropdown';
import { TextInput } from '../Form/TextInput';
import { IColumn } from './types';

export interface IFilterDropdownOptions {
  [key: string]: IDropdownValue[];
}

interface IFiltersFormProps {
  columns: IColumn[];
  filters?: ISearchFilter[];
  customFilters?: ICustomSearchFilter[];
  dropdownOptions?: IFilterDropdownOptions;
  onSubmit: (values: any) => void;
}

const FiltersForm = ({ columns, filters, customFilters, dropdownOptions, onSubmit }: IFiltersFormProps) => {
  const [initialFormState, setInitialFormState] = useState<any>({});

  useEffect(() => {
    const newState = getInitialFormState();

    setInitialFormState(newState);
  }, [filters]);

  const getInitialFormState = () => {
    const initialState: any = {};

    for (let col of columns.concat(
      (customFilters ?? []).map((f) => {
        return {
          headerName: f.customName,
          fieldName: f.fieldName,
          fieldType: f.dataType,
        } as IColumn;
      })
    )) {
      const isDropdown =
        col.fieldType === FilterDataTypeEnum.ENUM || (dropdownOptions && dropdownOptions[col.fieldName]);

      const currentFilter = filters?.find((filter) => filter.fieldName === col.fieldName);
      const currentFilterValue = currentFilter?.value;
      const currentFilterDisplay = currentFilter?.displayValue;

      const filterDropdownValue =
        isDropdown && dropdownOptions
          ? dropdownOptions[col.fieldName].find((val) => val.value === currentFilterValue)
          : null;
      // Handle boolean separately
      if (col.fieldType === FilterDataTypeEnum.BOOLEAN) {
        initialState[col.fieldName] = currentFilterValue ?? false;
      } else {
        if (currentFilterValue) {
          switch (col.fieldType) {
            case FilterDataTypeEnum.ENUM || isDropdown:
              initialState[col.fieldName] = filterDropdownValue;
              break;
            case FilterDataTypeEnum.TYPEAHEAD:
              initialState[col.fieldName] = {
                label: currentFilterDisplay,
                value: currentFilterValue,
              } as IDropdownValue;
              break;
            default:
              initialState[col.fieldName] = isDropdown ? filterDropdownValue : currentFilterValue ?? '';
          }
        }
      }
    }
    return initialState;
  };

  const getTypeaheadDropdown = (
    type: DropdownEntityType,
    fieldName: string,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void,
    value: any
  ) => {
    switch (type) {
      case DropdownEntityType.CLIENT:
        return (
          <ClientDropdown
            onChange={(newValue) => {
              setFieldValue(fieldName, newValue);
            }}
            value={value ?? undefined}
            gridFilter={fieldName !== 'clientId'}
          />
        );
      case DropdownEntityType.ALLCLIENT:
        return (
          <AllClientDropdown
            onChange={(newValue) => {
              setFieldValue(fieldName, newValue);
            }}
            value={value ?? undefined}
            gridFilter={fieldName !== 'clientId'}
          />
        );
    }
  };

  const getInputForColumn = (
    col: IColumn,
    values: any,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void,
    handleChange: {
      (e: React.ChangeEvent<any>): void;
      <T = string | React.ChangeEvent<any>>(field: T): T extends React.ChangeEvent<any>
        ? void
        : (e: string | React.ChangeEvent<any>) => void;
    }
  ): JSX.Element => {
    const { fieldName, headerName: label } = col;
    const value = values[fieldName];

    const hasDropdownValues = dropdownOptions && dropdownOptions[fieldName];

    // If the user provided dropdown options for this field, regardless of type, it needs to be displayed as a dropdown
    if (col.fieldType !== FilterDataTypeEnum.TYPEAHEAD && hasDropdownValues) {
      return (
        <Autocomplete
          label={col.headerName}
          value={value ?? null}
          onChange={(newValue) => {
            setFieldValue(fieldName, newValue);
          }}
          options={dropdownOptions ? dropdownOptions[fieldName] : []}
        />
      );
    }

    switch (col.fieldType) {
      case FilterDataTypeEnum.STRING: {
        return <TextInput name={fieldName} value={value ?? ''} label={label} onChange={handleChange} />;
      }

      case FilterDataTypeEnum.NUMBER: {
        return <TextInput name={fieldName} value={value ?? ''} label={label} onChange={handleChange} />;
      }

      case FilterDataTypeEnum.BOOLEAN: {
        return (
          <Checkbox id={fieldName} name={fieldName} label={label} checked={value ?? false} onChange={handleChange} />
        );
      }

      case FilterDataTypeEnum.TYPEAHEAD: {
        // Right now we just have the client dropdown. Additional dropdowns can be added by field name.
        return getTypeaheadDropdown(col.dropdownType!, fieldName, setFieldValue, value);
      }

      case FilterDataTypeEnum.ENUM || hasDropdownValues: {
        return (
          <Autocomplete
            label={col.headerName}
            value={value ?? null}
            onChange={(newValue) => {
              setFieldValue(fieldName, newValue);
            }}
            options={dropdownOptions ? dropdownOptions[fieldName] : []}
          />
        );
      }

      case FilterDataTypeEnum.DATE: {
        return <></>;
      }

      default: {
        return <TextInput name={fieldName} value={value} label={label} onChange={handleChange} />;
      }
    }
  };

  return (
    <Formik
      initialValues={initialFormState}
      enableReinitialize
      onSubmit={(values) => {
        onSubmit(values);
      }}
    >
      {({ values, handleChange, handleSubmit, setFieldValue }) => (
        <form id="filter_panel_form" onSubmit={handleSubmit}>
          <div className="flex flex-col space-y-2">
            {columns.map((col) => (
              <div key={`filter_${col.headerName}`} className="w-48">
                {getInputForColumn(col, values, setFieldValue, handleChange)}
              </div>
            ))}
            {customFilters?.map((filter) => (
              <div key={`filter_${filter.customName}`} className="w-48">
                {getInputForColumn(
                  { headerName: filter.customName, fieldName: filter.fieldName, fieldType: filter.dataType },
                  values,
                  setFieldValue,
                  handleChange
                )}
              </div>
            ))}
          </div>
        </form>
      )}
    </Formik>
  );
};

export { FiltersForm };
