import { Formik } from 'formik';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import * as yup from 'yup';
import { getCsvHeaders } from '../../providers/validation.provider';
import { Button } from '../shared/Buttons/Button';
import { ButtonVariantEnum } from '../shared/Buttons/types';
import { IDropdownValue } from '../shared/Form/Dropdown';
import AllClientDropdown from '../shared/Form/Dropdowns/AllClientDropdown';
import LoadingIndicator from '../shared/LoadingIndicator';
import { OldFileUpload } from '../shared/Form/OldFileUpload';

export interface IOptinUploadForm {
  client?: IDropdownValue;
  optinList?: File;
}

interface IUploadOptinFormProps {
  loading: boolean;
  phoneError?: string;
  onSubmit: (formData: IOptinUploadForm) => Promise<void>;
}

const UploadOptinForm = ({ phoneError, loading, onSubmit }: IUploadOptinFormProps) => {
  const [phoneErrorMsg, setPhoneErrorMsg] = useState(phoneError);

  useEffect(() => setPhoneErrorMsg(phoneError), [phoneError]);

  const validateCsvHeaders = async (file: File): Promise<boolean> => {
    try {
      const headers = await getCsvHeaders(file);

      return headers.length === 1 && headers[0].toLocaleLowerCase() === 'phone';
    } catch (err) {
      console.error(err);
      return false;
    }
  };

  const schema: yup.SchemaOf<IOptinUploadForm> = yup.object().shape({
    client: yup
      .object()
      .shape({
        label: yup.string().defined('Required'),
        value: yup.mixed().defined('Required'),
      })
      .nullable(),
    optinList: yup
      .mixed()
      .nullable()
      .test('csvValidate', 'Uploaded file must be .csv', (file: File): boolean => !file || file.name?.endsWith('.csv'))
      .test(
        'csvHeaderValidate',
        'CSV does not contain the proper headers',
        async (file: File): Promise<boolean> => !file || (await validateCsvHeaders(file))
      ),
  });

  return (
    <Formik
      initialValues={{
        client: undefined,
        optinList: undefined,
      }}
      validationSchema={schema}
      onSubmit={(values) => {
        onSubmit && onSubmit(values);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        setFieldTouched,
        setFormikState,
        isValid,
      }) => (
        <form id="upload-opt-in" onSubmit={handleSubmit}>
          <div className="flex flex-col space-y-2">
            <AllClientDropdown
              value={values.client}
              onChange={(newValue) => {
                setFieldValue('client', newValue);
              }}
              onBlur={() => {
                setFieldTouched('client');
              }}
              errorMessage={(errors?.client as any)?.value as string}
              hideAllClient
            />
            <OldFileUpload
              id="optin-upload-file-selector"
              name="optinList"
              label="Opt-in File"
              placeholder="Upload a .csv file"
              value={values.optinList!}
              error={touched.optinList && (errors.optinList as any)}
              onBlur={handleBlur}
              onChange={(fileUploadEvent: any) => {
                const file = fileUploadEvent.target.files[0];
                setFormikState((prevState) => {
                  const newState = { ...prevState };
                  newState.values.optinList = file;
                  return newState;
                });
                setFieldTouched('optinList');
                setPhoneErrorMsg(undefined);
              }}
            />

            <div className="mt-2">
              <p className="text-sm text-gray-500">Each row must have a number. eg "1234567890".</p>
              <p className="text-sm text-gray-500">
                Download the template{' '}
                <Link
                  className="font-semibold text-sky-500 hover:underline"
                  to="/sample_upload_optins.csv"
                  target="_blank"
                  download
                >
                  here
                </Link>
              </p>
            </div>
          </div>
          <div className="flex justify-center mt-4">
            <Button
              id="optin-upload-form"
              className="ml-auto"
              type="submit"
              variant={ButtonVariantEnum.SECONDARY}
              disabled={!isValid}
            >
              {loading ? <LoadingIndicator position="CENTER" /> : 'Upload'}
            </Button>
          </div>
        </form>
      )}
    </Formik>
  );
};

export default UploadOptinForm;
