export enum FilterOperatorEnum {

    EQUALS = 'EQUALS',
    NOT_EQUALS = 'NOT_EQUALS',
    LESS_THAN = 'LESS_THAN',
    GREATER_THAN = 'GREATER_THAN',
    LESS_THAN_OR_EQUALS = 'LESS_THAN_OR_EQUALS',
    GREATER_THAN_OR_EQUALS = 'GREATER_THAN_OR_EQUALS',
    CONTAINS = 'CONTAINS',
    IN = 'IN',
    NOT_IN = 'NOT_IN',
    IS = 'IS',
    IS_NOT = 'IS_NOT'

}