import useProvidersForClient from '@gr/portal/hooks/useProvidersForClient';
import { CampaignTypeEnum } from '@gr/shared/enums';
import { useEffect } from 'react';
import { IDropdownValue } from '../Dropdown';
import Autocomplete from './Autocomplete';

interface IProviderForClientDropdownProps {
  value: any;
  clientId: string;
  campaignType: CampaignTypeEnum;
  onChange: (newValue: IDropdownValue | undefined) => void;
  onBlur?: { (e: any): void; <T = any>(fieldOrEvent: T): T extends string ? (e: any) => void : void; };
  disabled?: boolean;
  touched?: boolean;
  errorMessage?: string;
}

const ProviderForClientDropdown = ({
  value,
  clientId,
  campaignType,
  errorMessage,
  disabled,
  touched,
  onChange,
  onBlur,
}: IProviderForClientDropdownProps): JSX.Element => {
  const [{ data, loading, error }, fetchProviders] = useProvidersForClient(clientId, campaignType);
  const dropdownValues: IDropdownValue[] | undefined = data?.data?.providers.map((pt) => ({
    label: pt.name,
    value: pt.id,
  }));

  useEffect(() => {
    if (clientId) {
      fetchProviders({ data: { clientId: clientId, campaignType: campaignType } });
    }
  }, [clientId]);

  return (
    <Autocomplete
      full
      value={value ? dropdownValues?.find((ct) => ct.value === value) : undefined}
      options={dropdownValues}
      loading={loading}
      onChange={onChange}
      onBlur={onBlur}
      disabled={disabled || dropdownValues?.length === 0 || !clientId}
      placeholder="Select a Provider"
      showError={touched}
      error={touched && dropdownValues?.length === 0 && clientId ? 'No Providers for Client' : errorMessage}
      search={false}
    />
  );
};

export default ProviderForClientDropdown;
