import { IDropdownValue } from '../Dropdown';
import Autocomplete from './Autocomplete';

interface ISystemNumbersDropdownProps {
  value: IDropdownValue[] | undefined;
  options: IDropdownValue[];
  onChange: (newValue?: (IDropdownValue | undefined)[]) => void;
  onBlur?: () => void;
  allClients?: boolean;
  disabled?: boolean;
  errorMessage?: string | undefined;
  showError: boolean;
  tcrCampaignId?: string;
}

const SystemNumbersDropdown = ({
  value,
  options,
  onChange,
  onBlur,
  disabled,
  errorMessage,
  showError,
}: ISystemNumbersDropdownProps): JSX.Element => {
  return (
    <Autocomplete
      label="Numbers"
      placeholder="Search for Numbers"
      value={value}
      options={options}
      onChange={onChange}
      onBlur={onBlur}
      error={errorMessage}
      disabled={disabled}
      multiple
      selectAll
      showError={showError}
      top
    />
  );
};

export default SystemNumbersDropdown;
