import { ICampaignEntity, IHttpResponse } from '@gr/shared/models';
import useAxios, { UseAxiosResult } from 'axios-hooks';

const useMyCampaigns = (): UseAxiosResult<IHttpResponse<ICampaignEntity[]>> => {
  return useAxios<IHttpResponse<ICampaignEntity[]>>({
    url: 'campaigns-get-my-campaigns',
    method: 'POST',
  });
};

export default useMyCampaigns;
