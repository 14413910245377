import LoadingIndicator from '../LoadingIndicator';
import { ButtonVariantEnum } from './types';

interface IButtonProps {
  id?: string;
  text?: string;
  title?: string;
  children?: React.ReactNode;
  disabled?: boolean;
  isLoading?: boolean;
  leadingIcon?: JSX.Element;
  formId?: string;
  variant?: ButtonVariantEnum;
  type?: 'button' | 'submit' | 'reset' | undefined;
  className?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  onTouchStart?: React.TouchEventHandler<HTMLButtonElement>;
  onTouchEnd?: React.TouchEventHandler<HTMLButtonElement>;
  onMouseDown?: React.MouseEventHandler<HTMLButtonElement>;
  onMouseUp?: React.MouseEventHandler<HTMLButtonElement>;
}

const Button = ({
  id,
  text,
  title,
  children,
  disabled,
  isLoading,
  leadingIcon,
  formId,
  variant,
  type,
  className,
  onClick,
  onMouseDown,
  onMouseUp,
  onTouchStart,
  onTouchEnd,
}: IButtonProps) => {
  const classes =
    variant === ButtonVariantEnum.SECONDARY
      ? 'inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded text-sky-700 dark:text-sky-900 dark:bg-sky-400 bg-sky-100 hover:bg-sky-200 dark:hover:bg-sky-700 dark:hover:text-sky-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500'
      : variant === ButtonVariantEnum.DEFAULT
      ? 'inline-flex bg-white py-2 px-4 border rounded-md shadow-sm text-sm font-medium dark:border-slate-600 dark:text-slate-600 text-gray-700 hover:bg-gray-50 dark:hover:bg-slate-600 dark:hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500'
      : variant === ButtonVariantEnum.TEXT_PRIMARY
      ? 'inline-flex px-4 py-2 text-sm font-medium text-sky-600 dark:text-slate-300 hover:text-sky-500'
      : variant === ButtonVariantEnum.TEXT_DEFAULT
      ? 'inline-flex px-4 py-2 text-sm font-medium text-gray-700 dark:text-slate-300 hover:text-gray-900'
      : variant === ButtonVariantEnum.DELETE
      ? 'inline-flex justify-center px-4 py-2 mt-auto ml-4 text-sm font-medium text-red-700 dark:bg-red-400 dark:text-red-700 dark:hover:bg-red-700 dark:hover:text-red-400 bg-red-100 border border-transparent rounded-md shadow-sm hover:bg-red-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500'
      : variant === ButtonVariantEnum.SUCCESS
      ? 'inline-flex justify-center px-4 py-2 mt-auto ml-4 text-sm font-medium dark:text-slate-300 text-emerald-700 bg-emerald-100 border border-transparent rounded-md shadow-sm hover:bg-emerald-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500'
      : variant === ButtonVariantEnum.LINK_TAG
      ? 'btn-link-tag'
      : variant === ButtonVariantEnum.TAG
      ? 'btn-tag'
      : 'inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-sky-600 border border-transparent rounded-md shadow-sm hover:bg-sky-700'; //  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500
  return (
    <>
      <button
        id={id}
        onClick={onClick}
        onTouchStart={onTouchStart}
        onTouchEnd={onTouchEnd}
        onMouseDown={onMouseDown}
        onMouseUp={onMouseUp}
        type={type}
        title={title}
        disabled={disabled}
        form={formId}
        className={`${classes} disabled:opacity-25 select-none ${disabled && 'cursor-default'} ${className ?? ''}`}
      >
        {leadingIcon && (
          <div className={`${text || children ? 'mr-1' : ''}`} aria-hidden="true">
            {leadingIcon}
          </div>
        )}

        {isLoading ? (
          <LoadingIndicator
            position="CENTER"
            className={variant === ButtonVariantEnum.DELETE ? 'border-t-red-500' : 'border-t-sky-500'}
          />
        ) : children ? (
          children
        ) : (
          text
        )}
      </button>
    </>
  );
};

export { Button };
