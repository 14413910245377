import { SocketContext } from '@gr/portal/contexts/SocketContext';
import { TagAttributes } from '@gr/shared/entities';
import {
  IAssignConversationsRequest,
  IContactTag,
  IConversationDetail,
  IFreeFormResponse,
  IHttpResponse,
  IOptoutConversationRequest,
  ITemplateResponse,
  ITwoWayConversationEntity
} from '@gr/shared/models';
import { AxiosResponse } from 'axios';
import { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { axiosGet, axiosPost, axiosPut } from '../../authAxios';
import { NotificationType, useNotifications } from '../../contexts/NotificationContext';
import { Button } from '../shared/Buttons/Button';
import { NewButton } from '../shared/Buttons/NewButton';
import { ButtonVariantEnum } from '../shared/Buttons/types';
import MarkCompletedPanel from '../shared/DetailsPanel/MarkCompletedPanel';
import SearchField from '../shared/Form/SearchField';
import { ActiveMessage } from './ActiveMessage';
import { Messages } from './Messages';
import { Responses } from './Responses';
import TagsModal from './TagsModal';
import { TagOption } from './types';

export const Conversations = () => {
  const [searchText, setSearchText] = useState('');
  const [completeLoading, setCompleteLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [activeMessageLoading, setActiveMessageLoading] = useState(false);
  const [optOutLoading, setOptOutLoading] = useState(false);
  const [replyLoading, setReplyLoading] = useState(false);
  const [activeMessage, setActiveMessage] = useState<ITwoWayConversationEntity>();
  const [activeResponse, setActiveResponse] = useState<ITemplateResponse | IFreeFormResponse>();
  const [assignedConvos, setAssignedConvos] = useState<ITwoWayConversationEntity[]>([]);
  const [lastConvo, setLastConvo] = useState<IConversationDetail>();
  const [sentConvos, setSentConvos] = useState<Partial<IConversationDetail>[]>([]);
  const [clientId, setClientId] = useState<string>();
  const [clientTags, setClientTags] = useState<TagAttributes[]>([]);
  const [currentTags, setCurrentTags] = useState<TagOption[]>([]);
  const [showTags, setShowTags] = useState(false);
  const [loadingCurrentTags, setLoadingCurrentTags] = useState(false);
  const [savingTags, setSavingTags] = useState(false);
  const { addNotification } = useNotifications();
  const socketManager = useContext(SocketContext);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    getConversations();
    const unsubscribeUnassignConversations = socketManager!.subscribe(
      'conversations-unassigned',
      getConversations
    );

    return () => {
      unsubscribeUnassignConversations();
    };
  }, []);

  useEffect(() => {
    getTags();
  }, [activeMessage]);

  const getTags = async () => {
    try {
      setLoadingCurrentTags(true);
      await Promise.all([
        getClientTags(),
        getActiveConvoTags()
      ]);
    } finally {
      setLoadingCurrentTags(false);
    }
  };

  const getClientTags = async () => {
    // upon new client only
    if (!activeMessage || activeMessage.clientId === clientId) {
      return;
    }

    try {
      const clientId = activeMessage.clientId;
      const resp = await axiosGet<TagAttributes[]>(`/tags?clientId=${clientId}`);
      if (resp.data) {
        setClientId(clientId);
        setClientTags(resp.data);
      }
    } catch (e) {

      console.log('Error loading tags:', e);

      setClientTags([]);

      addNotification({
        header: 'Error',
        content: 'Failed to load client tags',
        type: NotificationType.FAILURE,
      });
    }
  };

  const getActiveConvoTags = async () => {
    if (!activeMessage) {
      return;
    }

    try {
      const resp = await axiosGet<IHttpResponse<IContactTag[]>>(`/two-way-convos/${activeMessage.id}/tags`);

      if (resp.data?.data) {
        setCurrentTags(resp.data.data.map(t => ({
          id: t.tagId,
          name: t.name,
          checked: true
        })));
      }
    } catch (e) {
      console.log('Error loading convo tags:', e);

      setClientTags([]);

      addNotification({
        header: 'Error',
        content: 'Failed to load conversation tags',
        type: NotificationType.FAILURE,
      });
    }
  };

  const createOptOut = async () => {
    setOptOutLoading(true);
    try {
      const request: IOptoutConversationRequest = {
        twoWayConversationId: activeMessage?.id ?? '',
        conversationId: lastConvo?.id ?? '',
      };
      await axiosPost('/two-way-convos-optout', request);
      await getConversations();
      setActiveMessage(undefined);
      setActiveResponse(undefined);
    } catch (e) {
      console.log(e);
    } finally {
      setOptOutLoading(false);
    }
  };

  const markCompleted = async () => {
    setCompleteLoading(true);
    try {
      await axiosPost<IHttpResponse<void>>('/two-way-convos-complete', { conversationId: Array.of(activeMessage?.id) });
      setActiveMessage(undefined);
      await getConversations();
    } catch (e) {
      console.log(e);
    } finally {
      setCompleteLoading(false);
    }
  };

  const getConversations = async () => {
    setLoading(true);
    try {
      const response = await axiosPost<AxiosResponse<ITwoWayConversationEntity[]>>('/two-way-convos-get');
      setAssignedConvos(response?.data?.data ?? []);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const assignConversations = async (limit: number) => {
    setLoading(true);
    try {
      const request: IAssignConversationsRequest = {
        limit,
      };
      const response = await axiosPost<AxiosResponse<ITwoWayConversationEntity[]>>('/two-way-convos-assign', request);
      setAssignedConvos((prevState) => {
        const prevConvos = [...prevState];
        const newConvos = response?.data?.data ?? [];
        return [...prevConvos, ...newConvos];
      });
      addNotification({
        header: `Assigned ${response?.data?.data?.length ?? 0} new conversations.${limit < 20 ? ' You have reached the maximum number of assigned conversations.' : ''
          }`,
      });
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const saveTags = async (selectedTags: TagOption[]) => {
    const currentTagsSnapshot = currentTags.slice();

    try {

      const currentTagNames = currentTags.map(t => t.name);
      const selectedTagNames = selectedTags.map(t => t.name);
      if (currentTagNames.length === selectedTagNames.length && currentTagNames.every(name => selectedTagNames.includes(name))) {
        return;
      }

      setSavingTags(true);

      await axiosPut(`/two-way-convos/tags`, {
        twoWayConversationId: activeMessage!.id,
        clientId: activeMessage!.clientId,
        phoneNumber: activeMessage!.clientNumber,
        tagIds: selectedTags.map(t => t.id)
      });

      setCurrentTags(selectedTags);

    } catch (e) {
      console.log('Error saving tags:', e);

      setCurrentTags(currentTagsSnapshot);

      addNotification({
        header: 'Error',
        content: 'Failed to save conversation tags',
        type: NotificationType.FAILURE
      });
    } finally {
      setSavingTags(false);
      setShowTags(false);
    }
  };

  const markAllConversionRead = async () => {

    try {
      setCompleteLoading(true);
      let assignedConvosIds = assignedConvos.map((convos) => {
        return convos.id;
      });
      await axiosPost<IHttpResponse<void>>('/two-way-convos-complete', { conversationId: assignedConvosIds });
      setActiveMessage(undefined);
      setAssignedConvos([]);
    } catch (e) {
      console.log(e);
    } finally {
      setCompleteLoading(false);
    }
  };

  return (
    <>
      <div className="flex justify-between w-full">
        <h1>Conversations</h1>
        <div className="flex flex-col">
          <NewButton
            disabled={assignedConvos.length >= 20 || loading}
            onClick={() => assignConversations(Math.min(20 - assignedConvos.length, 20))}
            isLoading={loading}
          >
            LOAD MORE CONVERSATIONS
          </NewButton>
          <div className="mt-2 body-text-small">You can only be assigned 20 conversations at a time</div>
        </div>
      </div>
      <div className="flex flex-wrap gap-x-4" style={{ height: '90%' }}>
        <div className="flex flex-col flex-1 max-h-full space-y-6 grow">
          <SearchField
            id="ph-search"
            name="ph-search"
            placeholder="Search by phone number"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
          <div className="ps-5 font-semibold text-black underline gap-10">
            <Link
              onClick={(e) => {
                e.preventDefault();
                setShowModal(true);
              }}
              to=""
              target="_blank"
            >
              Mark All As Complete
            </Link>
          </div>
          <Messages
            searchText={searchText}
            activeMessage={activeMessage}
            messages={assignedConvos}
            setActiveMessage={setActiveMessage}
            setActiveResponse={setActiveResponse}
            assignConversations={assignConversations}
            loading={loading}
            setActiveMessageLoading={setActiveMessageLoading}
            replyLoading={optOutLoading || completeLoading || replyLoading}
            setSentConvos={setSentConvos}
          />
        </div>
        <div className="flex flex-1 max-h-full">
          <ActiveMessage
            message={activeMessage}
            activeResponse={activeResponse}
            setActiveResponse={setActiveResponse}
            getConversations={getConversations}
            setLastConvo={setLastConvo}
            setActiveMessage={setActiveMessage}
            disableSend={optOutLoading || completeLoading}
            loading={activeMessageLoading}
            optoutLoading={optOutLoading}
            setLoading={setActiveMessageLoading}
            replyLoading={replyLoading}
            setReplyLoading={setReplyLoading}
            sentConvos={sentConvos}
            setSentConvos={setSentConvos}
          />
        </div>
        <div className="flex flex-col items-center flex-1 max-h-full overflow-hidden">
          <Responses
            activeMessage={activeMessage}
            activeResponse={activeResponse}
            setActiveResponse={setActiveResponse}
          />
          {activeMessage && clientTags?.length ?
            <Button
              className="px-0 my-4 mt-1 underline disabled:cursor-auto hover:cursor-pointer"
              variant={ButtonVariantEnum.TEXT_PRIMARY}
              isLoading={activeMessageLoading || loadingCurrentTags}
              text={currentTags?.length ? `Conversation Tags (${currentTags.length})` : '+ Add Conversation Tags'}
              onClick={() => {
                setShowTags(true);
              }}
            />
            : ''
          }
          <div className="flex mt-auto">
            <NewButton
              className="mr-4"
              variant={ButtonVariantEnum.TERTIARY}
              disabled={
                !activeMessage?.id ||
                optOutLoading ||
                completeLoading ||
                activeMessageLoading ||
                replyLoading ||
                loading
              }
              onClick={markCompleted}
              isLoading={completeLoading}
            >
              MARK AS COMPLETE
            </NewButton>
            <NewButton
              disabled={
                !activeMessage?.id ||
                optOutLoading ||
                completeLoading ||
                activeMessageLoading ||
                replyLoading ||
                loading
              }
              variant={ButtonVariantEnum.SECONDARY}
              onClick={() => createOptOut()}
              isLoading={optOutLoading}
            >
              OPT OUT
            </NewButton>
          </div>
        </div>
        <MarkCompletedPanel
          showModal={showModal} isCampaignActive={false}
          onClose={() => {
            setShowModal(false);
          }}
          onSuccess={() => {
            markAllConversionRead();
            setShowModal(false);
          }}
          onCancel={() => {
            setShowModal(false);
          }}>

        </MarkCompletedPanel>
      </div>

      {activeMessage && !activeMessageLoading && clientTags?.length && !loadingCurrentTags &&
        <TagsModal
          show={showTags}
          loading={savingTags}
          convo={activeMessage}
          options={clientTags}
          currentSelectedTags={currentTags}
          onSave={saveTags}
          onClose={() => setShowTags(false)}
        />
      }
    </>
  );
};
