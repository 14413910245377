import { isFileLocalSize, isFileMediaType } from '@gr/portal/providers/validation.provider';
import { CampaignTypeEnum, MessageTypeEnum } from "@gr/shared/enums";
import { checkSHAFTCompliance, getSMSMessageSegmentCount } from '@gr/shared/utils';
import * as yup from 'yup';
import { IDropdownValue } from "../../shared/Form/Dropdown";
import { isMoreThanTwoDays } from '../services/campaigns.service';
import { ICampaignForm } from "../types";

export interface ICampaignPlanForm {
  name: string;
  client?: IDropdownValue;
  contactLists: IDropdownValue[];
  suppressionLists: IDropdownValue[];
  suppressionAreaCodes: IDropdownValue[];
  suppressionStates: IDropdownValue[];
  clickerGroup?: string | null;
  messageType: MessageTypeEnum;
  externalId?: string | null;
  campaignType: CampaignTypeEnum;
}

// We really don't care. It's already been validated.
export const campaignPreviewFormSchema: yup.SchemaOf<ICampaignForm> = yup.object();

export const campaignPlanFormSchema: yup.SchemaOf<ICampaignPlanForm> = yup.object().shape({
  name: yup.string().defined('Required'),
  client: yup
    .object()
    .shape({
      label: yup.string().defined('Required'),
      value: yup.mixed().defined('Required'),
    })
    .nullable(),
  messageType: yup.mixed<MessageTypeEnum>().required(),
  mediaName: yup.string().nullable(),
  isMMSVideo: yup.boolean().nullable(),
  mediaFile: yup.mixed().when('messageType', {
    is: (messageType: MessageTypeEnum) => {
      return messageType === MessageTypeEnum.MMS;
    },
    then: yup.mixed()
      .required('Required')
      .test('fileTypeValidate', 'Uploaded file must be image or video', (file: File): boolean =>
        isFileMediaType(file)
      )
      .test('fileSize', 'File is too large. File must be smaller than 850kb', (file: File): boolean =>
        isFileLocalSize(file)
      )
  }),
  mediaUrl: yup.string().when('messageType', {
    is: (messageType: MessageTypeEnum) => messageType === MessageTypeEnum.MMS,
    then: yup.string().defined('Required'),
  }).nullable(),
  campaignType: yup.mixed<CampaignTypeEnum>().defined('Required').required('Required'),
  contactLists: yup
    .array()
    .of(
      yup.object().shape({
        label: yup.string().defined(),
        value: yup.mixed().required('Required'),
        additionalData: yup.mixed(),
      })
    )
    .min(1, 'Pick at least one Contact List'),
  clickerGroup: yup.string().when('client', {
    is: (client: IDropdownValue) => !!client?.value ? !client?.additionalData?.clickerGroup : false,
    then: yup.string().typeError('Required').min(0, 'Required'), // Allows an empty string (None value)
    otherwise: yup.string().required('Required')
  }),
  externalId: yup.string().nullable(),
  suppressionLists: yup
    .array().of(
      yup.object().shape({
        label: yup.string().defined(),
        value: yup.mixed().required('Required'),
        additionalData: yup.mixed(),
      })
    ).when({
      is: ((contactList: IDropdownValue[]) => contactList.length <= 0),
      then: yup.array().max(0)
    }),
  suppressionAreaCodes: yup.array()
    .of(
      yup.object().shape({
        label: yup.string().defined(),
        value: yup.mixed().required('Required'),
        additionalData: yup.mixed(),
      })
    ),
  suppressionStates: yup.array()
    .of(
      yup.object().shape({
        label: yup.string().defined(),
        value: yup.mixed().required('Required'),
        additionalData: yup.mixed(),
      })
    ),
});

export interface ICampaignBuildForm {
  message: string;
}

export const campaignBuildFormSchema: yup.SchemaOf<ICampaignBuildForm> = yup.object().shape({
  message: yup
    .string()
    .test('notContainsPipe', 'Message must not contain "|" symbol', (message) => !(message && message.includes('|')))
    .when('messageType', {
      is: (messageType: MessageTypeEnum) => messageType === MessageTypeEnum.MMS,
      then: yup.string().max(1600, 'Message cannot be longer than 1600 characters'),
      otherwise: yup.string().max(1000, 'Message cannot be longer than 1000 characters'),
    })
    .test('containsLink', 'Message must contain {{link}}', (message: string | undefined, context): boolean => {
      return context.parent.clickTrackingEnabled && message ? message?.toLowerCase()?.includes('{{link}}') : true;
    })
    .test('notContainsLink', 'Message must not contain {{link}}', (message: string | undefined, context): boolean => {
      return !context.parent.clickTrackingEnabled && message ? !message?.toLowerCase()?.includes('{{link}}') : true;
    })
    .test('shaftCompliance', 'Please update your message to remove banned words.', (message: string | undefined, context): boolean => {
      return checkSHAFTCompliance(message);
    })
    .test('segmentCount', 'Segment count must not be larger than 10', (message: string | undefined, context): boolean => {
      if (context.parent.messageType === MessageTypeEnum.SMS) {
        return getSMSMessageSegmentCount(message ?? '') <= 10;
      } else {
        return true;
      }
    })
    .defined('Required'),
  clickTrackingEnabled: yup.boolean(),
  twoWayEnabled: yup.boolean(),
  generateUniqueLinks: yup.boolean(),
  url: yup.string().when('clickTrackingEnabled', {
    is: (clickTrackingEnabled: boolean) => clickTrackingEnabled,
    then: yup.string().test('apostrophe', 'URL cannot have an apostrophe', (value) => !value?.includes('\'')).url('Invalid URL format').defined('Required'),
  }),
  domainId: yup.string().when('clickTrackingEnabled', {
    is: (clickTrackingEnabled: boolean) => clickTrackingEnabled,
    then: yup.string().defined('Required'),
  }),
  domain: yup.string().when('clickTrackingEnabled', {
    is: (clickTrackingEnabled: boolean) => clickTrackingEnabled,
    then: yup.string().defined('Required'),
  }),
  startsAt: yup
    .date()
    .min(new Date(), 'Campaign cannot start in the past')
    .typeError('Invalid Date')
    .defined('Required')
  ,
  endsAt: yup
    .date()
    .typeError('Invalid Date')
    .defined('Required')
    .min(yup.ref('startsAt'), 'End date cannot be before Start date')
    .test('isMoreThanTwoDays', `Campaign must end within 2 business days of start time.`, (value, context): boolean => {
      return !isMoreThanTwoDays(context.parent.startsAt, context.parent.endsAt);
    })
});