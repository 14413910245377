import { Auth0CreateUsersRequest, Auth0Role } from '@gr/shared/models';
import { getAuth0Organization } from '@gr/shared/utils/auth0-sub-domain.service';
import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import { Fragment, useState } from 'react';
import { axiosPost } from '../../authAxios';
import { NotificationType, useNotifications } from '../../contexts/NotificationContext';
import { getCsvRecords } from '../../providers/utility.provider';
import { Button } from '../shared/Buttons/Button';
import { ButtonVariantEnum } from '../shared/Buttons/types';
import UploadUsersForm, { IUsersUploadForm } from './UploadUsersForm';

interface IUploadUsersModalProps {
  show: boolean;
  setShow: any;
  onUpload: () => Promise<void>;
}

const UploadUsersModal = ({ show, setShow, onUpload }: IUploadUsersModalProps) => {
  const { addNotification } = useNotifications();
  const [uploadingUsers, setUploadingUsers] = useState(false);

  const uploadUsers = async (form: IUsersUploadForm) => {
    try {
      setUploadingUsers(true);
      const organization = getAuth0Organization();
      const records = (await getCsvRecords(form.users as any)).map((record) => record.split(','));

      const request: Auth0CreateUsersRequest = {
        users: records.map(([email, firstName, lastName, role]) => ({
          clientIds: [],
          email: email.trim(),
          firstName: firstName.trim(),
          lastName: lastName.trim(),
          memberships: [
            {
              roles: [role.trim().replace(' ', '_').toUpperCase() as Auth0Role],
              organization,
            },
          ],
        })),
      };

      await axiosPost('/auth0-create-users', request);

      setShow(false);
      addNotification({ header: 'Users uploaded successfully!' });

      await onUpload();
    } catch (error) {
      console.error(error);
      addNotification({
        header: 'Error',
        content: 'Users failed to upload. Please verify all fields match up exactly.',
        type: NotificationType.FAILURE,
      });
    } finally {
      setUploadingUsers(false);
    }
  };

  return (
    <Transition appear show={show} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-10 overflow-y-auto"
        onClose={() => {
          setShow(false);
        }}
      >
        <div className="min-h-screen px-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75 dark:text-slate-400" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          {/* <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span> */}
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block w-full max-w-md p-6 my-8 text-left align-middle transition-all transform bg-white shadow-xl dark:bg-slate-700 rounded-2xl">
              <Button
                className="absolute top-0 right-0 rounded-tr-2xl hover:bg-gray-100 dark:hover:bg-slate-800"
                variant={ButtonVariantEnum.TEXT_DEFAULT}
                leadingIcon={<XIcon className="w-5 h-5 text-gray-300" />}
                onClick={() => setShow(false)}
              />

              <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900 dark:text-white">
                <span id="upload-optouts-modal-title">Upload Users</span>
              </Dialog.Title>
              <div className="mt-2">
                <UploadUsersForm onSubmit={uploadUsers} loading={uploadingUsers} />
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default UploadUsersModal;
