import { NotificationType, useNotifications } from '@gr/portal/contexts/NotificationContext';
import { ConversationTypeEnum } from '@gr/shared/enums';
import {
  Auth0Role,
  IConversationDetail,
  IFreeFormResponse,
  IHttpResponse,
  IReplyToConversationRequest,
  IRespondToConversationRequest,
  ITemplateResponse,
  ITwoWayConversationEntity
} from '@gr/shared/models';
import { getSMSMessageLength, getSMSMessageSegmentCount } from '@gr/shared/utils';
import useAxios from 'axios-hooks';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { axiosPost } from '../../authAxios';
import useRoles from '../../hooks/useRoles';
import NewTextArea from '../shared/Form/NewTextArea';
import { SendReply as ReplyIcon } from '../shared/Icons/SendReply';
import LoadingIndicator from '../shared/LoadingIndicator';

interface IActiveMessageProps {
  message: ITwoWayConversationEntity | undefined;
  activeResponse: ITemplateResponse | IFreeFormResponse | undefined;
  sentConvos: Partial<IConversationDetail>[];
  setSentConvos: (sentConvos: any) => void;
  setLastConvo: (lastConvo: IConversationDetail) => void;
  setActiveResponse: (response) => void;
  getConversations: () => void;
  setActiveMessage: (newMessage) => void;
  disableSend: boolean;
  loading: boolean;
  setLoading: (loading) => void;
  optoutLoading: boolean;
  replyLoading: boolean;
  setReplyLoading: (loading) => void;
}

export const ActiveMessage = ({
  message,
  activeResponse,
  sentConvos,
  setSentConvos,
  setActiveResponse,
  setLastConvo,
  getConversations,
  setActiveMessage,
  disableSend,
  loading,
  setLoading,
  replyLoading,
  setReplyLoading,
}: IActiveMessageProps) => {
  const [optoutLoading, setOptoutLoading] = useState(false);
  const canFreeForm = useRoles([Auth0Role.GR_CLIENT_MANAGER]);
  const { addNotification } = useNotifications();
  const [{ data: convos }, getDetails] = useAxios<IHttpResponse<IConversationDetail[]>>(
    {
      url: 'two-way-convos-details',
      method: 'POST',
      data: {
        conversationId: message?.id ?? '',
      },
    },
    { manual: true }
  );

  const onConversationUpdate = async () => {
    console.log('Recieved new conversation data');
    if (!!message) {
      // Get the new convo details
      await getConvoDetails();
    }
  };

  const getConvoDetails = async () => {
    try {
      const response = await getDetails();
      const convos = response?.data?.data ?? [];
      const hasOptout = convos.filter((c) => c.type === ConversationTypeEnum.INBOUND && !!c.optoutId).length > 0;
      if (hasOptout) {
        setOptoutLoading(true);
        await getConversations();
        setActiveMessage(undefined);
        setOptoutLoading(false);
        return;
      }
      setLastConvo(response?.data?.data[response?.data?.data.length - 1]);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };
  const sendReply = async () => {
    setReplyLoading(true);
    const newMessage = canFreeForm ? activeResponse?.message ?? '' : (activeResponse as ITemplateResponse).id ?? '';
    setSentConvos((prevState) => {
      return [
        ...prevState,
        {
          createdAt: new Date(),
          type: ConversationTypeEnum.OUTBOUND,
          message: activeResponse?.message ?? '',
        },
      ];
    });
    try {
      setActiveResponse(undefined);
      if (canFreeForm) {
        const request: IReplyToConversationRequest = {
          twoWayConversationId: message?.id ?? '',
          message: newMessage,
        };
        await axiosPost('/two-way-convos-reply', request);
      } else {
        const request: IRespondToConversationRequest = {
          twoWayConversationId: message?.id ?? '',
          responseTemplateId: newMessage,
        };
        await axiosPost('/two-way-convos-respond', request);
      }
      await getConvoDetails();
      // Remove the temporary sent convos, we have the new details now
      setSentConvos([]);
    } catch (e) {
      console.error(e);
      addNotification({ type: NotificationType.FAILURE, content: 'An error occured sending message. Please try again.' });
      setSentConvos([]);
      await getConversations();
    } finally {
      setReplyLoading(false);
    }
  };
  useEffect(() => {
    if (message?.id) {
      getConvoDetails();
      const interval = setInterval(() => getConvoDetails(), 30000);
      return () => {
        clearInterval(interval);
      };
    }
    return () => { };
  }, [message?.id]);

  return (
    <div className="bg-white w-full rounded-2.5xl flex flex-col">
      <div className="flex justify-between p-4 bg-light-gray rounded-t-2.5xl items-center">
        <div className="mr-4 text-black body-text">{message?.campaignName}</div>
        <div className="flex items-center">
          <div className="body-text-small-bold" style={{ lineHeight: '24px' }}>
            {message?.clientNumber}
          </div>
          {/* <MoreIcon /> */}
        </div>
      </div>
      <div className="flex flex-col h-full pt-4 overflow-scroll">
        {message?.id &&
          (!loading ? (
            [...(convos?.data ?? []), ...sentConvos].map((convo) => {
              const outbound = convo.type === ConversationTypeEnum.OUTBOUND;
              return (
                <div key={convo.id}>
                  <div className={`relative body-text-small mb-1 ${outbound ? 'ml-24 mr-7' : 'mr-24 ml-7'}`}>
                    {moment(convo.createdAt).format('LT')}
                  </div>
                  <div className={`${outbound ? 'bubble-out' : 'bubble-in'}`}>{convo.message}</div>
                </div>
              );
            })
          ) : (
            <div className="flex items-center justify-center h-full">
              <LoadingIndicator />
            </div>
          ))}
      </div>
      <div className="mx-4 mt-auto mb-4">
        <NewTextArea
          style={{ backgroundColor: '#F5F7F9', resize: 'none' }}
          className="h-[42px]"
          name="reply"
          readOnly={!canFreeForm}
          value={activeResponse?.message ?? ''}
          onChange={(e) =>
            setActiveResponse({
              message: e.target.value,
              segmentCount: getSMSMessageSegmentCount(e.target.value),
              characterCount: getSMSMessageLength(e.target.value),
            } as IFreeFormResponse)
          }
          disabled={replyLoading || !message || disableSend || optoutLoading}
          placeholder="Your response goes here..."
          onEnter={activeResponse?.message ? sendReply : () => { }}
          onIconClick={activeResponse?.message ? sendReply : () => { }}
          trailingIcon={
            <div style={{ maxWidth: '12px', maxHeight: '12px' }}>
              {replyLoading ? <LoadingIndicator small /> : <ReplyIcon />}
            </div>
          }
          trailingIconClassName={`${replyLoading || !message || disableSend || !activeResponse?.message || optoutLoading
            ? 'bg-medium-gray'
            : 'bg-wc-blue cursor-pointer'
            } rounded-full`}
        />
        <div className="flex justify-between mt-2 ml-auto text-sm text-gray-500 dark:text-slate-400">
          <div className="body-text">Segment count: {activeResponse?.segmentCount}</div>
          <div className="body-text">Character count: {activeResponse?.characterCount ?? 0}</div>
        </div>
      </div>
    </div>
  );
};
