import { NotificationType, useNotifications } from '@gr/portal/contexts/NotificationContext';
import { FocusEventHandler, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { ErrorMessage } from './ErrorMessage';

interface IFileUpload {
  id: string;
  name: string;
  fileName?: string;
  value: File | undefined;
  accepts?: string;
  className?: string;
  label?: string;
  disabled?: boolean;
  placeholder?: string;
  error?: string;
  optional?: boolean;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  onBlur?: FocusEventHandler<HTMLInputElement>;
}
//const fileInputRef = useRef(null);

const NewFileUpload = ({
  id,
  name,
  fileName,
  value,
  accepts,
  className,
  label,
  disabled,
  placeholder,
  error,
  optional,
  onChange,
  onBlur,
}: IFileUpload): JSX.Element => {
  const { addNotification } = useNotifications();
  const [previousError, setPreviousError] = useState(error);
  const inputRef = useRef<HTMLInputElement>(null);
  const [file, setFile] = useState(null);

  // Used to fix the animation by not removing the text as we're transitioning out
  useEffect(() => {
    if (error) {
      setPreviousError(error);
    }
  }, [error]);

  const onTargetClick = () => {
    console.log("sadas");
    // fileInputRef.current.click();
  };

  const inputText = fileName
    ? fileName
    : value
      ? value.name
      : disabled
        ? 'No File Selected'
        : placeholder || null;


  const handleDrop = (event) => {
    if (!event.dataTransfer.files[0].name.includes(accepts)) {
      addNotification({ header: `File must be ${accepts} format`, type: NotificationType.FAILURE });
      return;
    }
    if (!inputRef.current) return;
    const dataTransfer = new DataTransfer();
    dataTransfer.items.add(event.dataTransfer.files[0]);
    inputRef.current.files = dataTransfer.files;
    console.log(inputRef.current.files);
    inputRef.current.dispatchEvent(new Event("change", {
      bubbles: true
    }));
  };


  const handleClick = () => {
    inputRef?.current?.click();
  };

  return (
    <div className={className}>
      {/* Label */}
      <div className="flex justify-between">
        <label className="block text-xs text-gray-500">{label}</label>
        {optional && (
          <span className="text-sm" id="optional">
            Optional
          </span>
        )}
      </div>
      <div className="break-words overflow-wrap flex-col mt-5 min-h-6 text-left mb-5 block p-4 rounded-lg bg-gray-50 text-base focus:ring-blue-500 focus:border-blue-500 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        onDrop={(event) => {
          event.preventDefault();
          handleDrop(event);
        }}
        onDragOver={(event) => {
          event.preventDefault();
          console.log("drag");
        }}>

        <label className='text-sm w-auto flex-grow space-x-2 '>
          <img className="float-left mt-3 ml-5 " id="grassroots-logo" width="52" height="40" src="/Upload.png" alt="Upload"></img>
          {!inputText?.includes(accepts!) && (
            <>
              <div className='font-extrabold text-black pl-24 mt-2'>Drop your CSV file here<br /></div> <div className='pl-24 text-gray-500 mb-2'> or  <button className='text-blue-500' type='button' onClick={handleClick}> browse file</button> from your computer </div></>
          )}
          {inputText?.includes(accepts!) && (
            <div className='overflow-auto pl-8 text-justify min-h-6'><b> <button type='button' className='text-blue-500 mt-5 min-h-6' onClick={handleClick}> {inputText}</button> </b></div>
          )}
        </label>
      </div >
      <ErrorMessage show={!!error} message={previousError} />
      <input hidden
        accept={accepts ?? '.csv'}
        name={name}
        value=""
        ref={inputRef}
        id={id}
        type="file"
        placeholder={'Select file'}
        onChange={onChange}
        onClick={() => {
          console.log(inputRef.current!.files);
          // This allows for users to re-upload the same file (eg. after modifications) by clearing the value on every file upload
          inputRef.current!.value = '';
        }}
      />

      <div className="mt-2">
        <p className="text-sm dark-gray">Each row must have a number and contact name.</p>
        <p className="text-sm text-gray-500 mt-12">
          <Link
            className="font-semibold text-blue-500 underline underline-offset-8 text-lg"
            to="/sample_upload_contacts.csv"
            target="_blank"
            download
          >
            Download .csv template
          </Link>
        </p>
      </div>
    </div>

  );
};

export { NewFileUpload };

