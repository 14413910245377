import { CampaignStatusEnum } from '@gr/shared/enums';
import { ICampaignEntity } from '@gr/shared/models';

interface ICampaignProgressBarProps {
  campaign: ICampaignEntity;
}

const CampaignDeliveredProgressBar = ({ campaign }: ICampaignProgressBarProps): JSX.Element => {
  const {
    totalMessagesScheduled: scheduled = 0,
    totalMessagesSent: sent = 0,
    totalMessagesFailed: failed = 0,
    totalMessagesConfirmed: confirmed = 0,
  } = campaign;

  const tripWired = campaign.status == CampaignStatusEnum.PAUSED && campaign.tripWired == true;

  return (
    <div>
      {/* Confirmed / Scheduled */}
      <div className="flex items-center">
        <h5 className="mt-px mr-2 text-right basis-1/4 text-dark-gray">SCHEDULED</h5>
        <div
          className={`flex grow min-w-[12rem] basis-1/2 w-full h-2 overflow-hidden text-xs rounded ${campaign.totalMessagesScheduled === 0 ? 'bg-light-gray dark:bg-slate-400' : 'bg-mint'
            }`}
        >
          <>
            <div
              style={{
                width: `${Math.min(Math.ceil(((confirmed ?? 0) / (scheduled ?? 100)) * 100), 100)}%`,
              }}
              className={`flex flex-col justify-center text-center text-white shadow-none bg-grass whitespace-nowrap`}
            ></div>
          </>
        </div>
      </div>
      <h5 className="mt-1 text-right truncate text-medium-gray dark:text-slate-400 basis-full" title="Scheduled">
        {campaign?.totalMessagesConfirmed?.toLocaleString()} / {campaign?.totalMessagesScheduled?.toLocaleString()}
      </h5>

      {/* Sent / Delivered / Failed */}
      <div className="flex items-center mt-4">
        <h5 className="min-w-[65px] text-right mt-px mr-2 basis-1/4 text-dark-gray">DELIVERY</h5>
        <div
          className={`flex grow basis-1/2 h-2 overflow-hidden text-xs rounded justify-end ${campaign.totalMessagesSent === 0 ? 'bg-light-gray dark:bg-slate-400' : 'bg-mint'
            }`}
        >
          <>
            <div
              style={{
                width: `${Math.min(Math.ceil(((sent - failed) / (sent ?? 100)) * 100), 100)}%`,
              }}
              className={`flex flex-col justify-center text-center text-white bg-grass shadow-none bg-grass-400 whitespace-nowrap`}
            ></div>
            <div
              style={{
                width: `${Math.min(Math.ceil(((failed ?? 0) / (sent ?? 100)) * 100), 100)}%`,
              }}
              className={`flex flex-col justify-center text-center text-white bg-flamingo shadow-none whitespace-nowrap`}
            ></div>
          </>
        </div>
      </div>
      <h5
        className="mt-1 text-right truncate text-medium-gray dark:text-slate-400 basis-full"
        title="Sent / Delivered / Failed"
      >
        {campaign?.totalMessagesSent?.toLocaleString()} / {((campaign?.totalMessagesSent ?? 0) - (campaign?.totalMessagesFailed ?? 0))?.toLocaleString()} /{' '}
        {campaign?.totalMessagesFailed?.toLocaleString()}
      </h5>
    </div>
  );
};

export default CampaignDeliveredProgressBar;
