import { IClientView, IHttpSearchResponse } from '@gr/shared/models';
import useAxios, { UseAxiosResult } from 'axios-hooks';

export default function useClients(searchString: string): UseAxiosResult<IHttpSearchResponse<IClientView>> {
  return useAxios<IHttpSearchResponse<IClientView>>({
    url: 'clients-search',
    method: 'POST',
    data: { searchString },
  }, {
    manual: true
  });
}
