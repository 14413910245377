import { CampaignTypeEnum, MessageTypeEnum } from '@gr/shared/enums';
import { ICarrierExclusionView } from '@gr/shared/models';
import { FastField, Formik } from 'formik';
import { messageTypeOptions } from '../Campaigns/types';
import { IDropdownValue } from '../shared/Form/Dropdown';
import CampaignTypeForClientDropdown from '../shared/Form/Dropdowns/CampaignTypeDropdown';
import CarrierDropdown from '../shared/Form/Dropdowns/CarrierDropdown';
import ClientDropdown from '../shared/Form/Dropdowns/ClientDropdown';
import ProviderDropdown from '../shared/Form/Dropdowns/ProviderDropdown';
import { IRadioButtonOption, RadioButtonGroup } from '../shared/Form/RadioButton';
import { ICarrierExclusionForm, carrierExclusionFormSchema } from './types';

export interface ICarrierExclusionFormProps {
  carriers: IDropdownValue[];
  item?: ICarrierExclusionView;
  onSubmit: (values: any) => void;
}

const CarrierExclusionForm = ({ carriers, item, onSubmit }: ICarrierExclusionFormProps): JSX.Element => {
  const isReadOnly: boolean = !!item;

  const initialFormState: ICarrierExclusionForm = {
    client: { label: item?.clientName ?? '', value: item?.clientId ?? '' },
    carrierId: item?.carrierId ?? '',
    providerId: item?.providerId ?? '',
    campaignType: item?.campaignType ?? CampaignTypeEnum.FUNDRAISING,
    messageType: item?.messageType ?? MessageTypeEnum.SMS,
  };

  const slimMessageOptions = messageTypeOptions.map((option) => ({ ...option, description: undefined }));

  return (
    <>
      <Formik
        initialValues={initialFormState}
        validationSchema={carrierExclusionFormSchema}
        onSubmit={(values) => {
          onSubmit && onSubmit(values);
        }}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue, setFieldTouched }) => (
          <>
            <form id="carrier-exclusion-form" onSubmit={handleSubmit} className="space-y-2">
              <>
                <ClientDropdown
                  value={values.client}
                  onChange={(newValue) => {
                    setFieldValue('client', newValue);
                    setFieldValue('providerId', '');
                  }}
                  onBlur={() => {
                    setFieldTouched('client');
                  }}
                  disabled={isReadOnly}
                  errorMessage={(errors?.client as any)?.value}
                />
              </>

              <>
                <CarrierDropdown
                  value={values.carrierId}
                  onChange={(newValue) => {
                    setFieldValue('carrierId', newValue?.value ?? '');
                  }}
                  onBlur={() => {
                    setFieldTouched('carrierId', true);
                  }}
                  disabled={isReadOnly}
                  showError={!!(touched.carrierId && errors.carrierId)}
                  errorMessage={errors?.carrierId}
                />
              </>

              <>
                <ProviderDropdown
                  value={values.providerId}
                  onChange={(newValue) => {
                    setFieldValue('providerId', newValue?.value ?? '');
                    setFieldValue('provider', newValue?.value ?? '');
                  }}
                  onBlur={() => {
                    setFieldTouched('providerId', true);
                  }}
                  disabled={isReadOnly}
                  showError={!!(touched.providerId && errors.providerId)}
                  errorMessage={errors?.providerId}
                />
              </>

              <CampaignTypeForClientDropdown
                value={values.campaignType}
                clientId={values.client?.value}
                messageType={values.messageType}
                onChange={(newValue: IDropdownValue) => {
                  setFieldValue('campaignType', newValue.value);
                }}
                onBlur={() => {
                  setFieldTouched('campaignType');
                }}
                disabled={isReadOnly}
                touched={touched.campaignType}
                errorMessage={errors.campaignType}
              />

              <FastField
                name="messageType"
                component={RadioButtonGroup}
                options={slimMessageOptions}
                label="Message Type"
                error={touched.messageType && (errors.messageType as any)?.value}
                disabled={isReadOnly}
                value={slimMessageOptions.find((message) => message.value === values.messageType)}
                onBlur={handleBlur}
                onChange={(messageType: IRadioButtonOption) => {
                  setFieldTouched('messageType');
                  setFieldValue('messageType', messageType.value);
                }}
              />
            </form>
          </>
        )}
      </Formik>
    </>
  );
};

export default CarrierExclusionForm;
