import { EntityName } from '@gr/shared/enums';
import { FilterDataTypeEnum, ISearchRequest, SearchSortDirectionEnum } from '@gr/shared/models';
import * as yup from 'yup';
import { getCsvHeaders } from '../../providers/validation.provider';
import { IColumn, IColumnClickConfig } from '../shared/Table/types';

export interface ILinkForm {
  name: string;
  url: string;
  domain: string;
  // numberList?: File;
  numberList?: any;
}

export const defaultLinkTableOptions: ISearchRequest = {
  entity: EntityName.LINK_ORDERS,
  filters: [],
  pagination: {
    skip: 0,
    take: 100000,
  },
  sort: {
    fieldName: 'createdAt',
    sortDirection: SearchSortDirectionEnum.DESC,
  },
};

export const getColumns = (columnClickEvents: IColumnClickConfig): IColumn[] => {
  return [
    {
      headerName: 'Name',
      fieldName: 'name',
      isRowHeader: true,
      fieldType: FilterDataTypeEnum.STRING,
      onColumnClick: columnClickEvents['name'],
    },
    {
      headerName: 'URL',
      fieldName: 'originalUrl',
      fieldType: FilterDataTypeEnum.STRING,
    },
    {
      headerName: 'File Name',
      fieldName: 'displayFileName',
      fieldType: FilterDataTypeEnum.STRING,
    },
    // {
    //   headerName: 'Links',
    //   fieldName: 'fileName',
    //   fieldType: FilterDataTypeEnum.STRING,
    //   renderColumn: columnRenders['fileName'],
    // },
  ];
};

// TODO: Turn this into a call with validation options
const validateCsvHeaders = async (file: File) => {
  try {
    const headers = await getCsvHeaders(file);

    return headers.length === 1 && headers[0].toLocaleLowerCase() === 'phone';
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const linkFormSchema: yup.SchemaOf<ILinkForm> = yup.object().shape({
  name: yup.string().defined('Required'),
  url: yup.string().url('Invalid URL format').defined('Required'),
  domain: yup.string().defined('Required'),
  numberList: yup
    .mixed()
    .defined('Required')
    // If there's no file, no need to run validation
    .test('csvValidate', 'Uploaded file must be .csv', (file: File): boolean => {
      return file ? file.name?.endsWith('.csv') : true;
    })
    .test('csvHeaderValidate', 'CSV does not contain the proper headers', async (file: File): Promise<boolean> => {
      return file ? await validateCsvHeaders(file) : true;
    }),
});
