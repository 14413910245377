import LoadingIndicator from '../LoadingIndicator';
import { ButtonVariantEnum } from './types';

interface INewButtonProps {
  id?: string;
  text?: string;
  title?: string;
  children?: React.ReactNode;
  disabled?: boolean;
  isLoading?: boolean;
  leadingIcon?: JSX.Element;
  formId?: string;
  variant?: ButtonVariantEnum;
  type?: 'button' | 'submit' | 'reset' | undefined;
  className?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  onTouchStart?: React.TouchEventHandler<HTMLButtonElement>;
  onTouchEnd?: React.TouchEventHandler<HTMLButtonElement>;
  onMouseDown?: React.MouseEventHandler<HTMLButtonElement>;
  onMouseUp?: React.MouseEventHandler<HTMLButtonElement>;
}

export const NewButton = ({
  id,
  text,
  title,
  children,
  disabled,
  isLoading,
  leadingIcon,
  formId,
  variant,
  type,
  className,
  onClick,
  onMouseDown,
  onMouseUp,
  onTouchStart,
  onTouchEnd,
}: INewButtonProps) => {
  const getButtonVariant = () => {
    switch (variant) {
      case ButtonVariantEnum.PRIMARY:
        return 'button-1';
      case ButtonVariantEnum.SECONDARY:
        return 'button-2';
      case ButtonVariantEnum.TERTIARY:
        return 'button-3';
      default:
        return 'button-1';
    }
  };
  const classes = getButtonVariant();
  return (
    <>
      <button
        id={id}
        onClick={(e) => {
          if (onClick && !isLoading) {
            onClick(e);
          }
        }}
        onTouchStart={onTouchStart}
        onTouchEnd={onTouchEnd}
        onMouseDown={onMouseDown}
        onMouseUp={onMouseUp}
        type={type}
        title={title}
        disabled={disabled}
        form={formId}
        className={`${className ?? ''} ${classes}`}
      >
        {leadingIcon && (
          <div className={`${text || children ? 'mr-1' : ''}`} aria-hidden="true">
            {leadingIcon}
          </div>
        )}

        {isLoading ? (
          <div className="relative">
            {/* Make the text invisible, but keep the width so the button doesn't shrink */}
            <div className="h-0 opacity-0">{children || text}</div>
            <LoadingIndicator
              position="CENTER"
              className={`${variant === ButtonVariantEnum.DELETE ? 'border-t-red-500' : 'border-t-sky-500'}`}
            />
          </div>
        ) : children ? (
          children
        ) : (
          text
        )}
      </button>
    </>
  );
};
