import { CampaignStatusEnum } from '@gr/shared/enums';
import { Auth0Role, ExternalV1CampaignDetailsResponse } from '@gr/shared/models';
import { isArray as _isArray, isDate, set } from 'lodash';
import util from 'util';
import { v4 as uuid } from 'uuid';
import { checkAuth0Roles } from './auth-roles.service';
import { ALERT_LOG_PREFIX } from './logging.helper';

export function extractEmbeddedValues(str: string): string[] {

  // Regular expression to match all occurrences of {{value}}
  const regex = /{{(.*?)}}/g;
  const matches = str.match(regex);
  const values: Array<any> = [];

  if (matches) {
    // Iterate over all matches and remove the {{ and }} to extract the value
    for (const match of matches) {
      const value: any = match.slice(2, -2); // Remove the surrounding {{ and }}
      values.push(value);
    }
  }

  return values;
}

export function getEventDelayInSeconds(delayToDate: string): number {

  let delay = 0;

  const fireAt = new Date(delayToDate);
  const currentTime = new Date(new Date().toISOString());

  if (fireAt <= currentTime) {

    delay = 0;

  } else {

    delay = Math.round((fireAt.getTime() - currentTime.getTime()) / 1000);

  }

  return delay;

}

export function getFileMimeType(fileName: string): string {

  const extensionToMimeType: { [key: string]: string; } = {
    'jpg': 'image/jpeg',
    'jpeg': 'image/jpeg',
    'png': 'image/png',
    'gif': 'image/gif',
    'bmp': 'image/bmp',
    'svg': 'image/svg+xml',
    'txt': 'text/plain',
    'pdf': 'application/pdf',
    'doc': 'application/msword',
    'docx': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'xls': 'application/vnd.ms-excel',
    'xlsx': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'ppt': 'application/vnd.ms-powerpoint',
    'pptx': 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'mp3': 'audio/mpeg',
    'wav': 'audio/wav',
    'mpeg': 'video/mpeg',
    'mp4': 'video/mp4',
    'mov': 'video/quicktime',
    'avi': 'video/x-msvideo',
    'ogv': 'video/ogg',
    'webm': 'video/webm',
    'html': 'text/html',
    'css': 'text/css',
    'js': 'application/javascript',
    'ts': 'application/typescript',
    'json': 'application/json',
    'xml': 'application/xml'
  };

  const extension = fileName.split('.').pop()?.toLowerCase() || '';

  return extensionToMimeType[extension] || 'application/octet-stream';

}

export function getCommaSeparatedList(items: Array<any>, propertyName: string = 'id'): string | null {

  if (items.length === 0) { return null; }

  let ids = '';

  if (typeof items[0][propertyName] === 'string') {

    for (let index = 0; index < items.length; index++) {
      ids = ids + `'${items[index][propertyName]}',`;
    }

  } else {

    for (let index = 0; index < items.length; index++) {
      ids = ids + `${items[index][propertyName]},`;
    }

  }

  // Remove trailing comma
  ids = ids.replace(/,\s*$/, '');

  return ids;

}

export function getRandomInt(min: number, max: number): number {

  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;

}

export function addLeadingZeroToNumber(data: number): string {

  const stringNumber = data?.toString();

  if (stringNumber?.length > 1) {

    return stringNumber;

  } else {

    return `0${stringNumber}`;

  }

}

export function promiseTimeout(prom: Promise<any>, time: number, shouldResolve?: boolean): Promise<any> {
  // Be careful when modifying this method, it's used by all the pipes.

  return Promise.race([
    prom,
    new Promise<void>((resolve, reject) =>
      setTimeout(() => {
        shouldResolve ? resolve() : reject('promiseTimeout: Request timed out'); // @Vinnie, do ***NOT*** change this reject message, I got logic tied to it
      }, time)
    ),
  ]);
}

export function getSMSMessageSegmentCount(message: string): number {
  let messageLength: number = getSMSMessageLength(message);
  let segmentLength: number = 160; // GSM-7 Segment length
  let segmentHeaderLength: number = 7; // GSM-7 Segment Data Header

  if (!isGsmMessage(message)) {
    // UCS-2 Segment length and compensation
    segmentLength = 70;
    segmentHeaderLength = 3;
  }

  const effectiveSegment = segmentLength - segmentHeaderLength;

  const segments = messageLength <= segmentLength ? 1 : Math.ceil(messageLength / effectiveSegment);

  return segments;
}

export function isGsmMessage(message: string): boolean {
  const gsmCodePoints = new Set([
    0x000a, 0x000c, 0x000d, 0x0020, 0x0021, 0x0022, 0x0023, 0x0024, 0x0025, 0x0026, 0x0027, 0x0028, 0x0029, 0x002a,
    0x002b, 0x002c, 0x002d, 0x002e, 0x002f, 0x0030, 0x0031, 0x0032, 0x0033, 0x0034, 0x0035, 0x0036, 0x0037, 0x0038,
    0x0039, 0x003a, 0x003b, 0x003c, 0x003d, 0x003e, 0x003f, 0x0040, 0x0041, 0x0042, 0x0043, 0x0044, 0x0045, 0x0046,
    0x0047, 0x0048, 0x0049, 0x004a, 0x004b, 0x004c, 0x004d, 0x004e, 0x004f, 0x0050, 0x0051, 0x0052, 0x0053, 0x0054,
    0x0055, 0x0056, 0x0057, 0x0058, 0x0059, 0x005a, 0x005b, 0x005c, 0x005d, 0x005e, 0x005f, 0x0061, 0x0062, 0x0063,
    0x0064, 0x0065, 0x0066, 0x0067, 0x0068, 0x0069, 0x006a, 0x006b, 0x006c, 0x006d, 0x006e, 0x006f, 0x0070, 0x0071,
    0x0072, 0x0073, 0x0074, 0x0075, 0x0076, 0x0077, 0x0078, 0x0079, 0x007a, 0x007b, 0x007c, 0x007d, 0x007e, 0x00a1,
    0x00a3, 0x00a4, 0x00a5, 0x00a7, 0x00bf, 0x00c4, 0x00c5, 0x00c6, 0x00c7, 0x00c9, 0x00d1, 0x00d6, 0x00d8, 0x00dc,
    0x00df, 0x00e0, 0x00e4, 0x00e5, 0x00e6, 0x00e8, 0x00e9, 0x00ec, 0x00f1, 0x00f2, 0x00f6, 0x00f8, 0x00f9, 0x00fc,
    0x0393, 0x0394, 0x0398, 0x039b, 0x039e, 0x03a0, 0x03a3, 0x03a6, 0x03a8, 0x03a9, 0x20ac,
  ]);

  for (const s of message) {
    const codePoint = s.codePointAt(0);
    if (codePoint && !gsmCodePoints.has(codePoint)) {
      return false;
    }
  }
  return true;
}

const escapeAndReplaceHash = {
  "`": "'"
};

export function escapeAndReplaceDefinedCharacters(message: string) {
  const charactersToReplace = Object.keys(escapeAndReplaceHash);
  charactersToReplace.forEach((char) => {
    if (message.includes(char)) {
      message = message.replace(new RegExp(char, 'g'), escapeAndReplaceHash[char]);
    }
  });

  return message;
}

export function getSMSMessageLength(message: string): number {

  const length: number = message.length;

  // Account for escaped characters
  const escapedCharacters: Set<number> = new Set([
    0x007c, 0x005e, 0x20ac, 0x007b, 0x007d, 0x005b, 0x005d, 0x007e, 0x005c,
  ]);

  const escapedCharacterCount: number = message
    .split('')
    .filter((char) => escapedCharacters.has(char.codePointAt(0) ?? -1)).length;

  // It seems that different providers handle newlines differently. If we need to start calculating for them, add in this logic:
  // const lines = (message.match(/\n/g) || '').length;

  return length + escapedCharacterCount;
}

export function generateGUID(): string {
  return uuid();
}

export function isGUID(value: string): boolean {
  // Regular expression to check if string is a valid UUID
  const regex: RegExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;
  return regex.test(value);
}

export function numberWithCommas(value: number): string {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function isEmail(email: string): boolean {
  const regex: RegExp =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(email.toLowerCase());
}

// Returns random whole number
export function getRandomNumber(min: number, max: number): number {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function stringToBase64(str: string): string {
  return Buffer.from(str, 'utf-8').toString('base64');
}

export function isDummyNumber(phoneNumber?: string): boolean {
  return phoneNumber?.substring(3, 6) === '555';
}

export function deFormatPhone(phoneNumber: string): string | null {
  // Remove all parenthesis, dashes, plus, dots and spaces
  let deFormattedNumber = phoneNumber?.replace(/[()\-+\s\.]/g, '').trim();

  if (!deFormattedNumber) {
    console.log(`bad number: "${phoneNumber}"`);
    return null;
  }

  // Remove leading 1. US Area codes cannot start with 1, so leading 1's mean the country code was included
  if (deFormattedNumber.charAt(0) === '1') {
    deFormattedNumber = deFormattedNumber.substring(1);
  }

  // If they pass in anything other than a 10-digit number, skip
  if (!/^\d{10}$/g.test(deFormattedNumber)) {
    console.log('bad number:', phoneNumber);
    return null;
  }

  if (deFormattedNumber?.charAt(0) === '0') {
    console.log('bad number:', phoneNumber);
    return null;
  }

  return deFormattedNumber;
}

export function formatPhoneE164(phoneNumber: string): string {
  let formatted = phoneNumber.replace(/\D/g, '');
  formatted = formatted.trim();

  if (formatted.length === 10) {
    return `+1${formatted}`;
  }

  return phoneNumber;
}

export function chunkArray<T>(arrayData: T[], chunkSize: number): T[][] {
  let i, j;
  const arrayChunks: T[][] = [];

  if (!arrayData?.length) {
    return [];
  }

  if (typeof chunkSize === 'undefined' || arrayData.length <= chunkSize) {
    arrayChunks.push(arrayData);
  } else {
    for (i = 0, j = arrayData.length; i < j; i += chunkSize) {
      arrayChunks.push(arrayData.slice(i, i + chunkSize));
    }
  }

  return arrayChunks;
}

export function isNullOrEmptyOrUndefined(value: any): boolean {
  if (value === null || typeof value === 'undefined' || value === '' || value === 'undefined') {
    return true;
  }
  return false;
}

export interface BatchProcessRequest<T> {
  name: string;
  items: any[];
  size: number;
  parallel?: number;
  alert?: boolean;
  process: (chunk: any[]) => Promise<T>;
}

export async function batchProcess<T>({ name, items, size, process, parallel = 1, alert = false }: BatchProcessRequest<T>) {
  const time = name + ' ' + Date.now();
  try {
    if (!items || items.length <= 0) {
      return null;
    }

    console.time(time);

    const chunks = chunkArray(items, size);
    console.log(`${name}: Number of Chunks: ${chunks.length}`);

    const responses: T[] = [];

    for (let i = 0; i < chunks.length; i += parallel) {
      const indicies = range(i, parallel);
      console.log(`${name}: Processing Chunk #${indicies}`);
      const promises = indicies.filter(j => j < chunks.length).map(j => process(chunks[j]));
      responses.push(...await Promise.all(promises));
    }

    return responses;
  } catch (error) {
    console.error(alert ? ALERT_LOG_PREFIX : `${name}:`, { error });
    throw error;
  } finally {
    console.timeEnd(time);
  }
}

export function isNil(value: any): boolean {
  return [null, undefined, NaN].includes(value);
}

export function isString(value: any) {
  return !isNil(value) && typeof value == 'string';
}

export function isObject(value: any) {
  return !isNil(value) && typeof value == 'object';
}

export function isFunction(value: any) {
  return !isNil(value) && typeof value == 'function';
}

export function isArray(value: any) {
  return !isNil(value) && hasLength(value.length) && !isFunction(value);
}

export function range(start: number, size: number): ReadonlyArray<number> {
  return [...Array(size).keys()].map(i => i + start);
}

function hasLength(value: any) {
  return typeof value == 'number' && value > -1 && value % 1 == 0;
}

export function getSqlList(values: any[]) {
  return values.map(v => `'${v}'`).join(',');
}

export function deleteNilValues<T extends {}>(object: T): Partial<T> {
  const copy = clone(object);
  Object.keys(copy).forEach(key => isNil(copy[key]) ? delete copy[key] : {});
  return copy;
}

export function clone<T>(object: T): T {
  return JSON.parse(JSON.stringify(object));
}

export function clamp(value: number, min: number, max: number) {
  return value <= min
    ? min
    : value >= max
      ? max
      : value;
}

export function endsWith(value: string, suffix: string) {
  return value.indexOf(suffix, value.length - suffix.length) !== -1;
}

export function getFulfilledPromiseValues<T>(results: PromiseSettledResult<T>[]) {
  return results.filter(p => p?.status === 'fulfilled').map(p => <PromiseFulfilledResult<T>>p).map(p => p.value);
}

export function getRejectedPromiseReasons<T>(results: PromiseSettledResult<T>[]) {
  return results.filter(p => p?.status === 'rejected').map(p => <PromiseRejectedResult>p).map(p => p?.reason);
}

export function escapeSingleQuotes(value: string) {
  return value ? value.replace(new RegExp(`'`, 'g'), `''`) : '';
}

export function escapePercent(value: string) {
  return value ? value.replace(new RegExp('%', 'g'), '\\%') : '';
}

export function escapeDBString(value: string) {
  return value ? escapePercent(escapeSingleQuotes(value)) : '';
}

export function isSuperAdmin(role: Auth0Role) {
  return checkAuth0Roles({ userRoles: [role], targetRoles: [Auth0Role.GR_ADMIN] });
}

export function isAdmin(role: Auth0Role) {
  return checkAuth0Roles({ userRoles: [role], targetRoles: [Auth0Role.GR_STAFF_USER] });
}

export function isStaffUserOrHigher(role: Auth0Role) {
  return checkAuth0Roles({ userRoles: [role], targetRoles: [Auth0Role.GR_STAFF_USER] });
}

export function isClicker(role: Auth0Role) {
  return role === Auth0Role.GR_CLICKER;
}

export function logDeep(...items: any[]) {
  console.log(util.inspect(items, { depth: 10 }));
}

export function isCampaignPausable(status?: CampaignStatusEnum) {
  if (!status) {
    return false;
  } else {
    return [CampaignStatusEnum.SCHEDULING, CampaignStatusEnum.LIVE].includes(status);
  }
}

export function isCampaignUnpausable(campaign: ExternalV1CampaignDetailsResponse) {
  if (!!campaign && campaign.endsAt) {
    return campaign.status === CampaignStatusEnum.PAUSED && (new Date(campaign.endsAt) > new Date());
  } else {
    return false;
  }
}

export function extendObject<T>(value: T, data: Partial<T>): T {
  return <T>Object.assign({}, value ?? {}, data ?? {});
}

export function chunkString(value: string, length: number) {
  const items = value?.match(new RegExp('(.|\n|\r|\t|\f){1,' + length + '}', 'gm')) ?? [];
  return items?.filter(item => !!item)?.map(item => item) ?? [];
}

export function unflatten(value: any): any {
  if (isNil(value) || isDate(value)) {
    return value;
  }

  if (_isArray(value)) {
    return value?.map(item => unflatten(item)) ?? [];
  }

  switch (typeof value) {
    case 'function':
      return undefined;

    case 'object':
      return Object.keys(value)?.reduce((result, key) => set(result, key, unflatten(value?.[key])), {});

    case 'string':
    case 'number':
    case 'bigint':
    case 'boolean':
    case 'symbol':
    case 'undefined':
    default:
      return value;
  }
}

export type OptionalPromise<T> = Promise<T> | T;

export function trimToByteLength(value: string, maxBytes: number) {
  const encoder = new TextEncoder();
  const decoder = new TextDecoder('utf-8');
  const uint8Array = encoder.encode(value);
  const section = uint8Array.slice(0, maxBytes + 1);
  return decoder.decode(section).replace(/\uFFFD/g, '');
}

export function caseInsensitiveIncludes(value: string, targets: string[]) {
  return !!value
    && targets?.length > 0
    && !!targets?.find(target => value?.toLowerCase()?.trim()?.includes(target?.toLowerCase()?.trim()));
}

export function getStringByteSize(value: string) {
  return new Blob([value]).size;
}

export const checkSHAFTCompliance = (message: string | undefined): boolean => {
  // !!WARNING!! BANNED WORDS //
  const bannedWords = [
    'Ass',
    'Asshole',
    'Chink',
    'Islamophobe',
    'homophobe',
    'Bastard',
    'Bitch',
    'Fucker',
    'Cunt',
    'Fuck',
    'Goddamn',
    'Shit',
    'Motherfucker',
    'Nigga',
    'Nigger',
    'Prick',
    'Shit',
    'son of a bitch',
    'Whore',
    'Thot',
    'Slut',
    'Faggot',
    'Dick',
    'Pussy',
    'Penis',
    'Vagina',
    'Negro',
    'Coon',
    'Bitched',
    'Cock',
    'Rape',
    'Molest',
    'Anal',
    'Buttrape',
    'Coont',
    'Sex',
    'Retard',
    'Fuckface',
    'Dumbass',
    'anal',
    'anus',
    'asshole',
    'assholes',
    'assmunch',
    'asswhole',
    'autoerotic',
    'ballsack',
    'bastard',
    'beastial',
    'beastiality',
    'bitch',
    'bitches',
    'bitchin',
    'bitching',
    'blow job',
    'blowjob',
    'blowjobs',
    'boner',
    'boob',
    'boobs',
    'breasts',
    'chink',
    'cock',
    'coon',
    'cum',
    'cunt',
    'dick',
    'dildo',
    'dildos',
    'ejaculate',
    'fag',
    'faggot',
    'fagot',
    'fucked',
    'fucker',
    'fuckers',
    'fuckhead',
    'fuckheads',
    'fuckin',
    'fucking',
    'god damn',
    'jackoff',
    'masterbate',
    'mothafucker',
    'nigg3r',
    'nigg4h',
    'nigga',
    'niggah',
    'niggas',
    'niggaz',
    'nigger',
    'niggers',
    'nob',
    'orgasim',
    'penis',
    'porn',
    'porno',
    'pornography',
    'prick',
    'pussy',
    'queer',
    'rectum',
    'retard',
    'semen',
    'sex',
    'shit',
    'slut',
    'testical',
    'tits',
    'vagina',
    'viagra',
    'dumbass',
    'Child predator',
  ];
  // END BANNED WORDS //
  const wordSearch = !bannedWords.some(word => getWords(message ?? '').includes(word.toLowerCase()));
  const messageSearch = !bannedWords.some(word => getMessage(message ?? '').includes(' ' + word.toLowerCase() + ' '));
  return wordSearch && messageSearch;
};

function getWords(text: string) {
  return text
    ?.trim()
    ?.toLowerCase()
    ?.replace(/\s+/gm, ' ') // Squash sequential whitespace into one space
    ?.replace(/[^a-zA-Z0-9 ]/gm, ' ') // Remove all non alphanumeric characters
    ?.split(' ')
    ?.map(word => word?.trim()?.toLowerCase())
    ?.filter(word => !!word && word?.length > 0)
    ?? [];
}

function getMessage(text: string) {
  return (' ' + text
    ?.trim()
    ?.toLowerCase()
    ?.replace(/\s+/gm, ' ') // Squash sequential whitespace into one space
    ?.replace(/[^a-zA-Z0-9 ]/gm, ' ') // Remove all non alphanumeric characters
    + ' ');
}
