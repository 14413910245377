import { User, useAuth0 } from '@auth0/auth0-react';
import { useMembership } from '@gr/portal/contexts/MembershipContext';
import { SidebarContext } from '@gr/portal/contexts/SidebarContext';
import { getUserMemberships, logout } from '@gr/portal/providers/auth0.service';
import { Auth0Role } from '@gr/shared/models';
import { checkAuth0Roles } from '@gr/shared/utils';
import { getSubDomain, getSubDomainByOrg, getSubDomainLabel, goToSubDomain } from '@gr/shared/utils/auth0-sub-domain.service';
import { Disclosure } from '@headlessui/react';
import { useContext, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { IDropdownValue } from '../Form/Dropdown';
import SubDomainDropdown from '../Form/Dropdowns/SubDomainDropdown';
import { Campaigns as CampaignsIcon } from '../Icons/Campaigns';
import { Clients as ClientsIcon } from '../Icons/Clients';
import { Contacts as ContactsIcon } from '../Icons/Contacts';
import { Conversations as ConversationsIcon } from '../Icons/Conversations';
import { Logout as LogoutIcon } from '../Icons/Logout';
import { Outbox as OutboxIcon } from '../Icons/Outbox';
import { Settings as SettingsIcon } from '../Icons/Settings';
import { Support as SupportIcon } from '../Icons/Support';
import Festive from '../Other/Festive';
import { getFestiveAssetName, getFestiveStyle } from '../Other/types';
import Shimmer, { ShimmerType } from '../Shimmer';
import { MenuButton } from './MenuButton';
import { MenuItem } from './MenuItem';

export interface INavItem {
  name: string;
  icon?: any; // (props: SVGProps<SVGSVGElement>) => JSX.Element;
  href?: string;
  visible?: boolean;
  children?: INavItem[];
}

const LeftNavContent = (): JSX.Element => {
  const location = useLocation();
  const { devMode, sidebarOpen, setDevMode, setSidebarOpen, activeUser } = useContext(SidebarContext);
  const { logout: auth0Logout, user: auth0User, isLoading } = useAuth0();
  const { membership: memberships, isLoaded } = useMembership();
  const openDisc = useRef<HTMLButtonElement>();
  const subDomain = getSubDomain();
  const [showVersion, setShowVersion] = useState(false);

  const [subDomainDropdownValue, setSubDomainDropdownValue] = useState({
    label: getSubDomainLabel(subDomain),
    value: subDomain,
  } as IDropdownValue);
  const userRoles = memberships?.roles ?? [];
  const outboxRoles = [
    Auth0Role.GR_CLICKER,
    Auth0Role.GR_STAFF_MANAGER,
    Auth0Role.GR_STAFF_USER,
    Auth0Role.GR_CLIENT_MANAGER,
    Auth0Role.GR_CLIENT_USER,
    Auth0Role.GR_ADMIN
  ];

  const getNavbarItems = (): INavItem[] => {
    const navigation: INavItem[] = [
      {
        name: 'Campaigns',
        icon: CampaignsIcon,
        href: '/app/campaigns',
        visible: checkAuth0Roles({ userRoles, targetRoles: [Auth0Role.GR_STAFF_USER] }),
      },
      {
        name: 'Outbox',
        href: '/app/my-campaigns',
        icon: OutboxIcon,
        visible: userRoles?.some((role) => outboxRoles?.includes(role)),
      },
      {
        name: 'Conversations',
        href: '/app/conversations',
        icon: ConversationsIcon,
        visible: checkAuth0Roles({
          userRoles,
          targetRoles: [Auth0Role.GR_CLIENT_USER],
        }),
      },
      {
        name: 'Contacts',
        icon: ContactsIcon,
        visible: checkAuth0Roles({ userRoles, targetRoles: [Auth0Role.GR_STAFF_USER] }),
        children: [
          {
            name: 'Contact Lists',
            href: '/app/contact-lists',
            visible: checkAuth0Roles({
              userRoles,
              targetRoles: [Auth0Role.GR_STAFF_USER],
            }),
          },
          {
            name: 'Opt-outs',
            href: '/app/opt-outs',
            visible: checkAuth0Roles({
              userRoles,
              targetRoles: [Auth0Role.GR_STAFF_USER],
            }),
          },
          {
            name: 'Health Check Numbers',
            href: '/app/test-numbers',
            visible: checkAuth0Roles({
              userRoles,
              targetRoles: [Auth0Role.GR_STAFF_USER],
            }),
          },
          {
            name: 'Test Message Data',
            href: '/app/test-message-data',
            visible: checkAuth0Roles({
              userRoles,
              targetRoles: [Auth0Role.GR_STAFF_USER],
            }),
          },
        ],
      },
      {
        name: 'Clients',
        href: '/app/clients',
        icon: ClientsIcon,
        visible: checkAuth0Roles({ userRoles, targetRoles: [Auth0Role.GR_CLIENT_MANAGER] }),
      },
      {
        name: 'Settings',
        icon: SettingsIcon,
        visible: checkAuth0Roles({ userRoles, targetRoles: [Auth0Role.GR_STAFF_MANAGER] }),
        children: [
          {
            name: 'System Numbers',
            href: '/app/system-numbers',
            visible: checkAuth0Roles({
              userRoles,
              targetRoles: [Auth0Role.GR_ADMIN],
            }),
          },
          {
            name: 'Carrier Exclusions',
            href: '/app/carrier-exclusions',
            visible: checkAuth0Roles({
              userRoles,
              targetRoles: [Auth0Role.GR_ADMIN],
            }),
          },
          {
            name: 'TCR Campaigns',
            href: '/app/tcr-campaigns',
            visible: checkAuth0Roles({
              userRoles,
              targetRoles: [Auth0Role.GR_ADMIN],
            }),
          },
          {
            name: 'Users',
            href: '/app/users',
            visible: checkAuth0Roles({
              userRoles,
              targetRoles: [Auth0Role.GR_STAFF_MANAGER],
            }),
          },
        ],
      },
    ];
    return navigation;
  };

  const getFullName = (user?: User) => {
    const firstName = activeUser?.firstName ?? user?.given_name ?? user?.app_metadata?.firstName;
    const lastName = activeUser?.lastName ?? user?.family_name ?? user?.app_metadata?.lastName;
    return [firstName?.trim(), lastName?.trim()].filter((n) => n).join(' ');
  };

  const navItems: INavItem[] = getNavbarItems();

  return (
    <div id="nav-content" className="justify-between nav-bar">
      {devMode && <Festive />}
      <div className="nav-menu">
        <div className="select-none logo">
          <img
            id="grassroots-logo"
            width={160}
            height={32}
            src={`/${getFestiveAssetName()}`}
            alt="GrassRoots Logo"
            onDoubleClick={() => {
              setShowVersion(!showVersion);
            }}
          />
          {showVersion && <span className='absolute text-black pt-7'>{`v${memberships?.version ?? 'N/A'}`}</span>}
        </div>
        <nav
          className={`flex flex-col items-center flex-1 px-2 space-y-2 bg-white dark:bg-slate-800 ${devMode ? getFestiveStyle() : 'bg-gray-100'
            }`}
          aria-label="Sidebar"
        >
          {!isLoaded ? (
            <Shimmer type={ShimmerType.LIST} rows={5} />
          ) : (
            <>
              {(getUserMemberships(auth0User).flatMap((membership) =>
                getSubDomainByOrg(membership.organization)
              ).length ?? 0) > 1 && (
                  <SubDomainDropdown
                    auth0User={auth0User}
                    value={subDomainDropdownValue}
                    onChange={(v) => {
                      if (!!v) {
                        setSubDomainDropdownValue(v!);
                        goToSubDomain(v.value);
                      }
                    }}
                  />
                )}
              {navItems.map(
                (item) =>
                  item.visible &&
                  (!item.children ? (
                    <MenuItem key={item.name} item={item} active={item.href === location.pathname} />
                  ) : (
                    <Disclosure
                      as="div"
                      key={item.name}
                      className="space-y-1"
                      defaultOpen={!!item.children?.find((ch) => ch.href === location.pathname)}
                    >
                      {({ open, close }) => (
                        <>
                          <MenuButton
                            onClick={(e) => {
                              // Headless does not expose ways to imperatively close other panels, this is a work around
                              if (openDisc.current && openDisc.current.id !== e.currentTarget.id) {
                                // We're opening a nav item when a seperate one is already open here
                                openDisc.current.click();
                                openDisc.current = e.currentTarget;
                              } else if (openDisc.current) {
                                // We're closing a nav item here
                                openDisc.current = undefined;
                              } else {
                                // We're opening a nav item here
                                openDisc.current = e.currentTarget;
                              }
                            }}
                            key={item.name}
                            item={item}
                            active={item.href === location.pathname}
                            open={open}
                          />
                          <Disclosure.Panel className="space-y-1">
                            {item.children?.map(
                              (subItem) =>
                                subItem?.visible && (
                                  <MenuItem
                                    key={subItem.name}
                                    item={subItem}
                                    active={subItem.href === location.pathname}
                                  />
                                )
                            )}
                          </Disclosure.Panel>
                        </>
                      )}
                    </Disclosure>
                  ))
              )}
            </>
          )}
        </nav>
      </div>
      <div className="flex flex-col flex-shrink-0 px-2 pb-10 mt-4">
        {getFullName(auth0User).length > 0 && (
          <div className="flex flex-col items-center">
            <div className="button-text text-wc-blue">{`${getFullName(auth0User).toLocaleUpperCase()}`}</div>
            <div className="body-text-small">{auth0User?.email ?? ''}</div>
          </div>
        )}
        <div className="flex justify-center mt-5 space-x-8 align-middle">
          <div
            className="cursor-pointer"
            onClick={() => window.open('https://wondercave.freshdesk.com/support/home', '_blank')}
          >
            <SupportIcon />
          </div>
          <div
            className="cursor-pointer"
            onClick={() => {
              logout({ auth0Logout, auth0User });
            }}
          >
            <LogoutIcon />
          </div>
        </div>
        <div className="mt-12 text-tiny">{`© ${new Date().getFullYear()}, Wondercave. All rights reserved.`}</div>
        {/* <button
          onClick={() => window.open('https://wondercave.freshdesk.com/support/home', '_blank')}
          className="flex-shrink-0 block w-full px-2 py-2 rounded-md group hover:bg-gray-100 hover:text-sky-800 hover:dark:text-slate-900"
        >
          <div className="flex items-center">
            <div>
              <QuestionMarkCircleIcon className="w-6 h-6 " aria-hidden="true" />
            </div>
            <div className="ml-3">
              <p className="text-sm font-medium">Support</p>
            </div>
          </div>
        </button>
        <button
          className="flex-shrink-0 block w-full px-2 py-2 rounded-md group hover:bg-gray-100 hover:text-sky-800 hover:dark:text-slate-900"
          onClick={() => {
            logout({ auth0Logout, auth0User });
          }}
        >
          <div className="flex items-center">
            <div></div>
            <div className="ml-3 ">
              <p className="text-sm font-medium ">Sign Out</p>
            </div>
          </div>
        </button> */}
      </div>
    </div>
  );
};

export default LeftNavContent;
