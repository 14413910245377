import { Button, ButtonVariantEnum, IColumn, LoadingIndicator, TableList, TextInput } from '@Wonder-Cave/ui';
import { Filter } from '@Wonder-Cave/ui/dist/Table/Filters';
import { useCache } from '@gr/portal/contexts/CacheContext';
import { convertEnumToReadableString } from '@gr/portal/providers/utility.provider';
import { CampaignStatusEnum, CampaignTypeEnum, EntityName, MessageTypeEnum, UiCampaignStatusEnum } from '@gr/shared/enums';
import {
  FilterDataTypeEnum,
  FilterOperatorEnum,
  ICampaignCloneRequest,
  ICampaignCreationRequest,
  ICampaignDetails,
  ICampaignsListView,
  IDeleteOneRequest,
  IExportConversationsRequest,
  IHttpResponse,
  ISearchFilter
} from '@gr/shared/models';
import { isCampaignPausable } from '@gr/shared/utils';
import { CheckCircleIcon, ClockIcon, MinusCircleIcon, XCircleIcon } from '@heroicons/react/solid';
import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { axiosPatch, axiosPost } from '../../authAxios';
import { NotificationType, useNotifications } from '../../contexts/NotificationContext';
import { SocketContext } from '../../contexts/SocketContext';
import useCampaigns from '../../hooks/useCampaigns';
import { IDropdownValue } from '../shared/Form/Dropdown';
import ClientDropdown from '../shared/Form/Dropdowns/NewClientDropdown';
import { IRadioButtonOption } from '../shared/Form/RadioButton';
import CampaignDeleteModal from './CampaignDeleteModal';
import CampaignPauseResumeModal from './CampaignPauseResumeModal';
import CampaignDeliveredProgressBar from './Columns/CampaignDeliveredProgressBar';
import CampaignStatus from './Columns/CampaignStatus';
import { CampaignTitle } from './Columns/CampaignTitle';
import { getCampaignEndDateForStartDate, getCampaignStartDate, mapToStatusFilter } from './services/campaigns.service';
import { ICampaignForm, defaultCampaignsTableOptions, getColumns } from './types';

const Campaigns = () => {
  const [showDetailsPanel, setShowDetailsPanel] = useState(false);
  const [campaignCreating, setCampaignCreating] = useState(false);
  const [defaultFormValues, setDefaultFormValues] = useState<ICampaignDetails>();
  const [searchText, setSearchText] = useState('');
  const { addNotification } = useNotifications();
  const [tableOptions, setTableOptions] = useState(defaultCampaignsTableOptions);
  const socketManager = useContext(SocketContext);
  // TODO: Remove these and use hooks
  const [actionLoading, setActionLoading] = useState(false);
  const [loadingOverride, setLoadingOverride] = useState(false);
  const [selectedItem, setSelectedItem] = useState<ICampaignsListView>();
  const [showPauseModal, setShowPauseModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const history = useHistory();
  const { sendingWindow } = useCache();

  // I'm wondering if caching won't work until we have separate API calls so that hooks know what's going on.
  // We also need to enable it in app.tsx I'm assuming
  const [{ data: campaignData, loading: campaignLoading, error: campaignError }, refetch] = useCampaigns(tableOptions);
  //const [{ data: clientsData, loading: clientsLoading, error: clientsError }, refetchClients] = useClients(allEnabledClientsSearchOptions);

  const campaigns: ICampaignsListView[] =
    campaignData?.data.records.map((campaign, idx) => ({
      ...campaign,
      status: CampaignStatusEnum[campaign.status],
    })) ?? [];

  const campaignTotalCount = campaignData?.data.totalCount;

  const handleRefetch = async () => {
    try {
      await refetch();
    } catch (err) { }
  };

  const updateCampaignsInvisible = async () => {
    // Only refresh the grid if users aren't actively filtering and they're on page one
    if (tableOptions.filters.length === 0 && tableOptions.pagination.skip === 0) {
      setLoadingOverride(true);
      await handleRefetch();
      setLoadingOverride(false);
    }
  };

  useEffect(() => {
    const searchText = tableOptions.filters.find((filter) => filter.fieldName === 'name')?.value;

    handleRefetch();

    setSearchText(searchText ?? '');
  }, [tableOptions]);

  useEffect(() => {
    //const unsubscribeTestMessage = socketManager!.subscribe('test-message-inbound', updateCampaignsInvisible);
    const unsubscribeScheduledMessages = socketManager!.subscribe(
      'campaign-all-messages-scheduled',
      updateCampaignsInvisible
    );

    return () => {
      //unsubscribeTestMessage();
      unsubscribeScheduledMessages();
    };
  }, [tableOptions]);

  const closeDetailsPanel = () => {
    setShowDetailsPanel(false);
    setDefaultFormValues(undefined);
  };

  const columns = getColumns({
    title: (item: ICampaignDetails) => <CampaignTitle campaign={item} />,
    status: (item: ICampaignDetails) => (
      <CampaignStatus
        status={item.status}
        startsAt={item.startsAt ?? new Date()}
        totalMessagesScheduled={item.totalMessagesScheduled ?? 0}
        totalMessagesSent={item.totalMessagesSent ?? 0}
        totalMessagesConfirmed={item.totalMessagesConfirmed ?? 0}
        sendingWindow={sendingWindow}
        allListsClassified={item.allListsClassified}
      />
    ),
    deliveredProgress: (item: ICampaignDetails) => <CampaignDeliveredProgressBar campaign={item} />,
    healthCheck: (item: ICampaignDetails) =>
      item.sendTestMessages ? (
        item.totalTestMessagesFailed > 0 ? (
          <span title="One or more test messages failed to deliver" className="select-none">
            <XCircleIcon className="w-5 h-5 text-red-400" />
          </span>
        ) : item.totalTestMessagesSent === item.totalTestMessagesDelivered && item.totalTestMessagesSent !== 0 ? (
          <span title="All test messages delivered" className="select-none">
            <CheckCircleIcon className="w-5 h-5 text-emerald-400" />
          </span>
        ) : (
          <span title="Not all test messages are delivered yet" className="select-none">
            <ClockIcon className="w-5 h-5 text-sky-400" />
          </span>
        )
      ) : (
        <span title="No health check performed" className="select-none">
          <MinusCircleIcon className="w-5 h-5 text-gray-400" />
        </span>
      ),
  });

  const filterColumns: IColumn[] = columns.filter((col) =>
    ['name', 'clientName', 'messageType', 'type', 'status'].includes(col.fieldName)
  );

  const handleSearch = (overrideText?: string) => {
    let searchFilter: ISearchFilter = {
      dataType: FilterDataTypeEnum.STRING,
      fieldName: 'name',
      operator: FilterOperatorEnum.CONTAINS,
      value: searchText,
    };

    if (overrideText !== null && overrideText !== undefined) {
      setSearchText(overrideText);
      searchFilter.value = overrideText;
    }

    const newSearchOptions = { ...defaultCampaignsTableOptions };

    const nonSearchFilters = tableOptions.filters.filter((filter) => filter.fieldName !== 'name');

    newSearchOptions.filters = searchFilter.value ? [...nonSearchFilters, searchFilter] : [...nonSearchFilters];

    setTableOptions(newSearchOptions);
  };

  const handleSubmit = async (formData: ICampaignForm) => {
    const campaignRequest: ICampaignCreationRequest = {
      name: formData.name,
      clientId: formData.client?.value,
      contactListIds: formData.contactLists.map((cl) => cl.value),
      suppressionListIds: formData.suppressionLists.map((sl) => sl.value),
      suppressionAreaCodes: formData.suppressionAreaCodes.map((sac) => sac.value),
      suppressionStates: formData.suppressionStates.map((ss) => ss.value),
      clickerGroupId: (formData.clickerGroup !== '' ? formData.clickerGroup : null) ?? null,
      messageType: (formData.messageType as any as IRadioButtonOption).value,
      message: formData.message,
      startsAt: formData.startsAt?.toISOString() ?? new Date().toISOString(),
      endsAt: formData.endsAt?.toISOString() ?? getCampaignEndDateForStartDate(new Date()).toISOString(),
      externalId: formData.externalId,
      mediaName: formData.mediaName ?? '',
      mediaUrl: formData.mediaUrl ?? '',
      isMMSVideo: formData.isMMSVideo ?? false,
      sendTestMessages: formData.sendTestMessages ?? false,
      type: formData.campaignType!,
      clickTrackingEnabled: formData.clickTrackingEnabled ?? false,
      generateUniqueLinks: formData.generateUniqueLinks ?? false,
      enableTwoWay: (formData as any).enableTwoWay ?? false,
      url: formData.clickTrackingEnabled ? formData.url : '',
      domain: formData.clickTrackingEnabled ? formData.domain : '',
      timezonePrioritizationEnabled: formData.timezonePrioritizationEnabled ?? true,
    };

    setCampaignCreating(true);

    // Create campaign
    try {
      await axiosPost<IHttpResponse<string>>('/campaigns-create', campaignRequest);

      setTableOptions({ ...defaultCampaignsTableOptions });

      closeDetailsPanel();

      addNotification({
        header: 'Campaign saved successfully!',
      });
    } catch (err) {
      console.error('An error occurred when attempting to create a new campaign');
    } finally {
      setCampaignCreating(false);
    }
  };

  const toggleCampaignPause = async (): Promise<void> => {
    if (!selectedItem) return;

    setActionLoading(true);

    try {
      // TODO: Use hook for loading indicator
      await axiosPatch(`v1/campaigns/${selectedItem.id}`, {
        status: isCampaignPausable(selectedItem.status) ? CampaignStatusEnum.PAUSED : CampaignStatusEnum.LIVE
      });
      // once campaign toggle status is complete remove loading spinner
      setActionLoading(false);
      setLoadingOverride(true);
      await handleRefetch();
      setLoadingOverride(false);

      addNotification({
        header:
          selectedItem.status === CampaignStatusEnum.PAUSED
            ? 'Campaign resumed successfully'
            : 'Campaign paused successfully',
      });
    } catch (err) {
      console.error(err);
    } finally {
      setSelectedItem(undefined);
      setActionLoading(false);
    }
  };

  const handleCampaignClone = async (item: ICampaignsListView): Promise<void> => {
    try {
      setActionLoading(true);
      let startsAt = new Date(item?.startsAt ?? ''); // UTC -> EST
      if (startsAt < new Date()) {
        startsAt = new Date();
        startsAt.setTime(startsAt.getTime()); // set date to today; keep time the same
      }
      const endsAt = getCampaignEndDateForStartDate(startsAt ?? getCampaignStartDate()).toISOString();
      const req: ICampaignCloneRequest = { campaignId: item?.id ?? '', startsAt: startsAt.toISOString(), endsAt };
      const response = await axiosPost(`/campaigns/clone`, req);
      if (response.status === 200) {
        addNotification({ type: NotificationType.SUCCESS, header: 'Successfully cloned campaign.' });
        // if user navigates from campaign page during cloning, do not redirect to campaign edit page
        if (history.location.pathname === '/app/campaigns')
          history.push(`/app/campaigns/${response?.data?.data?.id}/edit`);
      } else {
        addNotification({ type: NotificationType.FAILURE, header: 'Failed to clone campaign.' });
      }
    } catch (err) {
      addNotification({ type: NotificationType.FAILURE, header: 'Failed to clone campaign.' });
      console.error(err);
    } finally {
      setActionLoading(false);
    }
  };

  const handleCampaignDelete = async (): Promise<void> => {
    if (!selectedItem) return;

    setActionLoading(true);

    const deleteRequest: IDeleteOneRequest = {
      entityId: selectedItem.id!,
      tableName: EntityName.CAMPAIGNS,
    };

    try {
      await axiosPost('/entities-delete-one', deleteRequest);
      // once delete request is processed remove loading spinner
      setActionLoading(false);
      await handleRefetch();

      closeDetailsPanel();

      addNotification({
        header: 'Campaign deleted successfully',
      });
    } catch (err) {
      console.error(err);
    } finally {
      setSelectedItem(undefined);
    }
  };

  const handleExportReplies = async (item: ICampaignsListView) => {
    try {
      const request: IExportConversationsRequest = {
        campaignId: item.id!,
        campaignName: item.name ?? '',
      };
      await axiosPost<IHttpResponse<string>>('/conversations-export', request);

      closeDetailsPanel();

      addNotification({
        header: 'An email has been sent with the exported replies.',
      });
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      {/* Removed padding due to date filters height. Add back in once we have a proper filters panel */}
      <div className="flex items-center justify-between pb-2 mx-auto px-[7.5rem] min-w-[1151px]">
        <h1>Campaigns</h1>
        <div className="flex">
          <TextInput disabled={actionLoading}
            id="campaignTitleSearch"
            name="campaignTitleSearch"
            placeholder="Search"
            parentClass="w-80"
            value={searchText}
            onEnter={(e) => {
              setTableOptions((prevState) => {
                // remove old search text from prev state
                const filteredPrevStateFilters = prevState.filters.filter((filter) => filter.fieldName !== 'name');
                prevState.filters = filteredPrevStateFilters;
                return {
                  ...prevState,
                  filters: [...prevState.filters,
                  ... (searchText.length > 0 ? [
                    {
                      fieldName: 'name',
                      dataType: FilterDataTypeEnum.STRING,
                      operator: FilterOperatorEnum.CONTAINS,
                      value: searchText,
                    },
                  ] : [])],
                };
              });
            }}
            onChange={(e) => {
              if (
                e.target.value.length < 1 &&
                tableOptions.filters.find((x) => x.fieldName === 'name')?.value?.length > 0
              ) {
                setTableOptions((prevState) => {
                  const filteredPrevStateFilters = prevState.filters.filter((filter) => filter.fieldName !== 'name');
                  filteredPrevStateFilters.push({
                    fieldName: 'name',
                    dataType: FilterDataTypeEnum.STRING,
                    operator: FilterOperatorEnum.CONTAINS,
                    value: '',
                  });
                  prevState.filters = filteredPrevStateFilters;
                  return {
                    ...prevState,
                    filters: [
                      ...prevState.filters
                    ],
                  };
                });
              }
              setSearchText(e.target.value);
            }}
            search
          />
          <Button
            variant={ButtonVariantEnum.PRIMARY}
            className="self-end ml-4"
            onClick={() => {
              history.push('/app/campaigns/create');
            }}
            disabled={actionLoading}
          >
            CREATE CAMPAIGN
          </Button>
        </div>
      </div>
      <div className="h-5/6 w-full min-w-[1151px]">
        {actionLoading && (
          <div
            className="flex absolute z-20 w-full h-5/6 backdrop-blur-[1.5px] min-w-[1151px]"
            aria-hidden="true"
          >
            <div className="mx-auto mt-1/5">
              <LoadingIndicator size={16} />
            </div>
          </div>
        )}
        <TableList
          loading={!loadingOverride && campaignLoading}
          shimmer
          columns={columns}
          items={campaigns}
          totalCount={campaignTotalCount ?? 0}
          limit={10}
          actions={[
            {
              label: 'Duplicate',
              action: async (item) => handleCampaignClone(item),
            },
            {
              label: 'Pause',
              action: async (item) => {
                setSelectedItem(item);
                setShowPauseModal(true);
              },
              hidden: (item) => {
                return ![CampaignStatusEnum.LIVE, CampaignStatusEnum.SCHEDULING].includes(item.status);
              },
            },
            {
              label: 'Resume',
              action: async (item) => {
                setSelectedItem(item);
                setShowPauseModal(true);
              },
              hidden: (item) => item.status !== CampaignStatusEnum.PAUSED,
            },
            {
              label: 'Delete',
              action: async (item) => {
                setSelectedItem(item);
                setShowDeleteModal(true);
              },
              hidden: (item) => {
                // TODO: Remove this check and create a legit delete campaign API. In this API we need to pause the campaign so that sending stops immediately
                return (
                  ![CampaignStatusEnum.PAUSED, CampaignStatusEnum.DRAFT].includes(item.status) ||
                  item.totalMessagesSent > 0
                );
              },
            },
          ]}
          skipEnumFormattingColumns={['messageType']}
          paginate
          onPaginate={(page) => {
            setTableOptions((prevState) => ({
              ...prevState,
              pagination: {
                ...prevState.pagination,
                skip: page * prevState.pagination.take,
              },
            }));
          }}
          filters={[{
            label: 'Client',
            id: 'clientId',
            dataType: FilterDataTypeEnum.STRING,
            operator: FilterOperatorEnum.EQUALS,
            customDropdown: (value, onChange) => {
              return <ClientDropdown
                placeholder='Search for a client'
                gray={true}
                noLabel={true}
                value={value}
                onChange={(newValue) => {
                  onChange(newValue as IDropdownValue);
                }}
              />;
            }
          }, {
            label: 'Campaign Type',
            id: 'type',
            dataType: FilterDataTypeEnum.STRING,
            operator: FilterOperatorEnum.EQUALS,
            options: Object.keys(CampaignTypeEnum).map(key => {
              return {
                label: convertEnumToReadableString(CampaignTypeEnum[key]),
                value: CampaignTypeEnum[key]
              };
            }),
            optionsLabel: 'Select Type',
          }, {
            label: 'Message Type',
            id: 'messageType',
            dataType: FilterDataTypeEnum.STRING,
            operator: FilterOperatorEnum.EQUALS,
            options: Object.keys(MessageTypeEnum).map(key => {
              return {
                label: key,
                value: MessageTypeEnum[key]
              };
            }),
            optionsLabel: 'Select Message Type'
          }, {
            label: 'Status',
            id: 'status',
            dataType: FilterDataTypeEnum.STRING,
            operator: FilterOperatorEnum.EQUALS,
            options: Object.values(UiCampaignStatusEnum).map(s => ({
              label: s,
              value: s
            })),
            optionsLabel: 'Select Status'
          }]}
          onFilter={(filters: Filter[]) => {
            if (filters.length > 0) {
              const formattedFilters: ISearchFilter[] = filters.map(f => {
                if (f.id !== 'status') {
                  return [{
                    fieldName: f.id,
                    dataType: f.dataType as FilterDataTypeEnum,
                    operator: f.operator,
                    value: f.selectedOption?.value
                  }];
                }

                const complexFilter = mapToStatusFilter(f.selectedOption?.value, sendingWindow);
                const statusFilter = {
                  fieldName: 'status',
                  dataType: f.dataType as FilterDataTypeEnum,
                  operator: f.operator,
                  value: complexFilter.value
                };
                return complexFilter.additionalFilters?.length ? [...complexFilter.additionalFilters, statusFilter] : [statusFilter];
              })
                .flat();

              // todo: logic added to ensure search text filter included after changes made to filter. remove and incorporate title search in table list component
              const searchTextFilter = tableOptions.filters.find((option) => option.fieldName === 'name');
              if (searchTextFilter) formattedFilters.push(searchTextFilter);
              setTableOptions((prevState) => ({
                ...prevState,
                filters: [
                  ...formattedFilters
                ]
              }));
            } else {
              // if table list filter is cleared ensure title search is not cleared as wel
              const searchTextFilter = tableOptions.filters.find((option) => option.fieldName === 'name');
              let updatedTableOptions = defaultCampaignsTableOptions;
              if (searchTextFilter) updatedTableOptions = { ...updatedTableOptions, filters: [searchTextFilter] };
              setTableOptions(updatedTableOptions);
            }
          }}
        />
      </div>

      <CampaignDeleteModal
        show={showDeleteModal}
        onClose={() => {
          setSelectedItem(undefined);
          setShowDeleteModal(false);
        }}
        onSuccess={() => {
          handleCampaignDelete();
          setShowDeleteModal(false);
        }}
        onCancel={() => {
          setSelectedItem(undefined);
          setShowDeleteModal(false);
        }}
      />

      <CampaignPauseResumeModal
        isCampaignActive={isCampaignPausable(selectedItem?.status) ?? false}
        showModal={showPauseModal}
        onClose={() => {
          setSelectedItem(undefined);
          setShowPauseModal(false);
        }}
        onSuccess={() => {
          toggleCampaignPause();
          setShowPauseModal(false);
        }}
        onCancel={() => {
          setSelectedItem(undefined);
          setShowPauseModal(false);
        }}
      />
    </>
  );
};

export default Campaigns;
