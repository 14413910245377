import { IContactListGetHeadersRequest } from '@gr/shared/models';
import useAxios from 'axios-hooks';

export function useCustomDataPoints(request?: IContactListGetHeadersRequest) {
  return useAxios<any>({
    method: 'POST',
    url: `contact-lists-get-headers`,
    data: {
      contactListIds: request?.contactListIds ?? []
    }
  });
}
