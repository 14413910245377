import {
  IAreaCodeEntity,
  IHttpSearchResponse,
  ISearchRequest
} from '@gr/shared/models';
import useAxios, { UseAxiosResult } from 'axios-hooks';

const useAreaCodes = (searchOptions: ISearchRequest): UseAxiosResult<IHttpSearchResponse<IAreaCodeEntity>> => {
  return useAxios<IHttpSearchResponse<IAreaCodeEntity>>({
    url: 'entities-search',
    method: 'POST',
    data: searchOptions,
  }, {
    manual: true
  });
};

export default useAreaCodes;
