import { Icon } from './Icon';

export const Campaigns = () => (
  <Icon nav>
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 45 40" fill="none">
      <path
        d="M37.388 11.6186V12.1186H37.888H39.1588C41.6653 12.1186 43.7105 14.1924 43.7105 16.7788C43.7105 19.3653 41.6653 21.4391 39.1588 21.4391H37.888H37.388V21.9391V32.2448C37.3746 32.45 37.3067 32.6366 37.2069 32.7555L37.2015 32.762L37.1963 32.7686C36.9376 33.0989 36.4635 33.1436 36.139 32.8706L36.1256 32.8593L36.1115 32.849C30.7264 28.9269 24.3047 26.7617 17.6909 26.6315L17.1811 26.6215V27.1314V34.8397C17.1811 37.4262 15.1358 39.5 12.6293 39.5C10.1229 39.5 8.0776 37.4262 8.0776 34.8397V26.7628V26.3785L7.7062 26.2796C3.47471 25.1534 0.5 21.2531 0.5 16.7628C0.5 11.324 4.8196 6.94313 10.102 6.95833H10.1035H16.422C23.4794 7.07137 30.377 4.8902 36.1269 0.708868L36.1452 0.695568L36.1622 0.68067C36.2949 0.564457 36.4615 0.5 36.6329 0.5C37.0431 0.5 37.3977 0.846617 37.3881 1.2872L37.388 1.2872V1.29808V11.6186ZM15.6552 9.03846V8.53846H15.1552H10.1035C5.6285 8.53846 2.02587 12.2339 2.02587 16.7788C2.02587 21.3238 5.6285 25.0192 10.1035 25.0192H15.1552H15.6552V24.5192V9.03846ZM10.1035 26.5994H9.60347V27.0994V34.8397C9.60347 36.5321 10.9469 37.9199 12.6293 37.9199C14.3117 37.9199 15.6552 36.5321 15.6552 34.8397V27.0994V26.5994H15.1552H10.1035ZM35.8621 29.512V3.76603V2.87223L35.1005 3.33995C29.8259 6.579 23.8246 8.34857 17.6682 8.50658L17.1811 8.51908V9.00641V24.5513V25.0398L17.6695 25.0511C23.8086 25.1933 29.8253 26.9783 35.1005 30.2177L35.3523 29.8077H35.3621H35.8621V29.7917V29.512ZM37.388 19.359V19.859H37.888H39.1588C40.8412 19.859 42.1847 18.4712 42.1847 16.7788C42.1847 15.0865 40.8412 13.6987 39.1588 13.6987H37.888H37.388V14.1987V19.359Z"
        fill="currentColor"
        stroke="currentColor"
      />
    </svg>
  </Icon>
);
