import { Autocomplete } from '@Wonder-Cave/ui';
import { IDropdownValue } from '@Wonder-Cave/ui/dist/Autocomplete';
import useContactListsManual from '@gr/portal/hooks/useContactListsManual';
import { FilterDataTypeEnum, FilterOperatorEnum, ISearchFilter } from '@gr/shared/models';
import { allEnabledClassifiedContactListSearchOptions } from '../../../Contacts/types';

interface IContactListDropdownProps {
  value: IDropdownValue[] | undefined;
  onChange: (newValue?: (IDropdownValue | undefined)[]) => void;
  onBlur?: () => void;
  allClients?: boolean;
  disabled?: boolean;
  errorMessage?: string | undefined;
  showError: boolean;
  gridFilter?: boolean;
}

const NewContactListDropdown = ({
  value,
  onChange,
  onBlur,
  disabled,
  errorMessage,
  showError,
}: IContactListDropdownProps): JSX.Element => {
  const [{ data: contactlistData, loading: contactListLoading, error: contactListError }, searchContactLists] =
    useContactListsManual(allEnabledClassifiedContactListSearchOptions);

  const contactLists: IDropdownValue[] =
    contactlistData?.data.records.map((contactList) => ({
      label: contactList.name ?? '',
      value: contactList.id,
      additionalData: contactList.originalFileName ?? '',
    })) ?? [];

  const handleSearchContactList = async (searchValue: string) => {
    let searchFilter: ISearchFilter = {
      dataType: FilterDataTypeEnum.STRING,
      fieldName: 'name',
      operator: FilterOperatorEnum.CONTAINS,
      value: searchValue,
    };
    const newSearchOptions = { ...allEnabledClassifiedContactListSearchOptions };

    const nonSearchFilters = allEnabledClassifiedContactListSearchOptions.filters.filter(
      (filter) => filter.fieldName !== 'name'
    );

    newSearchOptions.filters = searchFilter.value ? [...nonSearchFilters, searchFilter] : [...nonSearchFilters];
    try {
      await searchContactLists({ data: newSearchOptions });
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Autocomplete
      label="Contact Lists"
      placeholder="Type to search contacts"
      loading={contactListLoading}
      value={value}
      options={contactLists}
      onChange={onChange}
      onBlur={onBlur}
      refreshOptions={handleSearchContactList}
      error={errorMessage}
      disabled={disabled}
      multiple
      showError={showError}
    />
  );
};

export default NewContactListDropdown;
