import { ICampaignsListView, IHttpSearchResponse, ISearchRequest } from '@gr/shared/models';
import useAxios, { UseAxiosResult } from 'axios-hooks';

// TODO: Make hook for 'useEntitySearch', and keep hooks for one-offs
export default function useCampaigns(
  searchOptions: ISearchRequest
): UseAxiosResult<IHttpSearchResponse<ICampaignsListView>> {
  return useAxios<IHttpSearchResponse<ICampaignsListView>>({
    url: 'campaigns/search',
    method: 'POST',
    data: searchOptions,
  });
}
