import { PlusSmIcon as PlusSmIconSolid } from '@heroicons/react/solid';
interface IButtonProps {
  id?: string;
  type?: 'button' | 'submit' | 'reset' | undefined;
  className?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

const CircularButton = ({ id, type, className, onClick }: IButtonProps) => {
  return (
    <button
      id={id}
      onClick={onClick}
      type={type}
      className={`${
        className ?? ''
      } inline-flex items-center p-1 text-white bg-sky-600 border border-transparent rounded-full shadow-sm hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500`}
    >
      <PlusSmIconSolid className="w-5 h-5" aria-hidden="true" />
    </button>
  );
};

export { CircularButton };
