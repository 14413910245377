import {
  IHttpResponse,
  ISearchRequest,
  ITCRCampaignCreationRequest,
  ITCRCampaignDetails,
} from '@gr/shared/models';
import useAxios from 'axios-hooks';
import { useEffect, useState } from 'react';
import { NotificationType, useNotifications } from '../../contexts/NotificationContext';
import useProviders from '../../hooks/useProviders';
import useTCRCampaigns from '../../hooks/useTCRCampaigns';
import { Button } from '../shared/Buttons/Button';
import { ButtonVariantEnum } from '../shared/Buttons/types';
import { IDropdownValue } from '../shared/Form/Dropdown';
import { Table } from '../shared/Table/Table';
import { IColumn } from '../shared/Table/types';
import TCRCampaignDetailsPanel from './TCRCampaignDetailsPanel';
import { ITCRCampaignForm, defaultTCRCampaignTableOptions, getColumns, tcrCampaignDropdownOptions } from './types';

const TCRCampaigns = (): JSX.Element => {
  const defaultError = 'An unexpected error occurred when attempting to save the TCR Campaign.';
  const [selectedItem, setSelectedItem] = useState<ITCRCampaignDetails>();
  const [tableOptions, setTableOptions] = useState(defaultTCRCampaignTableOptions);
  const [showDetailsPanel, setShowDetailsPanel] = useState(false);
  const { addNotification } = useNotifications();

  const [{ data, loading, error }, refetch] = useTCRCampaigns(tableOptions);
  const [{ data: providerData, loading: providerLoading, error: providerError }] = useProviders();

  const [{ data: createData, loading: createLoading, error: createError }, createTCRCampaign] = useAxios<
    IHttpResponse<void>
  >(
    {
      url: 'tcr-campaigns-create',
      method: 'POST',
    },
    { manual: true }
  );

  const [{ loading: toggleLoading }, toggleTCRCampaign] = useAxios<IHttpResponse<void>>(
    {
      url: 'tcr-campaigns-toggle',
      method: 'POST',
    },
    { manual: true }
  );

  const tcrCampaigns: ITCRCampaignDetails[] =
    data?.data.records.map((res) => ({
      ...res,
      activeNumbers: parseInt(res.activeNumbers! as any),
    })) ?? [];

  const totalCount: number = data?.data.totalCount ?? 0;

  const providerOptions: IDropdownValue[] =
    providerData?.data.map((provider) => ({
      label: provider.name ?? '',
      value: provider.name ?? '',
    })) ?? [];

  tcrCampaignDropdownOptions.providerName = providerOptions;

  const handleSearchOptionChange = (searchOptions: ISearchRequest) => {
    setTableOptions(searchOptions);
  };

  useEffect(() => {
    handleRefetch();
  }, [tableOptions]);

  const columns = getColumns({
    tcrCampaignId: (item: ITCRCampaignDetails) => {
      openDetailsPanel(item);
    },
  });

  const handleRefetch = async () => {
    try {
      await refetch();
    } catch (err) { }
  };

  const handleToggle = async () => {
    try {
      await toggleTCRCampaign({ data: { id: selectedItem?.id, isActive: !selectedItem?.isActive } });

      await handleRefetch();

      closeDetailsPanel();

      addNotification({
        header: `TCR Campaign ${!selectedItem?.isActive ? 'enabled' : 'disabled'} successfully.`,
      });
    } catch (err) {
      console.error(err);
    }
  };

  const openDetailsPanel = (item?: ITCRCampaignDetails) => {
    if (item) {
      setSelectedItem(item);
    }
    setShowDetailsPanel(true);
  };

  const closeDetailsPanel = () => {
    setSelectedItem(undefined);
    setShowDetailsPanel(false);
  };

  const handleSubmit = async (formData: ITCRCampaignForm): Promise<void> => {
    try {
      await createTCRCampaign({
        data: {
          clientId: formData.client?.value,
          providerId: formData.providerId,
          campaignType: formData.campaignType,
          messageType: formData.messageType,
        } as ITCRCampaignCreationRequest,
      });

      await handleRefetch();

      closeDetailsPanel();

      addNotification({
        header: 'TCR Campaign created successfully!',
      });
    } catch (err) {
      console.error(err);
      addNotification({
        header: 'Error',
        content: defaultError,
        type: NotificationType.FAILURE,
      });
    }
  };

  const filterColumns: IColumn[] = columns.filter((col) =>
    ['clientName', 'providerName', 'messageType', 'campaignType'].includes(col.fieldName)
  );

  const displayError = createError?.response?.data
    ? createError.response.data.statusCode < 500
      ? createError.response.data.message
      : defaultError
    : null;

  return (
    <>
      <h2 className="pb-2 dark:text-white">TCR Campaigns</h2>

      <div className="flex justify-end pb-2 space-x-2">
        <Button
          id="system-number-add"
          variant={ButtonVariantEnum.SECONDARY}
          onClick={() => {
            setShowDetailsPanel(true);
          }}
        >
          Add
        </Button>
      </div>

      <Table
        shimmer
        loading={loading}
        columns={columns}
        items={tcrCampaigns}
        count={totalCount}
        skipEnumFormattingColumns={['messageType']}
        enumExceptions={[{ fieldName: 'campaignType', exceptions: ['GOTV'] }]}
        tableSearchOptions={tableOptions}
        onSearchOptionChange={handleSearchOptionChange}
        paginate
        filter
        filterColumns={filterColumns}
        filterDropdownOptions={tcrCampaignDropdownOptions}
      />

      <TCRCampaignDetailsPanel
        show={showDetailsPanel}
        loading={createLoading}
        toggleLoading={toggleLoading}
        selectedItem={selectedItem}
        errorMessage={displayError}
        onClosePanel={closeDetailsPanel}
        handleSubmit={handleSubmit}
        handleToggle={handleToggle}
      />
    </>
  );
};

export default TCRCampaigns;
