import { Button, ButtonVariantEnum, Modal } from '@Wonder-Cave/ui';

interface ICampaignDeleteModalProps {
  show: boolean;
  onClose: () => void;
  onSuccess: () => void;
  onCancel: () => void;
}

const CampaignDeleteModal = ({ show, onClose, onSuccess, onCancel }: ICampaignDeleteModalProps) => {
  return (
    <Modal title="Are you sure?" show={show} onClose={onClose}>
      <h3 className="px-8 text-center">
        Once you delete a campaign, it will no longer be visible on the grid. <br />
        This action cannot be undone.
      </h3>
      <div className="flex justify-center mt-8 mb-4">
        <Button variant={ButtonVariantEnum.SECONDARY} onClick={onCancel} className="mr-4 uppercase">
          CANCEL
        </Button>
        <Button onClick={onSuccess} variant={ButtonVariantEnum.PRIMARY} className="uppercase">
          DELETE CAMPAIGN
        </Button>
      </div>
    </Modal>
  );
};

export default CampaignDeleteModal;
