import { CampaignTypeEnum, EntityName, MessageTypeEnum } from '@gr/shared/enums';
import {
  FilterDataTypeEnum,
  FilterOperatorEnum,
  ISearchRequest,
  SearchSortDirectionEnum,
} from '@gr/shared/models';
import * as yup from 'yup';
import { IDropdownValue } from '../shared/Form/Dropdown';
import { dropdownSchema } from '../shared/Schema/yup';
import { IColumn, IColumnClickConfig } from '../shared/Table/types';

export const getColumns = (columnClickEvents: IColumnClickConfig): IColumn[] => {
  return [
    {
      headerName: 'Client',
      fieldName: 'clientName',
      isRowHeader: true,
      fieldType: FilterDataTypeEnum.STRING,
      onColumnClick: columnClickEvents['clientName'],
    },
    {
      headerName: 'Carrier',
      fieldName: 'carrierName',
      fieldType: FilterDataTypeEnum.STRING,
    },
    {
      headerName: 'Provider',
      fieldName: 'providerName',
      fieldType: FilterDataTypeEnum.STRING,
    },
    {
      headerName: 'Campaign Type',
      fieldName: 'campaignType',
      fieldType: FilterDataTypeEnum.ENUM,
    },
    {
      headerName: 'Message Type',
      fieldName: 'messageType',
      fieldType: FilterDataTypeEnum.ENUM,
    },
    {
      headerName: 'Created',
      fieldName: 'createdAt',
      fieldType: FilterDataTypeEnum.DATE,
    },
  ];
};

export interface ICarrierExclusionForm {
  client: IDropdownValue;
  carrierId: string;
  providerId: string;
  campaignType: CampaignTypeEnum;
  messageType: MessageTypeEnum;
}

export const carrierExclusionFormSchema: yup.SchemaOf<ICarrierExclusionForm> = yup.object().shape({
  client: dropdownSchema,
  carrierId: yup.string().defined('Required'),
  providerId: yup.string().defined('Required'),
  campaignType: yup.mixed<CampaignTypeEnum>().defined('Required'),
  messageType: yup.mixed<MessageTypeEnum>().defined('Required'),
});

// DB Name
export enum CarrierDBName {
  VERIZON = 'Verizon Wireless',
  ATT = 'AT&T',
  T_MOBILE = 'T-Mobile USA',
  SPRINT = 'The Sprint Group',
}

// UI Label
export const carrierNameToLabel = new Map<string, string>([
  [CarrierDBName.VERIZON, 'Verizon'],
  [CarrierDBName.ATT, 'AT&T'],
  [CarrierDBName.T_MOBILE, 'T-Mobile USA'],
  [CarrierDBName.SPRINT, 'The Sprint Group']
]);

export const majorCarrierSearch: ISearchRequest = {
  entity: EntityName.CARRIERS,
  filters: [
    {
      fieldName: 'name',
      dataType: FilterDataTypeEnum.STRING,
      operator: FilterOperatorEnum.IN,
      value: Object.values(CarrierDBName)
    },
  ],
  pagination: {
    skip: 0,
    take: 1000,
  },
  sort: {
    fieldName: 'name',
    sortDirection: SearchSortDirectionEnum.DESC,
  },
};
