import { LogoutOptions } from "@auth0/auth0-react";
import { Auth0User } from '@gr/shared/models';
import { getApiUrl } from '@gr/shared/utils/auth0-sub-domain.service';
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import { logout } from "./providers/auth0.service";

export interface TokenResponse {
  id_token?: string;
  access_token: string;
  refresh_token?: string;
  expires_in: number;
  scope?: string;
}

export interface ConfigureAxiosRequest {
  token: TokenResponse;
  auth0User: (Auth0User & any) | undefined;
  auth0Logout: (request?: LogoutOptions) => void;
}

let baseURL: string | undefined;
let authAxios: AxiosInstance | undefined;
let tokenResponse: TokenResponse;

export function configureAxiosWithAuth0(request: ConfigureAxiosRequest) {
  tokenResponse = request.token;
  baseURL = getApiUrl();

  authAxios = axios.create({ baseURL });

  authAxios.interceptors.request.use(
    (config) => <any>addTokenToRequest(request.token, config),
    (error) => Promise.reject(error)
  );

  authAxios.interceptors.response.use(
    (response) => response,
    (error) => handleError(error, request)
  );

  return authAxios;
}

function addTokenToRequest(token: TokenResponse, config: AxiosRequestConfig<any>) {
  if (token) {
    config.headers!.Authorization = `Bearer ${token.access_token}`;
  }

  return config;
}

function handleError(error: any, request: ConfigureAxiosRequest) {
  const status = error?.response?.status;

  switch (status) {
    case 403:
      return handleForbidden(error);
    case 401:
      return handleUnauthorized(error, request);
  }

  return Promise.reject(error);
}

function handleForbidden(error: any) {
  window.location.href = '/app/dashboard';
  return Promise.reject(error);
}

function handleUnauthorized(error: any, request: ConfigureAxiosRequest) {
  console.log('Logging out...');
  console.error(error);
  logout(request);
  return Promise.resolve();
}

export function axiosPut<T = any, R = AxiosResponse<T>, D = any>(url: string, data?: D): Promise<R> {
  return authAxios
    ? axios.put<T, R, D>(url, data, getConfig<D>())
    : Promise.reject('Axios has not been configured');
}

export function axiosDelete<T = any, R = AxiosResponse<T>, D = any>(url: string): Promise<R> {
  return authAxios
    ? axios.delete<T, R, D>(url, getConfig<D>())
    : Promise.reject('Axios has not been configured');
}

export function axiosPatch<T = any, R = AxiosResponse<T>, D = any>(url: string, data?: D): Promise<R> {
  return authAxios
    ? axios.patch<T, R, D>(url, data, getConfig<D>())
    : Promise.reject('Axios has not been configured');
}

export function axiosPost<T = any, R = AxiosResponse<T>, D = any>(url: string, data?: D): Promise<R> {
  return authAxios
    ? axios.post<T, R, D>(url, data, getConfig<D>())
    : Promise.reject('Axios has not been configured');
}

export function axiosGet<T = any, R = AxiosResponse<T>, D = any>(url: string): Promise<R> {
  return authAxios
    ? axios.get<T, R, D>(url, getConfig<D>())
    : Promise.reject('Axios has not been configured');
}

function getConfig<D = any>(): AxiosRequestConfig<D> {
  return {
    baseURL,
    headers: {
      Authorization: `Bearer ${tokenResponse.access_token}`
    }
  };
}
