import { Autocomplete } from '@Wonder-Cave/ui';
import useClientsManual from '@gr/portal/hooks/useClientsManual';
import { FilterDataTypeEnum, FilterOperatorEnum, ISearchFilter } from '@gr/shared/models';
import { useEffect } from 'react';
import { allEnabledClientsSearchOptions } from '../../../Clients/types';
import { IDropdownValue } from '../Dropdown';

interface IClientDropdownProps {
  value: IDropdownValue | (IDropdownValue | undefined)[] | undefined;
  onChange: (newValue: IDropdownValue | (IDropdownValue | undefined)[] | undefined) => void;
  onBlur?: () => void;
  allClients?: boolean;
  disabled?: boolean;
  errorMessage?: string | undefined;
  showError?: boolean;
  gridFilter?: boolean;
  multiple?: boolean;
  placeholder?: string;
  label?: string;
  full?: boolean;
  noLabel?: boolean;
  gray?: boolean;
  shimmer?: boolean;
  renderError?: boolean;
}

const ClientDropdown = ({
  value,
  onChange,
  onBlur,
  allClients = false,
  showError,
  disabled,
  errorMessage,
  gridFilter,
  multiple = false,
  placeholder,
  label,
  full,
  noLabel,
  gray,
  shimmer,
  renderError = true
}: IClientDropdownProps): JSX.Element => {
  const [{ data: clientData, loading: clientLoading, error: clientError }, searchClients] =
    useClientsManual(allEnabledClientsSearchOptions);

  const clients: IDropdownValue[] =
    clientData?.data.records.map((client) => ({
      label: client.name ?? '',
      value: gridFilter ? client.name ?? '' : client.id ?? '',
      additionalData: {
        clickerGroup: client.defaultClickerGroupId,
        twoWayEnabled: client.twoWayEnabled,
      },
    })) ?? [];

  const handleSearchClients = async (searchValue: string) => {
    const searchFilter: ISearchFilter = {
      dataType: FilterDataTypeEnum.STRING,
      fieldName: 'name',
      operator: FilterOperatorEnum.CONTAINS,
      value: searchValue,
    };
    const newSearchOptions = { ...allEnabledClientsSearchOptions };

    const nonSearchFilters = allEnabledClientsSearchOptions.filters.filter((filter) => filter.fieldName !== 'name');

    newSearchOptions.filters = searchFilter.value ? [...nonSearchFilters, searchFilter] : [...nonSearchFilters];
    try {
      await searchClients({ data: newSearchOptions });
    } catch (err) {
      console.error(err);
    }
  };

  const getFullClient = async (client: IDropdownValue) => {
    const searchFilter: ISearchFilter = {
      dataType: FilterDataTypeEnum.STRING,
      fieldName: 'id',
      operator: FilterOperatorEnum.EQUALS,
      value: client?.value,
    };
    const newSearchOptions = { ...allEnabledClientsSearchOptions };

    const nonSearchFilters = allEnabledClientsSearchOptions.filters.filter((filter) => filter.fieldName !== 'id');

    newSearchOptions.filters = searchFilter.value ? [...nonSearchFilters, searchFilter] : [...nonSearchFilters];
    const response = await searchClients({ data: newSearchOptions });
    if (response.status === 200) {
      const clientView = response?.data?.data?.records[0];
      onChange({
        label: clientView.name ?? '',
        value: clientView.id,
        additionalData: {
          clickerGroup: clientView.defaultClickerGroupId,
          twoWayEnabled: clientView.twoWayEnabled,
        },
      });
    }
  };

  useEffect(() => {
    if (!multiple) {
      if (!(value as IDropdownValue)?.additionalData && (value as IDropdownValue)?.value) {
        // Need to grab full client
        getFullClient(value as IDropdownValue);
      }
    }
  }, [value]);

  return !multiple ? (
    <Autocomplete
      label={noLabel ? undefined : label ?? 'Client'}
      loading={clientLoading}
      value={value as IDropdownValue}
      options={!allClients ? clients?.filter((x) => x.value !== '11111111-1111-1111-1111-111111111111') ?? [] : clients}
      onChange={onChange}
      onBlur={onBlur}
      refreshOptions={handleSearchClients}
      error={errorMessage}
      disabled={disabled}
      showError={showError}
      placeholder={placeholder}
      full={full}
      gray={gray}
      shimmer={shimmer}
      renderError={renderError}
    />
  ) : (
    <Autocomplete
      multiple
      full={full}
      loading={clientLoading}
      label={label ?? 'Client'}
      value={value as IDropdownValue[]}
      options={!allClients ? clients?.filter((x) => x.value !== '11111111-1111-1111-1111-111111111111') ?? [] : clients}
      onChange={onChange}
      onBlur={onBlur}
      refreshOptions={handleSearchClients}
      error={errorMessage}
      disabled={disabled}
      showError={showError}
      placeholder={placeholder}
      shimmer={shimmer}
      renderError={renderError}
    />
  );
};

export default ClientDropdown;
