import { addLeadingZeroToNumber } from '@gr/shared/utils';
import { hoursToMilliseconds, minutesToMilliseconds, secondsToMilliseconds, setHours, setMilliseconds, setMinutes, setSeconds, startOfDay, startOfHour, startOfMonth, subDays, subHours, subMilliseconds, subMinutes, subMonths, subSeconds, subWeeks, subYears } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';
import { isNil } from 'lodash';
import { DateTime } from 'luxon';
import { ISendingWindow } from '../models';

export function getStartOfCurrentDayTimestamp(): string {
  const date = new Date();

  return `${date.getFullYear()}-${addLeadingZeroToNumber(date.getMonth() + 1)}-${addLeadingZeroToNumber(date.getDate())} 00:00:00.000 -0400`;
}

export function getEndOfCurrentDayTimestamp(): string {

  const date = new Date();
  return `${date.getFullYear()}-${addLeadingZeroToNumber(date.getMonth() + 1)}-${addLeadingZeroToNumber(date.getDate())} 23:59:00.000 -0400`;

}

export function convertToLocalTimeString(date: Date): string {
  return new Date(date).toLocaleString([], { year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit' });

}

export function getUTCEpochTimestamp(): number {
  return Math.floor(Date.now() / 1000); // Return epoch timestamp in seconds
}

export function getUTCTimestamp(): string {
  return new Date().toISOString();
}

export function getUTCFuture5MinutesDate() {
  const date = new Date();

  date.setMinutes(date.getMinutes() + 5);

  return date.toISOString();
}

export function getUTCPrevious5MinutesDate() {
  const date = new Date();

  date.setMinutes(date.getMinutes() - 5);

  return date;
}

export function getUTCPrevious2MinutesDate() {
  const date = new Date();

  date.setMinutes(date.getMinutes() - 2);

  return date.toISOString();
}

export function getUTCPrevious15MinutesDate() {
  const date = new Date();

  date.setMinutes(date.getMinutes() - 15);

  return date.toISOString();
}

export function getUTCPreviousHourTimestamp(): string {
  const date = new Date();

  date.setHours(date.getHours() - 1);

  return date.toISOString();
}

export function getUTCExactPreviousHourTimestamp(): string {
  const date = new Date();

  date.setHours(date.getHours() - 1);

  return `${date.getFullYear()}-${addLeadingZeroToNumber(date.getMonth() + 1)}-${addLeadingZeroToNumber(date.getDate())}T${date.getUTCHours()}:00:00.000Z`;
}

export function getUTCExactCurrentHourTimestamp(): string {
  const date = new Date();

  return `${date.getFullYear()}-${addLeadingZeroToNumber(date.getMonth() + 1)}-${addLeadingZeroToNumber(date.getDate())}T${date.getUTCHours()}:00:00.000Z`;
}

export function getUTCPreviousDayTimestamp(): string {
  const date = new Date();

  date.setDate(date.getDate() - 1);

  return `${date.getFullYear()}-${addLeadingZeroToNumber(date.getMonth() + 1)}-${addLeadingZeroToNumber(date.getDate())}T00:00:00.000Z`;
}

export function getUTCCurrentDayStartTimestamp(): string {

  const date = new Date();
  return `${date.getFullYear()}-${addLeadingZeroToNumber(date.getMonth() + 1)}-${addLeadingZeroToNumber(date.getDate())}T00:00:00.000Z`;

}

export function getUTCPipeStartTimestamp(): string {

  const currentDay = DateTime.local({ zone: 'America/New_York' }).day;
  const currentMonth = DateTime.local({ zone: 'America/New_York' }).month;

  const date = new Date();
  date.setMonth(currentMonth - 1);

  return `${date.getFullYear()}-${addLeadingZeroToNumber(date.getMonth() + 1)}-${addLeadingZeroToNumber(currentDay)}T09:59:00.000Z`;

}

export function getUTCPipeEndTimestamp(): string {

  const currentDay = DateTime.local({ zone: 'America/New_York' }).day;
  const currentMonth = DateTime.local({ zone: 'America/New_York' }).month;

  const date = new Date();
  date.setMonth(currentMonth - 1);
  date.setDate(currentDay + 1);

  return `${date.getFullYear()}-${addLeadingZeroToNumber(date.getMonth() + 1)}-${addLeadingZeroToNumber(date.getDate())}T05:00:00.000Z`;

}

export function getUTCPreviousDayMorningTimestamp(): string {
  const date = new Date();

  date.setDate(date.getDate() - 1);

  return `${date.getFullYear()}-${addLeadingZeroToNumber(date.getMonth() + 1)}-${addLeadingZeroToNumber(date.getDate())}T12:30:00.000Z`;
}

export function getUTCPreviousWeekTimestamp(): string {
  const date = new Date();

  date.setDate(date.getDate() - 7);

  return `${date.getFullYear()}-${addLeadingZeroToNumber(date.getMonth() + 1)}-${addLeadingZeroToNumber(date.getDate())}T00:00:00.000Z`;
}

export function getPreviousMonthStart(): Date {
  const date = new Date();

  date.setMonth(date.getMonth() - 1);

  return new Date(date.getFullYear(), date.getMonth(), 1);
}

export function getPreviousTwoMonthStart(): Date {

  const date = new Date();

  date.setMonth(date.getMonth() - 2);

  return new Date(date.getFullYear(), date.getMonth(), 1);

}

export function getCurrentMonthStart(): Date {
  const date = new Date();

  return new Date(date.getFullYear(), date.getMonth(), 1);
}

export function getSimpleFormatDate(date: Date): string {
  const year = date.getFullYear();

  let month = (1 + date.getMonth()).toString();
  month = month.length > 1 ? month : '0' + month;

  let day = date.getDate().toString();
  day = day.length > 1 ? day : '0' + day;

  return month + '-' + day + '-' + year;
}

export interface PreviousDate {
  years?: number;
  months?: number;
  weeks?: number;
  days?: number;
  hours?: number;
  minutes?: number;
  seconds?: number;
  milliseconds?: number;
}

export interface PreviousDateOptions {
  startOfDay?: boolean;
  startOfHour?: boolean;
  startOfMonth?: boolean;
  clearTime?: boolean;
}

export function getCurrentDateTimestamp(options?: PreviousDateOptions) {
  return getCurrentDate(options).toISOString();
}

export function getPreviousDateTimestamp(previous: PreviousDate, options?: PreviousDateOptions) {
  return getPreviousDate(previous, options).toISOString();
}

export function getCurrentDate(options?: PreviousDateOptions) {
  return getPreviousDate({}, options);
}

export function getPreviousDate(previous: PreviousDate, options?: PreviousDateOptions) {
  const { years = 0, months = 0, weeks = 0, days = 0, hours = 0, minutes = 0, seconds = 0, milliseconds = 0 } = previous;

  let date = new Date();
  date = subYears(date, Math.max(years, 0));
  date = subMonths(date, Math.max(months, 0));
  date = subWeeks(date, Math.max(weeks, 0));
  date = subDays(date, Math.max(days, 0));
  date = subHours(date, Math.max(hours, 0));
  date = subMinutes(date, Math.max(minutes, 0));
  date = subSeconds(date, Math.max(seconds, 0));
  date = subMilliseconds(date, Math.max(milliseconds, 0));

  if (options?.startOfHour) {
    date = startOfHour(date);
  }

  if (options?.startOfDay) {
    date = startOfDay(date);
  }

  if (options?.startOfMonth) {
    date = startOfMonth(date);
  }

  // Set all time values to 0, ex. YYYY-MM-DDT00:00:00.000Z
  if (options?.clearTime) {
    date = setHours(date, 0);
    date = setMinutes(date, 0);
    date = setSeconds(date, 0);
    date = setMilliseconds(date, 0);
    date = zonedTimeToUtc(date, 'UTC');
  }

  return date;
}


export async function waitUntilSeconds(startMs: number, seconds: number, options?: { log?: boolean; }) {
  return await waitUntilMs(startMs, secondsToMilliseconds(seconds), options);
}

export async function waitUntilMs(startMs: number, ms: number, options?: { log?: boolean; }) {
  const elapsedMs = Date.now() - startMs;

  if (elapsedMs < ms) {
    if (options?.log) {
      console.log(`Waiting Until ${ms} ms has passed`);
    }

    await waitMs(ms - elapsedMs);
  }
}

export async function waitHours(hours: number) {
  await waitMs(hoursToMilliseconds(hours));
}

export async function waitMinutes(minutes: number) {
  await waitMs(minutesToMilliseconds(minutes));
}

export async function waitSeconds(seconds: number) {
  await waitMs(secondsToMilliseconds(seconds));
}

export async function waitMs(ms: number) {
  await new Promise(resolve => setTimeout(resolve, ms));
}

export function isInSendingWindow(sendingWindow: ISendingWindow) {
  if (isNil(sendingWindow?.startHour) || isNil(sendingWindow?.endHour)) {
    return false;
  }

  const currentHour = DateTime.local({ zone: 'America/New_York' }).hour;
  return currentHour >= sendingWindow?.startHour! && currentHour <= sendingWindow?.endHour!;
}

