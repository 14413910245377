import { Auth0Role } from '@gr/shared/models';
import { useLocation } from 'react-router-dom';
import { useMembership } from '../../contexts/MembershipContext';
import useRoles from '../../hooks/useRoles';
import AuthorizedShimmer from './AuthorizedShimmer';

interface IRolesProps {
  roles: Auth0Role[];
  children: JSX.Element;
  fallback?: JSX.Element;
}

// TODO: Convert urls with guids into parsable format, then map those urls to proper shimmers
const shimmerMap = new Map<string, JSX.Element>();

shimmerMap.set('/app/my-campaigns', <AuthorizedShimmer />);
shimmerMap.set('/app/contact-lists', <AuthorizedShimmer />);
shimmerMap.set('/app/suppression-lists', <AuthorizedShimmer />);
shimmerMap.set('/app/opt-outs', <AuthorizedShimmer />);
shimmerMap.set('/app/test-numbers', <AuthorizedShimmer />);
shimmerMap.set('/app/clients', <AuthorizedShimmer />);
shimmerMap.set('/app/system-numbers', <AuthorizedShimmer />);
shimmerMap.set('/app/carrier-exclusions', <AuthorizedShimmer />);
shimmerMap.set('/app/tcr-campaigns', <AuthorizedShimmer />);
shimmerMap.set('/app/users', <AuthorizedShimmer />);

const Roles = ({ roles, children, fallback }: IRolesProps): JSX.Element | null => {
  const { isLoaded } = useMembership();
  const allowed = useRoles(roles);

  const location = useLocation();

  const getShimmerForUrl = (): JSX.Element => {
    const url = location.pathname;

    return shimmerMap.get(url) ?? <></>;
  };

  if (allowed) {
    return <>{children}</>;
  } else if (!isLoaded) {
    return getShimmerForUrl();
  } else {
    return fallback ?? null;
  }
};

export default Roles;
