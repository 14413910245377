export const Search = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 16" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.5701 11.8227L14.0935 15.3461C14.2987 15.5513 14.6408 15.5513 14.8461 15.3461C15.0513 15.1408 15.0513 14.7987 14.8461 14.5935L11.3227 11.0701C12.2805 9.94128 12.8278 8.50456 12.8278 6.93102C12.8278 3.37343 9.95439 0.5 6.39681 0.5C2.83922 0.5 0 3.33923 0 6.89681C0 10.4544 2.87343 13.3278 6.43101 13.3278C8.00456 13.3278 9.47548 12.7463 10.5701 11.8227ZM6.43101 1.52623C9.37286 1.52623 11.8016 3.92075 11.8016 6.89681C11.8016 9.87286 9.40707 12.2674 6.43101 12.2674C3.45496 12.2674 1.06043 9.87286 1.06043 6.89681C1.06043 3.92075 3.48917 1.52623 6.43101 1.52623Z"
      fill="black"
    />
  </svg>
);
