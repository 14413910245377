import { IHttpSearchResponse, ISearchRequest } from '@gr/shared/models';
import useAxios, { UseAxiosResult } from 'axios-hooks';

// TODO: Type
export default function useLinks(searchOptions: ISearchRequest): UseAxiosResult<IHttpSearchResponse<any>> {
  return useAxios<IHttpSearchResponse<any>>({
    url: 'entities-search',
    method: 'POST',
    data: searchOptions,
  });
}
