import { Autocomplete } from '@Wonder-Cave/ui';
import { IDropdownValue } from '@Wonder-Cave/ui/dist/Autocomplete';
import useContactListsManual from '@gr/portal/hooks/useContactListsManual';
import {
  FilterDataTypeEnum,
  FilterOperatorEnum,
  ISearchFilter,
  ISearchRequest,
} from '@gr/shared/models';
import { allEnabledClassifiedContactListSearchOptions } from '../../../Contacts/types';
import { allEnabledSuppressionListSearchOptions } from '../../../Suppression/types';

interface ISuppressionListDropdownProps {
  value: IDropdownValue[] | undefined;
  onChange: (newValue?: (IDropdownValue | undefined)[]) => void;
  onBlur?: () => void;
  allClients?: boolean;
  disabled?: boolean;
  errorMessage?: string | undefined;
  showError: boolean;
  gridFilter?: boolean;
  contactListIds: string[];
}

const SuppressionListDropdown = ({
  value,
  contactListIds,
  onChange,
  onBlur,
  disabled,
  errorMessage,
  showError,
}: ISuppressionListDropdownProps): JSX.Element => {
  const [{ data: contactListsData, loading: contactListsLoading, error: contactListsError }, searchContactLists] =
    useContactListsManual(allEnabledClassifiedContactListSearchOptions);

  const suppressionLists: IDropdownValue[] = [];

  // don't allow choosing contact list as suppression list if already selected as normal contact list
  // if user selects as suppression list first, then tries to select it as normal contact list, this should remove it as suppression
  if (value) {
    const removals: number[] = [];
    value.forEach((dropdownValue, idx) => {
      if (contactListIds.includes(dropdownValue.value)) {
        removals.push(idx);
      }
    });
    removals.forEach((idx) => value.splice(idx, 1));
  }

  contactListsData?.data.records
    .filter((contactList) => !contactListIds.includes(contactList.id))
    .map((contactList) => ({
      label: contactList.name ?? '',
      value: contactList.id,
      additionalData: contactList.originalFileName ?? '',
    }))
    .forEach((data) => suppressionLists.push(data));

  const handleSearchSuppressionList = async (searchValue: string) => {
    let searchFilter: ISearchFilter = {
      dataType: FilterDataTypeEnum.STRING,
      fieldName: 'name',
      operator: FilterOperatorEnum.CONTAINS,
      value: searchValue,
    };

    const suppressionListSearchOptions = getNewSearchOptions(searchFilter, allEnabledSuppressionListSearchOptions);
    const contactListSearchOptions = getNewSearchOptions(searchFilter, allEnabledClassifiedContactListSearchOptions);

    try {
      await Promise.all([searchContactLists({ data: contactListSearchOptions })]);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Autocomplete
      label="Contact Lists"
      placeholder="Type to search for contact lists"
      loading={contactListsLoading}
      value={value}
      options={suppressionLists}
      onChange={onChange}
      onBlur={onBlur}
      refreshOptions={handleSearchSuppressionList}
      error={errorMessage}
      disabled={disabled}
      multiple
      showError={showError}
    />
  );
};

function getNewSearchOptions(searchFilter: ISearchFilter, defaultSearchRequest: ISearchRequest) {
  const newSearchOptions = { ...defaultSearchRequest };

  const nonSearchFilters = defaultSearchRequest.filters.filter((filter) => filter.fieldName !== 'name');

  newSearchOptions.filters = searchFilter.value ? [...nonSearchFilters, searchFilter] : [...nonSearchFilters];
  return newSearchOptions;
}

export default SuppressionListDropdown;
