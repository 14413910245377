import useClientsManual from '@gr/portal/hooks/useClientsManual';
import { FilterDataTypeEnum, FilterOperatorEnum, ISearchFilter } from '@gr/shared/models';
import { allEnabledClientsSearchOptions } from '../../../Clients/types';
import { IDropdownValue } from '../Dropdown';
import Autocomplete from './Autocomplete';

interface IClientDropdownProps {
  value: IDropdownValue | (IDropdownValue | undefined)[] | undefined;
  onChange: (newValue: IDropdownValue | (IDropdownValue | undefined)[] | undefined) => void;
  onBlur?: () => void;
  allClients?: boolean;
  disabled?: boolean;
  errorMessage?: string | undefined;
  showError?: boolean;
  gridFilter?: boolean;
  multiple?: boolean;
  placeholder?: string;
  label?: string;
}

const ClientDropdown = ({
  value,
  onChange,
  onBlur,
  allClients = false,
  showError,
  disabled,
  errorMessage,
  gridFilter,
  multiple = false,
  placeholder,
  label,
}: IClientDropdownProps): JSX.Element => {
  const [{ data: clientData, loading: clientLoading, error: clientError }, searchClients] =
    useClientsManual(allEnabledClientsSearchOptions);

  const clients: IDropdownValue[] =
    clientData?.data.records.map((client) => ({
      label: client.name ?? '',
      value: gridFilter ? client.name ?? '' : client.id ?? '',
      additionalData: {
        clickerGroup: client.defaultClickerGroupId,
        twoWayEnabled: client.twoWayEnabled,
      },
    })) ?? [];

  const handleSearchClients = async (searchValue: string) => {
    const searchFilter: ISearchFilter = {
      dataType: FilterDataTypeEnum.STRING,
      fieldName: 'name',
      operator: FilterOperatorEnum.CONTAINS,
      value: searchValue,
    };
    const newSearchOptions = { ...allEnabledClientsSearchOptions };

    const nonSearchFilters = allEnabledClientsSearchOptions.filters.filter((filter) => filter.fieldName !== 'name');

    newSearchOptions.filters = searchFilter.value ? [...nonSearchFilters, searchFilter] : [...nonSearchFilters];
    try {
      await searchClients({ data: newSearchOptions });
    } catch (err) {
      console.error(err);
    }
  };

  return !multiple ? (
    <Autocomplete
      label={label ?? 'Client'}
      loading={clientLoading}
      value={value as IDropdownValue}
      options={!allClients ? clients?.filter((x) => x.value !== '11111111-1111-1111-1111-111111111111') ?? [] : clients}
      onChange={onChange}
      onBlur={onBlur}
      refreshOptions={handleSearchClients}
      error={errorMessage}
      disabled={disabled}
      showError={showError}
      placeholder={placeholder}
    />
  ) : (
    <Autocomplete
      multiple
      loading={clientLoading}
      label={label ?? 'Client'}
      value={value as IDropdownValue[]}
      options={!allClients ? clients?.filter((x) => x.value !== '11111111-1111-1111-1111-111111111111') ?? [] : clients}
      onChange={onChange}
      onBlur={onBlur}
      refreshOptions={handleSearchClients}
      error={errorMessage}
      disabled={disabled}
      showError={showError}
      placeholder={placeholder}
    />
  );
};

export default ClientDropdown;
