import { UiCampaignStatusEnum } from '@gr/shared/enums';
import { Info } from '@Wonder-Cave/ui';
import { GetUIStatusRequest, mapToUiStatus } from '../services/campaigns.service';

interface ICampaignStatusProps extends GetUIStatusRequest {
}

const CampaignStatus = (props: ICampaignStatusProps) => {
  const status = mapToUiStatus(props);

  return (
    <>
      <span>
        {status}
        {status === UiCampaignStatusEnum.PENDING && <Info className="ml-1" text="Campaign scheduling will start once contact list is ready." />}
      </span>
    </>
  );
};

export default CampaignStatus;
