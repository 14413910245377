import { IHttpResponse, ISearchRequest, ITestNumberDto } from '@gr/shared/models';
import useAxios, { UseAxiosResult } from 'axios-hooks';

export default function useTestNumbers(
  searchOptions: ISearchRequest | null
): UseAxiosResult<IHttpResponse<ITestNumberDto[]>> {
  return useAxios<IHttpResponse<ITestNumberDto[]>>({
    url: 'test-numbers-get',
    method: 'POST',
    data: searchOptions,
  });
}
