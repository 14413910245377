import { ISearchRequest, ISearchResponse, ISearchTestMessageDataResponse } from '@gr/shared/models';
import useAxios, { UseAxiosResult } from 'axios-hooks';

export default function useTestNumbers(request: ISearchRequest): UseAxiosResult<ISearchResponse<ISearchTestMessageDataResponse>> {
  const name = request.filters.find(f => f.fieldName === 'name')?.value ?? '';

  return useAxios<ISearchResponse<ISearchTestMessageDataResponse>>({
    url: `test-message-data?skip=${request.pagination.skip}&take=${request.pagination.take}&name=${name}`,
    method: 'GET',
  });
}
