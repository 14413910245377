import { ITestNumberDto } from '@gr/shared/models';
import { DetailsPanel } from '../shared/DetailsPanel/DetailsPanel';
import { DetailsPanelSize, IButtonOptions } from '../shared/DetailsPanel/types';
import HealthCheckNumberForm from './HealthCheckNumberForm';
import { ITestNumberForm } from './types';

interface ITestNumberDetailsPanel {
  errorMessage: string;
  show: boolean;
  loading: boolean;
  selectedItem: ITestNumberDto;
  leftPanelButtonOptions: IButtonOptions[];
  onClosePanel: () => void;
  handleSubmit: (formData: ITestNumberForm) => Promise<void>;
}

const HealthCheckNumberDetailsPanel = ({
  errorMessage,
  show,
  loading,
  selectedItem,
  leftPanelButtonOptions,
  onClosePanel,
  handleSubmit,
}: ITestNumberDetailsPanel): JSX.Element => {
  return (
    <DetailsPanel
      item={selectedItem}
      errorMessage={errorMessage}
      title={selectedItem ? 'View Health Check Number' : 'Add Health Check Number'}
      formId="test-number-form"
      size={DetailsPanelSize.Medium}
      show={show}
      closePanel={onClosePanel}
      loading={loading}
      primaryButtonOptions={{ text: 'Save', visible: !selectedItem }}
      leftPanelButtonOptions={leftPanelButtonOptions}
    >
      <HealthCheckNumberForm item={selectedItem} onSubmit={handleSubmit} />
    </DetailsPanel>
  );
};

export default HealthCheckNumberDetailsPanel;
