import { useAuth0 } from '@auth0/auth0-react';
import { CampaignTypeEnum } from '@gr/shared/enums';
import { Auth0Role, IClientView } from '@gr/shared/models';
import { FastField, Field, FieldArray, Formik } from 'formik';
import { useEffect, useState } from 'react';
import * as yup from 'yup';
import useProvidersForCampaignType from '../../hooks/useProvidersForCampaignType';
import useRoles from '../../hooks/useRoles';
import { convertEnumToReadableString } from '../../providers/utility.provider';
import DetailsPanelCard from '../shared/DetailsPanel/DetailsPanelCard';
import AssignedAgentsDropdown from '../shared/Form/Dropdowns/AssignedAgentsDropdown';
import ProviderForClientDropdown from '../shared/Form/Dropdowns/ProviderForClientDropdown';
import { IRadioButtonOption, RadioButtonGroup } from '../shared/Form/RadioButton';
import { Slider } from '../shared/Form/Slider';
import { TextInput } from '../shared/Form/TextInput';
import LoadingIndicator from '../shared/LoadingIndicator';
import Roles from '../shared/Roles';

export interface IClientForm {
  name: string;
  isActive?: IRadioButtonOption;
  externalId?: string;
  senderIdentifier?: string;
  tcrBrandId?: string;
  messageFlow?: string;
  defaultClickerGroupId?: string;
  twoWayEnabled: boolean;
  providers?: Array<{ id: string | undefined; campaignType: string | undefined; provider: string | undefined; }>;
}

const options: IRadioButtonOption[] = [
  {
    label: 'Enabled',
    value: true,
  },
  {
    label: 'Disabled',
    value: false,
  },
];

interface IClientFormProps {
  item: IClientView | undefined;
  onSubmit?: (formValues: IClientForm) => void;
  isReadyOnly?: boolean;
}

const ClientsForm = ({ item, onSubmit, isReadyOnly }: IClientFormProps): JSX.Element => {
  const [loading, setLoading] = useState(false);
  const [{ data: providers, loading: providersLoading, error: providersErrors }, fetchProviders] =
    useProvidersForCampaignType(item?.id ?? '');
  const { user } = useAuth0();
  const providerRoles = [Auth0Role.GR_ADMIN];
  const providerPermissions = useRoles(providerRoles);
  useEffect(() => {
    if (item && providerPermissions) {
      const fetchData = async () => {
        try {
          await fetchProviders();
        } catch (ex) {
          console.error(ex);
          // Swallow this, we don't care
        }
      };
      fetchData();
    }
  }, [item]);

  const allowSenderId = useRoles([Auth0Role.GR_ADMIN]);
  const allowTCRBrandId = useRoles([Auth0Role.GR_ADMIN]);

  const initialFormState: IClientForm = {
    name: item?.name ?? '',
    isActive:
      item?.isActive !== undefined && item?.isActive !== null
        ? options.find((option) => option.value === item?.isActive)
        : options[0],
    providers: [],
    senderIdentifier: item?.senderIdentifier ?? '',
    tcrBrandId: item?.tcrBrandId ?? '',
    externalId: item?.externalId ?? '',
    messageFlow: item?.messageFlow ?? '',
    defaultClickerGroupId: item?.defaultClickerGroupId ?? undefined,
    twoWayEnabled: item?.twoWayEnabled ?? false,
  };

  const schema: yup.SchemaOf<IClientForm> = yup.object().shape({
    name: yup
      .string()
      .defined('Required')
      .test('all-clients', 'Name cannot be All Clients', (value) => value?.toLowerCase().trim() !== 'all clients'),
    isActive: yup
      .object()
      .shape({
        label: yup.string(),
        value: yup.boolean().required('Required'),
      })
      .nullable(),
    externalId: yup.string(),
    senderIdentifier: yup.string(),
    tcrBrandId: yup.string(),
    messageFlow: yup.string().url('Call To Action must be a valid URL'),
    providers: yup.array().of(
      yup.object().shape({
        id: yup.string(),
        campaignType: yup.string(),
        provider: yup.string(),
      })
    ),
    defaultClickerGroupId: yup.string(),
    twoWayEnabled: yup.boolean().defined(),
  });

  return (
    <>
      <Formik
        initialValues={initialFormState}
        validationSchema={schema}
        onSubmit={async (values) => {
          onSubmit && onSubmit(values);
        }}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue, setFieldTouched }) => (
          // Set form id so external submit button can still work
          <>
            <form id="client-form" onSubmit={handleSubmit}>
              <div className="flex flex-col space-y-4">
                <TextInput
                  id="name"
                  name="name"
                  label="Name"
                  disabled={isReadyOnly}
                  value={values.name}
                  error={touched.name ? errors.name : ''}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />

                <Field
                  name="isActive"
                  component={RadioButtonGroup}
                  options={options}
                  label="Status"
                  disabled={isReadyOnly}
                  error={touched.isActive && (errors.isActive as any)?.value}
                  value={values.isActive}
                  onBlur={handleBlur}
                  onChange={(isActive: IRadioButtonOption) => {
                    setFieldTouched('isActive');
                    setFieldValue('isActive', isActive);
                  }}
                />

                <Roles roles={[Auth0Role.GR_STAFF_USER]}>
                  <AssignedAgentsDropdown
                    label="Default Agent Group"
                    value={values.defaultClickerGroupId}
                    onChange={(newValue) => {
                      setFieldTouched('defaultClickerGroupId');
                      setFieldValue('defaultClickerGroupId', newValue?.value);
                    }}
                    onBlur={() => {
                      setFieldTouched('defaultClickerGroupId');
                    }}
                    defaultGroupName="None"
                    errorMessage={errors.defaultClickerGroupId as string}
                    showError={!!(touched.defaultClickerGroupId && errors.defaultClickerGroupId)}
                    includeNone
                  />
                </Roles>

                {loading && <LoadingIndicator position="CENTER" />}

                {item?.id && !loading && providerPermissions && (
                  <Roles roles={providerRoles}>
                    <DetailsPanelCard title="Providers" subtitle="Change providers by campaign type">
                      <div className="">
                        <FieldArray
                          name="providers"
                          render={(arrayHelpers) =>
                            Object.values(CampaignTypeEnum).map((type, index) => {
                              return (
                                <div
                                  key={type}
                                  className={`flex flex-col sm:flex-row sm:items-center pl-2 ${index !== 0 ? 'border-t border-gray-200 dark:border-slate-800 py-4' : 'py-4'
                                    }`}
                                >
                                  <h4 className="text-sm font-medium text-gray-700 sm:w-1/2 dark:text-slate-400">
                                    {type === CampaignTypeEnum.GOTV ? 'GOTV' : convertEnumToReadableString(type)}
                                  </h4>
                                  <ProviderForClientDropdown
                                    clientId={item?.id ?? ''}
                                    campaignType={type}
                                    value={
                                      values?.providers?.[index]?.provider ??
                                      providers?.data.types
                                        .map((x) => {
                                          return {
                                            campaignType: x.name,
                                            provider: x.providerId,
                                          };
                                        })
                                        .find((y) => y.campaignType === type)?.provider
                                    }
                                    onChange={(newValue) => {
                                      setFieldValue(`providers.${index}.provider`, newValue?.value ?? '');
                                      setFieldValue(`providers.${index}.campaignType`, type);
                                    }}
                                    onBlur={() => {
                                      setFieldTouched(`providers.${index}.provider`);
                                    }}
                                    touched={
                                      !!(
                                        touched.providers?.[index]?.provider &&
                                        (errors.providers?.[index] as any)?.provider
                                      )
                                    }
                                    errorMessage={(errors?.providers?.[index] as any)?.provider}
                                  />
                                </div>
                              );
                            })
                          }
                        />
                      </div>
                    </DetailsPanelCard>
                  </Roles>
                )}

                {/* TODO: Disable unless super admin, don't make it required */}
                <FastField
                  component={TextInput}
                  id="senderIdentifier"
                  name="senderIdentifier"
                  label="Sender Identifier"
                  disabled={!allowSenderId || isReadyOnly}
                  value={values.senderIdentifier}
                  error={touched.senderIdentifier ? errors.senderIdentifier : ''}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />

                <FastField
                  component={TextInput}
                  id="tcrBrandId"
                  name="tcrBrandId"
                  label="TCR Brand Id"
                  disabled={!allowTCRBrandId || isReadyOnly}
                  value={values.tcrBrandId}
                  error={touched.tcrBrandId ? errors.tcrBrandId : ''}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />

                <FastField
                  component={TextInput}
                  id="externalId"
                  name="externalId"
                  label="External Id"
                  disabled={isReadyOnly}
                  value={values.externalId}
                  error={touched.externalId ? errors.externalId : ''}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />

                <FastField
                  component={TextInput}
                  id="messageFlow"
                  name="messageFlow"
                  label="Call To Action URL"
                  disabled={isReadyOnly}
                  value={values.messageFlow}
                  error={touched.messageFlow ? errors.messageFlow : ''}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />

                {/* SUPER ADMIN ONLY */}
                <Roles roles={[Auth0Role.GR_ADMIN]}>
                  <FastField
                    component={Slider}
                    id="twoWayEnabled"
                    name="twoWayEnabled"
                    label="Two Way Conversations"
                    disabled={isReadyOnly}
                    value={values.twoWayEnabled}
                    checked={values.twoWayEnabled}
                    error={touched.twoWayEnabled ? errors.twoWayEnabled : ''}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Roles>
              </div>
            </form>
          </>
        )}
      </Formik>
    </>
  );
};

export default ClientsForm;
