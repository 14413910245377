import { FilterDataTypeEnum, ISearchRequest, SearchSortDirectionEnum } from '@gr/shared/models';
import { IColumn, IColumnClickConfig } from '../../shared/Table/types';

export const defaultMyCampaignsTableOptions: ISearchRequest = {
  filters: [],
  pagination: {
    skip: 0,
    take: 1000,
  },
  sort: {
    fieldName: 'createdAt',
    sortDirection: SearchSortDirectionEnum.DESC,
  },
};

export interface IAllocatedMessage {
  id: string;
  toNumber: number;
  message: string;
}

export interface IConfirmedMessage {
  id: string;
  message: string;
  mediaUrl?: string;
}

export const getColumns = (columnClickEvents: IColumnClickConfig, columnRenders: any): IColumn[] => {
  return [
    {
      headerName: 'Title',
      fieldName: 'name',
      fieldType: FilterDataTypeEnum.STRING,
      isRowHeader: true,
      onColumnClick: columnClickEvents['name'],
    },
    {
      headerName: 'Message Type',
      fieldName: 'messageType',
      fieldType: FilterDataTypeEnum.STRING,
    },
    {
      headerName: 'Progress',
      fieldName: '',
      fieldType: FilterDataTypeEnum.STRING,
      renderColumn: columnRenders['progress'],
    },
    {
      headerName: 'Starts',
      fieldName: 'startsAt',
      fieldType: FilterDataTypeEnum.DATE,
    },
    {
      headerName: 'Ends',
      fieldName: 'endsAt',
      fieldType: FilterDataTypeEnum.DATE,
    },
  ];
};
