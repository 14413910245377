import { IClientView, IHttpSearchResponse, ISearchRequest } from '@gr/shared/models';
import useAxios, { UseAxiosResult } from 'axios-hooks';

export default function useClientsManual(searchOptions?: ISearchRequest): UseAxiosResult<IHttpSearchResponse<IClientView>> {
  return useAxios<IHttpSearchResponse<IClientView>>({
    url: 'entities-search',
    method: 'POST',
    data: searchOptions,
  },
    { manual: true });
}
