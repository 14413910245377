import { ISocketBroadcastPayload } from '@gr/shared/models';

export class SocketManager {
  private socket: any;
  public subscriptions: Map<string, Array<(message: ISocketBroadcastPayload) => void>> = new Map<
    string,
    Array<(message: ISocketBroadcastPayload) => void>
  >();

  constructor(socket: any) {
    this.socket = socket;
  }

  subscribe = (event: string, cb: (message: ISocketBroadcastPayload) => void): (() => void) => {
    const subscriptions = this.subscriptions.get(event) ?? [];

    this.subscriptions.set(event, [...subscriptions, cb]);

    const index = (this.subscriptions.get(event) ?? []).length - 1;

    // Unsubscribe
    return () => {
      const subscriptions = this.subscriptions.get(event) ?? [];

      subscriptions.splice(index, 1);

      this.subscriptions.set(event, subscriptions);
    };
  };
}
