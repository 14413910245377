import { XIcon } from '@heroicons/react/outline';
import { Button } from '../Buttons/Button';

interface IErrorBannerProps {
  message?: string;
  onDismissClick?: () => void;
}

const ErrorBanner = ({ message, onDismissClick }: IErrorBannerProps): JSX.Element => {
  return (
    <div className="bg-red-400">
      <div className="flex justify-between py-3 pl-4 pr-2 sm:pl-6 sm:pr-4 max-w-7xl">
        <div className="flex items-center font-medium text-white">
          <span className="font-medium text-white">{message}</span>
        </div>

        <Button
          className="px-2 mr-2 hover:!bg-red-500 !bg-red-400 border-0  focus:outline-none focus:ring-2 focus:ring-white"
          leadingIcon={<XIcon className="w-6 h-6" />}
          onClick={onDismissClick}
        />
      </div>
    </div>
  );
};

export default ErrorBanner;
