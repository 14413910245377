import { RadioGroup } from '@headlessui/react';
import { ErrorMessage } from './ErrorMessage';

export interface IRadioButtonOption {
  label: string;
  value: any;
  description?: string;
}

interface IRadioButtonGroupProps {
  label?: string;
  options: IRadioButtonOption[];
  value: any;
  onChange: any;
  onBlur: any;
  disabled?: boolean;
  error?: string;
}

const RadioButtonGroup = ({
  label,
  options,
  value: selectedOption,
  onChange,
  onBlur,
  disabled,
  error,
}: IRadioButtonGroupProps): JSX.Element => {
  return (
    <>
      <RadioGroup value={selectedOption} onChange={onChange} onBlur={onBlur} disabled={disabled}>
        {label && (
          <RadioGroup.Label className="text-sm font-medium text-gray-900 dark:text-slate-400">{label}</RadioGroup.Label>
        )}
        <div
          className={`-space-y-px bg-white dark:bg-slate-700 dark:border-slate-800 rounded-md ${
            disabled && 'bg-gray-100 dark:bg-slate-800'
          }`}
        >
          {options.map((option, settingIdx) => (
            <RadioGroup.Option
              key={option.label}
              value={option}
              className={({ checked }) =>
                `relative border p-4 flex focus:outline-none ${settingIdx === 0 && 'rounded-tl-md rounded-tr-md'} ${
                  settingIdx === options.length - 1 && 'rounded-bl-md rounded-br-md'
                } ${
                  checked
                    ? 'bg-sky-50 border-sky-200 dark:bg-slate-500 dark:border-slate-400'
                    : 'border-gray-200 dark:border-slate-800'
                } ${disabled ? 'cursor-default' : 'cursor-pointer'}`
              }
            >
              {({ active, checked }) => (
                <>
                  <span
                    className={`h-4 w-4 mt-0.5 rounded-full border flex items-center justify-center ${
                      checked
                        ? 'bg-sky-600 dark:bg-slate-700 border-transparent'
                        : 'bg-white dark:bg-slate-400 dark:border-slate-800 border-gray-300'
                    } ${active && 'ring-2 ring-offset-2 ring-sky-500 dark:ring-slate-400'} ${
                      disabled ? 'cursor-default' : 'cursor-pointer'
                    }`}
                    aria-hidden="true"
                  >
                    <span className={`rounded-full bg-white w-1.5 h-1.5`} />
                  </span>

                  <div className="flex flex-col ml-3">
                    <RadioGroup.Label
                      as="span"
                      className={`block text-sm font-medium ${
                        checked ? 'text-sky-900 dark:text-white' : 'text-gray-900 dark:text-slate-400'
                      }`}
                    >
                      {option.label}
                    </RadioGroup.Label>
                    {option.description && (
                      <RadioGroup.Description
                        as="span"
                        className={`block text-sm ${checked ? 'text-sky-700 dark:text-slate-400' : 'text-gray-500'}`}
                      >
                        {option?.description}
                      </RadioGroup.Description>
                    )}
                  </div>
                </>
              )}
            </RadioGroup.Option>
          ))}
        </div>
      </RadioGroup>
      <ErrorMessage show={!!error} message={error} />
    </>
  );
};

export { RadioButtonGroup };
