import { ExternalV1ContactList, ExternalV1GetAllContactListsRequest, ExternalV1PaginatedResponse, ISearchRequest } from '@gr/shared/models';
import useAxios, { UseAxiosResult } from 'axios-hooks';

const useContactLists = (searchOptions: ISearchRequest): UseAxiosResult<ExternalV1PaginatedResponse<ExternalV1ContactList>> => {
  const params: ExternalV1GetAllContactListsRequest = {
    skip: searchOptions.pagination.skip,
    take: searchOptions.pagination.take,
    name: searchOptions.filters[0]?.value ?? undefined,
    sortDir: searchOptions.sort.sortDirection
  };
  return useAxios<ExternalV1PaginatedResponse<ExternalV1ContactList>>({
    url: '/v1/contact-lists?',
    method: 'GET',
    params,
  });
};

export default useContactLists;
