import { Auth0MembershipWithMeta } from '@gr/shared/models';
import React, { Dispatch, SetStateAction, useContext } from 'react';

interface IMembershipContext {
  membership: Auth0MembershipWithMeta | undefined;
  isLoaded: boolean;
  setMembership: Dispatch<SetStateAction<Auth0MembershipWithMeta | undefined>>;
}

const MembershipContext = React.createContext<IMembershipContext>({
  membership: undefined,
  isLoaded: false,
  setMembership: () => { },
});

const MembershipContextProvider = MembershipContext.Provider;
const MembershipContextConsumer = MembershipContext.Consumer;
const useMembership = () => useContext(MembershipContext);
export { MembershipContext, MembershipContextConsumer, MembershipContextProvider, useMembership };

