import useClientsFilter from '@gr/portal/hooks/useClientsFilter';
import { IDropdownValue } from '../Dropdown';
import Autocomplete from './Autocomplete';

interface IAllClientDropdownProps {
  value: IDropdownValue | (IDropdownValue | undefined)[] | undefined;
  onChange: (newValue: IDropdownValue | (IDropdownValue | undefined)[] | undefined) => void;
  onBlur?: () => void;
  disabled?: boolean;
  errorMessage?: string | undefined;
  showError?: boolean;
  gridFilter?: boolean;
  placeholder?: string;
  hideAllClient?: boolean;
}

const AllClientDropdown = ({
  value,
  onChange,
  onBlur,
  showError,
  disabled,
  errorMessage,
  gridFilter,
  placeholder,
  hideAllClient,
}: IAllClientDropdownProps): JSX.Element => {
  const [{ data: clientData, loading: clientLoading, error: clientError }, searchClients] = useClientsFilter('');

  const clients: IDropdownValue[] =
    clientData?.data.records
      .filter((client) => !hideAllClient || client.name?.trim().toLowerCase() !== 'all clients')
      .map((client) => ({
        label: client.name ?? '',
        value: gridFilter ? client.name ?? '' : client.id ?? '',
      })) ?? [];

  const handleSearchClients = async (searchValue: string) => {
    try {
      await searchClients({
        data: {
          searchString: searchValue,
        },
      });
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Autocomplete
      label="Client"
      loading={clientLoading}
      value={value as IDropdownValue}
      options={clients}
      onChange={onChange}
      onBlur={onBlur}
      refreshOptions={handleSearchClients}
      error={errorMessage}
      disabled={disabled}
      showError={showError}
      placeholder={placeholder}
    />
  );
};

export default AllClientDropdown;
