import { Autocomplete } from '@Wonder-Cave/ui';
import { IDropdownValue } from '@Wonder-Cave/ui/dist/Autocomplete';
import useAreaCodes from '@gr/portal/hooks/useAreaCodes';
import { FilterDataTypeEnum, FilterOperatorEnum, ISearchFilter } from '@gr/shared/models';
import { useEffect, useState } from 'react';
import { defaultAreaCodeOptions } from '../../../SystemNumbers/types';

interface IAreaCodeDropdownProps {
  value: IDropdownValue[] | undefined;
  onChange: (newValue?: (IDropdownValue | undefined)[]) => void;
  onBlur?: () => void;
  allClients?: boolean;
  disabled?: boolean;
  errorMessage?: string | undefined;
  showError: boolean;
  gridFilter?: boolean;
}

const AreaCodeDropdown = ({
  value,
  onChange,
  onBlur,
  disabled,
  errorMessage,
  showError,
}: IAreaCodeDropdownProps): JSX.Element => {
  const [{ data: areaCodeData, loading: areaCodeLoading, error: areaCodeError }, searchAreaCodes] =
    useAreaCodes(defaultAreaCodeOptions);
  const [initialLoad, setInitialLoad] = useState(false);
  const areaCodeDropdowns: IDropdownValue[] =
    areaCodeData?.data.records.map((code) => ({
      label: `${code.areaCode} - ${code.stateText}`,
      value: code.id,
      additionalData: code.areaCode,
    })) ?? [];

  useEffect(() => {
    const findAreaCodes = async () => {
      setInitialLoad(true);
      try {
        const filter: ISearchFilter = {
          dataType: FilterDataTypeEnum.NUMBER,
          fieldName: 'areaCode',
          operator: FilterOperatorEnum.IN,
          value: value?.map((v) => v.additionalData),
        };
        const currentACodes = await searchAreaCodes({
          data: {
            ...defaultAreaCodeOptions,
            filters: [...defaultAreaCodeOptions.filters, filter],
          },
        });
        onChange(
          currentACodes?.data?.data?.records?.map((code) => ({
            label: `${code.areaCode} - ${code.stateText}`,
            value: code.id,
            additionalData: code.areaCode,
          })) ?? []
        );
      } catch (e) {
        console.error(e);
      } finally {
        setInitialLoad(false);
      }
    };
    if (value && value?.length > 0) {
      findAreaCodes();
    }
  }, []);

  const handleSearchAreaCodes = async (searchValue: string) => {
    let searchFilter: ISearchFilter;
    if (!isNaN(Number(searchValue))) {
      searchFilter = {
        dataType: FilterDataTypeEnum.NUMBER,
        fieldName: 'areaCode',
        operator: FilterOperatorEnum.CONTAINS,
        value: searchValue,
      };
    } else {
      searchFilter = {
        dataType: FilterDataTypeEnum.STRING,
        fieldName: 'stateText',
        operator: FilterOperatorEnum.CONTAINS,
        value: searchValue,
      };
    }
    const newSearchOptions = { ...defaultAreaCodeOptions };

    const nonSearchFilters = defaultAreaCodeOptions.filters.filter((filter) => filter.fieldName !== 'areaCode');

    newSearchOptions.filters = searchFilter.value ? [...nonSearchFilters, searchFilter] : [...nonSearchFilters];
    try {
      await searchAreaCodes({ data: newSearchOptions });
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Autocomplete
      label="Area Code"
      placeholder="Search area codes"
      value={initialLoad ? [] : value}
      options={areaCodeDropdowns}
      loading={areaCodeLoading}
      refreshOptions={handleSearchAreaCodes}
      onChange={onChange}
      onBlur={onBlur}
      error={errorMessage}
      disabled={disabled}
      multiple
      showError={showError}
      full
    />
  );
};

export default AreaCodeDropdown;
