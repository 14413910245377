import { EntityName } from '@gr/shared/enums';
import { FilterDataTypeEnum, ISearchRequest, SearchSortDirectionEnum } from '@gr/shared/models';
import * as yup from 'yup';
import { IColumn, IColumnClickConfig } from '../shared/Table/types';

export const defaultTableOptions: ISearchRequest = {
  entity: EntityName.TEST_MESSAGE_DATA,
  filters: [],
  pagination: {
    skip: 0,
    take: 100000,
  },
  sort: {
    fieldName: 'name',
    sortDirection: SearchSortDirectionEnum.ASC,
  },
};

export const getColumns = (columnClickEvents: IColumnClickConfig): IColumn[] => {
  return [
    {
      headerName: 'Name',
      fieldName: 'name',
      isRowHeader: true,
      fieldType: FilterDataTypeEnum.STRING,
      onColumnClick: columnClickEvents['name'],
    },
    {
      headerName: 'Value',
      fieldName: 'value',
      isRowHeader: false,
      fieldType: FilterDataTypeEnum.STRING,
    },
  ];
};

export interface ITestMessageDataForm {
  name: string;
  value: string;
}

export const testMessageDataFormSchema: yup.SchemaOf<ITestMessageDataForm> = yup.object().shape({
  name: yup.string().defined('Required'),
  value: yup.string().required()
});
