interface ICheckboxProps {
  id?: string;
  name?: string;
  label: string;
  title?: string;
  checked?: boolean;
  disabled?: boolean;
  description?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
}

const Checkbox = ({
  id,
  name,
  label,
  title,
  checked,
  disabled,
  description,
  onChange,
}: ICheckboxProps): JSX.Element => {
  return (
    <div className="space-y-5">
      {/* <legend className="sr-only">Notifications</legend> */}
      <div className="relative flex items-start">
        <div className="flex items-center h-5">
          <input
            id={id}
            name={name}
            aria-describedby="comments-description"
            checked={checked}
            disabled={disabled}
            onChange={onChange}
            type="checkbox"
            title={title}
            className={`w-4 h-4 border-gray-300 dark:border-slate-800 rounded text-sky-600 focus:ring-sky-500 ${
              disabled && 'bg-gray-100 dark:bg-slate-400'
            }`}
          />
        </div>
        <div className="ml-3 text-sm">
          <label htmlFor={name} className="font-medium text-gray-700 dark:text-slate-400">
            {label}
          </label>
          <p id="checkbox-description" className="text-gray-500 dark:text-slate-400">
            {description}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Checkbox;
