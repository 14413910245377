import {
  ChangeEventHandler,
  FocusEventHandler,
  KeyboardEventHandler,
  MouseEventHandler,
  useEffect,
  useState,
} from 'react';
import { ErrorMessage } from './ErrorMessage';
import { Search as SearchIcon } from '../Icons/Search';

interface ISearchField {
  id?: string;
  name: string;
  value: any;
  className?: string;
  label?: string;
  type?: string;
  placeholder?: string;
  error?: string;
  optional?: boolean;
  disabled?: boolean;
  clearable?: boolean;
  leadingIcon?: JSX.Element;
  trailingIcon?: JSX.Element;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  onClear?: MouseEventHandler<HTMLButtonElement>;
  onEnter?: KeyboardEventHandler<HTMLInputElement>;
}

const SearchField = ({
  id,
  name,
  value,
  type,
  placeholder,
  error,
  disabled,
  onChange,
  onBlur,
  onEnter,
}: ISearchField): JSX.Element => {
  const [previousError, setPreviousError] = useState(error);
  const [hasFocus, setHasFocus] = useState(false);

  // Used to fix the animation by not removing the text as we're transitioning out
  useEffect(() => {
    if (error) {
      setPreviousError(error);
    }
  }, [error]);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      onEnter && onEnter(event);
    }
  };

  return (
    <>
      <div className={'flex relative w-full'}>
        {/* Input */}
        <input
          id={id}
          type={type || 'text'}
          name={name}
          className="search-field"
          disabled={disabled ?? false}
          placeholder={placeholder || ''}
          aria-describedby="optional"
          onChange={(ev) => {
            if (type === 'number') {
              // Replace 'e' and leading zeros
              const value = ev.target.value;
              const input = parseInt(value.replace(/e/g, '') || '0', 10);
              ev.target.value = input.toString();
            }
            onChange && onChange(ev);
          }}
          onBlur={(e) => {
            setHasFocus(false);
            onBlur && onBlur(e);
          }}
          onFocus={() => {
            setHasFocus(true);
          }}
          onKeyDown={handleKeyDown}
          value={value ?? ''} // Allow null values to be passed in and simply convert the input value to empty string for display purposes
          autoComplete="off"
        />
        {/* Trailing Icon */}
        <div className="absolute right-0 pr-4 -translate-y-1/2 top-1/2">
          <SearchIcon />
        </div>
      </div>
      <ErrorMessage show={!!error} message={previousError} />
    </>
  );
};

export default SearchField;
