import { ISearchTestMessageDataResponse } from '@gr/shared/models';
import { DetailsPanel } from '../shared/DetailsPanel/DetailsPanel';
import { DetailsPanelSize, IButtonOptions } from '../shared/DetailsPanel/types';
import TestMessageDataForm from './TestMessageDataForm';
import { ITestMessageDataForm } from './types';

interface ITestMessageDataDetailsPanel {
  errorMessage: string;
  show: boolean;
  loading: boolean;
  selectedItem: ISearchTestMessageDataResponse;
  leftPanelButtonOptions: IButtonOptions[];
  onClosePanel: () => void;
  handleSubmit: (formData: ITestMessageDataForm) => Promise<void>;
}

const TestMessageDataDetailsPanel = ({
  errorMessage,
  show,
  loading,
  selectedItem,
  leftPanelButtonOptions,
  onClosePanel,
  handleSubmit,
}: ITestMessageDataDetailsPanel): JSX.Element => {
  return (
    <DetailsPanel
      item={selectedItem}
      errorMessage={errorMessage}
      title={selectedItem ? 'View Test Message Data' : 'Add Test Message Data'}
      formId="test-message-data-form"
      size={DetailsPanelSize.Small}
      show={show}
      closePanel={onClosePanel}
      loading={loading}
      primaryButtonOptions={{ text: 'Save', visible: true }}
      leftPanelButtonOptions={leftPanelButtonOptions}
    >
      <TestMessageDataForm item={selectedItem} onSubmit={handleSubmit} />
    </DetailsPanel>
  );
};

export default TestMessageDataDetailsPanel;
