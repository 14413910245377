import { CampaignTypeEnum, MessageTypeEnum } from '@gr/shared/enums';
import { ITCRCampaignDetails } from '@gr/shared/models';
import { FastField, Formik } from 'formik';
import { messageTypeOptions } from '../Campaigns/types';
import { campaignTypeDropdownValues } from '../SystemNumbers/types';
import { Dropdown, IDropdownValue } from '../shared/Form/Dropdown';
import ClientDropdown from '../shared/Form/Dropdowns/ClientDropdown';
import ProviderDropdown from '../shared/Form/Dropdowns/ProviderDropdown';
import { IRadioButtonOption, RadioButtonGroup } from '../shared/Form/RadioButton';
import { ITCRCampaignForm, tcrCampaignFormSchema } from './types';

export interface ITCRCampaignFormProps {
  providers: IDropdownValue[];
  item?: ITCRCampaignDetails;
  onSubmit: (values: any) => void;
}

const TCRCampaignForm = ({ providers, item, onSubmit }: ITCRCampaignFormProps): JSX.Element => {
  const isReadOnly: boolean = !!item;

  const initialFormState: ITCRCampaignForm = {
    client: { label: item?.clientName ?? '', value: item?.clientId ?? '' },
    providerId: item?.providerId ?? '',
    campaignType: item?.campaignType ?? CampaignTypeEnum.FUNDRAISING,
    messageType: item?.messageType ?? MessageTypeEnum.SMS,
  };

  const slimMessageOptions = messageTypeOptions.map((option) => ({ ...option, description: undefined }));

  return (
    <>
      <Formik
        initialValues={initialFormState}
        validationSchema={tcrCampaignFormSchema}
        onSubmit={(values) => {
          onSubmit && onSubmit(values);
        }}
      >
        {({ values, errors, touched, handleBlur, handleSubmit, setFieldValue, setFieldTouched }) => (
          <>
            <form id="tcr-campaign-form" onSubmit={handleSubmit} className="space-y-2">
              <>
                <ClientDropdown
                  value={values.client}
                  onChange={(newValue) => {
                    setFieldValue('client', newValue);
                    setFieldValue('providerId', '');
                  }}
                  onBlur={() => {
                    setFieldTouched('client');
                  }}
                  disabled={isReadOnly}
                  errorMessage={(errors?.client as any)?.value}
                />
              </>

              <>
                <ProviderDropdown
                  value={values.providerId ?? ''}
                  onChange={(newValue) => {
                    setFieldValue('providerId', newValue?.value ?? '');
                  }}
                  disabled={isReadOnly}
                  onBlur={() => {
                    setFieldTouched('providerId', true);
                  }}
                  showError={!!(touched.providerId && errors.providerId)}
                  errorMessage={errors?.providerId}
                />
              </>

              {/* <CampaignTypeForClientDropdown
                value={values.campaignType}
                clientId={values.clientId}
                onChange={(newValue: IDropdownValue) => {
                  setFieldValue('campaignType', newValue.value);
                }}
                onBlur={() => {
                  setFieldTouched('campaignType');
                }}
                disabled={isReadOnly}
                touched={touched.campaignType}
                errorMessage={errors.campaignType}
              /> */}

              <Dropdown
                label="Campaign Type"
                value={
                  values.campaignType ? campaignTypeDropdownValues.find((ct) => ct.value === values.campaignType) : null
                }
                onChange={(newValue: IDropdownValue) => {
                  setFieldValue('campaignType', newValue.value);
                }}
                onBlur={handleBlur}
                options={campaignTypeDropdownValues}
                disabled={isReadOnly || campaignTypeDropdownValues.length === 0}
                placeholder="Select a Campaign Type"
                error={
                  touched && campaignTypeDropdownValues.length === 0 && values.client?.value
                    ? 'Client has no number for Provider'
                    : errors.campaignType
                }
              />

              <FastField
                name="messageType"
                component={RadioButtonGroup}
                options={slimMessageOptions}
                label="Message Type"
                error={touched.messageType && (errors.messageType as any)?.value}
                disabled={isReadOnly}
                value={slimMessageOptions.find((message) => message.value === values.messageType)}
                onBlur={handleBlur}
                onChange={(messageType: IRadioButtonOption) => {
                  setFieldTouched('messageType');
                  setFieldValue('messageType', messageType.value);
                }}
              />
            </form>
          </>
        )}
      </Formik>
    </>
  );
};

export default TCRCampaignForm;
