import useCampaignTypesForClient from '@gr/portal/hooks/useCampaignTypesForClient';
import { convertEnumToReadableString } from '@gr/portal/providers/utility.provider';
import { CampaignTypeEnum, MessageTypeEnum } from '@gr/shared/enums';
import { useEffect } from 'react';
import { Dropdown, IDropdownValue } from '../Dropdown';

interface ICampaignTypeDropdownProps {
  value: any;
  clientId: string;
  messageType: MessageTypeEnum;
  onChange: (newValue: IDropdownValue) => void;
  onBlur?: { (e: any): void; <T = any>(fieldOrEvent: T): T extends string ? (e: any) => void : void; };
  disabled?: boolean;
  touched?: boolean;
  errorMessage?: string;
}

const CampaignTypeForClientDropdown = ({
  value,
  clientId,
  messageType,
  errorMessage,
  disabled,
  touched,
  onChange,
  onBlur,
}: ICampaignTypeDropdownProps): JSX.Element => {
  const [{ data, loading, error }, fetchCampaignTypes] = useCampaignTypesForClient(clientId, messageType);

  const campaignTypes: CampaignTypeEnum[] = data?.data.types ?? [];

  const dropdownValues: IDropdownValue[] = campaignTypes.map((ct) => ({
    label: ct === 'GOTV' ? 'GOTV' : convertEnumToReadableString(ct), // We want GOTV to remain full caps
    value: ct,
  }));

  useEffect(() => {
    if (clientId && messageType) {
      const getCampaignTypes = async () => {
        try {
          await fetchCampaignTypes({ data: { clientId: clientId, messageType: messageType } });
        } catch (err) { }
      };
      getCampaignTypes();
    }
  }, [clientId, messageType]);

  return (
    <Dropdown
      label="Campaign Type"
      loading={loading}
      value={value ? dropdownValues.find((ct) => ct.value === value) : null}
      onChange={onChange}
      onBlur={onBlur}
      options={dropdownValues}
      disabled={disabled || dropdownValues.length === 0 || !clientId || !messageType}
      placeholder="Select a Campaign Type"
      error={touched && dropdownValues.length === 0 && clientId ? 'Client has no number for Provider' : errorMessage}
    />
  );
};

export default CampaignTypeForClientDropdown;
