import VideoPlayer from './VideoPlayer';

interface IPreviewMediaProps {
  mediaUrl: string;
  isMMSVideo?: boolean,
}
const PreviewMedia = ({ isMMSVideo, mediaUrl }: IPreviewMediaProps) => {
  return (
    !isMMSVideo ? <img className='rounded-2xl' src={mediaUrl} /> : <VideoPlayer mediaUrl={mediaUrl} />
  );
};

export default PreviewMedia;