import { convertEnumToReadableString } from '@gr/portal/providers/utility.provider';
import { Auth0Role } from '@gr/shared/models';
import { Autocomplete } from '@Wonder-Cave/ui';
import { IDropdownValue } from '../Dropdown';

interface IRoleDropdownProps {
  value: IDropdownValue;
  onChange: (newValue?: IDropdownValue | undefined) => void;
  onBlur?: () => void;
  disabled?: boolean;
  errorMessage?: string | undefined;
  showError?: boolean;
  shimmer?: boolean;
  hiddenRoles?: Array<Auth0Role>;
}

const RoleDropdown = ({
  value,
  onChange,
  onBlur,
  disabled,
  errorMessage,
  showError,
  shimmer,
  hiddenRoles
}: IRoleDropdownProps): JSX.Element => {
  const roles = Object.entries(Auth0Role).filter(([_, role]) => !(hiddenRoles ?? []).includes(role)).map(([label, value]) => ({
    label: convertEnumToReadableString(label).replace('Gr ', 'GR '),
    value,
  })) as IDropdownValue[];
  return (
    <Autocomplete
      label="Role"
      value={roles.find((r) => r.value === value?.value)}
      options={roles}
      onChange={onChange}
      onBlur={onBlur}
      showError={showError}
      error={errorMessage}
      disabled={disabled}
      shimmer={shimmer}
    />
  );
};

export default RoleDropdown;
