import useCarriers from '@gr/portal/hooks/useCarriers';
import { carrierNameToLabel, majorCarrierSearch } from '../../../CarrierExclusions/types';
import { IDropdownValue } from '../Dropdown';
import Autocomplete from './Autocomplete';

interface IClientDropdownProps {
  value: string;
  onChange: (newValue: IDropdownValue | undefined) => void;
  onBlur: () => void;
  disabled?: boolean;
  errorMessage?: string | undefined;
  showError?: boolean;
}

const CarrierDropdown = ({
  value,
  onChange,
  onBlur,
  disabled,
  errorMessage,
  showError,
}: IClientDropdownProps): JSX.Element => {
  const [{ data: carrierData, loading: carrierLoading, error: carrierError }] = useCarriers(majorCarrierSearch);

  const carriers: IDropdownValue[] =
    carrierData?.data.records.map((carrier) => ({
      label: carrierNameToLabel.get(carrier.name) ?? '',
      value: carrier.id ?? '',
    })) ?? [];

  return (
    <Autocomplete
      label="Carrier"
      value={carriers.find((carrier) => carrier.value === value)}
      options={carriers}
      onChange={onChange}
      onBlur={onBlur}
      showError={showError}
      error={errorMessage}
      disabled={disabled}
    />
  );
};

export default CarrierDropdown;
