import React, { Dispatch, SetStateAction } from 'react';

interface ActiveUser {
  firstName?: string;
  lastName?: string;
}

interface ISidebarContext {
  devMode: boolean;
  sidebarOpen: boolean;
  activeUser: ActiveUser;
  setDevMode: Dispatch<SetStateAction<boolean>>;
  setSidebarOpen: Dispatch<SetStateAction<boolean>>;
  setActiveUser: Dispatch<SetStateAction<ActiveUser>>;
}

const SidebarContext = React.createContext<ISidebarContext>({
  devMode: false,
  sidebarOpen: true,
  activeUser: { firstName: undefined, lastName: undefined },
  setDevMode: () => { },
  setSidebarOpen: () => { },
  setActiveUser: () => { },
});

const SidebarContextProvider = SidebarContext.Provider;
const SidebarContextConsumer = SidebarContext.Consumer;

export { SidebarContext, SidebarContextProvider, SidebarContextConsumer };
