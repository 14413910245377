import { ILinkOrderEntity } from '@gr/shared/models';
import { DetailsPanel } from '../shared/DetailsPanel/DetailsPanel';
import { DetailsPanelSize } from '../shared/DetailsPanel/types';
import LinkForm from './LinkOrderForm';
import { ILinkForm } from './types';

interface ILinkDetailsPanel {
  show: boolean;
  loading: boolean;
  selectedItem?: ILinkOrderEntity;
  onClosePanel: () => void;
  handleSubmit: (formData: ILinkForm) => Promise<void>;
}

const LinkDetailsPanel = ({
  show,
  loading,
  selectedItem,
  onClosePanel,
  handleSubmit,
}: ILinkDetailsPanel): JSX.Element => {
  return (
    <DetailsPanel
      item={selectedItem}
      title={selectedItem ? 'View Links' : 'Generate New Links'}
      formId="link-form"
      size={DetailsPanelSize.Medium}
      show={show}
      closePanel={onClosePanel}
      loading={loading}
      isReadOnly={!!selectedItem}
      primaryButtonOptions={{ text: 'Save', visible: !selectedItem }}
    >
      <LinkForm item={selectedItem} onSubmit={handleSubmit} />
    </DetailsPanel>
  );
};

export default LinkDetailsPanel;
