import useDomains from '@gr/portal/hooks/useDomains';
import { IDropdownValue } from '../Dropdown';
import Autocomplete from './Autocomplete';

interface IDomainsDropdownProps {
  value: string | IDropdownValue;
  onChange: (newValue: IDropdownValue | undefined) => void;
  onBlur: () => void;
  disabled?: boolean;
  errorMessage?: string | undefined;
  showError?: boolean;
}

const DomainsDropdown = ({
  value,
  onChange,
  onBlur,
  disabled,
  errorMessage,
  showError,
}: IDomainsDropdownProps): JSX.Element => {
  const [{ data: domainsData, loading: domainsLoading, error: domainsError }] = useDomains();

  const domains: IDropdownValue[] =
    domainsData?.data.map((domain) => ({
      label: domain.url ?? '',
      value: domain.url ?? '',
    })) ?? [];

  return (
    <Autocomplete
      label="Domain"
      value={domains.find((provider) => provider.value === value)}
      options={domains}
      onChange={onChange}
      onBlur={onBlur}
      showError={showError}
      error={errorMessage}
      disabled={disabled}
      search={false}
    />
  );
};

export default DomainsDropdown;
