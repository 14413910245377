export enum CampaignStatusEnum {
  DRAFT = 'DRAFT',
  SCHEDULING = 'SCHEDULING',
  LIVE = 'LIVE',
  PAUSED = 'PAUSED',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED'
}

export enum UiCampaignStatusEnum {
  ACTIVE = 'Active',
  SENDING = 'Sending',
  SCHEDULED = 'Scheduled',
  COMPLETED = 'Completed',
  INCOMPLETE = 'Incomplete',
  DID_NOT_SCHED = 'Did Not Schedule',
  DRAFT = 'Draft',
  PAUSED = 'Paused',
  SCHEDULING = 'Scheduling',
  QUEUED = 'Queued',
  PENDING = 'Pending'
}
