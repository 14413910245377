import { ISearchTestMessageDataResponse } from '@gr/shared/models';
import { FastField, Formik } from 'formik';
import { TextInput } from '../shared/Form/TextInput';
import { ITestMessageDataForm, testMessageDataFormSchema } from './types';

interface ITestMessageDataFormProps {
  item?: ISearchTestMessageDataResponse;
  onSubmit: (values: any) => void;
}

const TestMessageDataForm = ({ item, onSubmit }: ITestMessageDataFormProps): JSX.Element => {
  const initialFormState: ITestMessageDataForm = {
    name: item?.name ?? '',
    value: item?.value ?? '',
  };

  const isReadOnly: boolean = item !== null && item !== undefined;

  return (
    <>
      <Formik
        initialValues={initialFormState}
        validationSchema={testMessageDataFormSchema}
        onSubmit={(values) => {
          onSubmit && onSubmit(values);
        }}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue, setFieldTouched }) => (
          <>
            <form id="test-message-data-form" onSubmit={handleSubmit} className="space-y-2">
              <FastField
                component={TextInput}
                id="name"
                name="name"
                label="Name"
                disabled={isReadOnly}
                value={values.name}
                error={touched.name ? errors.name : ''}
                onChange={handleChange}
                onBlur={handleBlur}
              />

              <FastField
                component={TextInput}
                id="value"
                name="value"
                label="Value"
                value={values.value}
                error={touched.value ? errors.value : ''}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </form>
          </>
        )}
      </Formik>
    </>
  );
};

export default TestMessageDataForm;
