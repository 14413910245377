import { IHttpSearchResponse, IOptoutView, ISearchRequest } from '@gr/shared/models';
import useAxios, { UseAxiosResult } from 'axios-hooks';

const useOptOuts = (searchOptions: ISearchRequest): UseAxiosResult<IHttpSearchResponse<IOptoutView>> => {
  return useAxios<IHttpSearchResponse<IOptoutView>>({
    url: 'optouts-search',
    method: 'POST',
    data: searchOptions,
  });
};

export default useOptOuts;
