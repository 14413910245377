import { MessageTypeEnum } from '@gr/shared/enums';
import { ICampaignGetTypesRequest, ICampaignGetTypesResponse, IHttpResponse } from '@gr/shared/models';
import useAxios, { UseAxiosResult } from 'axios-hooks';

export default function useCampaignTypesForClient(
  clientId?: string,
  messageType?: MessageTypeEnum
): UseAxiosResult<IHttpResponse<ICampaignGetTypesResponse>> {
  return useAxios<IHttpResponse<ICampaignGetTypesResponse>>(
    {
      url: 'campaigns-get-types-for-client',
      method: 'POST',
      data: { clientId: clientId, messageType: messageType } as ICampaignGetTypesRequest,
    },
    { manual: true }
  );
}
