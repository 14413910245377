import { CampaignTypeEnum } from '@gr/shared/enums';
import { IHttpResponse, IProvidersGetForClientResponse } from '@gr/shared/models';
import useAxios, { UseAxiosResult } from 'axios-hooks';

export default function useProvidersForClient(
  clientId: string,
  campaignType: CampaignTypeEnum
): UseAxiosResult<IHttpResponse<IProvidersGetForClientResponse>> {
  return useAxios<IHttpResponse<IProvidersGetForClientResponse>>(
    {
      url: 'providers-get-for-client',
      method: 'POST',
      data: {
        clientId,
        campaignType
      },
    },
    { manual: true }
  );
}
