import { axiosPost } from '@gr/portal/authAxios';
import { NotificationType, useNotifications } from '@gr/portal/contexts/NotificationContext';
import { IContactListUploadRequest, IContactListUploadResponse, ICreateContactListRequest, IHttpResponse } from '@gr/shared/models';
import axios from 'axios';
import useAxios from 'axios-hooks';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import ContactListsCreateForm, { IContactListCreateForm } from './ContactListCreateForm';



const ContactListsCreate = () => {
  const history = useHistory();
  const { addNotification } = useNotifications();
  const [submitError, setSubmitError] = useState(false);
  const [{ error: createError }, createList] = useAxios<IHttpResponse<string>>(
    {
      url: '/contact-lists-create',
      method: 'POST',
    },
    { manual: true }
  );
  const saveClient = async (formData: IContactListCreateForm) => {
    try {
      setSubmitError(true);
      await createContactList(formData);
      addNotification({
        type: NotificationType.SUCCESS,
        header: 'Contact list saved successfully!',
        content: formData.contactList
          ? `You will receive an email once the contacts have been added to the list.`
          : undefined,
      });
    } catch (error: any) {
      console.error(error);
      const data = error?.response?.data;
      const statusCode = data?.statusCode ?? error.statusCode;

      if (statusCode === 404) {
        addNotification({
          type: NotificationType.FAILURE,
          header: 'Failed to save Contact list',
          content: `We were unable to find the contact list`,
        });
      } else
        if (statusCode === 409) {
          addNotification({
            type: NotificationType.FAILURE,
            header: 'Failed to save Contact list',
            content: `Contact List ${formData.name} already exists.`,
          });
        } else if (statusCode === 422) {
          addNotification({
            type: NotificationType.FAILURE,
            header: 'Failed to save Contact list',
            content: error.message,
          });
        } else {
          addNotification({
            type: NotificationType.FAILURE,
            header: 'Failed to save Contact list',
            content: `We were unable to save the contact list`,
          });
        }
    } finally {
      setSubmitError(false);
    }
  };

  const createContactList = async (formData: IContactListCreateForm): Promise<void> => {
    try {
      await createList({
        data: {
          name: formData.name,
          isActive: formData.isActive,
          originalFileName: formData.contactList?.name,
        } as ICreateContactListRequest,
      });

      if (formData.contactList) {
        uploadCsv(formData.name, formData);
      }
      history.push('/app/contact-lists');
    } catch (err) {
      throw err;
    }
  };

  const uploadCsv = async (contactListName: string, formData: IContactListCreateForm) => {
    const request: IContactListUploadRequest = {
      contactListName: contactListName,
    };

    const {
      data: {
        data: { url },
      },
    } = await axiosPost<IHttpResponse<IContactListUploadResponse>>('/contact-lists', request);

    await axios.put(url, formData.contactList, {
      headers: { 'Content-Type': 'text/csv' },
    });

  };

  return (
    <>
      <ContactListsCreateForm onSubmit={saveClient} item={undefined} onError={submitError} />
    </>
  );
};



export default ContactListsCreate;
