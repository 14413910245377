import { BUCKETS, EntityName } from '@gr/shared/enums';
import {
  FilterDataTypeEnum,
  FilterOperatorEnum,
  ISearchRequest,
  ISignedUrlRequest,
  SearchSortDirectionEnum,
} from '@gr/shared/models';
import * as yup from 'yup';
import { getCsvHeaders } from '../../providers/validation.provider';
import { IColumn, IColumnClickConfig } from '../shared/Table/types';
import { ISuppressionListForm } from './SuppressionListForm';

export const defaultSuppressionListTableOptions: ISearchRequest = {
  entity: EntityName.SUPPRESSION_LISTS,
  filters: [],
  pagination: {
    skip: 0,
    take: 25,
  },
  sort: {
    fieldName: 'createdAt',
    sortDirection: SearchSortDirectionEnum.DESC,
  },
};

export const allSuppressionListSearchOptions: ISearchRequest = {
  entity: EntityName.SUPPRESSION_LISTS,
  filters: [],
  pagination: {
    skip: 0,
    take: 10000,
  },
  sort: {
    fieldName: 'name',
    sortDirection: SearchSortDirectionEnum.ASC,
  },
};

export const allEnabledSuppressionListSearchOptions: ISearchRequest = {
  entity: EntityName.SUPPRESSION_LISTS,
  filters: [
    {
      dataType: FilterDataTypeEnum.BOOLEAN,
      fieldName: 'isActive',
      operator: FilterOperatorEnum.EQUALS,
      value: true,
    }
  ],
  pagination: {
    skip: 0,
    take: 10,
  },
  sort: {
    fieldName: 'name',
    sortDirection: SearchSortDirectionEnum.ASC,
  },
};

export const getColumns = (columnClickEvents: IColumnClickConfig): IColumn[] => {
  return [
    {
      headerName: 'Suppression List Name',
      fieldName: 'name',
      fieldType: FilterDataTypeEnum.STRING,
      isRowHeader: true,
      onColumnClick: columnClickEvents['name'],
    },
    {
      headerName: 'Status',
      fieldName: 'isActive',
      fieldType: FilterDataTypeEnum.BOOLEAN,
    },
    {
      headerName: 'Created At',
      fieldName: 'createdAt',
      fieldType: FilterDataTypeEnum.DATE,
    },
    {
      headerName: 'Last Updated Date',
      fieldName: 'updatedAt',
      fieldType: FilterDataTypeEnum.DATE,
    },
    {
      headerName: 'Last Updated By',
      fieldName: 'updatedBy',
      fieldType: FilterDataTypeEnum.STRING,
    },
  ];
};

export const s3UrlRequest: ISignedUrlRequest = {
  bucket: BUCKETS.SUPPRESSIONS,
  key: '',
  action: 'putObject',
  contentType: 'text/csv',
};

// TODO: Turn this into a call with validation options
const validateCsvHeaders = async (file: File) => {
  try {
    const headers = await getCsvHeaders(file);

    return (
      headers.length === 2 && headers[0].toLocaleLowerCase() === 'phone' && headers[1].toLocaleLowerCase() === 'name'
    );
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const suppressionListFormSchema: yup.SchemaOf<ISuppressionListForm> = yup.object().shape({
  name: yup.string().defined('Required'),
  isActive: yup
    .object()
    .shape({
      label: yup.string(),
      value: yup.boolean().required('Required'),
    })
    .nullable(),
  suppressionList: yup
    .mixed()
    // If there's no file, no need to run validation
    .test('csvValidate', 'Uploaded file must be .csv', (file: File): boolean => {
      return file ? file.name?.endsWith('.csv') : true;
    })
    .test('csvHeaderValidate', 'CSV does not contain the proper headers', async (file: File): Promise<boolean> => {
      return file ? await validateCsvHeaders(file) : true;
    }),
});
