import { Icon } from './Icon';

export const Radio = () => (
  <Icon nav={false}>
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <circle cx="10" cy="10" r="9" fill="#B5E74B" stroke="#B5E74B" stroke-width="2" />
      <path
        d="M14.9714 7.54891C14.9714 7.54891 14.9488 7.59522 14.9263 7.61837C14.9263 7.61837 14.915 7.62995 14.9037 7.64153L9.04591 13.6386C8.95562 13.7313 8.86533 13.8239 8.77503 13.9165C8.63959 14.0438 8.45901 14.0554 8.32357 13.9281C8.26713 13.8818 8.22198 13.8239 8.16555 13.766L6.57413 12.1336L5.34388 10.8716C5.26487 10.7906 5.17458 10.698 5.09557 10.6169C4.94885 10.4548 4.98271 10.2696 5.11815 10.1191L5.9195 9.2971C6.0098 9.20448 6.11138 9.06555 6.23553 9.01924C6.39354 8.97293 6.50641 9.06555 6.60799 9.16975C6.84501 9.41288 7.07074 9.64442 7.30776 9.88755C7.71408 10.3043 8.1204 10.7211 8.52673 11.1379C8.59445 11.0684 8.65088 11.0106 8.7186 10.9527C8.92176 10.7443 9.13621 10.5243 9.33937 10.3159L11.0775 8.53299L12.7479 6.81953C12.9285 6.63429 13.1091 6.44905 13.2784 6.27539C13.3687 6.18277 13.4703 6.04384 13.6057 6.00911C13.7976 5.9628 13.9105 6.10173 14.0233 6.22908C14.1588 6.36801 14.2942 6.51852 14.4409 6.65745L14.836 7.06266C14.836 7.06266 14.8924 7.12054 14.9263 7.15528C15.0279 7.27105 15.0391 7.40998 14.9827 7.53733L14.9714 7.54891Z"
        fill="black"
      />
    </svg>
  </Icon>
);
