import { v4 as uuid } from 'uuid';

export enum ShimmerType {
  LIST,
  HEADER,
  TABLE,
  INPUT,
}

interface IShimmerProps {
  name?: string;
  type: ShimmerType;
  rows?: number;
  columns?: number;
}

const Shimmer = ({ name = uuid(), type, rows, columns }: IShimmerProps) => {
  switch (type) {
    case ShimmerType.LIST:
      return (
        <ul className="flex flex-col space-y-2">
          {[...new Array(rows ?? 3)].map((row, index) => {
            return (
              <li className="flex items-center space-x-2" key={`${name}_shimmer_${index}`}>
                <span className="inline-flex items-center flex-none w-6 h-6 leading-none bg-gray-200 rounded-full dark:bg-slate-400 animate-pulse"></span>
                <span className="inline-flex items-center w-full h-6 my-1 text-sm leading-none bg-gray-200 rounded-md dark:bg-slate-400 animate-pulse"></span>
              </li>
            );
          })}
        </ul>
      );
    case ShimmerType.HEADER:
      return <div className="w-1/4 leading-none bg-gray-200 rounded-lg h-7 dark:bg-slate-400 animate-pulse"></div>;
    case ShimmerType.INPUT:
      return (
        <div className="flex flex-col space-y-4">
          <div className="w-1/2 h-3 bg-gray-200 rounded-lg dark:bg-slate-400 animate-pulse"></div>
          <div className="w-full bg-gray-200 rounded-lg h-7 dark:bg-slate-400 animate-pulse"></div>
        </div>
      );
    case ShimmerType.TABLE:
      return (
        <table className="w-full">
          <tbody className="dark:border-slate-800">
            {[...new Array(rows ?? 10)].map((item, index) => (
              <tr
                key={`item_shimmer_${index}`}
                className={index % 2 !== 0 ? 'bg-gray-50 dark:bg-slate-800' : 'bg-white dark:bg-slate-700'}
              >
                {[...new Array(columns ?? 5)].map((col, index) => (
                  <td
                    className="px-3 py-4 text-sm font-medium whitespace-nowrap hover:underline text-sky-600 dark:text-slate-300 dark:hover:text-white"
                    key={`item_td_shimmer_${index}`}
                  >
                    <span className="inline-flex items-center w-full h-4 text-sm leading-none bg-gray-200 rounded-lg dark:bg-slate-400 animate-pulse"></span>
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      );
    default:
      return <></>;
  }
};

export default Shimmer;
