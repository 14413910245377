import { IContactListEntity, IGetContactListBreakdownResponse } from '@gr/shared/models';
import { convertToLocalTimeString } from '@gr/shared/time';
import { FastField, Formik } from 'formik';
import { NewFileUpload } from '../shared/Form/NewFileUpload';
import { NewSlider } from '../shared/Form/NewSlider';
import { IRadioButtonOption } from '../shared/Form/RadioButton';
import { contactDetailsListFormSchema } from './types';

export interface IContactListForm {
  name: string;
  isActive?: boolean;
  contactList?: any;
  createdAt: any;
  updatedAt: any;
}

const options: IRadioButtonOption[] = [
  {
    label: 'Disabled',
    value: false,
  },
  {
    label: 'Enabled',
    value: true,
  },

];

interface IContactListFormProps {
  item: IContactListEntity | undefined;
  breakdownInfo?: IGetContactListBreakdownResponse[];
  onSubmit?: (formValues: IContactListForm) => void;
  onDelete?: (formValues: IContactListForm) => void;
  onExport?: (formValues: IContactListForm) => void;
}

const ContactListForm = ({ item, onSubmit }: IContactListFormProps): JSX.Element => {
  const initialFormState: IContactListForm = {
    name: item?.name ?? '',
    isActive: item?.isActive ?? false,
    contactList: undefined,
    createdAt: item?.createdAt ?? '',
    updatedAt: item?.updatedAt ?? ''
  };

  const onBack = () => {
    history.back();
  };


  return (
    <>
      <Formik
        initialValues={initialFormState}
        validationSchema={contactDetailsListFormSchema}
        onSubmit={async (values) => {
          onSubmit && onSubmit(values);
        }}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue, setFieldTouched }) => (
          // Set form id so external submit button can still work
          <>
            <form id="contact-form" onSubmit={handleSubmit}>
              <div className="flex flex-col space-y-4 text-left">
                <div className=''>

                  <h3 className='text-lg leading-6 font-extrabold text-black pb-8'>
                    Contact List
                  </h3>
                  <div className='flex mb-8'>
                    <div className='w-3/12 pr-32'>
                      <label className='text-gray-500 text-xs'>STATUS</label>
                      <FastField
                        id="isActive"
                        name="isActive"
                        component={NewSlider}
                        checked={values.isActive}
                        options={options}
                        error={touched.isActive ? errors.isActive : ''}
                        value={values.isActive}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                    </div>
                    <div className='w-3/12'>
                      <label className='text-gray-500 text-xs pr-2 break-words'>CREATED ON</label>
                      <div className='mt-2 pr-2 break-words'>{convertToLocalTimeString(values.createdAt)} </div>
                    </div>
                    <div className='w-3/12'>
                      <label className='text-gray-500 text-xs break-words'>LAST UPDATED</label>
                      <div className='mt-2 break-words' >{convertToLocalTimeString(values.updatedAt)}</div>
                    </div>
                  </div>
                </div>

                <div className='max-w-md'>
                  <NewFileUpload
                    id="contactList"
                    name="contactList"
                    label="ADD CONTACTS"
                    accepts=".csv"
                    value={values.contactList}
                    error={touched.contactList && (errors.contactList as any)}
                    onBlur={handleBlur}
                    placeholder={item?.originalFileName ?? 'Drop your CSV file here or browse file from your computer'}
                    onChange={(fileUploadEvent: any) => {
                      const file = fileUploadEvent.target.files[0];
                      setFieldTouched('contactList');
                      setFieldValue('contactList', file);
                      if (!values.name) {
                        const displayName = file.name.split('.csv')[0].replace(/_/g, ' ');
                        // If no name has been provided, default it to the filename
                        setFieldValue('name', `${displayName.charAt(0).toUpperCase()}${displayName.slice(1)}`);
                      }
                    }}
                  />
                </div>
              </div>
            </form>
            {/* <ul>
              {values.contactList?.map((val: any) => (
                <li>test</li>
              ))}
            </ul> */}
          </>
        )}
      </Formik >
    </>
  );
};

export default ContactListForm;
