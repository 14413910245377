import { ISearchPagination } from '@gr/shared/models';
import { Button } from '../Buttons/Button';
import { ButtonVariantEnum } from '../Buttons/types';

interface IPaginationProps {
  loading?: boolean;
  count: number;
  paginationConfig: ISearchPagination;
  onPaginate: (newPaginationConfig: ISearchPagination) => void;
}

const Pagination = ({ loading, count, paginationConfig, onPaginate }: IPaginationProps) => {
  const { skip, take } = paginationConfig;

  //   const numberOfPages = Math.ceil(count / take);
  //   const currentPage = Math.ceil(skip / take);

  return (
    <div className="sticky bottom-0 flex items-center justify-between px-4 py-3 bg-white border-t border-b border-gray-200 shadow dark:border-slate-800 dark:bg-slate-700 sm:rounded-b-lg sm:px-6">
      <div className="flex justify-between flex-1 sm:hidden">
        <PaginateButtons skip={skip} take={take} count={count} onPaginate={onPaginate} loading={loading} />
      </div>
      <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
        <div>
          {loading && (
            <p className="flex text-sm text-gray-700 dark:text-slate-400 ">
              <span className="inline-flex items-center w-48 h-4 text-sm leading-none bg-gray-300 rounded-lg animate-pulse"></span>
            </p>
          )}
          {!loading && count > 0 && (
            <p className="text-sm text-gray-700 dark:text-white">
              Showing <span className="font-medium">{skip + 1}</span> to{' '}
              <span className="font-medium">{Math.min(skip + take, count)}</span> of{' '}
              <span className="font-medium">{count.toLocaleString()}</span> results
            </p>
          )}
        </div>

        <div className="flex justify-between flex-1 sm:justify-end">
          <PaginateButtons skip={skip} take={take} count={count} onPaginate={onPaginate} loading={loading} />
        </div>
      </div>
    </div>
  );
};

interface IPaginateButtonProps {
  skip: number;
  take: number;
  count: number;
  loading?: boolean;
  onPaginate: (newPaginationConfig: ISearchPagination) => void;
}

const PaginateButtons = ({ skip, take, count, loading, onPaginate }: IPaginateButtonProps) => {
  return (
    <>
      <Button
        disabled={skip === 0 || loading}
        onClick={() => {
          onPaginate({ skip: Math.max(0, skip - take), take });
        }}
        variant={ButtonVariantEnum.TEXT_PRIMARY}
        className="border border-gray-300 rounded-md dark:border-slate-800 hover:bg-gray-100 dark:hover:bg-slate-400 dark:hover:text-slate-800"
      >
        Previous
      </Button>

      {!loading && count > 0 && (
        <div className="flex items-center text-center sm:hidden">
          <span className="text-sm font-medium text-gray-700 dark:text-white ">
            {skip + 1} - {Math.min(skip + take, count)} of {count.toLocaleString()}
          </span>
        </div>
      )}

      <Button
        onClick={() => {
          onPaginate({ skip: skip + take, take });
        }}
        disabled={skip + take >= count || loading}
        variant={ButtonVariantEnum.TEXT_PRIMARY}
        className="border border-gray-300 rounded-md sm:ml-3 dark:border-slate-800 hover:bg-gray-100 dark:hover:bg-slate-400 dark:hover:text-slate-800"
      >
        Next
      </Button>
    </>
  );
};

export { Pagination };
