import { IClientView, IHttpSearchResponse, ISearchRequest } from '@gr/shared/models';
import useAxios, { UseAxiosResult } from 'axios-hooks';

export default function useClients(searchOptions: ISearchRequest): UseAxiosResult<IHttpSearchResponse<IClientView>> {
  return useAxios<IHttpSearchResponse<IClientView>>({
    url: 'entities-search',
    method: 'POST',
    data: searchOptions,
  });
}
