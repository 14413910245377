import {
  FilterDataTypeEnum,
  FilterOperatorEnum,
  ISaveTestMessageDataRequest,
  ISaveTestMessageDataResponse,
  ISearchFilter,
  ISearchRequest,
  ISearchTestMessageDataResponse
} from '@gr/shared/models';
import useAxios from 'axios-hooks';
import { useState } from 'react';
import { axiosDelete } from '../../authAxios';
import { useNotifications } from '../../contexts/NotificationContext';
import useTestMessageData from '../../hooks/useTestMessageData';
import { Button } from '../shared/Buttons/Button';
import { ButtonVariantEnum } from '../shared/Buttons/types';
import Search from '../shared/Table/Search';
import { Table } from '../shared/Table/Table';
import { IColumn } from '../shared/Table/types';
import TestMessageDataDetailsPanel from './TestMessageDataDetailsPanel';
import { ITestMessageDataForm, defaultTableOptions, getColumns } from './types';

const HealthCheckNumbers = () => {
  const [showDetailsPanel, setShowDetailsPanel] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [selectedItem, setSelectedItem] = useState<any>();
  const [tableOptions, setTableOptions] = useState(defaultTableOptions);
  const { addNotification } = useNotifications();
  const [deleteLoading, setDeleteLoading] = useState(false);

  const [{ data: testMessageDataResponse, loading }, refetch] = useTestMessageData(tableOptions);

  const handleSearchOptionChange = (searchOptions: ISearchRequest) => {
    setTableOptions(searchOptions);
  };

  const [{ loading: createLoading, error: createError }, saveTestMessageData] = useAxios<ISaveTestMessageDataResponse>(
    {
      url: 'test-message-data',
      method: 'PUT',
    },
    { manual: true }
  );

  const testMessageData: ISearchTestMessageDataResponse[] = testMessageDataResponse?.records ?? [];

  const handleRefetch = async () => {
    try {
      await refetch();
    } catch (err) { }
  };

  const columns = getColumns({
    name: (item: ISearchTestMessageDataResponse) => {
      openDetailsPanel(item);
    },
  });

  const openDetailsPanel = (item?: ISearchTestMessageDataResponse) => {
    if (item) {
      setSelectedItem(item);
    }
    setShowDetailsPanel(true);
  };

  const handleSubmit = async (formData: ITestMessageDataForm) => {
    const request: ISaveTestMessageDataRequest = {
      name: formData.name,
      value: formData.value,
    };

    try {
      await saveTestMessageData({ data: { ...request } });

      closeDetailsPanel();

      handleRefetch();

      addNotification({ header: 'Test message data saved successfully' });
    } catch (err) {
      console.error(err);
    }
  };

  const handleDelete = async () => {
    if (!selectedItem) {
      return;
    }

    const deleteRequest = {
      id: selectedItem.id,
      clientId: selectedItem.clientId,
    };

    setDeleteLoading(true);

    try {
      await axiosDelete(`/test-message-data/${selectedItem.id}`);

      closeDetailsPanel();

      handleRefetch();

      addNotification({ header: 'Test message data deleted successfully' });
    } catch (err) {
      console.error(err);
    } finally {
      setDeleteLoading(false);
    }
  };

  const closeDetailsPanel = () => {
    setSelectedItem(undefined);
    setShowDetailsPanel(false);
  };

  const filterColumns: IColumn[] = columns.filter((col) => ['name'].includes(col.fieldName));

  const handleSearch = (overrideText?: string) => {
    let searchFilter: ISearchFilter = {
      dataType: FilterDataTypeEnum.STRING,
      fieldName: 'name',
      operator: FilterOperatorEnum.CONTAINS,
      value: searchText,
    };

    if (overrideText !== null && overrideText !== undefined) {
      setSearchText(overrideText);
      searchFilter.value = overrideText;
    }

    const newSearchOptions = { ...defaultTableOptions };

    const nonSearchFilters = tableOptions.filters.filter((filter) => filter.fieldName !== 'name');

    newSearchOptions.filters = searchFilter.value ? [...nonSearchFilters, searchFilter] : [...nonSearchFilters];

    setTableOptions(newSearchOptions);
  };

  return (
    <>
      <h2 id="test-message-data-title" className="pb-2 dark:text-white">
        Test Message Data
      </h2>

      <div className="flex justify-between pb-2">
        <Search
          id="nameSearch"
          name="nameSearch"
          placeholder="Name"
          searchText={searchText}
          setSearchText={setSearchText}
          handleSearch={handleSearch}
          tableOptions={tableOptions}
        />
        <Button
          id="test-number-add"
          className="self-end"
          variant={ButtonVariantEnum.SECONDARY}
          onClick={() => {
            setShowDetailsPanel(true);
          }}
        >
          Add
        </Button>
      </div>

      <Table
        shimmer
        loading={loading}
        columns={columns}
        items={testMessageData}
        count={testMessageData.length}
        tableSearchOptions={tableOptions}
        onSearchOptionChange={handleSearchOptionChange}
        filter
        filterColumns={filterColumns}
      />

      <TestMessageDataDetailsPanel
        errorMessage={createError?.response?.data?.message}
        show={showDetailsPanel}
        loading={createLoading}
        onClosePanel={closeDetailsPanel}
        selectedItem={selectedItem}
        handleSubmit={handleSubmit}
        leftPanelButtonOptions={[
          {
            text: 'Delete',
            visible: !!selectedItem,
            variant: ButtonVariantEnum.DELETE,
            loading: deleteLoading,
            onClick: handleDelete,
          },
        ]}
      />
    </>
  );
};

export default HealthCheckNumbers;
