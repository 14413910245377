import { Formik } from 'formik';
import { Link } from 'react-router-dom';
import * as yup from 'yup';
import { getCsvHeaders } from '../../providers/validation.provider';
import { Button } from '../shared/Buttons/Button';
import { ButtonVariantEnum } from '../shared/Buttons/types';
import LoadingIndicator from '../shared/LoadingIndicator';
import { OldFileUpload } from '../shared/Form/OldFileUpload';

export interface IUsersUploadForm {
  users?: File;
}

const initialFormState: IUsersUploadForm = {
  users: undefined,
};

interface IUploadUserFormProps {
  loading: boolean;
  onSubmit: (formData: IUsersUploadForm) => Promise<void>;
}

const UploadUsersForm = ({ loading, onSubmit }: IUploadUserFormProps) => {
  const validateCsvHeaders = async (file: File) => {
    try {
      const validHeaders = ['email', 'first name', 'last name', 'role'];
      const fileHeaders = (await getCsvHeaders(file)).map((header) => header.toLowerCase());

      return (
        validHeaders.length === fileHeaders.length &&
        fileHeaders.every((header, index) => fileHeaders[index] === validHeaders[index])
      );
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  const schema: yup.SchemaOf<IUsersUploadForm> = yup.object().shape({
    users: yup
      .mixed()
      .required('Required')
      .test('csvValidate', 'Uploaded file must be .csv', (file: File) => file?.name?.endsWith('.csv'))
      .test(
        'csvHeaderValidate',
        'CSV does not contain the proper headers',
        async (file: File) => await validateCsvHeaders(file)
      ),
  });

  return (
    <Formik
      initialValues={initialFormState}
      validationSchema={schema}
      onSubmit={(values) => onSubmit && onSubmit(values)}
    >
      {({ values, errors, touched, handleBlur, handleSubmit, setFieldValue, setFieldTouched }) => (
        <form id="upload-users" onSubmit={handleSubmit}>
          <div className="flex flex-col space-y-2">
            <OldFileUpload
              id="user-upload-file-selector"
              name="users"
              label="Users"
              placeholder="Upload a .csv file"
              value={values.users as any}
              error={touched.users && (errors.users as any)}
              onBlur={handleBlur}
              onChange={(event: any) => {
                const file = event.target.files[0];
                console.log({ event, file });

                setFieldValue('users', file);
                setFieldTouched('users');
              }}
            />

            <div className="mt-2">
              <p className="text-sm text-gray-500">
                Download the template{' '}
                <Link
                  className="font-semibold text-sky-500 hover:underline"
                  to="/sample_upload_users.csv"
                  target="_blank"
                  download
                >
                  here
                </Link>
              </p>
            </div>
          </div>
          <div className="flex justify-center mt-4">
            <Button id="user-upload-form" className="ml-auto" type="submit" variant={ButtonVariantEnum.SECONDARY}>
              {loading ? <LoadingIndicator position="CENTER" /> : 'Upload'}
            </Button>
          </div>
        </form>
      )}
    </Formik>
  );
};

export default UploadUsersForm;
