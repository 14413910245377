import { FocusEventHandler, useEffect, useRef, useState } from 'react';
import { ErrorMessage } from './ErrorMessage';

interface IFileUpload {
  id: string;
  name: string;
  fileName?: string;
  value: File | undefined;
  accepts?: string;
  className?: string;
  label?: string;
  disabled?: boolean;
  placeholder?: string;
  error?: string;
  optional?: boolean;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  onBlur?: FocusEventHandler<HTMLInputElement>;
}

const FileUpload = ({
  id,
  name,
  fileName,
  value,
  accepts,
  className,
  label,
  disabled,
  placeholder,
  error,
  optional,
  onChange,
  onBlur,
}: IFileUpload): JSX.Element => {
  const [previousError, setPreviousError] = useState(error);
  const inputRef = useRef<HTMLInputElement>(null);

  // Used to fix the animation by not removing the text as we're transitioning out
  useEffect(() => {
    if (error) {
      setPreviousError(error);
    }
  }, [error]);

  const inputText = fileName
    ? fileName
    : value
    ? value.name
    : disabled
    ? 'No File Selected'
    : placeholder || 'Select file';

  return (
    <div className={className}>
      {/* Label */}
      <div className="flex justify-between">
        <h5>{label}</h5>
        {optional && (
          <span className="text-sm text-gray-500" id="optional">
            Optional
          </span>
        )}
      </div>

      {/* File Upload */}
      <label htmlFor={id} className={`input mt-5 hover:cursor-pointer`}>
        {inputText}
      </label>
      <ErrorMessage show={!!error} message={previousError} />

      {/* Input */}
      <input
        id={id}
        ref={inputRef}
        type="file"
        accept={accepts ?? '.csv'}
        name={name}
        disabled={disabled}
        className="hidden w-full border-gray-300 rounded-md shadow-sm dark:border-slate-800 focus:ring-sky-500 focus:border-sky-500 sm:text-sm dark:placeholder:text-slate-400"
        placeholder={placeholder || 'Select file'}
        aria-describedby="optional"
        onChange={onChange}
        onBlur={onBlur}
        onClick={() => {
          // This allows for users to re-upload the same file (eg. after modifications) by clearing the value on every file upload
          inputRef.current!.value = '';
        }}
      />
    </div>
  );
};

export { FileUpload };
