import { IContactListEntity, IHttpSearchResponse, ISearchRequest } from '@gr/shared/models';
import useAxios, { UseAxiosResult } from 'axios-hooks';

const useContactLists = (searchOptions: ISearchRequest): UseAxiosResult<IHttpSearchResponse<IContactListEntity>> => {
  return useAxios<IHttpSearchResponse<IContactListEntity>>({
    url: 'entities-search',
    method: 'POST',
    data: searchOptions,
  }, {
    manual: true
  });
};

export default useContactLists;
