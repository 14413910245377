import { Domain } from '@gr/shared/entities';
import { IHttpResponse } from '@gr/shared/models';
import useAxios, { UseAxiosResult } from 'axios-hooks';

export default function useDomains(): UseAxiosResult<IHttpResponse<Domain[]>> {
  return useAxios<IHttpResponse<Domain[]>>({
    url: 'links-get-domains',
    method: 'POST'
  });
}
