import { Auth0Role, ICarrierExclusionView, IClientView, IHttpResponse } from '@gr/shared/models';
import { Transition } from '@headlessui/react';
import { Fragment, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { axiosPost } from '../../authAxios';
import useRoles from '../../hooks/useRoles';
import { ButtonVariantEnum } from '../shared/Buttons/types';
import { DetailsPanel } from '../shared/DetailsPanel/DetailsPanel';
import { DetailsPanelSize } from '../shared/DetailsPanel/types';
import { Table } from '../shared/Table/Table';
import ClientsForm, { IClientForm } from './ClientForm';
import { exclusionBreakdownColumns } from './types';

interface IClientPanelProps {
  show: boolean;
  loading?: boolean;
  selectedItem?: IClientView;
  isReadOnly?: boolean;
  onClosePanel: () => void;
  handleSubmit?: (formData: IClientForm) => Promise<void>;
}

const ClientDetailsPanel = ({
  show,
  loading,
  selectedItem,
  isReadOnly,
  onClosePanel,
  handleSubmit,
}: IClientPanelProps): JSX.Element => {
  const [exclusionBreakdown, setExclusionBreakdown] = useState(false);
  const [carrierExclusions, setCarrierExclusions] = useState<ICarrierExclusionView[]>([]);
  const allowTemplates = useRoles([Auth0Role.GR_ADMIN, Auth0Role.GR_CLIENT_MANAGER]);
  const history = useHistory();
  useEffect(() => {
    if (selectedItem?.hasExclusion) {
      getCarrierExclusions();
      setExclusionBreakdown(false);
    }
  }, [selectedItem]);

  const getCarrierExclusions = async () => {
    try {
      const {
        data: { data },
      } = await axiosPost<IHttpResponse<ICarrierExclusionView[]>>('/carrier-exclusions-get-for-client', {
        clientId: selectedItem?.id,
      });

      const carrierExclusions: ICarrierExclusionView[] = data ?? [];

      setCarrierExclusions(carrierExclusions);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <DetailsPanel
      item={selectedItem}
      title={selectedItem ? 'Save Client' : 'Add Client'}
      formId="client-form"
      size={DetailsPanelSize.Medium}
      show={show}
      closePanel={onClosePanel}
      loading={loading}
      isReadOnly={isReadOnly}
      leftPanelButtonOptions={[
        {
          text: 'Templates',
          onClick: () => history.push(`/app/clients/${selectedItem?.id}/conversations`),
          visible: allowTemplates && !!selectedItem,
          variant: ButtonVariantEnum.SECONDARY,
          hideConfirmation: true,
        },
      ]}
    >
      <div>
        <ClientsForm item={selectedItem} onSubmit={handleSubmit} isReadyOnly={isReadOnly} />
        {selectedItem?.hasExclusion && (
          <>
            {/* TODO: Make this into a custom component */}
            <div className="mt-8 mb-2 overflow-hidden bg-white shadow sm:rounded-lg">
              <div
                className="px-4 py-3 sm:px-6"
                onClick={() => {
                  setExclusionBreakdown(!exclusionBreakdown);
                }}
              >
                <h3 className={`text-lg font-medium leading-6 text-sky-600 hover:underline hover:cursor-pointer`}>
                  Active Carrier Exclusions
                </h3>
                <p className="max-w-2xl mt-1 text-sm text-gray-500">Carriers that will be excluded from sending</p>
              </div>
              <Transition
                show={exclusionBreakdown}
                as={Fragment}
                enter="ease-in-out duration-200"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="border-t border-gray-200">
                  <Table
                    columns={exclusionBreakdownColumns}
                    items={carrierExclusions}
                    shimmer={false}
                    height={'20vh'}
                    skipEnumFormattingColumns={['messageType']}
                    enumExceptions={[{ fieldName: 'campaignType', exceptions: ['GOTV'] }]}
                  />
                </div>
              </Transition>
            </div>
          </>
        )}
      </div>
    </DetailsPanel>
  );
};

export default ClientDetailsPanel;
