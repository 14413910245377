interface ISliderProps {
  label?: string;
  checked: boolean;
  onChange: () => void;
  id: string;
  name: string;
}

export const NewSlider = ({ id, name, label, checked, onChange }: ISliderProps) => {
  return (
    <div className="flex mt-2">
      <div className="flex items-top flex-shrink-0">{label} </div>
      <label className="switch">
        <input id={id} name={name} type="checkbox" checked={checked} onChange={onChange} />
        <div className="slider round"></div>
        <div className="absolute left-15">{checked ? 'Enabled' : 'Disabled'}</div>
      </label>
    </div>
  );
};
