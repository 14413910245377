import {
  Button,
  ButtonVariantEnum,
  LoaderIcon,
  RightArrowIcon,
  StaticTableList
} from '@Wonder-Cave/ui';
import { IDropdownValue } from '@Wonder-Cave/ui/dist/Autocomplete';
import { axiosPost } from '@gr/portal/authAxios';
import { NotificationType, useNotifications } from '@gr/portal/contexts/NotificationContext';
import useRoles from '@gr/portal/hooks/useRoles';
import { Auth0Role, ICampaignDetails, IGetTestMessageResponse, IHttpResponse, ITestMessageRequest } from '@gr/shared/models';
import { groupBy } from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import TestNumbersDropdown from '../../shared/Form/Dropdowns/TestNumbersDropdown';
import { Radio } from '../../shared/Icons/Radio';
import CampaignMessagePreview from '../CampaignMessagePreview';

interface IPreviewProps {
  campaign: Partial<ICampaignDetails>;
  submissionError?: string;
  status?: boolean;
  saveLoading: boolean;
  saveDraft: (formikProps: any, exit: boolean) => Promise<any>;
  sending: boolean;
  setSending: (value: boolean) => void;
  setStatus?: (value: boolean) => void;
  values?: any;
}

const Preview = ({ campaign, submissionError, status, saveLoading, saveDraft, sending, setSending, setStatus, ...rest }: IPreviewProps) => {
  const [messages, setMessages] = useState<Array<any>>([]);
  const [numbers, setNumbers] = useState<number[]>([]);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState<Date>();
  const [messagesScheduled, setMessagesScheduled] = useState(0);
  const isSuperAdmin = useRoles([Auth0Role.GR_ADMIN]);
  const { addNotification } = useNotifications();

  const initTestMessages = async () => {
    // Would rather not hit an if statement for each call, so this is a new function for the first call
    setLoading(true);
    try {
      const messageResponse = await axiosPost<IHttpResponse<IGetTestMessageResponse>>(`/messages-get-test-messages`, {
        campaignId: campaign?.id,
      } as ITestMessageRequest);
      const messages = messageResponse?.data?.data?.testMessages;
      const scheduled = messageResponse?.data?.data?.scheduled;
      if (messages?.length > 0) {
        setNumbers(messages.map((m) => Number(m.toNumber)));
        setMessages(messages);
      }
      setMessagesScheduled(scheduled);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const getTestMessages = async () => {
    try {
      if (!sending) {
        const messageResponse = await axiosPost<IHttpResponse<IGetTestMessageResponse>>(`/messages-get-test-messages`, {
          campaignId: campaign?.id,
        } as ITestMessageRequest);
        // has this changed while waiting
        if (!sending) {
          setMessages(messageResponse.data?.data?.testMessages);
        }
      }
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (campaign?.id && !sending) {
      initTestMessages();

      const interval = setInterval(() => {
        getTestMessages();
      }, 5000);

      return () => clearInterval(interval);
    }
  }, []);

  const sendTestMessages = async () => {
    if (numbers.length > 0) {
      let response;
      setSending(true);
      setMessagesScheduled(0);
      if (!campaign?.id || status) {
        response = await saveDraft(rest, false);
      }
      setDate(new Date()); // Record the date last clicked
      try {
        await axiosPost(`/v1/campaigns/${response?.data?.data?.campaignId ?? campaign?.id ?? rest?.values?.id}/test-messages`, {
          testNumbers: [...new Set(numbers?.map((n) => Number(n)))],
        });
        setMessagesScheduled(numbers.length);
      } catch (e: any) {
        console.error(e);
        addNotification({
          header: 'Error',
          content: e.response?.data?.message ?? 'Unexpected error creating test messages.',
          type: NotificationType.FAILURE,
        });
      } finally {
        setStatus!(false);
        setSending(false);
      }
    } else {
      setError(true);
    }
  };

  const formatPhoneNumber = (phoneNumberString: string) => {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      var intlCode = match[1] ? '+1 ' : '';
      return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
    }
    return phoneNumberString;
  };

  const groupedMessages = groupBy(messages, 'status');

  // const sentCount =
  //   (groupedMessages['SENT']?.length ?? 0) +
  //   (groupedMessages['DELIVERED']?.length ?? 0) +
  //   (groupedMessages['FAILED']?.length ?? 0);

  return (
    <div className="flex">
      <div className="flex basis-1/2">
        <CampaignMessagePreview campaign={campaign} domain={campaign.domain} />
      </div>
      <div className="flex flex-col justify-between basis-1/2">
        <div>
          <h2 className="mt-12">Preview your message</h2>
          <div className="mt-8">
            <TestNumbersDropdown
              value={numbers}
              loading={loading}
              onChange={(value) => {
                setNumbers((value as IDropdownValue[]).map((v) => v?.additionalData?.number));
                setError(false);
              }}
              showError={false}
              client={campaign.clientId ?? (campaign as any).client?.value ?? ''}
            />
            {/* TODO: Add a description to the component */}
            <div className={`${error ? 'text-flamingo' : 'text-medium-gray'} -mt-2 body-text-small`}>Select up to 20 contacts to send a test message.</div>
            <div className="flex items-center mt-8">
              <Button
                trailingIcon={<RightArrowIcon />}
                variant={ButtonVariantEnum.GREEN}
                onClick={sendTestMessages}
                type="button"
                isLoading={sending}
              >
                Send Test Message
              </Button>
              {saveLoading && <div className="flex items-center ml-6">
                <LoaderIcon />
                <p className="mb-px ml-3 body-text-bold text-medium-gray">
                  Saving draft...
                </p>
              </div>}
            </div>
            {!sending && messages?.length > 0 && (
              <StaticTableList
                className="mt-8 basis-full"
                columns={[
                  {
                    headerName: 'NUMBER',
                    fieldName: 'toNumber',
                    renderColumn: (row) => (
                      <div className="flex items-center whitespace-nowrap">
                        {formatPhoneNumber(row.toNumber)}
                        {!row.errorCode && (
                          <div className="mx-2">
                            <Radio />
                          </div>
                        )}
                      </div>
                    ),
                  },
                  {
                    headerName: 'CARRIER',
                    fieldName: 'carrierName',
                  },
                  {
                    headerName: 'ERROR CODE',
                    fieldName: 'errorCode',
                  },
                  {
                    headerName: 'ERROR TYPE',
                    fieldName: 'errorType',
                  },
                  ...(isSuperAdmin
                    ? [
                      {
                        headerName: 'PROVDER ERROR CODE',
                        fieldName: 'providerErrorCode',
                      },
                      {
                        headerName: 'PROVIDER ERROR TYPE',
                        fieldName: 'providerErrorType',
                      },
                    ]
                    : []),
                ]}
                data={messages}
              />
            )}
            <div className="status-box rounded-large border-white border-solid border-[5px] flex flex-col mt-8 mb-4 max-w-lg">
              <h3 className="mx-auto mt-6 text-wc-blue">Test Message Progress</h3>
              <h5 className="mx-auto mt-2 text-dark-gray">STATUS: {messages[0]?.status ?? 'SENT'}</h5>
              <div className="mx-8 mt-6">
                <div className="flex items-center mt-4">
                  <h5 className="min-w-[65px] text-right mt-px mr-2 basis-1/4 text-dark-gray">DELIVERY</h5>
                  <div
                    className={`flex grow h-2 overflow-hidden text-xs rounded justify-end ${messagesScheduled === 0 ? 'bg-light-gray dark:bg-slate-400' : 'bg-mint'
                      }`}
                  >
                    <>
                      <div
                        style={{
                          width: `${Math.min(
                            Math.ceil(((groupedMessages['DELIVERED']?.length ?? 0) / (messagesScheduled ?? 100)) * 100),
                            100
                          )}%`,
                        }}
                        className={`flex flex-col justify-center text-center text-white bg-grass shadow-none bg-grass-400 whitespace-nowrap`}
                      ></div>
                      <div
                        style={{
                          width: `${Math.min(
                            Math.ceil(((groupedMessages['FAILED']?.length ?? 0) / (messagesScheduled ?? 100)) * 100),
                            100
                          )}%`,
                        }}
                        className={`flex flex-col justify-center text-center text-white bg-flamingo shadow-none whitespace-nowrap`}
                      ></div>
                    </>
                  </div>
                </div>
                <h5
                  className="mt-1 text-right truncate text-medium-gray dark:text-slate-400 basis-full"
                  title="Sent / Delivered / Failed"
                >
                  {messagesScheduled.toLocaleString()} / {(groupedMessages['DELIVERED']?.length ?? 0).toLocaleString()} /{' '}
                  {(groupedMessages['FAILED']?.length ?? 0).toLocaleString()}
                </h5>
              </div>
              <div className="mx-auto my-8 body-text-small text-medium-gray">Sent: {moment(date).format('L, LT')}</div>
            </div>
          </div>
        </div>
        {submissionError && <h3 className='mb-4 text-right text-red-600'>{submissionError}</h3>}
      </div>
    </div>
  );
};

export default Preview;
