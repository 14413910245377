import { Button, ButtonVariantEnum, Checkbox, Modal } from '@Wonder-Cave/ui';
import { TagAttributes } from '@gr/shared/entities';
import { ITwoWayConversationEntity } from '@gr/shared/models';
import { useState } from 'react';
import { NewButton } from '../shared/Buttons/NewButton';
import { TagOption } from './types';

interface TagsModalProps {
  show: boolean;
  loading: boolean;
  options: TagAttributes[];
  currentSelectedTags: TagOption[];
  convo: ITwoWayConversationEntity;
  onSave: (selectedTags: TagOption[]) => void;
  onClose: () => void;
}

const TagsModal = ({ show, loading, options, currentSelectedTags, onSave, onClose }: TagsModalProps) => {

  const getInitialTagOptionState = () => {
    const initialSelectedTagNames = currentSelectedTags.map(t => t.name);

    return options.map(tag => ({
      id: tag.id,
      name: tag.name,
      checked: initialSelectedTagNames.includes(tag.name)
    }));
  };

  const [modalTags, setModalTags] = useState<TagOption[]>(getInitialTagOptionState());

  const checkboxes = modalTags.map((option) => (
    <div className='flex-[0_0_4rem]' key={option.name}>
      <Checkbox
        id={option.name}
        name={option.name}
        label={option.name}
        checked={option.checked}
        onChange={() => {
          const tag = modalTags.find(t => t.name === option.name);
          if (tag) {
            tag.checked = !tag.checked;
            setModalTags(modalTags.slice());
          }
        }}
      />
    </div>
  ));

  const closeAndReset = () => {
    if (!loading) {
      onClose();
      setModalTags(getInitialTagOptionState);
    }
  };

  return (
    <Modal
      title={''}
      show={show}
      onClose={closeAndReset}
      titleClassName='pl-8 text-black'
      childrenClassName='pl-8 pb-2 pt-2'
    >
      <div className='flex flex-col flex-wrap h-96'>
        {checkboxes}
      </div>
      <div className="flex justify-end w-full pb-2 mt-auto">
        <Button
          variant={ButtonVariantEnum.SECONDARY}
          className="mr-4"
          disabled={loading}
          type="button"
          onClick={closeAndReset}
        >
          CANCEL
        </Button>
        <NewButton
          text="SAVE"
          isLoading={loading}
          onClick={() => {
            const selected = modalTags.filter(t => t.checked);
            onSave(selected);
          }}
        />
      </div>
    </Modal>
  );
};

export default TagsModal;
