import { ICarrierEntity, IHttpSearchResponse, ISearchRequest } from '@gr/shared/models';
import useAxios, { UseAxiosResult } from 'axios-hooks';

export default function useCarriers(
  searchOptions: ISearchRequest
): UseAxiosResult<IHttpSearchResponse<ICarrierEntity>> {
  return useAxios<IHttpSearchResponse<ICarrierEntity>>({
    url: 'entities-search',
    method: 'POST',
    data: searchOptions,
  });
}
