import { IDropdownValue } from '../Dropdown';
import Autocomplete from './Autocomplete';

interface IRegionDropdownProps {
  value: IDropdownValue | undefined;
  onChange: (newValue: IDropdownValue | undefined) => void;
  options: IDropdownValue[];
  refreshOptions: (searchvalue: string) => Promise<void>;
  onBlur?: () => void;
  disabled?: boolean;
  errorMessage?: string | undefined;
  showError?: boolean;
}

const RegionDropdown = ({
  value,
  options,
  onChange,
  onBlur,
  refreshOptions,
  disabled,
  errorMessage,
  showError,
}: IRegionDropdownProps): JSX.Element => {
  return (
    <Autocomplete
      label="City"
      value={value}
      options={options}
      refreshOptions={refreshOptions}
      onChange={onChange}
      onBlur={onBlur}
      showError={showError}
      error={errorMessage}
      disabled={disabled}
    />
  );
};

export default RegionDropdown;
