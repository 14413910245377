import { ITwoWayConversationEntity } from '@gr/shared/models';
import moment from 'moment';
import { toPhoneNumberFormat } from '../../providers/utility.provider';
import { NewButton } from '../shared/Buttons/NewButton';

interface IMessagesProps {
  activeMessage: ITwoWayConversationEntity | undefined;
  messages: ITwoWayConversationEntity[];
  setActiveMessage: (message) => void;
  setActiveResponse: (response) => void;
  setSentConvos: (convos) => void;
  assignConversations: (limit: number) => void;
  loading: boolean;
  searchText?: string;
  setActiveMessageLoading: (loading) => void;
  replyLoading: boolean;
}

export const Messages = ({
  setActiveMessage,
  setActiveResponse,
  setSentConvos,
  messages,
  activeMessage,
  assignConversations,
  loading,
  searchText,
  setActiveMessageLoading,
  replyLoading,
}: IMessagesProps) => {
  return messages.length > 0 ? (
    <div className="max-h-full overflow-auto">
      {messages
        .filter((m) => m.clientNumber.toString().includes(searchText ?? '') && !m.optoutId)
        .map((message) => (
          <div key={message.id}>
            <div
              className={`${activeMessage?.id === message?.id && 'con-message-active'} con-message`}
              style={replyLoading ? { cursor: 'default' } : {}}
              onClick={() => {
                if (!replyLoading) {
                  setActiveMessageLoading(true);
                  if (activeMessage?.id !== message.id) {
                    setActiveResponse(undefined);
                  }
                  setActiveMessage(message);
                  // Remove sent convos so they don't show in the new active message
                  setSentConvos([]);
                }
              }}
            >
              <div className="flex flex-col text-ellipsis">
                <div className="whitespace-nowrap body-text-bold">{message.clientName}</div>
                <div className="whitespace-nowrap body-text-small text-medium-gray">
                  {toPhoneNumberFormat(message.clientNumber.toString())}
                </div>
                <div className="mt-2 line-clamp-2 body-text-small text-dark-gray">{message.latestReply}</div>
              </div>
              <div className="whitespace-nowrap text-medium-gray">{moment(message.latestReplyAt).format('LT')}</div>
            </div>
            <hr />
          </div>
        ))}
    </div>
  ) : (
    <div className="flex flex-col justify-center h-full">
      <div className="px-12 text-center body-text text-dark-gray">Looks like your inbox is empty.</div>
      <div className="px-12 text-center body-text text-dark-gray">
        Click on the button below to assign conversations (20 conversations at a time).
      </div>
      <NewButton
        disabled={loading}
        isLoading={loading}
        onClick={() => assignConversations(20)}
        className="mx-auto mt-10 grow-0"
      >
        LOAD CONVERSATIONS
      </NewButton>
    </div>
  );
};
