import { useAuth0, User } from '@auth0/auth0-react';
import { NotificationType, useNotifications } from '@gr/portal/contexts/NotificationContext';
import { SidebarContext } from '@gr/portal/contexts/SidebarContext';
import useAssignedClients from '@gr/portal/hooks/useAssignedClients';
import useClickerGroups from '@gr/portal/hooks/useClickerGroups';
import useRoles from '@gr/portal/hooks/useRoles';
import { createUser, getUserDetails, updateUser } from '@gr/portal/providers/auth0.service';
import { convertEnumToReadableString, orderStringAscending } from '@gr/portal/providers/utility.provider';
import { Auth0GetUserDetailsRequest, Auth0OrganizationName, Auth0Role, Auth0UserDetails } from '@gr/shared/models';
import { getOrganizationLabelByOrg, isAdmin, isClicker, isSuperAdmin } from '@gr/shared/utils';
import { Button, ButtonVariantEnum, TextInput } from '@Wonder-Cave/ui';
import { Field, Formik } from 'formik';
import { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { IDropdownValue } from '../shared/Form/Dropdown';
import NewAssignedAgentsDropdown from '../shared/Form/Dropdowns/NewAssignedAgentsDropdown';
import NewClientDropdown from '../shared/Form/Dropdowns/NewClientDropdown';
import OrganizationDropdown from '../shared/Form/Dropdowns/OrganizationDropdown';
import RoleDropdown from '../shared/Form/Dropdowns/RoleDropdown';
import { IUserForm, userFormSchema } from './types';

function getOrganization(user?: Auth0UserDetails, auth0User?: User) {
  const organization: Auth0OrganizationName = auth0User?.organization_name;
  return user ? getUserOrganization(user, organization) : organization;
}

function getUserOrganization(user: Auth0UserDetails, organization: Auth0OrganizationName) {
  const memberships = user?.app_metadata?.memberships ?? [];

  const match = memberships.find((m) => m?.organization === organization)?.organization;
  const first = memberships?.[0]?.organization;

  return match ?? first;
}

const isAdminRole = (values: IUserForm) => {
  return isAdmin(values?.role?.value as Auth0Role);
};

const UpsertUser = () => {
  const { id } = useParams<any>();
  const { user: auth0User } = useAuth0();
  const history = useHistory();
  const [user, setUser] = useState<Auth0UserDetails>();
  const [userCreating, setUserCreating] = useState(false);
  const [userLoading, setUserLoading] = useState(false);
  const { addNotification } = useNotifications();
  const { setActiveUser } = useContext(SidebarContext);
  const isUserSuperAdmin = useRoles([Auth0Role.GR_ADMIN]);

  const organization = getOrganization(user, auth0User);
  const organizationLabel = getOrganizationLabelByOrg(organization);
  const role = user?.app_metadata?.memberships?.[0]?.roles?.[0];

  const [{ data: clickerGroups, loading: clickerDataLoading, error: clickerDataError }] = useClickerGroups();
  const [{ data: assignedClientData, loading: clientsLoading, error }, refetchAssignedClients] = useAssignedClients({
    userId: id,
  });
  const clickerGroupsDropdown: IDropdownValue[] =
    clickerGroups?.data
      .map((group) => ({
        label: group.name,
        value: group.id,
      }))

      .sort(orderStringAscending('label')) ?? [];


  const getUser = async (id: string) => {
    setUserLoading(true);
    try {
      const request: Auth0GetUserDetailsRequest = { user_id: id ?? '' };
      const user = await getUserDetails(request);
      await refetchAssignedClients({
        params: {
          userId: id,
        },
      });
      setUser(user);
    } catch (e) {
      console.error(e);
      addNotification({ content: 'Failed to retrieve user', type: NotificationType.FAILURE });
    } finally {
      setUserLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      getUser(id);
    }
  }, [id]);


  const handleError = (axiosError: any) => {
    const error = axiosError.response?.data;
    if (!error?.message || 500 <= error?.statusCode) {
      addNotification({ content: 'Internal Server Error', type: NotificationType.FAILURE });
    } else if (error?.message) {
      const message = error.message.includes('409') ? 'Email must be unique.' : error.message;
      addNotification({ content: message, type: NotificationType.FAILURE });
    }
  };

  const upsertUser = async (form: IUserForm, user?: Auth0UserDetails) => {
    const successMessage: string = user ? 'User updated successfully' : 'User created successfully';
    const failMessage: string = user
      ? 'An error occurred while updating a user'
      : 'An error occurred while creating a user';

    try {
      setUserCreating(true);
      user ? await updateUser(user, form) : await createUser(form);
      addNotification({ header: successMessage });

      if (user?.user_id === auth0User?.user_id) {
        setActiveUser({ firstName: form.firstName, lastName: form.lastName });
      }
      history.push('/app/users');
    } catch (error) {
      handleError(error);
      console.error(failMessage);
    } finally {
      setUserCreating(false);
    }
  };

  const initialFormState: IUserForm = {
    email: user?.email ?? '',
    firstName: user?.given_name ?? '',
    lastName: user?.family_name ?? '',
    organization: {
      label: organizationLabel!,
      value: organization,
    },
    role: {
      label: convertEnumToReadableString(role ?? ''),
      value: role,
    },
    clickerGroupId: user?.clickerGroupId ?? '',
    clients: assignedClientData?.data?.map((c) => ({ label: c.name, value: c.id } as IDropdownValue)) ?? [],
  };
  return <div className='flex flex-col h-full pt-4 px-28'>
    <h1>{id ? 'Edit User' : 'Invite User'}</h1>
    <div className='pt-16'>
      <Formik
        initialValues={initialFormState}
        enableReinitialize
        onSubmit={(values) => {
          upsertUser(values, user);
        }}
        validationSchema={userFormSchema}
      >
        {({ values, touched, errors, handleChange, handleBlur, handleSubmit, setFieldValue, setFieldTouched }) => (
          <form id="user-form" onSubmit={handleSubmit}>
            <div className="flex flex-col space-y-4 dark:divide-slate-800">
              <div className="flex space-x-4">
                <Field
                  component={TextInput}
                  id="firstName"
                  name="firstName"
                  label="First Name"
                  loading={userLoading}
                  value={values.firstName}
                  error={touched.firstName ? errors.firstName : ''}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <Field
                  component={TextInput}
                  id="lastName"
                  name="lastName"
                  label="Last Name"
                  loading={userLoading}
                  value={values.lastName}
                  error={touched.lastName ? errors.lastName : ''}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
              <div className="flex space-x-4">
                <div className='basis-1/2'>
                  <Field
                    component={TextInput}
                    id="email"
                    name="email"
                    label="Email"
                    loading={userLoading}
                    value={values.email}
                    error={touched.email ? errors.email : ''}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
                <div className='basis-1/2'>
                  <OrganizationDropdown
                    value={values.organization}
                    onChange={(newValue) => {
                      setFieldValue('organization', newValue);
                      setFieldTouched('organization');
                    }}
                    onBlur={() => {
                      setFieldTouched('organization');
                    }}
                    showError={!!(touched.organization && errors.organization)}
                    errorMessage={(errors?.organization as any)?.value as string}
                    disabled
                    shimmer={userLoading}
                  />
                </div>
              </div>
              <div className='flex space-x-4'>
                <div className='basis-1/2'>
                  <RoleDropdown
                    value={values.role}
                    onChange={(newValue) => {
                      setFieldValue('role', newValue);
                      setFieldTouched('role');
                      if (![Auth0Role.GR_CLICKER].includes(newValue?.value)) {
                        setFieldValue('clickerGroupId', null);
                      } else if (!values.clickerGroupId) {
                        setFieldValue(
                          'clickerGroupId',
                          clickerGroupsDropdown.find((cg) => cg.label === 'WC Managed Group')?.value ?? ''
                        );
                      }
                    }}
                    onBlur={() => {
                      setFieldTouched('role');
                    }}
                    showError={!!(touched.role && errors.role)}
                    errorMessage={(errors?.role as any)?.value as string}
                    shimmer={userLoading}
                    hiddenRoles={!isSuperAdmin(role ?? Auth0Role.GR_CLICKER) ? [Auth0Role.GR_ADMIN] : []}
                  />
                </div>
                <div className='basis-1/2'>
                  <NewAssignedAgentsDropdown
                    label={'Agent Group'}
                    value={values.clickerGroupId ?? undefined}
                    onChange={(newValue) => {
                      setFieldValue('clickerGroupId', newValue?.value);
                      setFieldTouched('clickerGroupId');
                    }}
                    onBlur={() => {
                      setFieldTouched('clickerGroupId');
                    }}
                    options={isUserSuperAdmin ? clickerGroupsDropdown : clickerGroupsDropdown.filter(cg => cg.label !== 'WC Managed Group')}
                    showError={!!(touched.clickerGroupId && errors.clickerGroupId)}
                    errorMessage={(errors?.clickerGroupId as any)?.value as string}
                    disabled={![Auth0Role.GR_CLICKER].includes(values.role?.value)}
                    shimmer={userLoading}
                  />
                </div>
              </div>
              <div className='w-1/2 pr-2'>
                {!isAdminRole(values) && !isClicker(values?.role?.value as Auth0Role) && (
                  <NewClientDropdown
                    label="Clients"
                    value={isAdminRole(values) ? [] : values?.clients ?? []}
                    onChange={(newValue) => {
                      setFieldValue('clients', newValue);
                    }}
                    onBlur={() => {
                      setFieldTouched('clients');
                    }}
                    placeholder={values?.clients?.length > 0 ? '' : 'Search for a Client'}
                    multiple
                    showError={!!(touched.clients && errors.clients && !isAdminRole(values))}
                    errorMessage={errors.clients as string}
                    disabled={isAdminRole(values)}
                    allClients={false}
                    shimmer={userLoading}
                  />
                )}
              </div>
            </div>
          </form>
        )}
      </Formik>
    </div>
    <div className="flex justify-end pb-16 mt-auto">
      <Button
        variant={ButtonVariantEnum.SECONDARY}
        className="mr-4"
        type="button"
        onClick={() => history.goBack()}
      >
        BACK
      </Button>
      <Button formId='user-form' type="submit" isLoading={userCreating} disabled={userCreating}>
        {id ? 'UPDATE USER' : 'INVITE USER'}
      </Button>
    </div>
  </div>;
};

export default UpsertUser;