import { useAuth0 } from '@auth0/auth0-react';
import { SQSQueueNameEnum } from '@gr/shared/enums';
import {
  Auth0Role,
  FilterDataTypeEnum,
  FilterOperatorEnum,
  IExportRequest,
  IHttpResponse,
  IOptoutView,
  ISearchFilter,
  ISearchRequest,
  ISQSSendRequest,
} from '@gr/shared/models';
import { SearchIcon, UploadIcon } from '@heroicons/react/outline';
import { useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { axiosPost } from '../../authAxios';
import { useNotifications } from '../../contexts/NotificationContext';
import useOptOuts from '../../hooks/useOptOuts';
import { Button } from '../shared/Buttons/Button';
import { ButtonVariantEnum } from '../shared/Buttons/types';
import { TextInput } from '../shared/Form/TextInput';
import Roles from '../shared/Roles';
import { Table } from '../shared/Table/Table';
import { defaultOptOutTableOptions, getColumns, optoutFilterDropdownOptions } from './types';
import UploadOptinModal from './UploadOptinModal';
import UploadOptOutModal from './UploadOptOutModal';

const OptOuts = () => {
  const [searchText, setSearchText] = useState('');
  const [showUpload, setShowUpload] = useState(false);
  const [showOptin, setShowOptin] = useState(false);
  const { addNotification } = useNotifications();
  const { user } = useAuth0();

  const [tableOptions, setTableOptions] = useState(defaultOptOutTableOptions);

  const handleSearchOptionChange = (searchOptions: ISearchRequest) => {
    setTableOptions(searchOptions);
  };

  const [{ data: optOutData, loading, error }, refetch] = useOptOuts(tableOptions);

  const optOuts: IOptoutView[] = optOutData?.data.records ?? [];

  const totalCount = optOutData?.data.totalCount;

  const columns = getColumns({
    phoneNumber: (item: IOptoutView) => {
      const numberAsString = item?.phoneNumber?.toString() ?? '';
      return (
        <span className="font-medium text-gray-900 dark:text-white">{`(${numberAsString.substring(
          0,
          3
        )}) ${numberAsString.substring(3, 6)}-${numberAsString.substring(6)}`}</span>
      );
    },
    client: (item: IOptoutView) => item.clientName,
  });

  const handleRefetch = async () => {
    try {
      await refetch();
    } catch (err) { }
  };

  useEffect(() => {
    const searchText = tableOptions.filters.find((filter) => filter.fieldName === 'phoneNumber')?.value;

    handleRefetch();

    setSearchText(searchText ?? '');
  }, [tableOptions]);

  const handleSearch = () => {
    const sanitizedSearchText = searchText.replace(/[()-\s]/g, '');

    let searchFilter: ISearchFilter = {
      dataType: FilterDataTypeEnum.NUMBER,
      fieldName: 'phoneNumber',
      operator: FilterOperatorEnum.CONTAINS,
      value: sanitizedSearchText,
    };

    const newSearchOptions = { ...tableOptions };

    newSearchOptions.filters = newSearchOptions.filters.filter((filter) => filter.fieldName !== 'phoneNumber');

    newSearchOptions.pagination = defaultOptOutTableOptions.pagination;

    newSearchOptions.filters.push(searchFilter);

    setTableOptions(newSearchOptions);
  };

  const handleClear = () => {
    const newSearchOptions = { ...tableOptions };

    newSearchOptions.filters = newSearchOptions.filters.filter((filter) => filter.fieldName !== 'phoneNumber');

    setTableOptions(newSearchOptions);
  };

  const handleExport = async (tableOptions: ISearchRequest) => {
    try {
      if (!user?.email) {
        throw new Error('User email needs to be set');
      }

      const exportRequest: IExportRequest = {
        userEmail: user.email,
        tenantId: '', // This is OK, will be over-ridden in lambda
        fileName: `Optouts_Export_${new Date().toISOString()}.csv`,
        ...tableOptions,
        pagination: { skip: 0, take: 100000000 },
        columns: columns.map((col) => ({ displayName: col.headerName, columnName: col.fieldName })),
      };

      const triggerPayload: ISQSSendRequest<IExportRequest> = {
        messageId: uuid(),
        queueName: SQSQueueNameEnum.EXPORTS,
        payload: exportRequest,
      };

      await axiosPost<IHttpResponse<string>>('/utility-send-to-sqs', triggerPayload);

      addNotification({
        header: 'Grid exported successfully!',
        content: 'You should receive an email in a few minutes with the requested data.',
      });
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      <h2 id="optout-title" className="pb-2 dark:text-white">
        Opt-outs
      </h2>
      <div className="flex pb-2">
        <div className="flex">
          <TextInput
            id="phoneNumberLookup"
            name="phoneNumberLookup"
            placeholder="Phone Number"
            value={searchText}
            onChange={(e) => {
              setSearchText(e.target.value);
            }}
            onClear={handleClear}
            onEnter={handleSearch}
            leadingIcon={<SearchIcon />}
            clearable
          />
          <Button
            id="optout-search"
            variant={ButtonVariantEnum.SECONDARY}
            className="self-end ml-2"
            onClick={() => {
              handleSearch();
            }}
          >
            Search
          </Button>
        </div>

        <div className="flex ml-auto">
          <Roles roles={[Auth0Role.GR_ADMIN]}>
            <Button
              id="optin-upload"
              variant={ButtonVariantEnum.SECONDARY}
              className="mr-4"
              onClick={() => setShowOptin(true)}
            >
              Opt-In
            </Button>
          </Roles>
          <Button
            id="optout-upload"
            leadingIcon={<UploadIcon className="w-5 h-5" />}
            variant={ButtonVariantEnum.SECONDARY}
            className="self-end"
            onClick={() => {
              setShowUpload(true);
            }}
          >
            Upload
          </Button>
        </div>
      </div>

      <Table
        shimmer
        loading={loading}
        columns={columns}
        items={optOuts}
        count={totalCount}
        tableSearchOptions={tableOptions}
        onSearchOptionChange={handleSearchOptionChange}
        paginate
        filter
        onExport={handleExport}
        filterDropdownOptions={optoutFilterDropdownOptions}
      />

      <UploadOptOutModal show={showUpload} setShow={setShowUpload} />
      <UploadOptinModal show={showOptin} setShow={setShowOptin} />
    </>
  );
};

export default OptOuts;
