import { ISendingWindow } from '@gr/shared/models';
import React, { Dispatch, SetStateAction, useContext } from 'react';

export interface ICacheContext {
  sendingWindow: ISendingWindow;
  setSendingWindow: Dispatch<SetStateAction<ISendingWindow>>;
}

export const fallbackSendingWindow: ISendingWindow = { startHour: 8, endHour: 23 };

const CacheContext = React.createContext<ICacheContext>({
  sendingWindow: fallbackSendingWindow,
  setSendingWindow: () => { },
});

const CacheContextProvider = CacheContext.Provider;
const CacheContextConsumer = CacheContext.Consumer;
const useCache = () => useContext(CacheContext);

export { CacheContext, CacheContextConsumer, CacheContextProvider, useCache };

