import {
  ChangeEventHandler,
  FocusEventHandler,
  KeyboardEventHandler,
  MouseEventHandler,
  useEffect,
  useState,
} from 'react';
import { ErrorMessage } from './ErrorMessage';

interface INewTextInput {
  id?: string;
  name: string;
  value: any;
  className?: string;
  label?: string;
  type?: string;
  placeholder?: string;
  error?: string;
  optional?: boolean;
  disabled?: boolean;
  clearable?: boolean;
  leadingIcon?: JSX.Element;
  trailingIcon?: JSX.Element;
  style?: any;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  onClear?: MouseEventHandler<HTMLButtonElement>;
  onEnter?: KeyboardEventHandler<HTMLInputElement>;
  trailingIconClassName?: string;
}

const NewTextInput = ({
  id,
  name,
  value,
  label,
  type,
  placeholder,
  error,
  disabled,
  onChange,
  onBlur,
  onEnter,
  trailingIcon,
  trailingIconClassName,
  className,
  style,
}: INewTextInput): JSX.Element => {
  const [previousError, setPreviousError] = useState(error);
  const [hasFocus, setHasFocus] = useState(false);

  // Used to fix the animation by not removing the text as we're transitioning out
  useEffect(() => {
    if (error) {
      setPreviousError(error);
    }
  }, [error]);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      onEnter && onEnter(event);
    }
  };

  return (
    <>
      <div className={'relative w-full items-center'}>
        {/* Label */}
        {label && (
          <div className="flex justify-between mb-5 text-medium-gray">
            <h5>{label.toUpperCase()}</h5>
          </div>
        )}
        {/* Input */}
        <input
          id={id}
          type={type || 'text'}
          name={name}
          className={`${className} input h-12`}
          style={style}
          disabled={disabled ?? false}
          placeholder={placeholder || ''}
          aria-describedby="optional"
          onChange={(ev) => {
            if (type === 'number') {
              // Replace 'e' and leading zeros
              const value = ev.target.value;
              const input = parseInt(value.replace(/e/g, '') || '0', 10);
              ev.target.value = input.toString();
            }
            onChange && onChange(ev);
          }}
          onBlur={(e) => {
            setHasFocus(false);
            onBlur && onBlur(e);
          }}
          onFocus={() => {
            setHasFocus(true);
          }}
          onKeyDown={handleKeyDown}
          value={value ?? ''} // Allow null values to be passed in and simply convert the input value to empty string for display purposes
          autoComplete="off"
        />
        {/* Trailing Icon */}
        <div className={`absolute top-1/5 right-0 p-2 mr-2 ${trailingIconClassName}`}>
          <div>{trailingIcon}</div>
        </div>
      </div>
      <ErrorMessage show={!!error} message={previousError} />
    </>
  );
};

export default NewTextInput;
