import { Button, ButtonVariantEnum, LoadingIndicator, TextInput } from '@Wonder-Cave/ui';
import { axiosDelete, axiosGet, axiosPost } from '@gr/portal/authAxios';
import { NotificationType, useNotifications } from '@gr/portal/contexts/NotificationContext';
import { CreateTagRequest, TagEntity } from '@gr/shared/models';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Close as CloseIcon } from '../shared/Icons/Close';

export const ConversationTags = () => {
  const { clientId }: any = useParams();
  const { addNotification } = useNotifications();
  const [tags, setTags] = useState<TagEntity[]>([]);
  const [tagString, setTagString] = useState<string>('');
  const [tagError, setTagError] = useState<string>();
  const [createLoading, setCreateLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState<Array<string>>([]);
  const [loading, setLoading] = useState(false);
  const getTags = async () => {
    setLoading(true);
    try {
      const response = await axiosGet(`/tags?clientId=${clientId}`);
      if (response.status === 200) {
        setTags(response.data);
      } else {
        addNotification({ content: 'Failed to retrieve tags for client', type: NotificationType.FAILURE });
      }
    } catch (err) {
      console.error(err);
      addNotification({ content: 'Failed to retrieve tags for client', type: NotificationType.FAILURE });
    } finally {
      setLoading(false);
    }
  };

  const createTags = async () => {
    setCreateLoading(true);
    try {
      const responses = await Promise.all(tagString.split(',').map(tag => {
        const name = tag.trim();
        return axiosPost('/tags', {
          clientId,
          name
        } as CreateTagRequest);
      }));
      if (responses.filter(r => r.status !== 200).length <= 0) {
        setTags(prevState => [...prevState, ...responses.map(r => r.data)]);
        setTagString('');
        addNotification({ content: 'Tag(s) successfully created' });
      } else {
        addNotification({ content: 'Failed to create tag(s)', type: NotificationType.FAILURE });
      }
    } catch (err) {
      console.log(err);
      addNotification({ content: 'Failed to create tag(s)', type: NotificationType.FAILURE });
    } finally {
      setCreateLoading(false);
    }
  };

  const deleteTag = async (id: string) => {
    setDeleteLoading(prevState => [...prevState, id]);
    try {
      const response = await axiosDelete(`/tags/${id}`);
      if (response.status === 200) {
        addNotification({ content: 'Tag successfully deleted' });
        setTags(tags.filter(t => t.id !== id));
        getTags();
      } else {
        addNotification({ content: 'Failed to delete tag', type: NotificationType.FAILURE });
      }
    } catch (err) {
      addNotification({ content: 'Failed to delete tag', type: NotificationType.FAILURE });
      console.error(err);
    } finally {
      setDeleteLoading(prevState => prevState.filter(p => p !== id));
    }
  };

  useEffect(() => {
    getTags();
  }, [clientId]);

  return <div className='mb-8'>
    <h1>Conversation Tags</h1>
    <div className="flex items-center mt-6">
      <div className="mr-4 basis-1/2">
        <TextInput
          name="tags"
          label="TAGS"
          placeholder="e.g. Not interested, potential donor, etc"
          value={tagString}
          onChange={(e) => {
            const splitTags = e.target.value.split(',').map(t => t.trim().toLowerCase());
            if (tags.length + splitTags.length > 12 && e.target.value !== '') {
              setTagError('You cannot have more than 12 tags');
            } else if (new Set(splitTags).size !== splitTags.length || splitTags.filter(st => new Set(tags.map(t => t.name.toLowerCase())).has(st)).length > 0) {
              setTagError('Tags must be unique');
            } else if (tagError) {
              setTagError(undefined);
            }
            setTagString(e.target.value);
          }}
        />
      </div>
      <Button
        disabled={tags.length >= 12 || !!tagError}
        isLoading={createLoading || loading}
        onClick={createTags}
        variant={ButtonVariantEnum.TERTIARY}
      >
        SUBMIT
      </Button>
    </div>
    <div className="-mt-4 body-text-small text-medium-gray">You can add up to 12 tags, separate tags with commas.</div>
    <div className='h-4 mt-2 mb-4 body-text-small text-flamingo'>{tagError}</div>
    <div className="flex flex-wrap">
      {tags.map((tag) => {
        return (
          <div key={tag.id} className="inline-flex items-center px-2.5 py-3 gap-2.5 bg-mint mb-4 mr-4">
            <h5 className="mr-2 text-black">{tag.name}</h5>
            <div onClick={() => deleteTag(tag.id)} className="cursor-pointer">
              {deleteLoading.includes(tag.id) ? <LoadingIndicator /> : <CloseIcon />}
            </div>
          </div>
        );
      })}
    </div>
  </div>;
};