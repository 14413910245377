import {
  IHttpResponse,
  ILocationEntity,
  ISystemNumbersBuyLocalRequest,
  ISystemNumbersSearchLocalRequest,
  ITCRCampaignDetails,
} from '@gr/shared/models';
import { Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import useAxios from 'axios-hooks';
import { Fragment, useEffect, useState } from 'react';
import { useNotifications } from '../../contexts/NotificationContext';
import { toPhoneNumberFormat } from '../../providers/utility.provider';
import { Button } from '../shared/Buttons/Button';
import { ButtonVariantEnum } from '../shared/Buttons/types';
import ErrorBanner from '../shared/ErrorBanner/ErrorBanner';
import { IDropdownValue } from '../shared/Form/Dropdown';
import RegionDropdown from '../shared/Form/Dropdowns/RegionDropdown';
import { TextInput } from '../shared/Form/TextInput';
import LoadingIndicator from '../shared/LoadingIndicator';

interface IPurchaseNumbersCardProps {
  selectedItem: ITCRCampaignDetails;
  closePanel: () => void;
}

const PurchaseNumbersCard = ({ selectedItem, closePanel }: IPurchaseNumbersCardProps) => {
  const [collapseNumberPurchasing, setCollapseNumberPurchasing] = useState(false);
  const [showNumbers, setShowNumbers] = useState(false);
  const [numbersCount, setNumbersCount] = useState(0);
  const [phoneNumbers, setPhoneNumbers] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);

  const [region, setRegion] = useState<IDropdownValue>();
  const [searchString, setSearchString] = useState<string>('');
  const maxQuantity = Math.max(49 - selectedItem.activeNumbers!, 0);
  const [quantity, setQuantity] = useState(maxQuantity);

  const [errorMessage, setErrorMessage] = useState<string>();

  const { addNotification } = useNotifications();

  const [{ data: searchNumbersData, loading: searchNumbersLoading, error: searchNumbersError }, searchPhoneNumbers] =
    useAxios<IHttpResponse<string[]>>(
      {
        url: 'system-numbers-search-10dlc-numbers',
        method: 'POST',
      },
      { manual: true }
    );

  const [
    { data: purchaseNumbersData, loading: purchaseNumbersLoading, error: purchaseNumbersError },
    purchasePhoneNumbers,
  ] = useAxios<IHttpResponse<string[]>>(
    {
      url: 'system-numbers-buy-10dlc-numbers',
      method: 'POST',
      data: {
        tcrCampaignId: selectedItem.id,
        phoneNumbers: phoneNumbers,
        messagingProfileId: selectedItem.providerProfileId,
        providerId: selectedItem.providerId,
      } as ISystemNumbersBuyLocalRequest,
    },
    { manual: true }
  );

  const [{ data: regionData, loading: regionLoading, error: regionError }, searchRegions] = useAxios<
    IHttpResponse<ILocationEntity[]>
  >(
    {
      url: 'utility-search-locations',
      method: 'POST',
    },
    { manual: true }
  );
  const options: ILocationEntity[] = regionData?.data ?? [];

  const dropdownValues: IDropdownValue[] = options.map((region) => ({
    value: region.id ?? '',
    label: `${region.city} - ${region.stateCode}`,
    additionalData: { stateCode: region.stateCode, city: region.city },
  }));

  const isSinchProvider: boolean = selectedItem.providerName === 'Sinch 10DLC';

  useEffect(() => {
    const errorMessage =
      regionError?.response?.data?.message ??
      searchNumbersError?.response?.data?.message ??
      purchaseNumbersError?.response?.data?.message;

    setErrorMessage(errorMessage);
  }, [regionError, searchNumbersError, purchaseNumbersError]);

  useEffect(() => {
    if (searchString.length >= 3 && !isSinchProvider) {
      handleRegionSearch();
    }
  }, [searchString]);

  useEffect(() => {
    setNumbersCount(phoneNumbers.length);
  }, [phoneNumbers.length]);

  const handleRegionSearch = async () => {
    try {
      await searchRegions();
    } catch (err: any) {
      if (!err?.message) {
        // Cancellation, ignore
      } else {
        console.error(err);
      }
    }
  };

  const searchNumbers = async () => {
    try {
      setErrorMessage('');

      const request: ISystemNumbersSearchLocalRequest = isSinchProvider
        ? {
          areaCode: parseInt(searchString, 10),
          limit: quantity,
          providerId: selectedItem.providerId!,
        }
        : {
          city: region?.additionalData.city,
          state: region?.additionalData.stateCode,
          limit: quantity,
          providerId: selectedItem.providerId!,
        };

      const {
        data: { data: newPhoneNumbers },
      } = await searchPhoneNumbers({ data: request });

      setPhoneNumbers(newPhoneNumbers ?? []);

      setNumbersCount(newPhoneNumbers.length ?? 0);
    } catch (err) {
      console.error(err);
    }
  };

  const removePhoneNumber = (number: string) => {
    const newNumbers = phoneNumbers.filter((num) => num !== number);
    setPhoneNumbers(newNumbers);
  };

  const purchaseNumbers = async () => {
    setLoading(true);
    try {
      const response = await purchasePhoneNumbers();

      closePanel();

      addNotification({
        header: 'Number Order created successfully!',
        content: 'You will receive an email once the order has been fulfilled.',
      });
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="mt-4 mb-1 bg-white shadow sm:rounded-lg">
      <div
        className="px-4 py-3 sm:px-6"
        onClick={() => {
          setCollapseNumberPurchasing(!collapseNumberPurchasing);
        }}
      >
        <h3 className={`text-lg font-medium leading-6 text-sky-600 hover:underline hover:cursor-pointer`}>
          Purchase Numbers
        </h3>
        <p className="max-w-2xl mt-1 text-sm text-gray-500">{selectedItem.activeNumbers} active numbers</p>
      </div>
      <Transition
        show={collapseNumberPurchasing}
        as={Fragment}
        enter="ease-in-out duration-200"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in-out duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div>
          {errorMessage && (
            <ErrorBanner
              message={errorMessage}
              onDismissClick={() => {
                setErrorMessage('');
              }}
            />
          )}

          <div className="px-4 py-4 border-t border-gray-200">
            <div className="space-y-4 ">
              {!isSinchProvider && (
                <RegionDropdown
                  options={dropdownValues}
                  value={region}
                  disabled={!selectedItem?.isActive}
                  onChange={(newValue) => {
                    setRegion(newValue);
                  }}
                  refreshOptions={async (search) => {
                    await searchRegions({ data: { searchString: search } });
                  }}
                />
              )}

              {isSinchProvider && (
                <TextInput
                  id="areaCode"
                  name="areaCode"
                  label="Area Code"
                  type="number"
                  disabled={!selectedItem?.isActive}
                  value={searchString}
                  onChange={(val) => {
                    const value = val.target.value;
                    value.length > 3
                      ? setSearchString(value.substring(0, 3))
                      : value === '0'
                        ? setSearchString('')
                        : setSearchString(value);
                  }}
                />
              )}

              <TextInput
                id="quantity"
                name="quantity"
                label="Quantity"
                type="number"
                disabled={!selectedItem?.isActive}
                value={quantity}
                onChange={(val) => {
                  const quantity: number = Math.min(parseInt(val.target.value), maxQuantity);
                  setQuantity(quantity);
                }}
              />

              <div className="flex justify-between">
                <Button
                  onClick={searchNumbers}
                  disabled={
                    !selectedItem ||
                    !selectedItem?.isActive ||
                    !quantity ||
                    (!region && !isSinchProvider) ||
                    (isSinchProvider && searchString.length !== 3) ||
                    searchNumbersLoading
                  }
                >
                  {searchNumbersLoading ? <LoadingIndicator position="CENTER" /> : 'Search'}
                </Button>

                {numbersCount === 0 && !searchNumbersLoading && searchNumbersData && (
                  <p className="max-w-2xl mt-1 text-sm text-gray-500">No numbers found</p>
                )}

                <Button
                  variant={ButtonVariantEnum.SUCCESS}
                  disabled={numbersCount === 0 || purchaseNumbersLoading || loading || !selectedItem?.isActive}
                  onClick={purchaseNumbers}
                >
                  {purchaseNumbersLoading || loading ? <LoadingIndicator position="CENTER" /> : 'Purchase'}
                </Button>
              </div>
            </div>

            <Button
              className="px-0 mt-1 hover:underline disabled:cursor-auto hover:cursor-pointer"
              disabled={numbersCount === 0}
              variant={ButtonVariantEnum.TEXT_PRIMARY}
              text={`${showNumbers ? 'Hide' : 'Show'} ${numbersCount ? `${numbersCount} ` : ''}Numbers`}
              onClick={() => {
                setShowNumbers(!showNumbers);
              }}
            />
            {showNumbers && (
              <div className="pb-2 space-y-1">
                {phoneNumbers.map((phoneNumber) => (
                  <span
                    key={`${phoneNumber}`}
                    style={{ width: 140 }}
                    className="inline-flex justify-evenly rounded-full items-center py-0.5 pl-2 pr-1 ml-1 text-sm font-medium bg-emerald-100 text-emerald-700 shadow"
                  >
                    {toPhoneNumberFormat(phoneNumber)}
                    <button
                      type="button"
                      className="flex-shrink-0 ml-0.5 h-4 w-4 rounded-full inline-flex items-center justify-center text-emerald-400 hover:bg-emerald-200 hover:text-emerald-500 focus:outline-none focus:bg-emerald-500 focus:text-white"
                      onClick={() => {
                        removePhoneNumber(phoneNumber);
                      }}
                    >
                      <span className="sr-only">Remove Phone Number</span>
                      <XIcon />
                    </button>
                  </span>
                ))}
              </div>
            )}
          </div>
        </div>
      </Transition>
    </div>
  );
};

export default PurchaseNumbersCard;
