import { Disclosure } from '@headlessui/react';
import { IMenuItem } from './MenuItem';
import { Dropdown as DropdownIcon } from '../Icons/Dropdown';

export const MenuButton = ({ item, active, open, onClick }: IMenuItem) => (
  <Disclosure.Button className={`menu-item`} onClick={onClick}>
    <item.icon
      className={`mr-3 flex-shrink-0
                              ${item.href === location.pathname && 'text-sky-800 dark:text-slate-900'}
                            `}
      aria-hidden="true"
    />
    <div className="flex w-full">
      <span>{item.name}</span>
      <div className={`w-full mt-px flex justify-center ${open ? 'rotate-180' : ''}`}>
        <DropdownIcon />
      </div>
    </div>
  </Disclosure.Button>
);
