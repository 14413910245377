import { SocketContext } from '@gr/portal/contexts/SocketContext';
import { useStateRef } from '@gr/portal/hooks/useStateRef';
import { CampaignStatusEnum, MessageTypeEnum } from '@gr/shared/enums';
import {
  ExternalV1CampaignDetailsResponse,
  IHttpResponse,
  IMessagesAllocateResponse,
  IMessagesConfirmRequest,
  IMessagesDeallocateRequest,
  ISocketBroadcastPayload
} from '@gr/shared/models';
import {
  getSMSMessageLength,
  getSMSMessageSegmentCount,
  isCampaignPausable,
  isCampaignUnpausable,
} from '@gr/shared/utils';
import { Dialog, Transition } from '@headlessui/react';
import { PaperAirplaneIcon, XIcon } from '@heroicons/react/outline';
import useAxios from 'axios-hooks';
import { differenceInSeconds } from 'date-fns';
import { Fragment, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { axiosPost } from '../../../authAxios';
import useInterval from '../../../hooks/useInterval';
import { Button } from '../../shared/Buttons/Button';
import { ButtonVariantEnum } from '../../shared/Buttons/types';
import { Card } from '../../shared/Card';
import Chip from '../../shared/Chip';
import { TextArea } from '../../shared/Form/TextArea';
import { TextInput } from '../../shared/Form/TextInput';
import { IAllocatedMessage, IConfirmedMessage } from './types';

const ExecuteCampaign = () => {
  const { campaignId } = useParams<{ campaignId: string; }>();
  const socketManager = useContext(SocketContext);
  const history = useHistory();
  const messageIntervalCount = useRef(0);
  const allocatedMessages = useRef<IAllocatedMessage[]>([]);
  const [sendDisabled, setSendDisabled, sendDisabledRef] = useStateRef(false);
  const campaign = useRef<ExternalV1CampaignDetailsResponse | undefined>();

  const [isIdle, setIsIdle] = useState(false);
  const [isAllocating, setIsAllocating] = useState(true);
  const [triggerMessages, setTriggerMessages] = useState(false);
  const [isCampaignComplete, setIsCampaignComplete] = useState(false);

  const [lastActionTime, setLastActionTime] = useState<Date>(new Date());
  const [modifiedMessage, setModifiedMessage] = useState<string | undefined>();
  const [manualAllocationRequest, setManualAllocationRequest] = useState(false);
  const [confirmedMessages, setConfirmedMessages] = useState<IConfirmedMessage[]>([]);

  const [{ data, loading, error }] = useAxios<IHttpResponse<ExternalV1CampaignDetailsResponse>>({
    url: `v1/campaigns/${campaignId}`,
    method: 'GET',
  });

  campaign.current = data?.data;

  const isCampaignDisabled = !!campaign.current && isCampaignUnpausable(campaign.current);

  const getAllocatedMessages = async () => {
    if (isCampaignComplete) {
      return;
    }

    setIsAllocating(true);
    setManualAllocationRequest(false);

    try {
      const {
        data: {
          data: { messages },
        },
      } = await axiosPost<IHttpResponse<IMessagesAllocateResponse>>('messages-allocate', {
        campaignId,
      });

      // NOTE: This needs to be a ref because otherwise it takes a snapshot of the state
      allocatedMessages.current = [...allocatedMessages.current, ...(messages ?? [])];

      // If you ever get less than 1000 messages, these are the last messages for this campaign
      if (messages.length < 1000) {
        setIsCampaignComplete(true);
      }
    } catch (err) {
      console.error(err);
      setManualAllocationRequest(true);
    } finally {
      setIsAllocating(false);
    }
  };

  const sendConfirmedMessages = async () => {
    const confirmMessagePayload: IMessagesConfirmRequest = {
      campaignId,
      messages: confirmedMessages,
    };

    axiosPost('/messages-confirm', confirmMessagePayload);

    setConfirmedMessages([]);
    setModifiedMessage(undefined);
  };

  const onPauseCampaign = useCallback((msg: ISocketBroadcastPayload) => {
    if (msg.payload.campaignId === campaignId && isCampaignPausable(campaign.current?.status)) {
      // When a campaign is paused, don't send any currently confirmed messages and navigate users away
      campaign.current!.status = CampaignStatusEnum.PAUSED;

      setConfirmedMessages([]);

      setTriggerMessages(false);
    }
  }, []);

  // Set hotkey, socket connection, and get initial allocated messages
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'k' && !sendDisabledRef.current) {
        setTriggerMessages(true);
      }
    };

    const handleKeyUp = (e: KeyboardEvent) => {
      if (e.key === 'k' && !sendDisabledRef.current) {
        setTriggerMessages(false);
      }
    };

    const handleMouseUp = () => {
      setTriggerMessages(false);
    };

    const unsubscribe = socketManager!.subscribe('campaign-paused-status-changed', onPauseCampaign);

    getAllocatedMessages();

    document.addEventListener('keydown', handleKeyDown);
    document.addEventListener('keyup', handleKeyUp);
    document.addEventListener('mouseup', handleMouseUp);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('keyup', handleKeyUp);
      document.removeEventListener('mouseup', handleMouseUp);
      unsubscribe();
    };
  }, []);

  console.log(
    `${allocatedMessages.current.length} messages allocated | ${confirmedMessages.length} confirmed messages `
  );

  // Deallocate messages
  useEffect(() => {
    const deallocatePayload: IMessagesDeallocateRequest = {
      campaignId,
    };

    window.addEventListener('beforeunload', () => {
      // Deallocate any messages on browser close/etc.
      axiosPost('messages-deallocate', deallocatePayload);
    });

    return () => {
      window.removeEventListener('beforeunload', () => { });
      // Deallocate any messages on page navigation/etc.
      axiosPost('messages-deallocate', deallocatePayload);
    };
  }, [campaignId]);

  // Idle check
  useInterval(() => {
    const date = new Date();
    const campaignEnd = new Date(campaign.current?.endsAt!) <= date;
    const idleDuration: number = differenceInSeconds(date, lastActionTime);

    if (idleDuration >= 35 || campaignEnd) {
      history.push('/app/my-campaigns', { showEndCampaign: campaignEnd });
    } else if (idleDuration >= 30) {
      setIsIdle(true);
    }
  }, 5000);

  const shouldThrottle = (length: number): boolean => {
    return (
      (length === 1 && messageIntervalCount.current < 24) ||
      (length === 2 && messageIntervalCount.current < 36) ||
      (length === 3 && messageIntervalCount.current < 48) ||
      (length === 4 && messageIntervalCount.current < 60) ||
      (length === 5 && messageIntervalCount.current < 68) ||
      (length === 6 && messageIntervalCount.current < 76) ||
      (length === 7 && messageIntervalCount.current < 82) ||
      (length === 8 && messageIntervalCount.current < 88) ||
      (length === 9 && messageIntervalCount.current < 94) ||
      (length === 10 && messageIntervalCount.current < 100)
    );
  };

  // Confirm messages at a rate of 40msgs/sec
  useInterval(() => {
    if (triggerMessages && allocatedMessages.current.length > 0) {
      messageIntervalCount.current++;
      // If hit our throttle max, don't send message

      if (shouldThrottle(confirmedMessages.length)) {
        return;
      }

      // Set the last action time as now to avoid becoming idle
      setLastActionTime(new Date());

      // Move the pending message to the confirmed message array
      // TODO: Does it make more sense to simply increment a 'slice point' counter and only do the operation before send?
      const removedMessage = allocatedMessages.current.shift()!;

      const nextMessage: IConfirmedMessage = {
        id: removedMessage.id,
        message: modifiedMessage ?? removedMessage.message ?? '',
      };

      setConfirmedMessages([...confirmedMessages, nextMessage]);
    } else if (!triggerMessages && confirmedMessages.length > 0) {
      // User has let go of the send button, send everything they've accumulated so far
      console.log(`sending ${confirmedMessages.length} messages due to user release`);
      sendConfirmedMessages();
      messageIntervalCount.current = 0;
    }
  }, 25);

  // Send confirmed messages
  useEffect(() => {
    // If we have 1,000 confirmed messages, send a request to the backend to confirm all messages
    if (
      confirmedMessages.length >= 1000 ||
      (isCampaignComplete && allocatedMessages.current.length === 0 && confirmedMessages.length > 0)
    ) {
      sendConfirmedMessages();
    }
  }, [confirmedMessages.length]);

  // Allocate messages
  useEffect(() => {
    // If we have less than 500 pending messages, send a request to allocate more for this user
    if ((allocatedMessages.current.length <= 500 && !isAllocating && !isCampaignComplete) || manualAllocationRequest) {
      getAllocatedMessages();
    }
  }, [allocatedMessages.current.length, manualAllocationRequest]);

  const navigateToMyCampaigns = async () => {
    history.push('/app/my-campaigns');
  };

  return (
    <div className="flex justify-center">
      <Transition show={isIdle} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto"
          onClose={() => {
            setIsIdle(false);
            setLastActionTime(new Date());
          }}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </Transition.Child>

            <span className="inline-block h-screen align-middle" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full max-w-md p-6 my-8 text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                <Button
                  className="absolute top-0 right-0 rounded-tr-2xl hover:bg-gray-100"
                  variant={ButtonVariantEnum.TEXT_DEFAULT}
                  leadingIcon={<XIcon className="w-5 h-5 text-gray-300" />}
                  onClick={() => {
                    setIsIdle(false);
                    setLastActionTime(new Date());
                  }}
                />

                <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                  <span id="upload-optouts-modal-title">Inactivity Alert</span>
                </Dialog.Title>
                <div className="mt-2">
                  <p className="text-sm text-gray-700">You're about to be navigated back to My Campaigns.</p>
                  <p className="text-sm text-gray-700">Click anywhere to stay on this page.</p>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>

      <Transition
        show={(isCampaignComplete && allocatedMessages.current.length === 0 && !isIdle) || isCampaignDisabled}
        as={Fragment}
      >
        <Dialog as="div" className="fixed inset-0 z-10 overflow-y-auto" onClose={navigateToMyCampaigns}>
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </Transition.Child>

            <span className="inline-block h-screen align-middle" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full max-w-md p-6 my-8 text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                <Button
                  className="absolute top-0 right-0 rounded-tr-2xl hover:bg-gray-100"
                  variant={ButtonVariantEnum.TEXT_DEFAULT}
                  leadingIcon={<XIcon className="w-5 h-5 text-gray-300" />}
                  onClick={navigateToMyCampaigns}
                />

                <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                  <span id="upload-optouts-modal-title">Campaign Complete</span>
                </Dialog.Title>
                <div className="flex flex-col mt-2">
                  <p className="text-sm text-gray-700">
                    {isCampaignDisabled
                      ? 'This campaign has been disabled.'
                      : 'This campaign is complete and has no more messages.'}
                  </p>
                  <div className="flex mt-4">
                    <Button text="Navigate to My Campaigns" className="w-full" onClick={navigateToMyCampaigns} />
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>

      <Card
        header={
          <div className="flex items-center gap-2">
            {`Campaign: ${campaign.current?.name}`}
            <Chip color={campaign.current?.messageType === MessageTypeEnum.SMS ? 'sky' : 'emerald'}>
              {campaign.current?.messageType}
            </Chip>
          </div>
        }
        className="sm:w-7/12"
      >
        <div className="space-y-4">
          <TextInput
            label="To"
            name="campaignTo"
            value={allocatedMessages.current[0]?.toNumber ?? 'Loading Messages...'}
            disabled
          />

          <TextArea
            label="Message"
            name="campaignMessage"
            value={modifiedMessage ?? allocatedMessages.current[0]?.message}
            disabled={true}
            rows={9}
            onEditButtonClick={(isDisabled: boolean) => {
              // Don't allow an empty message, and substring the modified response to the max character limit for both message types
              if (modifiedMessage?.length === 0) {
                setModifiedMessage(allocatedMessages.current[0]?.message);
              }
              if (modifiedMessage && modifiedMessage.length > 1000) {
                if (campaign.current?.messageType === MessageTypeEnum.SMS) {
                  setModifiedMessage(modifiedMessage.substring(0, 1000));
                } else if (campaign.current?.messageType === MessageTypeEnum.MMS) {
                  setModifiedMessage(modifiedMessage.substring(0, 1600));
                }
              }
              setLastActionTime(new Date());
              setSendDisabled(!isDisabled);
            }}
            description={
              <p className="mt-2 ml-auto text-sm text-gray-500 dark:text-slate-400">
                Segment count:{' '}
                {campaign.current?.messageType === MessageTypeEnum.MMS
                  ? 1
                  : modifiedMessage !== null && modifiedMessage !== undefined
                    ? getSMSMessageSegmentCount(modifiedMessage)
                    : getSMSMessageSegmentCount(allocatedMessages.current[0]?.message ?? '')}{' '}
                | Character count{' '}
                {modifiedMessage !== null && modifiedMessage !== undefined
                  ? getSMSMessageLength(modifiedMessage)
                  : getSMSMessageLength(allocatedMessages.current[0]?.message ?? '')}
                /1600
              </p>
            }
            onChange={(e) => setModifiedMessage(e.target.value)}
          />

          {/* <div>SMS Message Parsed</div> */}
          <div className="flex">
            <div className="flex ml-auto space-x-4 select-none">
              <Button variant={ButtonVariantEnum.DEFAULT} text="Cancel" onClick={navigateToMyCampaigns} />
              <Button
                id="send_message"
                leadingIcon={<PaperAirplaneIcon className="w-5 h-5" />}
                text="Send"
                variant={ButtonVariantEnum.SECONDARY}
                disabled={sendDisabled || allocatedMessages.current.length === 0}
                className={`${triggerMessages ? 'bg-sky-200 outline-none ring-2 ring-offset-2 ring-sky-500' : 'bg-sky-100'
                  }`}
                onTouchStart={() => {
                  setTriggerMessages(true);
                }}
                onTouchEnd={() => {
                  setTriggerMessages(false);
                }}
                onMouseDown={() => {
                  setTriggerMessages(true);
                }}
                onMouseUp={() => {
                  setTriggerMessages(false);
                }}
                onClick={() => {
                  setTriggerMessages(true);
                  // For automated testing purposes since the tool cannot simulate a mouse down/up
                  setTimeout(() => {
                    setTriggerMessages(false);
                  }, 100);
                }}
              />
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default ExecuteCampaign;
