import { XIcon } from '@heroicons/react/solid';

interface IChipProps {
  children?: React.ReactNode;
  color?: string;
  onRemove?: () => void;
}

const Chip = ({ children, onRemove, color }: IChipProps) => {
  return (
    <span
      className={`inline-flex rounded-full items-center py-1 px-2 text-sm font-medium bg-${
        color ?? 'emerald'
      }-100 text-${color ?? 'emerald'}-700 shadow`}
    >
      {children}
      {!!onRemove && (
        <button
          type="button"
          className={`flex-shrink-0 ml-1.5 h-4 w-4 rounded-full inline-flex items-center justify-center text-${
            color ?? 'emerald'
          }-400 hover:bg-emerald-200 hover:text-${color ?? 'emerald'}-500 focus:outline-none focus:bg-${
            color ?? 'emerald'
          }-500 focus:text-white`}
          onClick={() => {
            !!onRemove && onRemove();
          }}
        >
          <>
            <span className="sr-only">Remove filter</span>
            <XIcon />
          </>
        </button>
      )}
    </span>
  );
};

export default Chip;
