import { ICreateLinkOrderRequest, IHttpResponse, ILinkOrderEntity } from '@gr/shared/models';
import axios from 'axios';
import useAxios from 'axios-hooks';
import { useState } from 'react';
import { useNotifications } from '../../contexts/NotificationContext';
import useLinks from '../../hooks/useLinks';
import { Button } from '../shared/Buttons/Button';
import { ButtonVariantEnum } from '../shared/Buttons/types';
import { Table } from '../shared/Table/Table';
import LinkDetailsPanel from './LinkOrderDetailsPanel';
import { ILinkForm, defaultLinkTableOptions, getColumns } from './types';

const Links = (): JSX.Element => {
  const [showDetailsPanel, setShowDetailsPanel] = useState(false);
  const [selectedItem, setSelectedItem] = useState<ILinkOrderEntity>(); // TODO: Type item
  const { addNotification } = useNotifications();
  const [isLoading, setIsLoading] = useState(false);

  const [{ data, loading, error }, refetch] = useLinks(defaultLinkTableOptions);

  const [{ data: createData, loading: createLoading, error: createError }, createLinkOrder] = useAxios<
    IHttpResponse<{ url: string; }>
  >(
    {
      url: 'link-orders-create',
      method: 'POST',
    },
    { manual: true }
  );

  const links: any[] = data?.data.records ?? [];
  const totalCount = data?.data.totalCount ?? 0;

  const handleSubmit = async (formData: ILinkForm): Promise<void> => {
    try {
      // Upload takes longer than expected
      setIsLoading(true);
      const {
        data: {
          data: { url },
        },
      } = await createLinkOrder({
        data: {
          name: formData.name,
          url: formData.url,
          domain: formData.domain,
          displayFileName: formData.numberList?.name,
        } as ICreateLinkOrderRequest,
      });

      await axios.put(url, formData.numberList, {
        headers: { 'Content-Type': 'text/csv' },
      });

      closeDetailsPanel();

      await refetch();

      setIsLoading(false);

      addNotification({
        header: 'Link Order created successfully!',
        content: 'You will receive an email with the generated links shortly.',
      });
    } catch (err) {
      console.error(err);
    }
  };

  const openDetailsPanel = (item?: any) => {
    setSelectedItem(item);
    setShowDetailsPanel(true);
  };

  const closeDetailsPanel = (): void => {
    setSelectedItem(undefined);
    setShowDetailsPanel(false);
  };

  const exportLinks = async () => {
    // const {
    //   data: { data: signedUrl },
    // } = await axiosPost<IHttpResponse<string>>('/contact-lists-export', request);
    // downloadFileFromUrl(signedUrl);
  };

  const columns = getColumns(
    {
      name: (item: any) => {
        // TODO: Type item
        openDetailsPanel(item);
      },
    }
    // {
    //   fileName: (item: any) => {
    //     const content = true ? (
    //       <Button variant={ButtonVariantEnum.TEXT_PRIMARY} onClick={exportLinks}>
    //         Download File
    //       </Button>
    //     ) : (
    //       <LoadingIndicator />
    //     );

    //     return content;
    //   },
    // }
  );

  return (
    <>
      <h2 id="links-title" className="pb-2 dark:text-white">
        Link Orders
      </h2>

      <div className="flex justify-end pb-2">
        <Button
          id="link-order-add"
          variant={ButtonVariantEnum.SECONDARY}
          onClick={() => {
            setShowDetailsPanel(true);
          }}
        >
          Add
        </Button>
      </div>

      <Table
        shimmer
        loading={loading}
        columns={columns}
        items={links}
        count={totalCount}
        tableSearchOptions={defaultLinkTableOptions}
        paginate
      />

      <LinkDetailsPanel
        show={showDetailsPanel}
        loading={createLoading || isLoading}
        onClosePanel={closeDetailsPanel}
        selectedItem={selectedItem}
        handleSubmit={handleSubmit}
      />
    </>
  );
};

export default Links;
