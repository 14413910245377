import { Checkbox, DateTimePicker, Info, TextAreaTags, TextInput } from '@Wonder-Cave/ui';
import { useCustomDataPoints } from '@gr/portal/hooks/useCustomDataPoints';
import { Domain } from '@gr/shared/entities';
import { MessageTypeEnum } from '@gr/shared/enums';
import { escapeAndReplaceDefinedCharacters, getSMSMessageLength, getSMSMessageSegmentCount, isGsmMessage } from '@gr/shared/utils';
import 'draft-js/dist/Draft.css';
import { FastField, Field } from 'formik';
import moment from 'moment';
import NewDomainsDropdown from '../../shared/Form/Dropdowns/NewDomainsDropdown';
import Tags from '../../shared/Form/Tags';
import { ICampaignForm } from '../types';

interface IBuildProps {
  values?: ICampaignForm;
  errors?: any;
  touched?: any;
  setFieldValue?: (field: string, value: any) => void;
  setFieldTouched?: (field: string, touched: boolean) => void;
  handleChange?: () => void;
  handleBlur?: () => void;
  onSubmit: (formikProps) => void;
  leads: number;
  domains: Array<Domain> | undefined;
  submissionError?: string;
}

const Build = ({
  values,
  errors,
  touched,
  setFieldValue,
  setFieldTouched,
  handleChange,
  handleBlur,
  leads,
  domains,
  onSubmit,
  submissionError
}: IBuildProps) => {
  const [{ data, loading }] = useCustomDataPoints({ contactListIds: values?.contactLists.map(cl => cl.value)! });
  const tags = data?.data ?? [];

  let errorMessages: string[] = [];
  if (touched.message) {
    if (!isGsmMessage(values?.message!)) {
      errorMessages.push('Message contains non-GSM characters');
    }
    if (errors.message) {
      errorMessages.push(errors.message);
    }
  }

  const errorMsg = errorMessages.join('; ') ?? '';

  return (
    <>
      <div className="flex flex-wrap space-y-8">
        <div className="flex justify-between w-full">
          <h2 className="mb-2 text-black basis-full">Create Message</h2>
          <div className="mt-1 min-w-[16rem]">
            {(values?.client?.additionalData?.twoWayEnabled || values?.twoWayEnabled) && (
              <Checkbox
                id="twoWayEnabled"
                name="twoWayEnabled"
                label="Enable two way conversations"
                checked={values?.twoWayEnabled!}
                onChange={handleChange}
              />
            )}
          </div>
        </div>
        <Field
          component={TextAreaTags}
          id="message"
          name="message"
          label="Message"
          placeholder="Enter your message here..."
          className="flex flex-row-reverse items-start justify-end w-full build-section gap-x-4"
          wrapperClassName="shrink-0"
          value={values?.message!}
          error={errorMsg}
          onChange={(value: string) => {
            if (value.length > 0) {
              setFieldTouched!('message', true);
              value = escapeAndReplaceDefinedCharacters(value);
            }
            setFieldValue!('message', value);
          }}
          onBlur={handleBlur}
          description={
            <div className="flex justify-between body-text-small text-medium-gray">
              <p>
                Segments:{' '}
                {values?.messageType === MessageTypeEnum.MMS
                  ? 1
                  : getSMSMessageSegmentCount(
                    values?.message?.toLowerCase()?.replace(/{{link}}/gi, `${values?.domain}/aaaaaa`)!
                  )}{' '}
              </p>
              <p>
                Characters:{' '}
                {getSMSMessageLength(values?.message?.toLowerCase()?.replace(/{{link}}/gi, `${values?.domain}/aaaaaa`)!)}/
                {values?.messageType === MessageTypeEnum.MMS ? 1600 : 1000}
              </p>
            </div>
          }
          contactListIds={values?.contactLists.map((cl) => cl.value)}
          renderTags={(onTagClick) => <Tags tags={tags.filter(t => t !== "linkId")} onClick={onTagClick} />}
        />
        <div className="flex flex-wrap items-start w-full border-b-2 build-section border-light-gray">
          <div style={{ flexBasis: '100%' }} className="mb-6">
            <Checkbox
              id="clickTrackingEnabled"
              name="clickTrackingEnabled"
              label="Enable Click Tracking"
              description="Generate unique links for each message by including {link} in your message"
              checked={values?.clickTrackingEnabled}
              onChange={handleChange}
            />
          </div>

          {values?.clickTrackingEnabled && (
            <>
              <div className="mb-6">
                <FastField
                  component={TextInput}
                  id="url"
                  name="url"
                  label="DESTINATION URL"
                  value={values?.url}
                  error={touched.url ? errors.url : ''}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
              <div className="pl-4 mb-6">
                <NewDomainsDropdown
                  value={values?.domainId ?? ''}
                  options={domains}
                  onChange={(selectedValue) => {
                    setFieldValue!('domainId', selectedValue?.value);
                    // Need label for message text
                    setFieldValue!('domain', selectedValue?.label);
                  }}
                  onBlur={() => {
                    setFieldTouched!('domainId', true);
                  }}
                  showError={touched.domain}
                  errorMessage={errors.domain}
                />
              </div>
              {tags.includes('linkId') && <div className="flex items-center mb-6">
                <div className='shrink-0'>
                  <Checkbox
                    id="generateUniqueLinks"
                    name="generateUniqueLinks"
                    label="Enable deep links"
                    checked={values.generateUniqueLinks}
                    onChange={handleChange}
                  />
                </div>
                <div>
                  <Info className='pr-2 -ml-4' text='This will add the values you input in the link ID field of your contact list to the destination url for each contact. For example, if the destination URL is "www.google.com" and the link ID is /user123, the final url for the contact will be "www.google.com/user123"' />
                </div>
              </div>}

            </>
          )}
        </div>
        <div className="flex flex-wrap items-start w-full">
          <div className="flex flex-row justify-between w-full">

            <div className='flex flex-col basis-full'>
              <h2 className="mb-6 text-black ">Schedule your message</h2>
              <div className='flex flex-row'>
                <h4 className="mb-6 text-dark-gray">When would you like to send your message?</h4>
                <Info className='ml-2 mt-0.5' text='Daily sending window: 8 AM to 9 PM' />
              </div>
            </div>

            <div className="flex flex-row justify-between mt-1 min-w-[16rem] items-start pl-10">
              <Checkbox
                id="time-zone-prioritization"
                disabled={false}
                label='Time Zone Prioritization'
                checked={!!values?.timezonePrioritizationEnabled}
                onChange={() => {
                  setFieldValue!('timezonePrioritizationEnabled', !values?.timezonePrioritizationEnabled);
                  setFieldTouched!('timezonePrioritizationEnabled', true);
                }}
              />
              <Info position='BOTTOM' className="w-0 mt-1" width={225} bubbleWrapperClassName='w-[12rem]' text={
                <div>
                  <div>
                    When enabled, all messages in the campaign will prioritize sending to earlier timezones.
                  </div>
                  <div>
                    E.g. sending to EST before PST.
                  </div>
                </div>
              } />
            </div>



          </div>

          <div className="flex w-full mt-6 mb-6 gap-x-4">
            <FastField
              component={DateTimePicker}
              full
              label="START DATE & TIME"
              name="startsAt"
              value={new Date(values?.startsAt!)}
              error={touched.startsAt && errors.startsAt ? (errors.startsAt as string) : ''}
              onChange={(newValue: Date) => {
                setFieldTouched!('startsAt', true);
                setFieldValue!('startsAt', newValue);
              }}
            />
            {/* Note: Currently a UI bug where min date doesn't get updated since it's a fast field. Without being a fast field the page gets laggy because the text area can't be a fast field  */}

            <div className='w-full'>
              <label htmlFor="endsAt">
                <div className="flex items-center w-full mb-5">
                  <h5 className='shrink-0 text-medium-gray'>END DATE & TIME</h5>
                  <Info className="w-0 ml-[0.5rem] z-10" width={165} bubbleWrapperClassName='w-[8rem]' text="You can select an end date up to 2 days into the future." />
                </div>
              </label>

              <FastField
                component={DateTimePicker}
                full
                id="datePicker_right"
                name="endsAt"
                value={new Date(values?.endsAt!)}
                error={touched.endsAt && errors.endsAt ? (errors.endsAt as string) : ''}
                onChange={(newValue: Date) => {
                  setFieldTouched!('endsAt', true);
                  setFieldValue!('endsAt', newValue);
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="flex items-start justify-between w-full">
        <h3 className="mb-4 text-wc-blue">
          {`${leads > 0 ? leads.toLocaleString() + ' m' : 'M'}essages scheduled to send ${`at ${moment(values?.startsAt).format('L LT')}`}`}
        </h3>
        {submissionError && <h3 className='mb-4 text-red-600'>{submissionError}</h3>}
      </div>
    </>
  );
};

export default Build;
