import { IHttpResponse, IProvidersGetForTypesResponse } from '@gr/shared/models';
import useAxios, { UseAxiosResult } from 'axios-hooks';

export default function useProvidersForClient(
  clientId: string
): UseAxiosResult<IHttpResponse<IProvidersGetForTypesResponse>> {
  return useAxios<IHttpResponse<IProvidersGetForTypesResponse>>(
    {
      url: 'providers-get-for-types',
      method: 'POST',
      data: { clientId: clientId },
    },
    { manual: true }
  );
}
