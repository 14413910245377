const ChristmasSidebarStyle: string = 'bg-gradient-to-b from-slate-900 to-slate-700';

export const getFestiveStyle = (): string => {
  const month = new Date().getMonth() + 1;

  switch (month) {
    case 2:
      return 'valentines';
    case 3:
      return 'ukraine';
    case 10:
      return 'halloween';
    case 12:
      return ChristmasSidebarStyle;
    default:
      return 'partyMode';
  }
};

export const getFestiveAssetName = (): string => {
  const month = new Date().getMonth() + 1;

  switch (month) {
    default:
      return 'grassroots_logo.svg';
  }
};
