import { CampaignStatusEnum } from '@gr/shared/enums';
import { ICampaignDetails } from '@gr/shared/models';
import { Info } from '@Wonder-Cave/ui';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { Exclamation } from '../../shared/Icons/Exclamation';
import { Time as TimeIcon } from '../../shared/Icons/Time';

interface ICampaignTitleProps {
  campaign: ICampaignDetails;
}

export const CampaignTitle = ({ campaign }: ICampaignTitleProps) => {
  const history = useHistory();

  let infoIcon: JSX.Element | null = null;
  if (campaign.totalTestMessagesFailed > 0) {
    infoIcon = <Info infoElement={<Exclamation />} className="mr-1" text={'This campaign failed to send some test messages.'} />;
  } else if (campaign.status === CampaignStatusEnum.DRAFT && campaign.allListsFailed) {
    infoIcon = <Info infoElement={<Exclamation />} bubbleWrapperClassName="w-[28rem] absolute left-full z-50 transform opacity-100 scale-100" className="mr-1" text={'Selected contact list failed to process and has been removed from the campaign.  Please select a new list to finalize the campaign.'} />;
  }

  return (
    <div>
      {campaign.totalTestMessagesFailed > 0 && (
        <div title={'This campaign failed to send some test messages.'} className="mr-4">
          <Exclamation />
        </div>
      )}
      <div>
        <h5 className="mb-1 text-medium-gray">{campaign.clientName}</h5>
        <h3
          onClick={() => {
            if (campaign.status === CampaignStatusEnum.DRAFT) {
              history.push(`/app/campaigns/${campaign.id}/edit`);
            } else {
              history.push(`/app/campaigns/${campaign.id}`);
            }
          }}
          title={campaign.name}
          className="max-w-lg mb-3 text-black break-words whitespace-normal cursor-pointer hover:underline hover:text-wc-blue"
        >
          <span>{infoIcon} {campaign.name}</span>
        </h3>
        <div className="flex items-center mt-2">
          <TimeIcon />
          <div className="ml-2 body-text-small text-medium-gray">{moment(campaign.startsAt).format('L, LT')}</div>
        </div>
      </div>
    </div>
  );
};
