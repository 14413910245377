import { ConversationTags } from './ConversationTags';
import { ConversationTemplates } from './ConversationTemplates';

export const Conversations = () => {
  return (
    <div className="flex flex-col px-16 pt-8">
      <ConversationTags />
      <ConversationTemplates />
    </div>
  );
};
