import { ICarrierExclusionView, ICreateCarrierExclusionRequest, IHttpResponse } from '@gr/shared/models';
import { AxiosError } from 'axios';
import useAxios from 'axios-hooks';
import { useState } from 'react';
import { axiosPost } from '../../authAxios';
import { NotificationType, useNotifications } from '../../contexts/NotificationContext';
import { Button } from '../shared/Buttons/Button';
import { ButtonVariantEnum } from '../shared/Buttons/types';
import { Table } from '../shared/Table/Table';
import CarrierExclusionDetailsPanel from './CarrierExclusionDetailsPanel';
import { ICarrierExclusionForm, getColumns } from './types';

const CarrierExclusions = (): JSX.Element => {
  const [selectedItem, setSelectedItem] = useState<ICarrierExclusionView>();
  const [showDetailsPanel, setShowDetailsPanel] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const { addNotification } = useNotifications();

  const columns = getColumns({
    clientName: (item: any) => {
      openDetailsPanel(item);
    },
  });

  const [{ data: carrierExclusionResponse, loading, error }, refetch] = useAxios<
    IHttpResponse<ICarrierExclusionView[]>
  >({
    url: 'carrier-exclusions-get',
    method: 'POST',
  });

  const carrierExclusions: ICarrierExclusionView[] = carrierExclusionResponse?.data ?? [];

  const handleRefetch = async () => {
    try {
      await refetch();
    } catch (err) { }
  };

  const openDetailsPanel = (item?: any) => {
    if (item) {
      setSelectedItem(item);
    }
    setShowDetailsPanel(true);
  };

  const closeDetailsPanel = () => {
    setSelectedItem(undefined);
    setShowDetailsPanel(false);
  };

  const handleSubmit = async (formData: ICarrierExclusionForm): Promise<void> => {
    try {
      const request: ICreateCarrierExclusionRequest = {
        clientId: formData.client.value,
        carrierId: formData.carrierId,
        providerId: formData.providerId,
        campaignType: formData.campaignType,
        messageType: formData.messageType,
      };

      await axiosPost('carrier-exclusions-create', request);

      await handleRefetch();

      closeDetailsPanel();

      addNotification({
        header: 'Carrier Exclusion created successfully!',
      });
    } catch (err) {
      console.error(err);
      closeDetailsPanel();
      // default notification message for failure
      let notificationMessage = 'An unexpected error occurred when attempting to save the Carrier Exclusion.';

      // if error has message received from api display that message
      if (err instanceof AxiosError && err.response?.data.message)
        notificationMessage = err.response?.data.message;

      addNotification({
        header: 'Error',
        content: notificationMessage,
        type: NotificationType.FAILURE,
      });
    }
  };

  const handleCarrierExclusionDelete = async (): Promise<void> => {
    if (!selectedItem) {
      return;
    }

    setDeleteLoading(true);

    try {
      const deleteRequest = {
        clientId: selectedItem.clientId,
        carrierId: selectedItem.carrierId,
        providerId: selectedItem.providerId,
        campaignType: selectedItem.campaignType,
        messageType: selectedItem.messageType,
      };

      await axiosPost('carrier-exclusions-delete', deleteRequest);

      await handleRefetch();

      closeDetailsPanel();

      addNotification({
        header: 'Carrier Exclusion deleted successfully',
      });
    } catch (err) {
      console.error(err);
      addNotification({
        header: 'Error',
        content: 'An unexpected error occurred when attempting to delete the Carrier Exclusion.',
        type: NotificationType.FAILURE,
      });
    } finally {
      setDeleteLoading(false);
    }
  };

  return (
    <>
      <h2 className="pb-2 dark:text-white">Carrier Exclusions</h2>

      <div className="flex justify-end pb-2 space-x-2">
        <Button
          id="system-number-add"
          variant={ButtonVariantEnum.SECONDARY}
          onClick={() => {
            setShowDetailsPanel(true);
          }}
        >
          Add
        </Button>
      </div>

      <Table
        shimmer
        loading={loading}
        columns={columns}
        items={carrierExclusions}
        skipEnumFormattingColumns={['messageType']}
        enumExceptions={[{ fieldName: 'campaignType', exceptions: ['GOTV'] }]}
      />

      <CarrierExclusionDetailsPanel
        show={showDetailsPanel}
        loading={loading}
        deleteLoading={deleteLoading}
        selectedItem={selectedItem}
        onClosePanel={closeDetailsPanel}
        handleSubmit={handleSubmit}
        handleDelete={handleCarrierExclusionDelete}
      />
    </>
  );
};

export default CarrierExclusions;
