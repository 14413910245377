import {
  ChangeEventHandler,
  FocusEventHandler,
  KeyboardEventHandler,
  MouseEventHandler,
  useEffect,
  useState,
} from 'react';
import { ErrorMessage } from './ErrorMessage';

interface INewTextArea {
  id?: string;
  name: string;
  value: any;
  className?: string;
  label?: string;
  type?: string;
  placeholder?: string;
  error?: string;
  optional?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  clearable?: boolean;
  leadingIcon?: JSX.Element;
  trailingIcon?: JSX.Element;
  style?: any;
  onChange?: ChangeEventHandler<HTMLTextAreaElement>;
  onBlur?: FocusEventHandler<HTMLTextAreaElement>;
  onClear?: MouseEventHandler<HTMLButtonElement>;
  onEnter?: KeyboardEventHandler<HTMLTextAreaElement>;
  onIconClick?: MouseEventHandler<HTMLDivElement>;
  trailingIconClassName?: string;
  expandLimit?: number;
}

const NewTextArea = ({
  id,
  name,
  value,
  label,
  type,
  placeholder,
  error,
  disabled,
  onChange,
  onBlur,
  onEnter,
  onIconClick,
  trailingIcon,
  trailingIconClassName,
  className,
  style,
  expandLimit,
  readOnly,
}: INewTextArea): JSX.Element => {
  const [previousError, setPreviousError] = useState(error);
  const [hasFocus, setHasFocus] = useState(false);

  // Used to fix the animation by not removing the text as we're transitioning out
  useEffect(() => {
    if (error) {
      setPreviousError(error);
    }
  }, [error]);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    event.currentTarget.style.height = 'inherit';
    event.currentTarget.style.height = `${event.currentTarget.scrollHeight}px`;
    event.currentTarget.style.height = `${Math.min(event.currentTarget.scrollHeight, expandLimit ?? 200)}px`;
    if (event.key === 'Enter') {
      onEnter && onEnter(event);
    }
  };

  return (
    <>
      <div className={'relative w-full items-center'}>
        {/* Label */}
        {label && (
          <div className="flex justify-between mb-5 text-medium-gray">
            <h5>{label.toUpperCase()}</h5>
          </div>
        )}
        {/* Input */}
        <textarea
          id={id}
          name={name}
          className={`${className} text-area`}
          style={style}
          disabled={disabled ?? false}
          readOnly={readOnly ?? false}
          placeholder={placeholder || ''}
          aria-describedby="optional"
          onChange={(ev) => {
            if (type === 'number') {
              // Replace 'e' and leading zeros
              const value = ev.target.value;
              const input = parseInt(value.replace(/e/g, '') || '0', 10);
              ev.target.value = input.toString();
            }
            onChange && onChange(ev);
          }}
          onBlur={(e) => {
            setHasFocus(false);
            onBlur && onBlur(e);
          }}
          onFocus={() => {
            setHasFocus(true);
          }}
          onKeyDown={handleKeyDown}
          value={value ?? ''} // Allow null values to be passed in and simply convert the input value to empty string for display purposes
          autoComplete="off"
        />
        {/* Trailing Icon */}
        <div
          onClick={(e) => {
            if (!!onIconClick && !disabled) {
              onIconClick(e);
            }
          }}
          className={`absolute top-1/5 right-0 p-2 mr-2 ${trailingIconClassName}`}
        >
          <div>{trailingIcon}</div>
        </div>
      </div>
      <ErrorMessage show={!!error} message={previousError} />
    </>
  );
};

export default NewTextArea;
