import { ICarrierExclusionView } from '@gr/shared/models';
import useCarriers from '../../hooks/useCarriers';
import { ButtonVariantEnum } from '../shared/Buttons/types';
import { DetailsPanel } from '../shared/DetailsPanel/DetailsPanel';
import { DetailsPanelSize, IButtonOptions } from '../shared/DetailsPanel/types';
import { IDropdownValue } from '../shared/Form/Dropdown';
import CarrierExclusionForm from './CarrierExclusionForm';
import { ICarrierExclusionForm, majorCarrierSearch } from './types';

interface ICarrierExclusionDetailsPanel {
  show: boolean;
  loading: boolean;
  deleteLoading?: boolean;
  selectedItem?: ICarrierExclusionView;
  onClosePanel: () => void;
  handleSubmit: (formData: ICarrierExclusionForm) => Promise<void>;
  handleDelete?: () => Promise<void>;
}

const CarrierExclusionDetailsPanel = ({
  show,
  loading,
  deleteLoading,
  selectedItem,
  onClosePanel,
  handleSubmit,
  handleDelete,
}: ICarrierExclusionDetailsPanel): JSX.Element => {
  const [{ data: carrierData, loading: carrierLoading, error: carrierError }] = useCarriers(majorCarrierSearch);

  const carriers: IDropdownValue[] =
    carrierData?.data.records.map((carrier) => ({
      label: carrier.name ?? '',
      value: carrier.id ?? '',
    })) ?? [];

  const leftPanelButtonOptions: IButtonOptions[] = [
    {
      text: 'Delete',
      visible: !!selectedItem,
      onClick: handleDelete,
      variant: ButtonVariantEnum.DELETE,
      loading: deleteLoading,
    },
  ];

  return (
    <DetailsPanel
      item={selectedItem}
      title={selectedItem ? 'View Carrier Exclusion' : 'Add Carrier Exclusion'}
      formId="carrier-exclusion-form"
      size={DetailsPanelSize.Medium}
      show={show}
      closePanel={onClosePanel}
      loading={loading}
      primaryButtonOptions={{ text: 'Save', visible: !selectedItem }}
      leftPanelButtonOptions={leftPanelButtonOptions}
    >
      <CarrierExclusionForm item={selectedItem} onSubmit={handleSubmit} carriers={carriers} />
    </DetailsPanel>
  );
};

export default CarrierExclusionDetailsPanel;
