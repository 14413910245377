export enum EntityName {
  ALL_CLIENTS_VIEW = 'all_clients_view',
  ALL_ASSIGNED_CLIENTS_VIEW = 'all_assigned_clients_view',
  ALL_HEALTH_CHECK_NUMBERS_VIEW = 'all_health_check_numbers_view',
  ALL_OPTOUTS_VIEW = 'all_optouts_view',
  ALL_SYSTEM_NUMBERS_VIEW = 'all_system_numbers_view',
  ALL_CAMPAIGNS_VIEW = 'all_campaigns_view',
  ALL_TCR_CAMPAIGNS_VIEW = 'all_tcr_campaigns_view',
  AREA_CODES = 'area_codes',
  CAMPAIGNS = 'campaigns',
  CARRIERS = 'carriers',
  CLIENTS = 'clients',
  CONTACT_LISTS = 'contact_lists',
  LINKS = 'links',
  LINK_ORDERS = 'link_orders',
  LOCAL_SYSTEM_NUMBERS = 'local_system_numbers',
  SUPPRESSION_LISTS = 'suppression_lists',
  SYSTEM_NUMBERS = 'system_numbers',
  TCR_CAMPAIGNS = 'tcr_campaigns',
  USERS = 'users',
  TEST_MESSAGE_DATA = 'test_message_data'
}
