import { orderStringAscending } from '@gr/portal/providers/utility.provider';
import { Auth0OrganizationName } from '@gr/shared/models';
import { getOrganizationLabelByOrg } from '@gr/shared/utils/auth0-sub-domain.service';
import { Autocomplete } from '@Wonder-Cave/ui';
import { IDropdownValue } from '../Dropdown';

interface IOrganizationDropdownProps {
  value: IDropdownValue | undefined;
  onChange: (newValue?: IDropdownValue | undefined) => void;
  onBlur?: () => void;
  disabled?: boolean;
  errorMessage?: string | undefined;
  showError?: boolean;
  shimmer?: boolean;
}

const OrganizationDropdown = ({
  value,
  onChange,
  onBlur,
  disabled,
  errorMessage,
  showError,
  shimmer
}: IOrganizationDropdownProps): JSX.Element => {
  const organizations = Object.entries(Auth0OrganizationName)
    .map(([label, value]) => ({
      label: getOrganizationLabelByOrg(value),
      value,
      additionalData: label,
    }))
    .filter(org => !!org?.label)
    .sort(orderStringAscending('label')) as IDropdownValue[];

  return (
    <Autocomplete
      label="Organization"
      value={organizations.find((o) => o.value === value?.value)}
      options={organizations}
      onChange={onChange}
      onBlur={onBlur}
      showError={showError}
      error={errorMessage}
      disabled={disabled}
      search={false}
      shimmer={shimmer}
    />
  );
};

export default OrganizationDropdown;
