import { Formik } from 'formik';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import * as yup from 'yup';
import { getCsvHeaders } from '../../providers/validation.provider';
import { Button } from '../shared/Buttons/Button';
import { ButtonVariantEnum } from '../shared/Buttons/types';
import { IDropdownValue } from '../shared/Form/Dropdown';
import AllClientDropdown from '../shared/Form/Dropdowns/AllClientDropdown';
import { TextInput } from '../shared/Form/TextInput';
import LoadingIndicator from '../shared/LoadingIndicator';
import { OldFileUpload } from '../shared/Form/OldFileUpload';

export interface IOptOutUploadForm {
  client?: IDropdownValue;
  optOutList?: any;
  phone?: string | null | undefined;
}

interface IUploadOptOutFormProps {
  loading: boolean;
  phoneError?: string;
  onSubmit: (formData: IOptOutUploadForm) => Promise<void>;
}

const UploadOptOutForm = ({ phoneError, loading, onSubmit }: IUploadOptOutFormProps) => {
  const [phoneErrorMsg, setPhoneErrorMsg] = useState(phoneError);

  useEffect(() => setPhoneErrorMsg(phoneError), [phoneError]);

  const validateCsvHeaders = async (file: File): Promise<boolean> => {
    try {
      const headers = await getCsvHeaders(file);

      return headers.length === 1 && headers[0].toLocaleLowerCase() === 'phone';
    } catch (err) {
      console.error(err);
      return false;
    }
  };

  const schema: yup.SchemaOf<IOptOutUploadForm> = yup
    .object()
    .shape({
      client: yup
        .object()
        .shape({
          label: yup.string().defined('Required'),
          value: yup.mixed().defined('Required'),
        })
        .nullable(),
      optOutList: yup
        .mixed()
        .nullable()
        .test(
          'csvValidate',
          'Uploaded file must be .csv',
          (file: File): boolean => !file || file.name?.endsWith('.csv')
        )
        .test(
          'csvHeaderValidate',
          'CSV does not contain the proper headers',
          async (file: File): Promise<boolean> => !file || (await validateCsvHeaders(file))
        ),
      phone: yup
        .string()
        .test('len', 'Number must be 10 digits long', (num) => !num || /^\d{10}$/.test(num))
        .nullable(),
    })
    .test(
      'validatePhoneOrList',
      'A number or .csv file is required',
      (form: IOptOutUploadForm): boolean => form.phone || form.optOutList
    );

  return (
    <Formik
      initialValues={{
        client: undefined,
        optOutList: undefined,
        phone: undefined,
      }}
      validationSchema={schema}
      onSubmit={(values) => {
        onSubmit && onSubmit(values);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        setFieldTouched,
        setFormikState,
        isValid,
      }) => (
        <form id="upload-opt-out" onSubmit={handleSubmit}>
          <div className="flex flex-col space-y-2">
            <AllClientDropdown
              value={values.client}
              onChange={(newValue) => {
                setFieldValue('client', newValue);
              }}
              onBlur={() => {
                setFieldTouched('client');
              }}
              errorMessage={(errors?.client as any)?.value as string}
            />
            <TextInput
              id="phone"
              name="phone"
              type="number"
              label="Opt-out Number"
              value={values.phone}
              error={errors.phone ?? phoneErrorMsg}
              placeholder="Enter phone number"
              onChange={(event) => {
                setPhoneErrorMsg(undefined);
                handleChange(event);
              }}
              disabled={values.optOutList}
            />
            <div className="py-2 text-xs">- OR -</div>
            <OldFileUpload
              id="optout-upload-file-selector"
              name="optOutList"
              label="Opt-outs File"
              placeholder="Upload a .csv file"
              value={values.optOutList}
              error={touched.optOutList && (errors.optOutList as any)}
              onBlur={handleBlur}
              onChange={(fileUploadEvent: any) => {
                const file = fileUploadEvent.target.files[0];
                setFormikState((prevState) => {
                  const newState = { ...prevState };
                  newState.values.phone = undefined;
                  newState.values.optOutList = file;
                  delete newState.errors.phone;
                  return newState;
                });
                setFieldTouched('optOutList');
                setPhoneErrorMsg(undefined);
              }}
            />

            <div className="mt-2">
              <p className="text-sm text-gray-500">Each row must have a number. eg "1234567890".</p>
              <p className="text-sm text-gray-500">
                Download the template{' '}
                <Link
                  className="font-semibold text-sky-500 hover:underline"
                  to="/sample_upload_optouts.csv"
                  target="_blank"
                  download
                >
                  here
                </Link>
              </p>
            </div>
          </div>
          <div className="flex justify-center mt-4">
            <Button
              id="optout-upload-form"
              className="ml-auto"
              type="submit"
              variant={ButtonVariantEnum.SECONDARY}
              disabled={!isValid}
            >
              {loading ? <LoadingIndicator position="CENTER" /> : 'Upload'}
            </Button>
          </div>
        </form>
      )}
    </Formik>
  );
};

export default UploadOptOutForm;
