import { Autocomplete } from '@Wonder-Cave/ui';
import { IDropdownValue } from '@Wonder-Cave/ui/dist/Autocomplete';


interface NewMessageTypeDropdownProps {
  label?: string | undefined;
  value: string | undefined;
  options?: IDropdownValue[];
  onChange: (newValue?: IDropdownValue | undefined) => void;
  onBlur?: () => void;
  disabled?: boolean;
  errorMessage?: string | undefined;
  showError: boolean;
  gridFilter?: boolean;
  defaultGroupName?: string;
  includeNone?: boolean;
  shimmer?: boolean;
}

const NewMessageTypeDropdown = ({
  label,
  value,
  options,
  onChange,
  onBlur,
  disabled,
  errorMessage,
  showError,

  shimmer
}: NewMessageTypeDropdownProps): JSX.Element => {
  return (
    <Autocomplete
      label={label ?? 'MESSAGE TYPE'}
      placeholder="Select"
      value={options?.find((cgd) => cgd.label === value)}
      options={options}
      onChange={onChange}
      onBlur={onBlur}
      error={errorMessage}
      disabled={disabled}
      showError={showError}
      shimmer={shimmer}
    />
  );
};

export default NewMessageTypeDropdown;
