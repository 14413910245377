import { ISocketBroadcastPayload } from '@gr/shared/models';
import { getWebSocketUrl } from '@gr/shared/utils/auth0-sub-domain.service';
import React, { createContext } from 'react';
import { SocketManager } from '../providers/socket.provider';

let socketManager: SocketManager;
let SocketContext: React.Context<SocketManager | null>;
let SocketContextProvider: React.Provider<SocketManager | null>;
let SocketContextConsumer: React.Consumer<SocketManager | null>;

function initializeWebSocket() {
  if (socketManager) {
    return;
  }

  const socket = new WebSocket(getWebSocketUrl());

  socket.onopen = (ev: Event) => {
    console.log('Socket connected successfully');
  };

  socket.onmessage = (ev: MessageEvent<any>) => {
    const message: ISocketBroadcastPayload = JSON.parse(ev.data);

    console.log(message);

    const subscriptions = socketManager.subscriptions.get(message.eventName) ?? [];

    subscriptions.forEach((sub) => sub(message));
  };

  socketManager = new SocketManager(socket);
  SocketContext = createContext<SocketManager | null>(null);
  SocketContextProvider = SocketContext.Provider;
  SocketContextConsumer = SocketContext.Consumer;
}

export { SocketContext, SocketContextConsumer, SocketContextProvider, initializeWebSocket, socketManager };

