import { IHttpSearchResponse, ISearchRequest, ISystemNumberListView } from '@gr/shared/models';
import useAxios, { UseAxiosResult } from 'axios-hooks';

export default function useSystemNumbers(
  searchOptions: ISearchRequest
): UseAxiosResult<IHttpSearchResponse<ISystemNumberListView>> {
  return useAxios<IHttpSearchResponse<ISystemNumberListView>>({
    url: 'entities-search',
    method: 'POST',
    data: searchOptions,
  });
}
