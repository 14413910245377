import { CampaignStatusEnum } from '@gr/shared/enums';
import { ICampaignEntity } from '@gr/shared/models';

interface ICampaignProgressBarProps {
  campaign: ICampaignEntity;
}

const CampaignProgressBar = ({ campaign }: ICampaignProgressBarProps): JSX.Element => {
  const status: CampaignStatusEnum = campaign.status;
  const tripWired = campaign.status == CampaignStatusEnum.PAUSED && campaign.tripWired == true;

  return (
    <div>
      <div
        className={`flex h-2 overflow-hidden text-xs rounded ${tripWired
            ? 'bg-amber-400'
            : status === CampaignStatusEnum.LIVE || status === CampaignStatusEnum.SCHEDULING
              ? 'bg-sky-200 dark:bg-slate-400'
              : 'bg-gray-200 dark:bg-slate-500'
          }`}
      >
        {!tripWired && (
          <div
            style={{
              width: `${Math.min(
                Math.ceil(((campaign?.totalMessagesConfirmed ?? 0) / (campaign?.totalMessagesScheduled ?? 100)) * 100),
                100
              )}%`,
            }}
            className={`flex flex-col justify-center text-center text-white shadow-none ${(campaign?.totalMessagesConfirmed ?? 0) >= (campaign?.totalMessagesScheduled || 0)
                ? 'bg-emerald-400'
                : 'bg-sky-500'
              } whitespace-nowrap`}
          ></div>
        )}
      </div>
      <span className="text-sm font-medium text-gray-900 truncate dark:text-slate-400" title="Confirmed / Scheduled">
        {campaign?.totalMessagesConfirmed?.toLocaleString()} / {campaign?.totalMessagesScheduled?.toLocaleString()}
      </span>
    </div>
  );
};

export default CampaignProgressBar;
