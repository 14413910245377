import { ILinkOrderEntity } from '@gr/shared/models';
import { Formik } from 'formik';
import { Link } from 'react-router-dom';
import DomainsDropdown from '../shared/Form/Dropdowns/DomainsDropdown';
import { OldFileUpload } from '../shared/Form/OldFileUpload';
import { TextInput } from '../shared/Form/TextInput';
import { ILinkForm, linkFormSchema } from './types';

interface ILinkFormProps {
  item?: ILinkOrderEntity;
  onSubmit: (values: ILinkForm) => void;
}

const LinkForm = ({ item, onSubmit }: ILinkFormProps): JSX.Element => {
  const initialFormState: ILinkForm = {
    name: item?.name ?? '',
    url: item?.originalUrl ?? '',
    domain: item?.domain ?? '',
    numberList: item?.displayFileName,
  };

  const isReadOnly: boolean = !!item;

  return (
    <>
      <Formik
        initialValues={initialFormState}
        validationSchema={linkFormSchema}
        onSubmit={(values) => {
          onSubmit && onSubmit(values);
        }}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue, setFieldTouched }) => (
          <>
            <form id="link-form" onSubmit={handleSubmit}>
              <div className="flex flex-col space-y-4">
                <TextInput
                  id="name"
                  name="name"
                  label="Order Name"
                  value={values.name}
                  error={touched.name ? errors.name : ''}
                  disabled={isReadOnly}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />

                <DomainsDropdown
                  value={values.domain ?? ''}
                  onChange={(selectedValue) => {
                    setFieldValue('domain', selectedValue?.value);
                  }}
                  onBlur={() => {
                    setFieldTouched('domain');
                  }}
                  disabled={isReadOnly}
                  showError={touched.domain}
                  errorMessage={errors.domain}
                />

                <TextInput
                  id="url"
                  name="url"
                  label="URL"
                  value={values.url}
                  error={touched.url ? errors.url : ''}
                  disabled={isReadOnly}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />

                <OldFileUpload
                  id="numberList"
                  name="numberList"
                  label="Phone Number List"
                  accepts=".csv"
                  value={isReadOnly ? { name: values.numberList } : values.numberList}
                  error={touched.numberList && (errors.numberList as any)}
                  onBlur={handleBlur}
                  placeholder={'Select a .csv'}
                  disabled={isReadOnly}
                  onChange={(fileUploadEvent: any) => {
                    const file = fileUploadEvent.target.files[0];
                    setFieldTouched('numberList');
                    setFieldValue('numberList', file);
                    if (!values.name) {
                      const displayName = file.name.split('.csv')[0].replace(/_/g, ' ');
                      // If no name has been provided, default it to the filename
                      setFieldValue('name', `${displayName.charAt(0).toUpperCase()}${displayName.slice(1)}`);
                    }
                  }}
                />

                {!isReadOnly && (
                  <p className="text-sm text-gray-500">
                    Each row must have a number. Download the template{' '}
                    <Link
                      className="font-semibold text-sky-500 hover:underline"
                      to="/sample_upload_numbers.csv"
                      target="_blank"
                      download
                    >
                      here
                    </Link>
                  </p>
                )}
              </div>
            </form>
          </>
        )}
      </Formik>
    </>
  );
};

export default LinkForm;
