import { EntityName } from '@gr/shared/enums';
import {
  FilterDataTypeEnum,
  FilterOperatorEnum,
  ISearchRequest,
  SearchSortDirectionEnum,
} from '@gr/shared/models';
import { IColumn, IColumnClickConfig } from '../shared/Table/types';

export const defaultClientTableOptions: ISearchRequest = {
  entity: EntityName.ALL_CLIENTS_VIEW,
  filters: [],
  pagination: {
    skip: 0,
    take: 25,
  },
  sort: {
    fieldName: 'createdAt',
    sortDirection: SearchSortDirectionEnum.DESC,
  },
};

export const noAllClientsClientTableOptions: ISearchRequest = {
  entity: EntityName.ALL_CLIENTS_VIEW,
  filters: [
    {
      dataType: FilterDataTypeEnum.STRING,
      fieldName: 'id',
      operator: FilterOperatorEnum.NOT_EQUALS,
      value: '11111111-1111-1111-1111-111111111111'
    }
  ],
  pagination: {
    skip: 0,
    take: 25,
  },
  sort: {
    fieldName: 'createdAt',
    sortDirection: SearchSortDirectionEnum.DESC,
  },
};

export const allEnabledClientsSearchOptions: ISearchRequest = {
  entity: EntityName.ALL_CLIENTS_VIEW,
  filters: [
    {
      dataType: FilterDataTypeEnum.BOOLEAN,
      fieldName: 'isActive',
      operator: FilterOperatorEnum.EQUALS,
      value: true,
    },
  ],
  pagination: {
    skip: 0,
    take: 10,
  },
  sort: {
    fieldName: 'name',
    sortDirection: SearchSortDirectionEnum.ASC,
  },
};

export const getColumns = (columnClickEvents: IColumnClickConfig, showColumn: boolean): IColumn[] => {
  const columns = [
    {
      headerName: 'Name',
      fieldName: 'name',
      isRowHeader: true,
      fieldType: FilterDataTypeEnum.STRING,
      onColumnClick: columnClickEvents['name'],
    },
    {
      headerName: 'Status',
      fieldName: 'isActive',
      fieldType: FilterDataTypeEnum.BOOLEAN,
    },
    {
      headerName: 'Active Carrier Exclusion',
      fieldName: 'hasExclusion',
      fieldType: FilterDataTypeEnum.BOOLEAN,
    },
    {
      headerName: 'Created Date',
      fieldName: 'createdAt',
      fieldType: FilterDataTypeEnum.DATE,
    },
  ];

  if (showColumn) {
    columns.splice(2, 0, {
      headerName: 'Provider Updated',
      fieldName: 'providerUpdatedAt',
      fieldType: FilterDataTypeEnum.DATE,
    });
  }

  return columns;
};

export const exclusionBreakdownColumns: IColumn[] = [
  {
    headerName: 'Carrier',
    fieldName: 'carrierName',
    fieldType: FilterDataTypeEnum.STRING,
    isRowHeader: true,
  },
  {
    headerName: 'Provider',
    fieldName: 'providerName',
    fieldType: FilterDataTypeEnum.STRING,
  },
  {
    headerName: 'Campaign Type',
    fieldName: 'campaignType',
    fieldType: FilterDataTypeEnum.ENUM,
  },
  {
    headerName: 'Message Type',
    fieldName: 'messageType',
    fieldType: FilterDataTypeEnum.ENUM,
  },
];
