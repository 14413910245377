import { IHttpSearchResponse, ISearchRequest, ITCRCampaignDetails } from '@gr/shared/models';
import useAxios, { UseAxiosResult } from 'axios-hooks';

export default function useTCRCampaigns(
  searchOptions: ISearchRequest
): UseAxiosResult<IHttpSearchResponse<ITCRCampaignDetails>> {
  return useAxios<IHttpSearchResponse<ITCRCampaignDetails>>({
    url: 'entities-search',
    method: 'POST',
    data: searchOptions,
  });
}
