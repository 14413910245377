import { useAuth0 } from '@auth0/auth0-react';
import { IHttpResponse, IOptoutsUndoUploadRequest } from '@gr/shared/models';
import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import axios from 'axios';
import { Fragment, useState } from 'react';
import { axiosPost } from '../../authAxios';
import { useNotifications } from '../../contexts/NotificationContext';
import { Button } from '../shared/Buttons/Button';
import { ButtonVariantEnum } from '../shared/Buttons/types';
import UploadOptinForm, { IOptinUploadForm } from './UploadOptinForm';

interface IUploadOptinModalProps {
  show: boolean;
  setShow: any;
}

const UploadOptinModal = ({ show, setShow }: IUploadOptinModalProps) => {
  const { addNotification } = useNotifications();
  const [uploadingFile, setUploadingFile] = useState(false);
  const { user } = useAuth0();
  const [phoneErrorMsg, setPhoneErrorMsg] = useState();

  const uploadOptins = async (formData: IOptinUploadForm) => {
    try {
      setUploadingFile(true);
      if (formData.optinList) {
        if (!user?.email) {
          throw new Error('User email needs to be set');
        }
        await uploadFile(formData, user?.email, setShow, addNotification);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setUploadingFile(false);
    }
  };

  function onClose() {
    setShow(false);
    setPhoneErrorMsg(undefined);
  }

  return (
    <Transition appear show={show} as={Fragment}>
      <Dialog as="div" className="fixed inset-0 z-10 overflow-y-auto" onClose={onClose}>
        <div className="min-h-screen px-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          {/* <span
                className="inline-block h-screen align-middle"
                aria-hidden="true"
              >
                &#8203;
              </span> */}
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block w-full max-w-md p-6 my-8 text-left align-middle transition-all transform bg-white shadow-xl dark:bg-slate-700 rounded-2xl">
              <Button
                className="absolute top-0 right-0 rounded-tr-2xl hover:bg-gray-100 dark:hover:bg-slate-800"
                variant={ButtonVariantEnum.TEXT_DEFAULT}
                leadingIcon={<XIcon className="w-5 h-5 text-gray-300" />}
                onClick={onClose}
              />

              <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900 dark:text-white">
                <span id="upload-optins-modal-title">Upload Opt-in(s)</span>
              </Dialog.Title>
              <div className="mt-2">
                <UploadOptinForm onSubmit={uploadOptins} loading={uploadingFile} phoneError={phoneErrorMsg} />
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

async function uploadFile(
  formData: IOptinUploadForm,
  email: string,
  setShow: any,
  addNotification: Function
): Promise<void> {
  const request: IOptoutsUndoUploadRequest = {
    clientId: formData?.client?.value ?? '',
    email,
    fileName: formData.optinList?.name ?? '',
  };
  // Grab s3 signed url
  const {
    data: { data: uploadUrl },
  } = await axiosPost<IHttpResponse<string>>('/optouts-optin-url', request);

  // Upload file using default axios to avoid auth token
  await axios.put(uploadUrl, formData.optinList, {
    headers: { 'Content-Type': 'text/csv' },
  });

  setShow(false);

  addNotification({
    header: 'Opt-in list uploaded successfully!',
    content: `You will receive an email once the opt-ins have been added to the system.`,
  });
}

export default UploadOptinModal;
