import { Icon } from './Icon';

interface ICloseProps {
  color?: string;
  big?: boolean;
}

export const Close = ({ color, big }: ICloseProps) => (
  <Icon nav>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={big ? '16' : '9'}
      height={big ? '16' : '9'}
      viewBox="0 0 9 9"
      fill="none"
    >
      <path
        d="M1.42105 9L4.4974 5.91845L7.57895 9L9 7.57895L5.91845 4.4974L9 1.42105L7.57895 0L4.4974 3.08155L1.42105 0L0 1.42105L3.08155 4.4974L0 7.57895L1.42105 9Z"
        fill={color ?? '#F5F7F9'}
      />
    </svg>
  </Icon>
);
