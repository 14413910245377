import { CampaignTypeEnum, EntityName, MessageTypeEnum } from '@gr/shared/enums';
import { DropdownEntityType, FilterDataTypeEnum, FilterOperatorEnum, ISearchRequest, ITCRCampaignDetails, SearchSortDirectionEnum } from '@gr/shared/models';
import * as yup from 'yup';
import { campaignTypeDropdownValues, messageTypeDropdownValues } from '../SystemNumbers/types';
import { IDropdownValue } from '../shared/Form/Dropdown';
import { dropdownSchema } from '../shared/Schema/yup';
import { IFilterDropdownOptions } from '../shared/Table/FilterForm';
import { IColumn, IColumnClickConfig } from '../shared/Table/types';

export const defaultTCRCampaignTableOptions: ISearchRequest = {
  entity: EntityName.ALL_TCR_CAMPAIGNS_VIEW,
  filters: [],
  pagination: {
    skip: 0,
    take: 25,
  },
  sort: {
    fieldName: 'createdAt',
    sortDirection: SearchSortDirectionEnum.DESC,
  },
};

export function tcrCampaignSystemNumberTableOptions(tcrCampaignId: string): ISearchRequest {
  return {
    entity: EntityName.ALL_SYSTEM_NUMBERS_VIEW,
    filters: [
      {
        dataType: FilterDataTypeEnum.STRING,
        fieldName: 'tcrCampaignId',
        operator: FilterOperatorEnum.EQUALS,
        value: tcrCampaignId,
      },
    ],
    pagination: {
      skip: 0,
      take: 25,
    },
    sort: {
      fieldName: 'createdAt',
      sortDirection: SearchSortDirectionEnum.DESC,
    },
  };
};

export function defaultSystemNumberDropdownOptions(selectedItem: ITCRCampaignDetails | undefined): ISearchRequest {
  return {
    entity: EntityName.LOCAL_SYSTEM_NUMBERS,
    filters: [
      {
        dataType: FilterDataTypeEnum.STRING,
        fieldName: 'tcrCampaignId',
        operator: FilterOperatorEnum.EQUALS,
        value: selectedItem?.id,
      },
      {
        dataType: FilterDataTypeEnum.BOOLEAN,
        fieldName: 'isActive',
        operator: FilterOperatorEnum.EQUALS,
        value: true,
      },
    ],
    pagination: {
      skip: 0,
      take: 50,
    },
    sort: {
      fieldName: 'createdAt',
      sortDirection: SearchSortDirectionEnum.DESC,
    },
  };
};

export const getColumns = (columnClickEvents: IColumnClickConfig): IColumn[] => {
  return [
    {
      fieldName: 'tcrCampaignId',
      fieldType: FilterDataTypeEnum.STRING,
      headerName: 'TCR Id',
      isRowHeader: true,
      onColumnClick: columnClickEvents['tcrCampaignId'],
    },
    {
      fieldName: 'description',
      fieldType: FilterDataTypeEnum.STRING,
      headerName: 'Description',
    },
    {
      fieldName: 'clientName',
      fieldType: FilterDataTypeEnum.TYPEAHEAD,
      dropdownType: DropdownEntityType.CLIENT,
      headerName: 'Client',
    },
    {
      fieldName: 'isActive',
      fieldType: FilterDataTypeEnum.BOOLEAN,
      headerName: 'Enabled',
    },
    {
      fieldName: 'providerName',
      fieldType: FilterDataTypeEnum.STRING,
      headerName: 'Provider',
    },
    {
      fieldName: 'campaignType',
      fieldType: FilterDataTypeEnum.ENUM,
      headerName: 'Campaign Type',
    },
    {
      fieldName: 'messageType',
      fieldType: FilterDataTypeEnum.ENUM,
      headerName: 'Message Type',
    },
    {
      fieldName: 'activeNumbers',
      fieldType: FilterDataTypeEnum.NUMBER,
      headerName: 'Active Number Count',
    },
  ];
};

export interface ITCRCampaignForm {
  client: IDropdownValue;
  providerId: string;
  campaignType: CampaignTypeEnum;
  messageType: MessageTypeEnum;
}

export const tcrCampaignFormSchema: yup.SchemaOf<ITCRCampaignForm> = yup.object().shape({
  client: dropdownSchema,
  providerId: yup.string().defined('Required'),
  campaignType: yup.mixed<CampaignTypeEnum>().defined('Required'),
  messageType: yup.mixed<MessageTypeEnum>().defined('Required'),
});

export const tcrCampaignDropdownOptions: IFilterDropdownOptions = {
  clientName: [],
  providerName: [],
  messageType: messageTypeDropdownValues,
  campaignType: campaignTypeDropdownValues,
};
