import { User } from '@auth0/auth0-react';
import { getUserMemberships } from '@gr/portal/providers/auth0.service';
import { Auth0OrganizationName, Auth0SubDomain, Auth0UserMembership } from '@gr/shared/models';
import { getSubDomainByOrg, getSubDomainLabel, } from '@gr/shared/utils/auth0-sub-domain.service';
import { orderBy } from 'lodash';
import { Dropdown, IDropdownValue } from '../Dropdown';

interface ISubDomainDropdownProps {
  value: IDropdownValue | undefined;
  onChange: (newValue?: IDropdownValue | undefined) => void;
  onBlur?: () => void;
  disabled?: boolean;
  errorMessage?: string | undefined;
  showError?: boolean;
  auth0User: User | undefined;
}

const SubDomainDropdown = ({
  value,
  onChange,
  onBlur,
  disabled,
  errorMessage,
  auth0User,
}: ISubDomainDropdownProps): JSX.Element => {
  const memberships = getUserMemberships(auth0User);

  const options = getAllowedSubDomains(memberships)
    ?.filter(domain => !!domain)
    ?.map(domain => domain ? getOption(domain) : undefined)
    ?.filter(option => option?.value !== value?.value)
    ?.filter(option => !!option?.label)
    ?? [];

  const orderedOptions = orderBy(options, 'label') as unknown as IDropdownValue[];

  return (
    <Dropdown
      label="Organization"
      value={value}
      options={orderedOptions}
      onChange={onChange}
      onBlur={onBlur}
      error={errorMessage}
      disabled={disabled}
      full
      labelColor="black"
      className="px-3 pb-2"
    />
  );
};

function getOption(domain: Auth0SubDomain): IDropdownValue {
  return {
    label: getSubDomainLabel(domain),
    value: domain,
  };
}

function getAllowedSubDomains(memberships: Auth0UserMembership[]) {
  const organizations = Object.values(Auth0OrganizationName);

  return memberships
    ?.filter(membership => organizations.includes(membership.organization))
    ?.flatMap(membership => getSubDomainByOrg(membership.organization))
    ?.filter(subDomain => !!subDomain)
    ?? [];
}

export default SubDomainDropdown;
