import { Autocomplete } from '@Wonder-Cave/ui';
import { IDropdownValue } from '@Wonder-Cave/ui/dist/Autocomplete';
import useClickerGroupsManual from '@gr/portal/hooks/useClickerGroupsManual';
import { orderStringAscending } from '@gr/portal/providers/utility.provider';
import { useEffect } from 'react';

interface IAssignedAgentsDropdownProps {
  label?: string | undefined;
  value: string | undefined;
  options?: IDropdownValue[];
  onChange: (newValue?: IDropdownValue | undefined) => void;
  onBlur?: () => void;
  allClients?: boolean;
  disabled?: boolean;
  errorMessage?: string | undefined;
  showError: boolean;
  gridFilter?: boolean;
  defaultGroupName?: string;
  includeNone?: boolean;
  shimmer?: boolean;
}

const NewAssignedAgentsDropdown = ({
  label,
  value,
  options,
  onChange,
  onBlur,
  disabled,
  errorMessage,
  showError,
  defaultGroupName,
  includeNone,
  shimmer
}: IAssignedAgentsDropdownProps): JSX.Element => {
  const [{ data: clickerGroups, loading: clickerDataLoading, error: clickerDataError }, getClickerGroups] =
    useClickerGroupsManual();
  useEffect(() => {
    if (!options) {
      const getCG = async () => {
        try {
          await getClickerGroups();
        } catch (err) { }
      };
      getCG();
    }
  }, []);
  const clickerGroupsDropdown: IDropdownValue[] =
    clickerGroups?.data
      .map((group) => ({
        label: group.name,
        value: group.id,
      }))
      .sort(orderStringAscending('label')) ?? [];

  includeNone && clickerGroupsDropdown.push({ label: 'None', value: '' });

  const getValue = () => {
    const foundGroup = (options ?? clickerGroupsDropdown).find((cgd) => cgd.value === value);
    const defaultGroup = (options ?? clickerGroupsDropdown).find((cgd) => cgd.label === defaultGroupName);
    return foundGroup ?? defaultGroup;
  };

  return (
    <Autocomplete
      label={label ?? 'Assigned Agents'}
      placeholder="Select assigned agents"
      loading={clickerDataLoading}
      value={getValue()}
      options={options ?? clickerGroupsDropdown}
      onChange={onChange}
      onBlur={onBlur}
      error={errorMessage}
      disabled={disabled}
      showError={showError}
      shimmer={shimmer}
    />
  );
};

export default NewAssignedAgentsDropdown;
