import {
  ITemplateCategory,
  ITemplateResponse,
  ITwoWayConversationEntity,
} from '@gr/shared/models';
import { useEffect, useState } from 'react';
import { axiosPost } from '../../authAxios';
import { Radio as RadioIcon } from '../shared/Icons/Radio';
import LoadingIndicator from '../shared/LoadingIndicator';

interface IResponsesProps {
  activeMessage: ITwoWayConversationEntity | undefined;
  activeResponse: Partial<ITemplateResponse> | undefined;
  setActiveResponse: (response) => void;
}

export const Responses = ({ activeMessage, activeResponse, setActiveResponse }: IResponsesProps) => {
  const [activeCategory, setActiveCategory] = useState<ITemplateCategory>();
  const [loading, setLoading] = useState(false);
  const [responses, setResponses] = useState<ITemplateResponse[]>();
  const [categories, setCategories] = useState<ITemplateCategory[]>();
  const getResponses = async () => {
    setLoading(true);
    try {
      const cRes = await axiosPost('/two-way-convos-template-categories-get', {
        clientId: activeMessage?.clientId,
      });
      const rRes = await axiosPost('/two-way-convos-template-responses-get', {
        clientId: activeMessage?.clientId,
      });
      setCategories(cRes?.data?.data);
      setResponses(rRes?.data?.data);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    setActiveCategory(undefined);
  }, [activeMessage?.id]);
  useEffect(() => {
    if (activeMessage?.clientId) {
      getResponses();
    } else {
      setCategories(undefined);
      setResponses(undefined);
    }
  }, [activeMessage?.clientId]);
  return (
    <>
      <h2 className="mx-auto mt-16">Responses</h2>
      <div className="mx-auto mt-4 body-text">Respond to contacts using the templates below:</div>
      <div className="flex flex-col justify-between w-full max-w-xs mt-8 overflow-hidden">
        {!!activeCategory ? (
          <>
            <h5>1. Select Response:</h5>
            {!loading ? (
              <div className="px-2 mt-8 mb-4 space-y-4 overflow-auto">
                {responses
                  ?.filter((r) => r.categoryId === activeCategory.id)
                  .map((response, index) => {
                    return (
                      <div
                        key={index}
                        className={`flex cursor-pointer input ${activeResponse?.id === response.id ? 'body-text-bold text-black' : 'body-text text-dark-gray'
                          }`}
                        style={{ paddingRight: '1.5rem', height: 'auto' }}
                        onClick={() => setActiveResponse(response)}
                      >
                        <div>{response.message}</div>
                        {activeResponse?.id === response.id && (
                          <div className="ml-auto">
                            <RadioIcon />
                          </div>
                        )}
                      </div>
                    );
                  })}
              </div>
            ) : (
              <div className="flex items-center justify-center my-4">
                <LoadingIndicator />
              </div>
            )}
            <div className="flex m-auto mb-4 link-text w-fit" onClick={() => setActiveCategory(undefined)}>
              Back To Categories
            </div>
          </>
        ) : (
          <>
            <h5>1. Select Category:</h5>
            {!loading ? (
              <div className="px-2 mt-8 mb-4 space-y-4 overflow-auto">
                {categories?.map((cat, index) => {
                  return (
                    <div
                      key={index}
                      className={`flex cursor-pointer input ${activeResponse?.categoryId === cat.id ? 'body-text-bold text-black' : 'body-text text-dark-gray'
                        }`}
                      style={{ paddingRight: '1.5rem', height: 'auto' }}
                      onClick={() => setActiveCategory(cat)}
                    >
                      <div>{cat.name}</div>
                      {activeResponse?.categoryId === cat.id && (
                        <div className="ml-auto">
                          <RadioIcon />
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            ) : (
              <div className="flex items-center justify-center my-4">
                <LoadingIndicator />
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};
