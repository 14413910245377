import ReactPlayer from 'react-player';

interface IVideoPlayerProps {
  mediaUrl: string;
}
const videoPlayerStyles = {
  background: 'lightgray',
  'border-radius': '1rem',
  'overflow': 'hidden',
};

const VideoPlayer = ({ mediaUrl }: IVideoPlayerProps) => {
  return (
    <ReactPlayer light controls url={mediaUrl} width='100%' height='100%' style={videoPlayerStyles} />
  );
};

export default VideoPlayer;