export const isFileMediaType = (file: File): boolean => {
  return file ? file.type.startsWith('image') || file.type.startsWith('video') : false;
};

export const isFileTollFreeSize = (file: File): boolean => {
  return file ? Math.ceil(file.size / 1024) <= 524 : false;
};

export const isFileLocalSize = (file: File): boolean => {
  return file ? Math.ceil(file.size / 1024) <= 850 : false;
};

export const getCsvHeaders = (file: File): Promise<string[]> => {
  return new Promise((resolve, reject) => {
    if (file && file.name.endsWith('.csv')) {
      const reader = new FileReader();

      reader.onload = (event: ProgressEvent<FileReader>) => {
        const buffer = event?.target?.result;

        const encoder = new TextDecoder('utf-8');

        const csvContent = encoder.decode(buffer as ArrayBuffer);

        const headers = splitCsvRows(csvContent)[0]
          .split(',')
          .map((header) => {
            return header.replace(/[\r\n]/g, '');
          });

        resolve(headers);
      };

      reader.onerror = () => {
        reader.abort();
        reject(new DOMException('Error when attempting to parse the file'));
      };

      reader.readAsArrayBuffer(file.slice(0, 64));
    }
  });
};

export const getCsvSample = async (file: File): Promise<string[][]> => {
  const rows: string[][] = [];

  if (file && file.name.endsWith('.csv')) {
    const encoder = new TextDecoder('utf-8');

    const reader = file.stream().getReader();

    while (rows.length < 2) {
      const chunk = await reader.read();
      if (chunk.value) {
        const csvContent = encoder.decode(chunk.value as ArrayBuffer);
        const newRows = splitCsvRows(csvContent)
          .map(row => row
            .split(',')
            .map(cell => cell.replace(/[\r\n]/g, '')
            ));

        newRows.forEach(r => rows.push(r));
      } else {
        break;
      }
    }
  }

  return rows;
};

export function splitCsvRows(content: string): string[] {
  // Because of MACs, we can't have nice things. This function detects really old, MAC new line characters
  // https://stackoverflow.com/a/39259747

  const cr = (content.match(/\r/g) ?? []).length;
  const lf = (content.match(/\n/g) ?? []).length;

  // Note: in the file that prompted this, new lines were represented using "\r"...except for the final one which had both characters *facepalm*
  const crLfRatio = cr / lf;
  return lf === 0 || crLfRatio > 1 ? content.split('\r') : content.split('\n');
}
