import {
  Button,
  ButtonVariantEnum,
  Checkbox,
  Chip,
  Info,
  Shimmer,
  ShimmerType,
  Static,
  StaticTableList,
} from '@Wonder-Cave/ui';
import { useCache } from '@gr/portal/contexts/CacheContext';
import { CampaignStatusEnum, MessageTypeEnum } from '@gr/shared/enums';
import {
  Auth0Role,
  ExternalV1CampaignDetailsResponse,
  ExternalV1ClickerGroupDetailsResponse,
  ExternalV1ContactListDetailsResponse,
  ICampaignErrorCodesRequest,
  ICampaignErrorCodesResponse,
  IExportConversationsRequest,
  IExportLinkClicksRequest,
  IExportOptoutsRequest,
  IGetTestMessageResponse,
  IHttpResponse,
  ITestMessageRequest
} from '@gr/shared/models';
import useAxios from 'axios-hooks';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { axiosGet, axiosPatch, axiosPost } from '../../authAxios';
import { useNotifications } from '../../contexts/NotificationContext';
import useRoles from '../../hooks/useRoles';
import { convertEnumToReadableString } from '../../providers/utility.provider';
import { Exclamation } from '../shared/Icons/Exclamation';
import { Radio } from '../shared/Icons/Radio';
import CampaignMessagePreiveiw from './CampaignMessagePreview';
import CampaignPauseResumeModal from './CampaignPauseResumeModal';
import CampaignStatus from './Columns/CampaignStatus';
import { getErrorColumns } from './types';

interface IMetricCardProps {
  title: string;
  metric: number;
  loading: boolean;
  footer?: React.ReactNode;
  _export?: boolean;
  onExport?: () => void;
  exportLoading?: boolean;
  flat?: boolean;
  disabled?: boolean;
}

const MetricCard = ({ title, loading, metric, footer, _export, onExport, flat, exportLoading, disabled = false }: IMetricCardProps) => {
  return (
    <div
      className={`${flat ? '' : 'rounded-2.5xl'} bg-white flex flex-col items-center p-4 space-y-4 ${flat ? '' : 'w-44'
        }`}
      style={{ boxShadow: flat ? '' : '0px 0px 70px 0px #E9EBF0' }}
    >
      <h5 className="text-medium-gray">{title}</h5>
      {loading ? (
        <Shimmer type={ShimmerType.CUSTOM} className="w-16 h-4" />
      ) : (
        <div>
          {isFinite(metric) ? (flat ? metric : metric.toFixed(1)) : 0}
          {flat ? '' : '%'}
        </div>
      )}
      {!!onExport ? (
        <h4
          onClick={_export && !exportLoading && !disabled ? onExport : () => { }}
          className={`${(_export && !exportLoading && !disabled)
            ? 'cursor-pointer text-wc-blue decoration-wc-blue decoration-2 hover:underline'
            : `cursor-default text-medium-gray ${exportLoading ? 'animate-pulse' : ''}`
            }`}
        >
          <div className='flex items-center'>
            Export Data
            {disabled && <Info className="ml-1" text={`Unable to export ${title} data as it has been more than 30 days since campaign start date.`} />}
          </div>
        </h4>
      ) : (
        footer
      )}
    </div>
  );
};

export const CampaignDetails = () => {
  const { id } = useParams<any>();
  const history = useHistory();
  const [campaign, setCampaign] = useState<ExternalV1CampaignDetailsResponse>();
  const [showModal, setShowModal] = useState(false);
  const [pauseLoading, setPauseLoading] = useState(false);
  const [suppressions, setSuppressions] = useState<Array<any>>([]);
  const [showMetrics, setShowMetrics] = useState(false);
  const [contactLists, setContactLists] = useState<ExternalV1ContactListDetailsResponse[]>();
  const [clickerGroup, setClickerGroup] = useState<ExternalV1ClickerGroupDetailsResponse>();
  const [clickRateLoading, setClickRateLoading] = useState(false);
  const [optoutRateLoading, setOptoutRateLoading] = useState(false);
  const [responseLoading, setResponseLoading] = useState(false);
  const [repliesOptoutsLoading, setRepliesOptoutsLoading] = useState(false);
  const [domain, setDomain] = useState<string>();
  const { addNotification } = useNotifications();
  const { sendingWindow } = useCache();
  const isSuperAdmin = useRoles([Auth0Role.GR_ADMIN]);
  const isActive = [CampaignStatusEnum.LIVE, CampaignStatusEnum.SCHEDULING].includes(
    campaign?.status ?? CampaignStatusEnum.COMPLETED
  );

  const [showId, setShowId] = useState<boolean>(false);

  const onNameDoubleClick = () => {
    setShowId(!showId);

    try {
      const message = [
        campaign?.id ? `ID: ${campaign?.id}` : undefined,
      ]?.filter(v => !!v)?.join('\n') ?? 'N/A';

      navigator?.clipboard?.writeText(message);
    } catch (error) {
      console.error(error);
    }
  };

  const handleExportOptouts = async (campaign?: ExternalV1CampaignDetailsResponse) => {
    setOptoutRateLoading(true);
    try {
      if (!campaign) {
        return;
      }

      const request: IExportOptoutsRequest = {
        campaignId: campaign?.id,
        campaignName: campaign?.name,
      };
      await axiosPost<IHttpResponse<string>>('/optouts-exports', request);

      addNotification({
        header: 'An email has been sent with the exported optouts.',
      });
    } catch (err) {
      console.error(err);
    } finally {
      setOptoutRateLoading(false);
    }
  };

  const handleExportLinkClicks = async (id, name, startsAt) => {
    setClickRateLoading(true);
    try {
      const request: IExportLinkClicksRequest = {
        campaignId: id,
        campaignName: name,
        campaignStartDate: startsAt
      };
      await axiosPost<IHttpResponse<string>>('/link-clicks-export', request);

      addNotification({
        header: 'An email has been sent with the exported link clicks.',
      });
    } catch (err) {
      console.error(err);
    } finally {
      setClickRateLoading(false);
    }
  };

  const handleExportConversations = async (id, name, excludeOptouts: boolean) => {
    excludeOptouts ? setRepliesOptoutsLoading(true) : setResponseLoading(true);
    try {
      const request: IExportConversationsRequest = {
        campaignId: id,
        campaignName: name,
        excludeOptouts,
      };
      await axiosPost<IHttpResponse<string>>('/conversations-export', request);

      addNotification({
        header: 'An email has been sent with the exported replies.',
      });
    } catch (err) {
      console.error(err);
    } finally {
      excludeOptouts ? setRepliesOptoutsLoading(false) : setResponseLoading(false);
    }
  };
  const [{ data: campaignErrorData, loading: campaignErrorLoading, error: campaignErrorError }] = useAxios<
    IHttpResponse<ICampaignErrorCodesResponse>
  >({
    url: 'campaigns-get-errors',
    method: 'POST',
    data: { campaignId: id } as ICampaignErrorCodesRequest,
  });

  const [{ data: campaignHealthCheckData, loading: campaignHealthCheckLoading, error: campaignHealthCheckError }] =
    useAxios<IHttpResponse<IGetTestMessageResponse>>({
      url: 'messages-get-test-messages',
      method: 'POST',
      data: { campaignId: id } as ITestMessageRequest,
    });

  useEffect(() => {
    if (id) {
      getCampaign(id);
    }
  }, [id]);

  const getCampaign = async (id: string) => {
    const {
      data: { data: campaign },
    } = await axiosGet<IHttpResponse<ExternalV1CampaignDetailsResponse>>(`/v1/campaigns/${id}`);
    const contactListsResponses = await Promise.all(
      campaign.contactListIds.map((id) =>
        axiosGet<IHttpResponse<ExternalV1ContactListDetailsResponse>>(`/v1/contact-lists/${id}`)
      )
    );
    const suppressionListResponses = await Promise.all(
      campaign.suppressionListIds.map((id) =>
        axiosGet<IHttpResponse<ExternalV1ContactListDetailsResponse>>(`/v1/contact-lists/${id}`)
      )
    );
    if (campaign?.clickerGroupId) {
      try {
        const clickerGroupResponse = await axiosGet(`/v1/clicker-groups/${campaign?.clickerGroupId}`);
        if (clickerGroupResponse.status === 200) {
          setClickerGroup(clickerGroupResponse?.data?.data);
        }
      } catch (e) {
        console.error(e);
      }
    }
    setContactLists(contactListsResponses.map((clr) => clr?.data?.data));
    setCampaign({
      ...campaign,
      startsAt: new Date(campaign.startsAt as any),
      endsAt: new Date(campaign.endsAt as any),
    });
    const _suppressions: Array<any> = [];
    campaign?.suppressionAreaCodes?.sort();
    let maxLength = Math.max(
      suppressionListResponses?.length ?? 0,
      campaign?.suppressionAreaCodes?.length ?? 0,
      campaign?.suppressionStates?.length ?? 0
    );
    for (let i = 0; i < maxLength; i++) {
      const s = {
        sList: suppressionListResponses[i]?.data?.data?.name ?? '-',
        state: campaign?.suppressionStates[i] ?? '-',
        areaCode: campaign?.suppressionAreaCodes[i] ?? '-',
      };
      _suppressions.push(s);
    }
    setSuppressions(_suppressions);
    if (campaign?.domainId) {
      try {
        const domainResponse = await axiosGet(`/v1/domains/${campaign?.domainId}`);
        if (domainResponse.status === 200) {
          setDomain(domainResponse.data?.data?.url);
        }
      } catch (e) {
        console.error(e);
      }
    }
  };

  const handleCampaignPause = async (): Promise<void> => {
    setPauseLoading(true);

    try {
      // TODO: Use hook for loading indicator
      await axiosPatch(`v1/campaigns/${id}`, {
        status: isActive ? CampaignStatusEnum.PAUSED : CampaignStatusEnum.LIVE
      });

      await getCampaign(id);

      addNotification({
        header: !isActive ? 'Campaign resumed successfully' : 'Campaign paused successfully',
      });
    } catch (err) {
      console.error(err);
    } finally {
      setPauseLoading(false);
    }
  };

  const formatPhoneNumber = (phoneNumberString: string) => {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      var intlCode = match[1] ? '+1 ' : '';
      return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
    }
    return phoneNumberString;
  };

  return (
    <>
      <div className="px-20">
        <h1 className="flex items-center text-black basis-full">
          <div className='flex flex-row items-center justify-center'>
            <div onDoubleClick={() => onNameDoubleClick()}>{campaign?.name}</div>
            {showId && campaign?.id && <div className='pl-2 text-sm'>{campaign.id}</div>}
          </div>
          <Button className="ml-auto" onClick={() => history.goBack()} variant={ButtonVariantEnum.SECONDARY}>
            BACK
          </Button>
        </h1>
        <h3 className="mt-2 font-semibold text-wc-blue">
          {campaign?.messagesSent?.toLocaleString() ?? '-'} messages sent at{' '}
          {moment(campaign?.updatedAt).format('L, LT')}
        </h3>
      </div>
      <div className="flex items-center px-20 pt-8 space-x-4">
        <h4 className="text-dark-gray">View:</h4>
        <Chip active={!showMetrics} onClick={() => setShowMetrics(false)}>
          Campaign Details
        </Chip>
        <Chip active={showMetrics} onClick={() => setShowMetrics(true)}>
          Performance Metrics
        </Chip>
      </div>
      <div className="flex px-20 pb-4 mt-8">
        <div className="basis-2/3">
          {!showMetrics ? (
            <div className="space-y-8">
              {/* HEADER */}
              <div className="flex">
                <h2>Details</h2>
              </div>
              {/* BASIC INFO */}
              <div className="flex details-row">
                <Static shimmer={10} title="CLIENT">
                  {campaign?.clientName}
                </Static>
                <Static shimmer={10} title="CAMPAIGN">
                  {campaign?.type ? convertEnumToReadableString(campaign?.type) : ''}
                </Static>
                <Static shimmer={10} title="STATUS">
                  {campaign?.status ? (
                    <CampaignStatus
                      status={campaign?.status}
                      startsAt={campaign?.startsAt}
                      totalMessagesScheduled={campaign?.messagesScheduled}
                      totalMessagesSent={campaign?.messagesSent}
                      totalMessagesConfirmed={campaign?.messagesConfirmed}
                      sendingWindow={sendingWindow}
                      allListsClassified={campaign?.allListsClassified}
                    />
                  ) : (
                    ''
                  )}
                </Static>
              </div>
              {/* START/END DATE */}
              <div className="flex details-row">
                <Static shimmer={10} title="START DATE">
                  {campaign?.startsAt ? moment(campaign?.startsAt).format('L, LT') : ''}
                </Static>
                <Static shimmer={10} title="END DATE">
                  {campaign?.startsAt ? moment(campaign?.endsAt).format('L, LT') : ''}
                </Static>
              </div>
              <div className="flex flex-wrap">
                <div className="basis-full">
                  <Checkbox
                    id="time-zone-prioritization"
                    label="Time Zone Prioritization"
                    readOnly
                    checked={campaign?.timezonePrioritizationEnabled ?? false}
                  />
                </div>
              </div>
              {/* MESSAGE/NUMBER TYPE */}
              <div className="flex details-row">
                <Static shimmer={10} title="MESSAGE">
                  {campaign?.messageType}
                </Static>
                <Static shimmer={10} title="NUMBER">
                  {campaign?.numberType ? convertEnumToReadableString(campaign.numberType) : ''}
                </Static>
              </div>
              {/* LISTS */}
              <div className="flex details-row">
                <Static shimmer={10} title="CONTACT LISTS">
                  {contactLists && (
                    <ol>
                      {contactLists?.map((cl) => (
                        <li key={cl.id}>{cl.name}</li>
                      ))}
                    </ol>
                  )}
                </Static>
                <Static shimmer={10} title="CLICKER GROUP">
                  {clickerGroup?.name ?? (!!campaign ? '-' : null)}
                </Static>
                <Static shimmer={10} title="EXTERNAL ID">
                  {!!campaign && (campaign?.externalId || '-')}
                </Static>
              </div>
              {/* SUPPRESSIONS */}
              <div className="flex flex-wrap">
                <div className="basis-full">
                  <Checkbox id="Suppression" label="Suppression" readOnly checked={(suppressions?.length ?? 0) > 0} />
                </div>
                {suppressions.length > 0 && (
                  <div className="w-full mt-4 basis-full">
                    <StaticTableList
                      className="w-full"
                      columns={[
                        {
                          headerName: 'SUPPRESSION LIST',
                          fieldName: 'sList',
                        },
                        {
                          headerName: 'STATE',
                          fieldName: 'state',
                        },
                        {
                          headerName: 'AREA CODE',
                          fieldName: 'areaCode',
                        },
                      ]}
                      data={suppressions ?? []}
                    />
                  </div>
                )}
              </div>
              <div>
                <Checkbox
                  id="two-way"
                  label="Enable two way conversations"
                  readOnly
                  checked={campaign?.twoWayEnabled ?? false}
                />
              </div>
              {/* CLICK TRACKING */}
              <div className="flex flex-wrap">
                <div className="basis-1/2">
                  <Checkbox
                    id="click-tracking"
                    label="Enable Click Tracking"
                    readOnly
                    checked={campaign?.clickTrackingEnabled ?? false}
                  />
                </div>
                <div className="basis-1/2">
                  <Checkbox
                    id="unique-links"
                    label="Use Deep Links"
                    readOnly
                    checked={campaign?.generateUniqueLinksEnabled ?? false}
                  />
                </div>
                {campaign?.clickTrackingEnabled && (
                  <>
                    <div className="mt-4 basis-1/2">
                      <Static shimmer={10} title="URL">
                        {campaign?.url}
                      </Static>
                    </div>
                    <div className="mt-4 basis-1/2">
                      <Static shimmer={10} title="DOMAIN">
                        {domain}
                      </Static>
                    </div>
                  </>
                )}
              </div>
            </div>
          ) : (
            <div className="space-y-8">
              {/* HEADER */}
              <div className="flex">
                <h2>Performance Metrics</h2>
              </div>
              {/* METRICS L1 */}
              <div>
                <div className="flex justify-between mb-8">
                  <MetricCard
                    title="DELIVERABILITY RATE"
                    //_export
                    loading={!campaign}
                    metric={((campaign?.messagesDelivered ?? 0) / (campaign?.messagesSent ?? 0)) * 100 || 0}
                  // TODO: Export failed messages
                  />
                  <MetricCard
                    title="CLICK RATE"
                    loading={!campaign}
                    _export={!!campaign}
                    metric={((campaign?.linkClicks ?? 0) / (campaign?.messagesDelivered ?? 0)) * 100 || 0}
                    onExport={() => handleExportLinkClicks(campaign?.id, campaign?.name, campaign?.startsAt)}
                    exportLoading={clickRateLoading}
                    // if the start date of the campaign was 30 or more days ago. disable export data button for click rate metric
                    disabled={campaign && Math.round((new Date().getTime() - new Date(campaign.startsAt).getTime()) / (1000 * 3600 * 24)) > 30}
                  />
                  <MetricCard
                    title="OPT-OUT RATE"
                    loading={!campaign}
                    _export={!!campaign}
                    exportLoading={optoutRateLoading}
                    metric={
                      (Object.values(campaign?.optOuts ?? {}).reduce((x, y) => x + y, 0) /
                        (campaign?.messagesDelivered ?? 0)) *
                      100 || 0
                    }
                    onExport={() => handleExportOptouts(campaign)}
                  />
                </div>
                <div
                  className="rounded-2.5xl bg-white flex items-center p-4 justify-between"
                  style={{ boxShadow: '0px 0px 70px 0px #E9EBF0' }}
                >
                  <h3 className="ml-8">Reply Rate</h3>
                  <MetricCard
                    title="ALL RESPONSES"
                    flat
                    metric={campaign?.replies ?? 0}
                    loading={!campaign}
                    _export={!!campaign}
                    onExport={() => handleExportConversations(campaign?.id, campaign?.name, false)}
                    exportLoading={responseLoading}
                  />
                  <MetricCard
                    title="REPLIES WITHOUT OPT-OUTS"
                    flat
                    _export={!!campaign}
                    onExport={() => handleExportConversations(campaign?.id, campaign?.name, true)}
                    loading={!campaign}
                    metric={(campaign?.replies ?? 0) - (campaign?.optOuts?.REPLY_AUTOMATIC ?? 0) || 0}
                    exportLoading={repliesOptoutsLoading}
                  />
                </div>
              </div>
              {/* MESSAGE STATUS */}
              <div className="flex flex-wrap justify-between pt-12 pb-8 px-16 rounded-2.5xl bg-ice border-2 border-white">
                <h2 className="m-auto mb-12 text-center text-black basis-full">Message Status</h2>
                <div>
                  <div className="flex items-center">
                    {/* Confirmed / Scheduled */}
                    <h5 className="mr-2 text-right text-dark-gray">SCHEDULED</h5>
                    <div
                      className={`flex grow w-36 basis-1/2 h-2 overflow-hidden text-xs rounded ${campaign?.messagesScheduled === 0 ? 'bg-light-gray' : 'bg-mint'
                        }`}
                    >
                      <>
                        <div
                          style={{
                            width: `${Math.min(
                              Math.ceil(
                                ((campaign?.messagesConfirmed ?? 0) / (campaign?.messagesConfirmed ?? 100)) * 100
                              ),
                              100
                            )}%`,
                          }}
                          className={`shadow-none bg-grass`}
                        ></div>
                      </>
                    </div>
                  </div>
                  <h5
                    className="mt-0.5 text-right truncate text-medium-gray dark:text-slate-400 basis-full"
                    title="Scheduled"
                  >
                    {campaign?.messagesConfirmed?.toLocaleString()} / {campaign?.messagesScheduled?.toLocaleString()}
                  </h5>
                </div>
                <div>
                  <div className="flex items-center">
                    {/* Sent / Delivered / Failed */}
                    <h5 className="min-w-[65px] text-right mt-px mr-2 text-dark-gray">DELIVERY</h5>
                    <div
                      className={`flex grow w-36 basis-1/2 h-2 overflow-hidden text-xs rounded justify-end ${campaign?.messagesSent === 0 ? 'bg-light-gray' : 'bg-mint'
                        }`}
                    >
                      <>
                        <div
                          style={{
                            width: `${Math.min(
                              Math.ceil(
                                ((campaign?.messagesDelivered ?? 0) / (campaign?.messagesDelivered ?? 100)) * 100
                              ),
                              100
                            )}%`,
                          }}
                          className={`shadow-none bg-grass`}
                        ></div>
                        <div
                          style={{
                            width: `${Math.min(
                              Math.ceil(((campaign?.messagesFailed ?? 0) / (campaign?.messagesSent ?? 100)) * 100),
                              100
                            )}%`,
                          }}
                          className={`flex flex-col justify-center text-center text-white shadow-none bg-red-400 whitespace-nowrap`}
                        ></div>
                      </>
                    </div>
                  </div>
                  <h5
                    className="mt-0.5 text-right truncate text-medium-gray dark:text-slate-400 basis-full"
                    title="Sent / Delivered / Failed"
                  >
                    {campaign?.messagesSent?.toLocaleString()} / {campaign?.messagesDelivered?.toLocaleString()} /{' '}
                    {campaign?.messagesFailed?.toLocaleString()}
                  </h5>
                </div>
              </div>
              {/* TEST MESSAGE STATUS */}
              {(campaignHealthCheckData?.data?.testMessages?.length ?? 0) > 0 && (
                <div className="flex flex-wrap">
                  <h2 className="flex items-center mr-2">Test Message Status</h2>
                  <div className="mt-1">
                    {campaignHealthCheckData?.data?.testMessages?.filter((chcd) => chcd.errorType)?.length ?? 0 > 0 ? (
                      <Exclamation />
                    ) : (
                      <Radio />
                    )}
                  </div>
                  <StaticTableList
                    className="mt-8 basis-full"
                    columns={[
                      {
                        headerName: 'NUMBER',
                        fieldName: 'toNumber',
                        renderColumn: (row) => (
                          <div className="flex items-center whitespace-nowrap">
                            {formatPhoneNumber(row.toNumber)}
                            {!row.errorCode && (
                              <div className="mx-2">
                                <Radio />
                              </div>
                            )}
                          </div>
                        ),
                      },
                      {
                        headerName: 'CARRIER',
                        fieldName: 'carrierName',
                      },
                      {
                        headerName: 'ERROR CODE',
                        fieldName: 'errorCode',
                      },
                      {
                        headerName: 'ERROR TYPE',
                        fieldName: 'errorType',
                      },
                      ...(isSuperAdmin
                        ? [
                          {
                            headerName: 'PROVDER ERROR CODE',
                            fieldName: 'providerErrorCode',
                          },
                          {
                            headerName: 'PROVIDER ERROR TYPE',
                            fieldName: 'providerErrorType',
                          },
                        ]
                        : []),
                    ]}
                    data={campaignHealthCheckData?.data?.testMessages ?? []}
                  />
                </div>
              )}
              {(campaignErrorData?.data?.errorCodes?.length ?? 0) > 0 && (
                <div className="flex flex-wrap w-full">
                  <h2 className="flex items-center w-full mb-4 text-flamingo">Message Failure Log</h2>
                  <StaticTableList
                    className="w-full"
                    columns={getErrorColumns(false)}
                    data={campaignErrorData?.data?.errorCodes ?? []}
                  />
                </div>
              )}
              {isSuperAdmin && (campaignErrorData?.data?.internalErrorCodes?.length ?? 0) > 0 && (
                <div className="flex flex-wrap w-full">
                  <h2 className="flex items-center w-full mb-4 text-flamingo">Admin Message Failure Log</h2>
                  <StaticTableList
                    className="w-full"
                    columns={getErrorColumns(false)}
                    data={campaignErrorData?.data?.internalErrorCodes ?? []}
                  />
                </div>
              )}
            </div>
          )}
        </div>
        <div className="flex flex-col ml-auto">
          <CampaignMessagePreiveiw campaign={campaign} domain={domain} />
          {campaign?.messageType === MessageTypeEnum.MMS && (
            <div className="flex">
              <div className="pl-8 mx-auto mt-1">
                <a href={campaign?.mediaUrl!} target="_blank" download className="text-wc-blue hover:underline">
                  Download Media File
                </a>
              </div>
            </div>
          )}
          <div className="pl-8 mx-auto mt-2 body-text-small">
            Segments: {campaign?.segmentsCount} | Characters: {campaign?.charactersCount}/1000
          </div>
          {/* PAUSE CAMPAIGN */}
          {!!campaign &&
            [CampaignStatusEnum.LIVE, CampaignStatusEnum.PAUSED, CampaignStatusEnum.SCHEDULING].includes(
              campaign.status
            ) && (
              <div className="pl-8 mx-auto mt-4">
                <Button
                  onClick={() => setShowModal(true)}
                  isLoading={pauseLoading}
                  variant={ButtonVariantEnum.SECONDARY}
                  disabled={!campaign}
                >
                  {isActive && new Date(campaign.endsAt) > new Date() ? 'PAUSE CAMPAIGN' : 'RESUME CAMPAIGN'}
                </Button>
              </div>
            )}
        </div>
      </div>

      <CampaignPauseResumeModal
        isCampaignActive={isActive ?? false}
        showModal={showModal}
        onClose={() => {
          setShowModal(false);
        }}
        onSuccess={() => {
          handleCampaignPause();
          setShowModal(false);
        }}
        onCancel={() => {
          setShowModal(false);
        }}
      />
    </>
  );
};
