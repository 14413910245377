import { ClickerGroup } from '@gr/shared/entities';
import { IHttpResponse } from '@gr/shared/models';
import useAxios, { UseAxiosResult } from 'axios-hooks';

const useClickerGroups = (): UseAxiosResult<IHttpResponse<ClickerGroup[]>> => {
  return useAxios<IHttpResponse<ClickerGroup[]>>({
    url: 'clicker-groups-get',
    method: 'POST',
  });
};

export default useClickerGroups;
