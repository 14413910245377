import { useAuth0 } from '@auth0/auth0-react';
import { Auth0Role } from '@gr/shared/models';
import { Redirect, Route } from 'react-router-dom';
import Roles from '../Roles';
import { PageNotFound } from './PageNotFound';

export interface ProtectedRouteProps {
  component: any;
  roles?: Auth0Role[];
  [x: string]: any;
}

const ProtectedRoute = ({ component: Component, permissions, roles, ...rest }: ProtectedRouteProps) => {
  const { isAuthenticated, isLoading } = useAuth0();

  if (isLoading) {
    return <div>Loading Protected Route ...</div>;
  }
  return (
    <Route
      {...rest}
      render={(props) => {
        if (isAuthenticated && roles) {
          return (
            <Roles roles={roles} fallback={<PageNotFound />}>
              <Component {...props} />
            </Roles>
          );
        }

        return (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location },
            }}
          />
        );
      }}
    />
  );
};

export { ProtectedRoute };

