import { Button, ButtonVariantEnum, Modal } from '@Wonder-Cave/ui';

interface IMarkCompletedPanelProp {
  isCampaignActive: boolean;
  showModal: boolean;
  onClose: () => void;
  onSuccess: () => void;
  onCancel: () => void;
}

const MarkCompletedPanel = ({
  showModal,
  onClose,
  onSuccess,
  onCancel,
}: IMarkCompletedPanelProp) => {
  return (
    <Modal title="Are you sure?" show={showModal} onClose={onClose}>
      <h3 className="text-base text-center font-normal">
        This action will mark all of conversations as complete
      </h3>
      <h3 className="text-base text-center font-normal">
        and will clear out your queue.
      </h3>
      <div className="flex justify-center mt-8 mb-4">
        <Button variant={ButtonVariantEnum.SECONDARY} onClick={onCancel} className="mr-4 uppercase">
          CANCEL
        </Button>
        <Button onClick={onSuccess} variant={ButtonVariantEnum.PRIMARY} className="uppercase">
          MARK ALL AS COMPLETE
        </Button>
      </div>
    </Modal>
  );
};

export default MarkCompletedPanel;

