import { Dispatch, MutableRefObject, SetStateAction, useEffect, useRef, useState } from 'react';

/**
 * Custom hook that allows both a state variable and ref to track the same value.
 * Used when the value in question must be used in contexts that require a ref (to keep the value up-to-date),
 * such as DOM event declarations and timers/callbacks
 */
export const useStateRef = <T>(initialValue: T): [T, Dispatch<SetStateAction<T>>, MutableRefObject<T>] => {
  const [value, setValue] = useState<T>(initialValue);

  const ref = useRef(value);

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return [value, setValue, ref];
};
