import { Button, ButtonVariantEnum, TextInput } from '@Wonder-Cave/ui';
import { FastField, Formik } from 'formik';
import { useState } from 'react';
import { NewFileUpload } from '../shared/Form/NewFileUpload';
import { IRadioButtonOption } from '../shared/Form/RadioButton';
import { Slider } from '../shared/Form/Slider';
import { contactListFormSchema } from './types';

export interface IContactListCreateForm {
  name: string;
  isActive?: boolean;
  contactList?: any;
}


const options: IRadioButtonOption[] = [
  {
    label: 'Disabled',
    value: false,
  },
  {
    label: 'Enabled',
    value: true,
  },

];
const onBack = () => {
  history.back();
};


const ContactListsCreateForm = ({ item, onSubmit, onError }): JSX.Element => {
  const [saveExitLoading, setSaveExitLoading] = useState(false);
  const initialFormState: IContactListCreateForm = {
    name: item?.name ?? '',
    isActive: item?.isActive ?? true,
    contactList: undefined,
  };


  return (
    <>
      <Formik
        initialValues={initialFormState}
        validationSchema={contactListFormSchema}
        onSubmit={async (values) => {
          setSaveExitLoading(true);
          onSubmit && onSubmit(values);
        }}
        onCancel={async () => {
        }}

      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue, setFieldTouched }) => (
          // Set form id so external submit button can still work
          <>
            <div className='px-40'>
              <h1>
                Create Contact List
              </h1>
              <form id="contact-form" onSubmit={handleSubmit}>
                <div className="flex-col space-y-4 my-14">
                  <div className="flex">
                    <div className='w-1/2 text-gray-500'>
                      <TextInput
                        placeholder='Enter Contact List Name'
                        id="name"
                        name="name"
                        label="CONTACT LIST NAME"
                        value={values.name}
                        error={touched.name ? errors.name : ''}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </div>
                    <div className='mt-10 ms-8 font-bold space-x-5 flex-shrink-0"'>
                      <FastField
                        id="isActive"
                        name="isActive"
                        component={Slider}
                        checked={values.isActive}
                        options={options}
                        label="Select Status"
                        error={touched.isActive ? errors.isActive : ''}
                        value={values.isActive}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className='w-1/2'>
                    <NewFileUpload
                      id="contactList"
                      name="contactList"
                      label="UPLOAD CONTACTS"
                      accepts=".csv"
                      value={values.contactList}
                      error={touched.contactList && (errors.contactList as any)}
                      onBlur={handleBlur}
                      placeholder={item?.originalFileName ?? 'Drop your CSV file here or browse file from your computer'}
                      onChange={(fileUploadEvent: any) => {
                        const file = fileUploadEvent.target.files[0];
                        console.log(file);
                        setFieldTouched('contactList');
                        setFieldValue('contactList', file);
                        if (!values.name) {
                          const displayName = file.name.split('.csv')[0].replace(/_/g, ' ');
                          // If no name has been provided, default it to the filename
                          setFieldValue('name', `${displayName.charAt(0).toUpperCase()}${displayName.slice(1)}`);
                        }
                      }}
                    />
                  </div>

                </div>
                <div className="flex justify-end w-full">
                  <Button type="button" variant={ButtonVariantEnum.SECONDARY} onClick={onBack} className="mr-4 uppercase">
                    BACK
                  </Button>
                  <Button type="submit" disabled={onError} variant={ButtonVariantEnum.PRIMARY} className="uppercase">
                    SAVE
                  </Button>
                </div>
              </form>
            </div >
            {/* <ul>
              {values.contactList?.map((val: any) => (
                <li>test</li>
              ))}
            </ul> */}
          </>
        )
        }
      </Formik >
    </>
  );
};



export default ContactListsCreateForm;
