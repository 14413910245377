import { XIcon } from '@heroicons/react/outline';
import {
  ChangeEventHandler,
  FocusEventHandler,
  KeyboardEventHandler,
  MouseEventHandler,
  useEffect,
  useState,
} from 'react';
import { ErrorMessage } from './ErrorMessage';

interface ITextInput {
  id?: string;
  name: string;
  value: any;
  className?: string;
  label?: string;
  type?: string;
  placeholder?: string;
  error?: string;
  optional?: boolean;
  disabled?: boolean;
  clearable?: boolean;
  leadingIcon?: JSX.Element;
  trailingIcon?: JSX.Element;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  onClear?: MouseEventHandler<HTMLButtonElement>;
  onEnter?: KeyboardEventHandler<HTMLInputElement>;
}

const TextInput = ({
  id,
  name,
  value,
  className,
  label,
  type,
  placeholder,
  error,
  optional,
  disabled,
  clearable,
  leadingIcon,
  trailingIcon,
  onChange,
  onBlur,
  onClear,
  onEnter,
}: ITextInput): JSX.Element => {
  const [previousError, setPreviousError] = useState(error);
  const [hasFocus, setHasFocus] = useState(false);

  // Used to fix the animation by not removing the text as we're transitioning out
  useEffect(() => {
    if (error) {
      setPreviousError(error);
    }
  }, [error]);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      onEnter && onEnter(event);
    }
  };

  return (
    <div className={`${className ? `${className} ` : ''} dark:text-slate-400`}>
      {/* Label */}
      <div className="flex justify-between">
        <label htmlFor={id} className="block text-sm font-medium text-gray-700 dark:text-slate-400">
          {label}
        </label>
        {optional && (
          <span className="text-sm text-gray-500 dark:text-slate-400" id="optional">
            Optional
          </span>
        )}
      </div>

      <div className={`mt-1 ${leadingIcon || trailingIcon ? 'relative rounded-md shadow-sm' : ''}`}>
        {/* Leading Icon */}
        {leadingIcon && (
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <div className="w-5 h-5 text-gray-400 dark:text-slate-400" aria-hidden="true">
              {leadingIcon}
            </div>
          </div>
        )}

        {/* Input */}
        <input
          id={id}
          type={type || 'text'}
          name={name}
          disabled={disabled ?? false}
          className={`${className} block w-full border-gray-300 dark:border-slate-800 dark:bg-slate-700 rounded-md shadow-sm disabled:bg-gray-100 dark:disabled:bg-slate-900 focus:ring-sky-500 focus:border-sky-500 sm:text-sm ${
            leadingIcon && 'pl-10'
          } ${(trailingIcon || clearable) && 'pr-10'}`}
          placeholder={placeholder || ''}
          aria-describedby="optional"
          onChange={(ev) => {
            if (type === 'number') {
              // Replace 'e' and leading zeros
              const value = ev.target.value;
              const input = parseInt(value.replace(/e/g, '') || '0', 10);
              ev.target.value = input.toString();
            }
            onChange && onChange(ev);
          }}
          onBlur={(e) => {
            setHasFocus(false);
            onBlur && onBlur(e);
          }}
          onFocus={() => {
            setHasFocus(true);
          }}
          onKeyDown={handleKeyDown}
          value={value ?? ''} // Allow null values to be passed in and simply convert the input value to empty string for display purposes
          autoComplete="off"
        />

        {/* Clear - this takes the place of the trailing icon */}
        {clearable && value && (
          <button
            className={`absolute inset-y-0 right-0 flex items-center px-2 border border-l-0 border-gray-300 dark:border-slate-800 rounded-md rounded-l-none cursor-pointer hover:bg-gray-100 dark:hover:bg-slate-800 ${
              hasFocus && 'ring-sky-500 border-sky-500'
            }`}
            onClick={onClear}
          >
            <div className="w-5 h-5 text-gray-400 ">
              <XIcon />
            </div>
          </button>
        )}

        {/* Trailing Icon */}
        {!clearable && trailingIcon && (
          <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
            <div className="w-5 h-5 text-gray-400" aria-hidden="true">
              {trailingIcon}
            </div>
          </div>
        )}
      </div>

      <ErrorMessage show={!!error} message={previousError} />
    </div>
  );
};

export { TextInput };
