import { DropdownEntityType, FilterDataTypeEnum, ISearchRequest, SearchSortDirectionEnum } from '@gr/shared/models';

export interface IColumn {
  headerName: string;
  fieldName: string;
  fieldType: FilterDataTypeEnum;
  dropdownType?: DropdownEntityType; // Specifies which typeahead dropdown entity to retrieve. Required for typeahead dropdowns.
  isRowHeader?: boolean;
  description?: string;
  sortable?: boolean;
  renderColumn?: (item?: any) => JSX.Element;
  onColumnClick?: (item: any, index?: number) => void;
  //   minWidth?: number;
  //   maxWidth?: number;
  //   hidden?: boolean;
}

export interface IColumnClickConfig {
  [key: string]: (item: any, index?: number) => void;
}

export const defaultTableOptions: ISearchRequest = {
  filters: [],
  pagination: {
    skip: 0,
    take: 25,
  },
  sort: {
    fieldName: 'createdAt',
    sortDirection: SearchSortDirectionEnum.DESC,
  },
};

export interface IBooleanDisplayValues {
  fieldName: string;
  displayValues: IBooleanDisplayValue;
}

export interface IBooleanDisplayValue {
  trueLabel: string;
  falseLabel: string;
}

export interface IEnumException {
  fieldName: string;
  exceptions: string[];
}
