import { Icon } from './Icon';

export const Exclamation = () => (
  <Icon nav={false}>
    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
      <path
        d="M7.5 0C3.35196 0 0 3.35196 0 7.5C0 11.648 3.35196 15 7.5 15C11.648 15 15 11.648 15 7.5C15 3.35196 11.648 0 7.5 0ZM8.63128 2.55587L8.04469 9.09218H6.99721L6.41061 2.55587H8.63128ZM7.5 12.4441C6.82961 12.4441 6.36872 11.9413 6.36872 11.3128C6.36872 10.6844 6.87151 10.1816 7.5419 10.1816C8.21229 10.1816 8.67318 10.6425 8.67318 11.3128C8.67318 11.9832 8.21229 12.4441 7.5 12.4441Z"
        fill="#E76841"
      />
    </svg>
  </Icon>
);
