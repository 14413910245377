import { NotificationType, useNotifications } from '@gr/portal/contexts/NotificationContext';
import { SocketContext } from '@gr/portal/contexts/SocketContext';
import useMyCampaigns from '@gr/portal/hooks/useMyCampaigns';
import { MessageTypeEnum } from '@gr/shared/enums';
import { ICampaignDetails, ICampaignEntity } from '@gr/shared/models';
import format from 'date-fns/format';
import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '../../shared/Buttons/Button';
import { ButtonVariantEnum } from '../../shared/Buttons/types';
import Chip from '../../shared/Chip';
import { Table } from '../../shared/Table/Table';
import CampaignProgressBar from '../CampaignProgressBar';
import { defaultMyCampaignsTableOptions, getColumns } from './types';

const MyCampaigns = () => {
  const history = useHistory();
  const [silentLoad, setSilentLoad] = useState(false);
  const [tableOptions, setTableOptions] = useState(defaultMyCampaignsTableOptions);
  const socketManager = useContext(SocketContext);
  const { addNotification } = useNotifications();

  const [{ data, loading, error }, refetch] = useMyCampaigns();

  const myCampaigns: ICampaignEntity[] = data?.data ?? [];
  const totalCount = myCampaigns.length;

  const handleRefetch = async () => {
    setSilentLoad(true);
    try {
      await refetch();
    } catch (err) {
      console.error(err);
    } finally {
      setSilentLoad(false);
    }
  };
  useEffect(() => {
    (history.location.state as { showEndCampaign: boolean; })?.showEndCampaign
      ? (addNotification({ header: 'Campaign has ended.', type: NotificationType.INFO }),
        history.replace(location.pathname)) // Remove the state. We don't want to show the notification on every refresh.
      : null;

    const unsubscribe = socketManager!.subscribe('campaign-paused-status-changed', handleRefetch);

    return unsubscribe;
  }, []);

  const columns = getColumns(
    {
      name: (item: any) => {
        history.push(`${history.location.pathname}/${item.id}`);
      },
    },
    {
      progress: (item: ICampaignDetails) => <CampaignProgressBar campaign={item} />,
    }
  );

  return (
    <>
      <h2 className="pb-4 dark:text-white">My Campaigns</h2>

      <div className="hidden md:flex">
        <Table
          shimmer
          loading={loading && !silentLoad}
          columns={columns}
          items={myCampaigns}
          count={totalCount}
          paginate
          tableSearchOptions={tableOptions}
        // onSearchOptionChange={() => {
        //   // setTableOptions();
        // }}
        />
      </div>

      {/* TODO: Turn this card into a card variant */}
      <ul role="list" className="flex flex-col space-y-4 md:hidden">
        {myCampaigns.map((campaign) => (
          <li key={campaign.id} className="col-span-1 bg-white divide-y divide-gray-200 rounded-lg shadow">
            <div className="flex items-center justify-between w-full p-6">
              <div className="flex-1 space-y-4 truncate">
                <div className="flex items-center gap-2">
                  <span className="text-sm font-medium text-gray-900 truncate">{campaign.name}</span>
                  <Chip color={campaign.messageType === MessageTypeEnum.SMS ? 'sky' : 'emerald'}>
                    {campaign.messageType}
                  </Chip>
                </div>
                <CampaignProgressBar campaign={campaign} />
                <div className="flex flex-row justify-between mt-1 text-sm text-gray-500">
                  <div className="flex flex-col">
                    <label className="font-semibold">Starts: </label>
                    <label className="font-semibold">Ends: </label>
                  </div>
                  <div className="flex flex-col">
                    <span>{format(new Date(`${campaign.startsAt}`), 'MM/dd, h:mm a')}</span>
                    <span>{format(new Date(`${campaign.endsAt}`), 'MM/dd, h:mm a')}</span>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="flex -mt-px divide-x divide-gray-200">
                <div className="flex flex-1 w-0 -ml-px">
                  <Button
                    className="relative inline-flex items-center justify-center flex-1 w-0 py-4"
                    variant={ButtonVariantEnum.TEXT_DEFAULT}
                    onClick={() => {
                      history.push(`${history.location.pathname}/${campaign.id}`);
                    }}
                  >
                    Execute
                  </Button>
                </div>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </>
  );
};

export default MyCampaigns;
