import { ITemplateCategory, ITemplateResponse } from '@gr/shared/models';
import { getSMSMessageLength, getSMSMessageSegmentCount } from '@gr/shared/utils';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { axiosPost } from '../../authAxios';
import { useNotifications } from '../../contexts/NotificationContext';
import { NewButton } from '../shared/Buttons/NewButton';
import { ButtonVariantEnum } from '../shared/Buttons/types';
import NewAutocomplete, { IDropdownValue } from '../shared/Form/Dropdowns/NewAutocomplete';
import NewTextArea from '../shared/Form/NewTextArea';
import NewTextInput from '../shared/Form/NewTextInput';
import { Close as CloseIcon } from '../shared/Icons/Close';
import LoadingIndicator from '../shared/LoadingIndicator';
import Shimmer, { ShimmerType } from '../shared/Shimmer';

export const ConversationTemplates = () => {
  const params: any = useParams();
  const [titles, setTitles] = useState('');
  const [categories, setCategories] = useState<ITemplateCategory[]>([]);
  const [responses, setResponses] = useState<ITemplateResponse[]>([]);
  const [responsesLoading, setResponsesLoading] = useState(false);
  const [loadingCategories, setLoadingCategories] = useState(false);
  const [createResponseLoading, setCreateResponseLoading] = useState(false);
  const { addNotification } = useNotifications();

  const [deleteLoading, setDeleteLoading] = useState<Array<string>>([]);
  const getCategories = async () => {
    const response = await axiosPost('/two-way-convos-template-categories-get', {
      clientId: params.clientId,
    });
    setCategories(response?.data?.data);
  };
  const getResponses = async () => {
    setResponsesLoading(true);
    try {
      const response = await axiosPost('/two-way-convos-template-responses-get', {
        clientId: params.clientId,
      });
      const responses = response?.data?.data;
      setResponses(responses.length > 0 ? responses : [{}]);
    } catch (e) {
      console.error(e);
    } finally {
      setResponsesLoading(false);
    }
  };
  useEffect(() => {
    getCategories();
    getResponses();
  }, []);
  const createCategory = async () => {
    setLoadingCategories(true);
    try {
      await axiosPost('/two-way-convos-template-categories-create', {
        clientId: params.clientId,
        titles: titles,
      });
      await getCategories();
      setTitles('');
    } catch (e) {
      console.error(e);
    } finally {
      setLoadingCategories(false);
    }
  };
  const deleteCategory = async (id: string) => {
    setDeleteLoading((prev) => {
      const deletes = [...prev];
      deletes.push(id);
      return deletes;
    });
    try {
      await axiosPost('/two-way-convos-template-categories-delete', {
        templateId: id,
      });
      await getCategories();
    } catch (e) {
      console.error(e);
    } finally {
      setDeleteLoading((prev) => {
        const deletes = [...prev];
        deletes.splice(deletes.indexOf(id), 1);
        return deletes;
      });
    }
  };
  const createResponses = async () => {
    setCreateResponseLoading(true);
    try {
      await axiosPost('/two-way-convos-template-responses-create', {
        responses: responses,
      });
      await getResponses();
      addNotification({ content: 'Successfully created/updated response(s)' });
    } catch (e) {
      console.error(e);
    } finally {
      setCreateResponseLoading(false);
    }
  };
  const deleteResponse = async (id: string, index: number) => {
    if (id) {
      // This is a response in the db, call BE
      setDeleteLoading((prev) => {
        const deletes = [...prev];
        deletes.push(id);
        return deletes;
      });
      try {
        await axiosPost('/two-way-convos-template-responses-delete', {
          responseId: id,
        });
        await getResponses();
      } catch (e) {
        console.error(e);
      } finally {
        setDeleteLoading((prev) => {
          const deletes = [...prev];
          deletes.splice(deletes.indexOf(id), 1);
          return deletes;
        });
      }
    } else {
      // This is a recently added response, splice it off the response list
      index > 0 &&
        setResponses((prev) => {
          const deletes = [...prev];
          deletes.splice(index, 1);
          return deletes;
        });
    }
  };
  return (
    <>
      <h1>Conversation Templates</h1>
      <div className="flex flex-col mt-6 space-y-4">
        <h4 className="mb-4">STEP 01: Create template categories</h4>
        <div className="flex items-end">
          <div className="mr-4 basis-1/2">
            <NewTextInput
              name="categoryTitles"
              label="category titles"
              placeholder="e.g. Voting tomorrow, Need help voting, etc"
              value={titles}
              onChange={(e) => setTitles(e.target.value)}
            />
          </div>
          <NewButton
            disabled={titles.length <= 0}
            isLoading={loadingCategories}
            onClick={createCategory}
            variant={ButtonVariantEnum.TERTIARY}
          >
            SUBMIT
          </NewButton>
        </div>
        <div className="body-text-small text-medium-gray">Separate category titles with commas</div>
        <div className="flex flex-wrap">
          {categories.map((cat) => {
            return (
              <div key={cat.id} className="inline-flex items-center px-2.5 py-3 gap-2.5 bg-mint mb-4 mr-4">
                <h5 className="mr-2 text-black">{cat.name}</h5>
                <div onClick={() => deleteCategory(cat.id)} className="cursor-pointer">
                  {deleteLoading.includes(cat.id) ? <LoadingIndicator /> : <CloseIcon />}
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="flex flex-col mt-16 space-y-4">
        <h4 className="mb-4">STEP 02: Create Responses</h4>
        {!responsesLoading ? (
          responses.map((r, index) => (
            <div className="flex flex-wrap p-10 gap-x-4 rounded-2.5xl bg-light-gray shadow-[0_0_70px_0_#E9EBF0]">
              <div className="flex justify-between w-full">
                <h3 className="mb-8 text-black">Response Template {index + 1}</h3>
                {r.categoryId && (
                  <div onClick={() => deleteResponse(r.id, index)} className="cursor-pointer">
                    {deleteLoading.includes(r.id) ? <LoadingIndicator /> : <CloseIcon color={'black'} big />}
                  </div>
                )}
              </div>
              <div className="space-y-4 basis-2/6">
                <NewAutocomplete
                  placeholder="Select category"
                  options={categories.map((c) => ({
                    label: c.name,
                    value: c.id,
                  }))}
                  value={{
                    value: r.categoryId,
                    label: categories.find((c) => c.id === r.categoryId)?.name ?? '',
                  }}
                  label="Template Category"
                  onChange={(newValue: IDropdownValue | undefined) => {
                    setResponses((prevState) => {
                      const responses = [...prevState];
                      responses[index].categoryId = newValue?.value;
                      return responses;
                    });
                  }}
                />
              </div>
              <div className="basis-3/6 grow">
                <NewTextArea
                  label="Enter Message Response"
                  placeholder="Enter your message here..."
                  onChange={(e) => {
                    setResponses((prevState) => {
                      const responses = [...prevState];
                      const response = responses[index];
                      response.message = e.target.value;
                      response.segmentCount = getSMSMessageSegmentCount(e.target.value);
                      response.characterCount = getSMSMessageLength(e.target.value);
                      responses[index] = response;
                      return responses;
                    });
                  }}
                  name={`mes-response-${index}`}
                  value={r.message}
                />
                <div className="flex justify-between mt-2 ml-auto text-sm text-gray-500 dark:text-slate-400">
                  <div className="body-text">Segment count: {getSMSMessageSegmentCount(r.message ?? '')}</div>
                  <div className="body-text">Character count: {getSMSMessageLength(r.message ?? '')}</div>
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className="flex flex-wrap p-10 space-y-4 gap-x-4 rounded-2.5xl bg-light-gray shadow-[0_0_70px_0_#E9EBF0]">
            <div className="w-full">
              <Shimmer type={ShimmerType.HEADER} />
            </div>
            <div className="flex-grow">
              <Shimmer type={ShimmerType.INPUT} />
            </div>
            <div className="flex-grow">
              <Shimmer type={ShimmerType.INPUT} />
            </div>
          </div>
        )}
      </div>
      <div className="mt-4">
        <NewButton
          variant={ButtonVariantEnum.SECONDARY}
          onClick={() =>
            setResponses((prevState) => {
              const responses = [...prevState];
              responses.push({} as ITemplateResponse);
              return responses;
            })
          }
        >
          ADD ANOTHER RESPONSE
        </NewButton>
      </div>
      <div className="flex justify-end my-4 space-x-2">
        <NewButton onClick={() => history.back()} variant={ButtonVariantEnum.SECONDARY}>
          Back
        </NewButton>
        <NewButton
          isLoading={createResponseLoading || responsesLoading || loadingCategories}
          onClick={() => createResponses()}
          variant={ButtonVariantEnum.PRIMARY}
          disabled={
            createResponseLoading ||
            responsesLoading ||
            loadingCategories ||
            responses.length <= 0 ||
            responses.some((r) => !r.categoryId || !r.message)
          }
        >
          Save
        </NewButton>
      </div>
    </>
  );
};
