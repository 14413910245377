import { useAuth0 } from '@auth0/auth0-react';
import { configure } from 'axios-hooks';
import { useEffect, useState } from 'react';
import "react-datetime/css/react-datetime.css";
import { Route, Switch } from 'react-router-dom';
import AppWrapper from './AppWrapper';
import { TokenResponse, configureAxiosWithAuth0 } from './authAxios';
import Login from './components/Login';
import { PageNotFound } from './components/shared/Auth/PageNotFound';
import { initializeWebSocket } from './contexts/SocketContext';
import { auth0Audience, auth0Scope } from './providers/auth0.service';

const App = (): JSX.Element => {
  const [isConfigured, setIsConfigured] = useState(false);
  const {
    isAuthenticated: isLoggedIn,
    getAccessTokenSilently,
    getAccessTokenWithPopup,
    logout: auth0Logout,
    user: auth0User,
  } = useAuth0();

  initializeWebSocket();

  async function getAccessToken() {
    const response = await getAccessTokenSilently({
      detailedResponse: true,
      audience: auth0Audience,
      ignoreCache: false,
      scope: auth0Scope,
    });

    const token: TokenResponse = {
      id_token: response.id_token,
      access_token: response.access_token,
      expires_in: response.expires_in,
      scope: response.scope,
    };

    await configureAxios(token);
  }

  async function authenticateWithConsent() {
    const authToken = await getAccessTokenWithPopup({ audience: auth0Audience, ignoreCache: true, scope: auth0Scope });

    const response: TokenResponse = {
      access_token: authToken,
      expires_in: 1000000,
    };

    await configureAxios(response);
  }

  async function configureAxios(token: TokenResponse) {
    const authAxios = configureAxiosWithAuth0({ token, auth0User, auth0Logout });

    configure({
      axios: authAxios,
      defaultOptions: { useCache: false },
    });

    setIsConfigured(true);
  }
  useEffect(() => {
    const auth = async () => {
      try {
        await getAccessToken();
      } catch (e) {
        console.error(e);
      }
    };
    if (isLoggedIn) {
      auth();
    }
  }, [isLoggedIn]);
  return (
    <Switch>
      <Route path="/app" render={(props) => <AppWrapper configured={isConfigured} />} />
      <Route
        exact
        path="/"
        render={(props) => (
          <Login
            isConfigured={isConfigured}
            authenticate={getAccessToken}
            authenticateWithConsent={authenticateWithConsent}
          />
        )}
      />
      <Route
        exact
        path="/login"
        render={(props) => (
          <Login
            isConfigured={isConfigured}
            authenticate={getAccessToken}
            authenticateWithConsent={authenticateWithConsent}
          />
        )}
      />
      <Route component={PageNotFound} />
    </Switch>
  );
};

export default App;
