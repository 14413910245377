import { BUCKETS, EntityName, OptoutTypeEnum } from '@gr/shared/enums';
import {
  DropdownEntityType,
  FilterDataTypeEnum,
  ISearchRequest,
  ISignedUrlRequest,
  SearchSortDirectionEnum
} from '@gr/shared/models';
import { IFilterDropdownOptions } from '../shared/Table/FilterForm';
import { IColumn } from '../shared/Table/types';

export const defaultOptOutTableOptions: ISearchRequest = {
  entity: EntityName.ALL_OPTOUTS_VIEW,
  filters: [],
  pagination: {
    skip: 0,
    take: 25,
  },
  sort: {
    fieldName: 'createdAt',
    sortDirection: SearchSortDirectionEnum.DESC,
  },
};

// export const columns: IColumn[] = [
export const getColumns = (columnRenders: any): IColumn[] => {
  return [
    {
      headerName: 'Phone Number',
      fieldName: 'phoneNumber',
      isRowHeader: true,
      fieldType: FilterDataTypeEnum.NUMBER,
      renderColumn: columnRenders['phoneNumber'],
    },
    {
      headerName: 'Client',
      fieldName: 'clientId',
      fieldType: FilterDataTypeEnum.TYPEAHEAD,
      dropdownType: DropdownEntityType.ALLCLIENT,
      renderColumn: columnRenders['client'],
    },
    {
      headerName: 'Type',
      fieldName: 'type',
      fieldType: FilterDataTypeEnum.ENUM,
    },
    {
      headerName: 'Campaign',
      fieldName: 'campaignName',
      fieldType: FilterDataTypeEnum.STRING,
    },
    {
      headerName: 'Created Date',
      fieldName: 'createdAt',
      fieldType: FilterDataTypeEnum.DATE,
    },
  ];
};

export const optoutFilterDropdownOptions: IFilterDropdownOptions = {
  type: Object.values(OptoutTypeEnum)
    .filter((val) => !Number.isInteger(val))
    .map((enumValue) => {
      return {
        label: enumValue
          .split('_')
          .map((fragment) => fragment.charAt(0).toLocaleUpperCase() + fragment.slice(1).toLocaleLowerCase())
          .join(' '),
        value: enumValue,
      };
    }),
  clientName: [],
};

export const s3UrlRequest: ISignedUrlRequest = {
  bucket: BUCKETS.OPTOUTS,
  key: '',
  action: 'putObject',
  contentType: 'text/csv',
};